import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {AppLayoutMain, ContentBlock, ContentItem, HeaderBar, useStyles} from '@ace-de/ui-components';
import * as memberSelectors from './memberSelectors';
import MemberHeader from './ui-elements/MemberHeader';
import MemberCaseHistoryPanel from './ui-elements/MemberCaseHistoryPanel';
import MemberInformationPanel from './ui-elements/MemberInformationPanel';
import MemberCreditInformationPanel from './ui-elements/MemberCreditInformationPanel';
import MessagesPanel from '../communication/ui-elements/MessagesPanel';

const MemberScreen = ({member}) => {
    const {cx} = useStyles();
    const {translate} = useTranslate();

    // if no member don't render
    if (!member) return null;

    return (
        <Fragment>
            <HeaderBar className={cx('ace-c-header-bar--primary-navigation')}>
                <MemberHeader member={member} />
            </HeaderBar>
            <AppLayoutMain>
                <ContentBlock>
                    <ContentItem className={cx('ace-c-content-item--span-9')}>
                        <MemberCreditInformationPanel />
                        <Fragment>
                            <MemberCaseHistoryPanel />
                        </Fragment>
                        <MessagesPanel title={translate('member_messages_panel.title.communication')} />
                    </ContentItem>
                    <ContentItem>
                        <MemberInformationPanel />
                    </ContentItem>
                </ContentBlock>
            </AppLayoutMain>
        </Fragment>
    );
};


MemberScreen.propTypes = {
    member: PropTypes.object,
};

MemberScreen.defaultProps = {
    member: null,
};

const mapStateToProps = (state, props) => {
    const memberByMembershipNoSelector = memberSelectors.createMemberByMembershipNoSelector();
    return {
        member: memberByMembershipNoSelector(state, props),
    };
};

export default connect(mapStateToProps, null)(MemberScreen);
