import {take, put, fork, select, delay} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {Task, etmTaskStatusTypes, etmTaskTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as taskActionTypes from '../taskActionTypes';
import taskDataModalTypes from '../modals/taskDataModalTypes';
import errorTypes from '../../application/errorTypes';

const initiateCreateTaskFlow = function* initiateCreateTaskFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');
    let shouldWaitForAction = true;
    let shouldRetryTaskCreation = false;
    let retryTaskCreationData = null;

    while (true) {
        if (shouldWaitForAction) {
            yield take(taskActionTypes.INITIATE_TASK_CREATION_FLOW);
        }

        let chosenModalOption;

        if (!shouldRetryTaskCreation) {
            yield* openModal(modalIds.TASK_DATA_MODAL, {type: taskDataModalTypes.CREATE_TASK});
            chosenModalOption = yield take([
                taskActionTypes.CONFIRM_TASK_CREATION,
                taskActionTypes.DECLINE_TASK_CREATION,
            ]);
        }

        if (shouldRetryTaskCreation || chosenModalOption.type === taskActionTypes.CONFIRM_TASK_CREATION) {
            const taskData = !shouldRetryTaskCreation && chosenModalOption?.payload?.taskData
                ? chosenModalOption?.payload?.taskData : retryTaskCreationData?.taskData;

            yield* openModal(modalIds.LOADER, {type: taskDataModalTypes.CREATE_TASK});

            yield fork(
                fetchRequest,
                taskActionTypes.CREATE_TASK_REQUEST,
                ecsTaskManagementService.createTask,
                {
                    taskDTO: Task.objectToDTO(taskData),
                },
            );

            const responseAction = yield take([
                taskActionTypes.CREATE_TASK_REQUEST_SUCCEEDED,
                taskActionTypes.CREATE_TASK_REQUEST_FAILED,
            ]);

            yield delay(1300);
            yield* closeModal(modalIds.LOADER, {type: taskDataModalTypes.CREATE_TASK});
            yield delay(100);

            if (responseAction.type === taskActionTypes.CREATE_TASK_REQUEST_SUCCEEDED) {
                shouldWaitForAction = true;
                shouldRetryTaskCreation = false;
                yield* closeModal(modalIds.TASK_DATA_MODAL, {type: taskDataModalTypes.CREATE_TASK});

                const searchQueryParams = new URLSearchParams();
                searchQueryParams.append('assignedCaseId', taskData.assignedCaseId);
                searchQueryParams.append('taskType', etmTaskTypes.LEA);
                searchQueryParams.append('status', etmTaskStatusTypes.DUE);
                searchQueryParams.append('status', etmTaskStatusTypes.OVERDUE);

                yield put({
                    type: taskActionTypes.SEARCH_TASKS,
                    payload: {
                        searchQueryParams,
                    },
                });
                continue;
            }

            if (responseAction.error) {
                yield* closeModal(modalIds.TASK_DATA_MODAL, {type: taskDataModalTypes.CREATE_TASK});
                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.TASK_CREATION_FAILED});

                const nextAction = yield take([
                    taskActionTypes.RETRY_TASK_CREATION,
                    taskActionTypes.CANCEL_RETRY_TASK_CREATION,
                ]);

                if (nextAction.type === taskActionTypes.RETRY_TASK_CREATION) {
                    shouldRetryTaskCreation = true;
                    shouldWaitForAction = false;
                    retryTaskCreationData = {taskData};
                }

                if (nextAction.type === taskActionTypes.CANCEL_RETRY_TASK_CREATION) {
                    shouldWaitForAction = true;
                    shouldRetryTaskCreation = false;
                }

                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                continue;
            }
        }

        yield* closeModal(modalIds.TASK_DATA_MODAL, {type: taskDataModalTypes.CREATE_TASK});
    }
};

export default initiateCreateTaskFlow;
