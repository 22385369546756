import React from 'react';
import PropTypes from 'prop-types';
import {camelCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {NotificationBar, useStyles, Icon, warningOutlinedIcon} from '@ace-de/ui-components';
import {alfFailedConstraintVariables} from '@ace-de/eua-entity-types';

const priceRelatedConstraints = [
    alfFailedConstraintVariables.REQUESTED_AMOUNT_LIMIT,
    alfFailedConstraintVariables.TOTAL_AMOUNT_LIMIT,
];

const ManualCheckFailedBanner = props => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const {failedConstraints} = props;

    return (
        <NotificationBar type="negative">
            <div
                className={cx([
                    'ace-u-width--full',
                    'ace-u-margin--16-0',
                    'ace-u-flex',
                    'ace-u-flex--justify-center',
                    'ace-u-typography--variant-body',
                    'global!ace-u-typography--color-contrast',
                ])}
            >
                <Icon
                    className={cx(['ace-c-icon--color-contrast', 'global!ace-u-margin--right-8'])}
                    icon={warningOutlinedIcon}
                />
                {failedConstraints.map((failedConstraint, idx) => {
                    const {type, restrictions} = failedConstraint;
                    const restrictionsMap = {};
                    if (restrictions.length > 0) {
                        restrictions.forEach(restriction => {
                            const {key, value} = restriction;
                            restrictionsMap[camelCase(key)] = priceRelatedConstraints.includes(key)
                                ? Number(value).toFixed(2).replace('.', ',')
                                : value;
                        });
                    }

                    return (
                        <span key={idx}>
                            {translate(`global.failed_constrains.${type.toLowerCase()}`, restrictionsMap)}
                            {idx < (failedConstraints.length - 1) ? '|' : ''}
                        </span>
                    );
                })}
            </div>
        </NotificationBar>
    );
};

ManualCheckFailedBanner.propTypes = {
    failedConstraints: PropTypes.array.isRequired,
};

export default ManualCheckFailedBanner;
