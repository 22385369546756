import {put, take, fork, select} from 'redux-saga/effects';
import {eupUserRoleTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as userProfileActionTypes from '../userProfileActionTypes';

const loadLeaUserProfiles = function* loadLeaUserProfiles() {
    const {serviceManager} = yield select(state => state.application);
    const userProfileService = serviceManager.loadService('userProfileService');

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('userRole', eupUserRoleTypes.AUDITOR);

    yield fork(fetchRequest,
        userProfileActionTypes.FETCH_LEA_USER_PROFILES_REQUEST,
        userProfileService.searchUsers,
        {searchQueryParams});

    const fetchUserProfilesResponseAction = yield take([
        userProfileActionTypes.FETCH_LEA_USER_PROFILES_REQUEST_SUCCEEDED,
        userProfileActionTypes.FETCH_LEA_USER_PROFILES_REQUEST_FAILED,
    ]);

    if (!fetchUserProfilesResponseAction.error) {
        const {response} = fetchUserProfilesResponseAction.payload;
        const {results} = response;

        yield put({
            type: userProfileActionTypes.STORE_LEA_USER_PROFILES,
            payload: {userDTOs: results},
        });
    }
};

export default loadLeaUserProfiles;
