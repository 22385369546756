import {createSelector} from 'reselect';

/**
 * General state selectors
 */
export const getTasks = state => state.tasks.taskSearchResults;

/**
 * Returns selector for Tasks matched from route query params
 */
export const createQueryParamsTaskSelector = () => createSelector([
    getTasks,
    (state, props) => {
        const {location} = props;
        const {taskId} = location?.query;
        return taskId;
    },
], (tasks, taskId) => {
    if (!tasks || !taskId) return null;
    return tasks.find(task => task.id === Number(taskId));
});
