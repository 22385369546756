import {fork, put, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import {push, resolveRoute} from '@computerrock/formation-router';
import {alfInvoiceStatusTypes} from '@ace-de/eua-entity-types';
import * as invoiceActionTypes from '../invoiceActionTypes';
import routePaths from '../../routePaths';

const createInvoiceForAssignmentFlow = function* createInvoiceForAssignmentFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(invoiceActionTypes.CREATE_INVOICE_FOR_ASSIGNMENT);
        const {invoiceId, serviceCaseId} = payload;

        yield fork(
            fetchRequest,
            invoiceActionTypes.CREATE_INVOICE_FOR_ASSIGNMENT_REQUEST,
            leaAssignmentFlowService.updateInvoiceStatus,
            {
                invoiceId,
                invoiceStatusData: {status: alfInvoiceStatusTypes.IN_CREATION},
            },
        );

        const responseAction = yield take([
            invoiceActionTypes.CREATE_INVOICE_FOR_ASSIGNMENT_REQUEST_FAILED,
            invoiceActionTypes.CREATE_INVOICE_FOR_ASSIGNMENT_REQUEST_SUCCEEDED,
        ]);

        if (!responseAction.error) {
            const {payload: responsePayload} = responseAction;
            const {invoiceDTO} = responsePayload.response;
            const invoiceDTOs = [invoiceDTO];

            yield put({
                type: invoiceActionTypes.STORE_INVOICES,
                payload: {invoiceDTOs},
            });

            yield put(push(resolveRoute(routePaths.INVOICES, {
                serviceCaseId,
                invoiceId,
            })));
        }
    }
};

export default createInvoiceForAssignmentFlow;
