/**
 * Command action types
 */

/**
 * Event action types
 */
export const FETCH_USER_PROFILE_REQUEST = '[user-profile] FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_REQUEST_SENT = '[user-profile] FETCH_USER_PROFILE_REQUEST_SENT';
export const FETCH_USER_PROFILE_REQUEST_SUCCEEDED = '[user-profile] FETCH_USER_PROFILE_REQUEST_SUCCEEDED';
export const FETCH_USER_PROFILE_REQUEST_FAILED = '[user-profile] FETCH_USER_PROFILE_REQUEST_FAILED';

export const FETCH_USER_PROFILES_REQUEST = '[user-profile] FETCH_USER_PROFILES_REQUEST';
export const FETCH_USER_PROFILES_REQUEST_SENT = '[user-profile] FETCH_USER_PROFILES_REQUEST_SENT';
export const FETCH_USER_PROFILES_REQUEST_SUCCEEDED = '[user-profile] FETCH_USER_PROFILES_REQUEST_SUCCEEDED';
export const FETCH_USER_PROFILES_REQUEST_FAILED = '[user-profile] FETCH_USER_PROFILES_REQUEST_FAILED';

export const FETCH_LEA_USER_PROFILES_REQUEST = '[user-profile] FETCH_LEA_USER_PROFILES_REQUEST';
export const FETCH_LEA_USER_PROFILES_REQUEST_SENT = '[user-profile] FETCH_LEA_USER_PROFILES_REQUEST_SENT';
export const FETCH_LEA_USER_PROFILES_REQUEST_SUCCEEDED = '[user-profile] FETCH_LEA_USER_PROFILES_REQUEST_SUCCEEDED';
export const FETCH_LEA_USER_PROFILES_REQUEST_FAILED = '[user-profile] FETCH_LEA_USER_PROFILES_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_USER_PROFILE = '[user-profile] STORE_USER_PROFILE';
export const STORE_USER_PROFILES = '[user-profile] STORE_USER_PROFILES';
export const STORE_LEA_USER_PROFILES = '[user-profile] STORE_LEA_USER_PROFILES';
