import {useEffect, useRef} from 'react';

const useBeforeUnloadEffect = eventHandler => {
    if (eventHandler === null || typeof eventHandler !== 'function') {
        throw new Error('useBeforeUnloadEffect: `eventHandler` must be a function.');
    }

    const eventListenerRef = useRef();

    useEffect(() => {
        eventListenerRef.current = event => {
            const returnValue = eventHandler?.(event);
            const {request, requestData = {}, isArrayOfRequests = false} = returnValue;

            if (!event.persisted && typeof request === 'function') {
                // send beacon request
                if (isArrayOfRequests) {
                    requestData.forEach(data => {
                        navigator.sendBeacon = () => request(data);
                        navigator.sendBeacon();
                    });
                }
                if (!isArrayOfRequests) {
                    navigator.sendBeacon = () => request(requestData);
                    navigator.sendBeacon();
                }
            }

            // delay: function must not end until we send unlock request
            for (let i = 0; i < 500000000; i += 1) {
                // eslint-disable-line no-empty
            }
        };
    }, [eventHandler]);

    useEffect(() => {
        const eventListener = event => eventListenerRef.current(event);

        window.addEventListener('beforeunload', eventListener);

        return () => {
            window.removeEventListener('beforeunload', eventListener);
        };
    }, []);
};

export default useBeforeUnloadEffect;
