import {put, take, select, fork} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED, resolveRoute, parseSearchQueryParams} from '@computerrock/formation-router';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as invoiceActionTypes from '../../invoices/invoiceActionTypes';
import updateServiceCase from './updateServiceCase';
import fetchRequest from '../../application/sagas/fetchRequest';
import routePaths from '../../routePaths';

const updateServiceCaseMandatoryInfo = function* updateServiceCaseMandatoryInfo() {
    let updateResponse;

    while (true) {
        const {payload} = yield take(serviceCaseActionTypes.UPDATE_SERVICE_CASE_MANDATORY_DATA);
        const {serviceCaseData, serviceCaseId, hasInvoiceCreation, history} = payload;
        const {location} = history;
        const {isEditModeActive} = parseSearchQueryParams(history.location.search);
        const serviceCase = yield select(state => state.serviceCases.serviceCases[serviceCaseId]);

        const {serviceManager} = yield select(state => state.application);
        const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

        if (serviceCaseData?.damage?.location?.coordinates && serviceCase?.member?.personalDetails?.coordinates) {
            const memberResidenceLocation = serviceCase.member.personalDetails.coordinates;
            const damageLocation = serviceCaseData.damage.location.coordinates;

            yield fork(
                fetchRequest,
                serviceCaseActionTypes.FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST,
                arcGISRESTService.getRoute,
                {
                    startingPoint: {
                        longitude: memberResidenceLocation.longitude,
                        latitude: memberResidenceLocation.latitude,
                    },
                    destination: {
                        longitude: damageLocation.longitude,
                        latitude: damageLocation.latitude,
                    },
                },
            );

            const routeResponseAction = yield take([
                serviceCaseActionTypes.FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SUCCEEDED,
                serviceCaseActionTypes.FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_FAILED,
            ]);

            if (!routeResponseAction.error) {
                const {response} = routeResponseAction.payload;
                const {arcGISRouteDTO} = response;
                serviceCaseData['residenceToDamageLocationDistance'] = arcGISRouteDTO.totalKilometers;
            }
        }

        if (serviceCaseData && !location?.state?.isModalOpen) {
            updateResponse = yield* updateServiceCase({
                caller: serviceCaseActionTypes.UPDATE_SERVICE_CASE_MANDATORY_DATA,
                serviceCaseId,
                serviceCaseData,
            });
        }

        if (hasInvoiceCreation && updateResponse?.type === serviceCaseActionTypes.BATCH_UPDATE_SERVICE_CASE_COMPLETED) {
            yield put({
                type: invoiceActionTypes.CREATE_NEW_INVOICE,
                payload: {serviceCaseId},
            });
        }

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: serviceCaseActionTypes.UPDATE_SERVICE_CASE_MANDATORY_DATA},
        });

        if (isEditModeActive) history.push(resolveRoute(routePaths.SERVICE_CASES_OVERVIEW, {serviceCaseId}));
    }
};

export default updateServiceCaseMandatoryInfo;
