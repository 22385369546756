import applicationReducer from './application/applicationReducer';
import keycloakAuth from './keycloakAuth';
import leaAccessControl from './leaAccessControl';
import userProfileReducer from './user-profiles/userProfileReducer';
import memberReducer from './members/memberReducer';
import arcGISAuth from './arcGISAuth';
import serviceCaseReducer from './service-cases/serviceCaseReducer';
import invoiceReducer from './invoices/invoiceReducer';
import contactReducer from './contacts/contactReducer';
import communicationReducer from './communication/communicationReducer';
import caseLogReducer from './case-logs/caseLogReducer';
import taskReducer from './tasks/taskReducer';
import dashboardReducer from './dashboard/dashboardReducer';
// application reducers
const reducers = {
    application: applicationReducer,
    user: userProfileReducer,
    members: memberReducer,
    serviceCases: serviceCaseReducer,
    invoices: invoiceReducer,
    contacts: contactReducer,
    communication: communicationReducer,
    caseLogs: caseLogReducer,
    tasks: taskReducer,
    dashboard: dashboardReducer,
    ...keycloakAuth.reducer,
    ...leaAccessControl.reducer,
    ...arcGISAuth.reducer,
};

export default reducers;
