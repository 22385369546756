import {fork, select} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import getResourceEndpointKey from '../datafactoryResourceEndpoints';
import * as contactActionTypes from '../contactActionTypes';

const selectContactAddressGeolocation = function* selectContactAddressGeolocation({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const datafactoryService = serviceManager.loadService('datafactoryService');
    const {uuid, key, selectedFields} = payload;
    const isKeyStreet = key === 'street';

    yield fork(
        fetchRequest,
        contactActionTypes.SELECT_CONTACT_ADDRESS_GEOLOCATION,
        isKeyStreet
            ? datafactoryService.selectAddressSuggestion
            : datafactoryService.selectAddressSuggestionByField,
        {
            ...(!isKeyStreet
                ? {resourcePathKey: getResourceEndpointKey(selectedFields, key, 'SELECT')}
                : {}),
            uuid,
        },
    );
};

export default selectContactAddressGeolocation;
