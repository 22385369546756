import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {prepareSearchQueryParams, resolveRoute, withRouter} from '@computerrock/formation-router';
import {Badge, DropDown, DropDownTrigger, Icon, notificationsIcon, useStyles} from '@ace-de/ui-components';
import styles from './NotificationBar.module.scss';
import routePaths from '../../routePaths';
import scOverviewScreenTabs from '../../service-cases/scOverviewScreenTabs';
import config from '../../config';
import * as communicationActionTypes from '../../communication/communicationActionTypes';

const generateMessagePreview = (messageContent, isFullContent) => {
    if (!messageContent) return;
    if (isFullContent || messageContent.length <= config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT) {
        return messageContent.replaceAll(/<[^>]*>/g, ' ').trim();
    }
    let characters = '';
    [...messageContent].forEach((char, index) => {
        if (index <= config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT - 1) characters += char;
    });
    return `${characters.replaceAll(/<[^>]*>/g, ' ').trim()}... `;
};

const NotificationBar = props => {
    const {cx} = useStyles(props, styles);
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('dashboard_screen');
    const {ellaUnreadMessages, history, updateMessageAsRead} = props;

    const handleOnMessageClick = message => {
        if (!message) return;
        updateMessageAsRead({messageIds: [message.id]});
        history.push(resolveRoute(routePaths.SERVICE_CASES_OVERVIEW, {
            serviceCaseId: message.serviceCaseId,
        }, {search: prepareSearchQueryParams({
            activeTab: scOverviewScreenTabs.COMMUNICATION,
            messageId: message.id?.toString(),
        })}));
    };

    return (
        <DropDownTrigger>
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-cursor--pointer',
                ])}
            >
                <Icon icon={notificationsIcon} />
                {translateScreen('notification_bar_title.messages')}
                <Badge
                    className={cx([
                        'global!ace-u-margin--left-8',
                        'ace-c-badge--status-notification', {
                            'ace-c-badge--status-platinum': ellaUnreadMessages.length === 0,
                        },
                    ])}
                >
                    {ellaUnreadMessages.length}
                </Badge>
            </div>
            <DropDown alignment="end">
                <div className={cx('global!ace-u-padding--16-256-16-24')}>
                    <p className={cx(['global!ace-u-typography--variant-caption'])}>
                        {translateScreen('notification_bar_list_title.last_messages')}
                    </p>
                </div>
                <div
                    className={cx([
                        'ace-c-notification-bar__drop-down-list',
                        'global!ace-u-width--full',
                    ])}
                >
                    {ellaUnreadMessages.map((message, index) => (
                        <div
                            key={`${message.serviceCaseId}-${index}`}
                            className={cx([
                                'ace-c-notification-bar__drop-down-option',
                                'global!ace-u-padding--24',
                                'global!ace-u-flex',
                                'global!ace-u-flex--direction-column',
                            ], {
                                'global!ace-u-box-skin': index % 2 === 0,
                            })}
                            onClick={() => handleOnMessageClick(message)}
                        >
                            <p className={cx('global!ace-u-typography--variant-caption-bold')}>
                                {generateMessagePreview(message.content)}
                            </p>
                            <div
                                className={cx([
                                    'global!ace-u-margin--top-8',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-flex--justify-space-between',
                                ])}
                            >
                                <p
                                    className={cx([
                                        'global!ace-u-margin--right-128',
                                        'global!ace-u-typography--variant-caption-bold',
                                        'global!ace-u-typography--color-highlighted',
                                    ])}
                                >
                                    {translateScreen('notification_bar_list_option.service_case_id', {serviceCaseId: message.serviceCaseId})}
                                </p>
                                <p className={cx(['global!ace-u-typography--variant-caption'])}>
                                    {message.createdAt}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </DropDown>
        </DropDownTrigger>
    );
};

NotificationBar.propTypes = {
    ellaUnreadMessages: PropTypes.array,
    history: PropTypes.object.isRequired,
    updateMessageAsRead: PropTypes.func.isRequired,
};

NotificationBar.defaultProps = {
    ellaUnreadMessages: [],
};

const mapStateToProps = state => ({
    ellaUnreadMessages: state.communication.ellaUnreadMessages,
});


const mapDispatchToProps = dispatch => ({
    updateMessageAsRead: payload => dispatch({
        type: communicationActionTypes.UPDATE_MESSAGE_AS_READ,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationBar));
