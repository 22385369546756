import {actionChannel, all, fork, put, select, take} from 'redux-saga/effects';
import {buffers} from 'redux-saga';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {fetchRequest} from '@computerrock/formation-core';
import * as invoiceActionTypes from '../invoiceActionTypes';

const batchUpdateInvoiceServiceLineFlow = function* batchUpdateInvoiceServiceLineFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        let nextBatchUpdate = yield take(invoiceActionTypes.BATCH_UPDATE_INVOICE_SERVICE_LINE);

        const {payload} = nextBatchUpdate;
        const {invoiceId} = payload;

        yield put({
            type: invoiceActionTypes.SET_INVOICE_PERSISTENCE_STATE,
            payload: {invoiceId, persistenceState: persistenceStates.PENDING},
        });

        const expandingBuffer = buffers.expanding(10);
        const batchUpdateChannel = yield actionChannel(
            invoiceActionTypes.BATCH_UPDATE_INVOICE_SERVICE_LINE,
            expandingBuffer,
        );
        const completedBatchUpdateActions = [];

        while (true) {
            const {payload, meta} = nextBatchUpdate;
            const {invoiceId, invoiceServiceLineData, lineNo} = payload;
            let batchUpdateResponseAction = {};

            yield fork(
                fetchRequest,
                invoiceActionTypes.UPDATE_INVOICE_SERVICE_LINES_REQUEST,
                leaAssignmentFlowService.updateInvoiceLines, {
                    invoiceId,
                    lineNo,
                    invoiceLinesData: invoiceServiceLineData,
                },
            );

            batchUpdateResponseAction = yield take([
                invoiceActionTypes.UPDATE_INVOICE_SERVICE_LINES_REQUEST_SUCCEEDED,
                invoiceActionTypes.UPDATE_INVOICE_SERVICE_LINES_REQUEST_FAILED,
            ]);

            const {payload: responsePayload, error: responseError} = batchUpdateResponseAction;

            completedBatchUpdateActions.push({
                type: invoiceActionTypes.BATCH_UPDATE_INVOICE_SERVICE_LINE_COMPLETED,
                payload: responsePayload,
                error: responseError,
                meta,
            });

            // exit if no more update requests, or continue processing
            if (expandingBuffer.isEmpty()) break;
            nextBatchUpdate = yield take(batchUpdateChannel);
        }

        yield fork(
            fetchRequest,
            invoiceActionTypes.FETCH_INVOICE_REQUEST,
            leaAssignmentFlowService.getInvoice,
            {invoiceId},
        );

        const fetchInvoiceResponseAction = yield take([
            invoiceActionTypes.FETCH_INVOICE_REQUEST_SUCCEEDED,
            invoiceActionTypes.FETCH_INVOICE_REQUEST_FAILED,
        ]);

        if (!fetchInvoiceResponseAction.error) {
            const {response} = fetchInvoiceResponseAction.payload;
            const {invoiceDTO} = response;

            yield put({
                type: invoiceActionTypes.STORE_INVOICES,
                payload: {invoiceDTOs: [invoiceDTO]},
            });
        }

        yield put({
            type: invoiceActionTypes.SET_INVOICE_PERSISTENCE_STATE,
            payload: {invoiceId, persistenceState: persistenceStates.READY},
        });

        // signal completed batch updates
        yield all(completedBatchUpdateActions
            .map(batchUpdateAction => put(batchUpdateAction)));
    }
};

export default batchUpdateInvoiceServiceLineFlow;
