import {take, put, select, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contactActionTypes from '../contactActionTypes';

const fetchAccountParty = function* fetchAccountParty() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take([contactActionTypes.FETCH_ACCOUNT_PARTY]);
        const {accountPartyId, accountPartyType, client} = payload;

        if (accountPartyId && accountPartyType && client) {
            yield fork(
                fetchRequest,
                contactActionTypes.FETCH_ACCOUNT_PARTY_REQUEST,
                leaAssignmentFlowService.getAccountParty,
                {
                    accountPartyId,
                    accountPartyType,
                    client,
                },
            );

            const responseAction = yield take([
                contactActionTypes.FETCH_ACCOUNT_PARTY_REQUEST_SUCCEEDED,
                contactActionTypes.FETCH_ACCOUNT_PARTY_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {accountPartyDTO} = response;

                yield put({
                    type: contactActionTypes.STORE_ACCOUNT_PARTY,
                    payload: {accountPartyDTO},
                });
            }
        }
    }
};

export default fetchAccountParty;
