import {createSelector} from 'reselect';

/**
 * General state selectors
 */
export const getContacts = state => state.contacts.contacts;
export const getAccountParty = state => state.contacts.accountParty;

/**
 * Returns selector for LeistContact matched from route
 */
export const createContactSelector = () => createSelector([
    getContacts,
    (state, props) => {
        const {location} = props;
        const queryParams = new URLSearchParams(location?.search || '');
        return queryParams.get('contactId') || null;
    },
], (contacts, contactId) => {
    if (!contacts?.length || !contactId) return null;
    return contacts.find(contact => contact.id === contactId);
});

