import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {renderRoutes} from '@computerrock/formation-router';
import {AppLayoutMain, NavigationBar} from '@ace-de/ui-components/app-layout';
import NavigationBarContent from './ui-elements/NavigationBarContent';

const DashboardSectionScreen = ({route}) => {
    return (
        <Fragment>
            <NavigationBar>
                <NavigationBarContent />
            </NavigationBar>
            <AppLayoutMain>
                {renderRoutes(route.routes)}
            </AppLayoutMain>
        </Fragment>
    );
};

DashboardSectionScreen.propTypes = {
    route: PropTypes.object.isRequired,
};

export default DashboardSectionScreen;
