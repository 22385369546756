import {alfInvoiceStatusTypes, alfInvoiceSubStatusTypes} from '@ace-de/eua-entity-types';

const invoiceStatusPillColorMap = {
    [alfInvoiceStatusTypes.IN_CREATION]: 'information',
    [alfInvoiceStatusTypes.OPEN]: 'information',
    [alfInvoiceStatusTypes.CLOSED]: 'information',
    [alfInvoiceStatusTypes.DECLINED]: 'information',
    [alfInvoiceStatusTypes.APPROVED]: 'positive',
    [alfInvoiceStatusTypes.MANUAL_CHECK]: 'negative',
    [alfInvoiceStatusTypes.BC_ERROR]: 'negative',
    [alfInvoiceStatusTypes.BOOKED]: 'positive',
    [alfInvoiceSubStatusTypes.ELLA_INQUIRY]: 'negative',
};
export default invoiceStatusPillColorMap;
