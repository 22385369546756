import {take, call, select, delay} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {euaFeatureTypes, euaFeatureEntityTypes} from '@ace-de/eua-arcgis-feature-types';
import * as invoiceActionTypes from '../invoiceActionTypes';
import modalIds from '../../modalIds';
import config from '../../config';

const initiateExpandMapFlow = function* initiateExpandMapFlow() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    while (true) {
        yield take([invoiceActionTypes.INITIATE_EXPAND_MAP_FLOW]);

        yield* openModal(modalIds.EXPANDED_MAP_MODAL);
        yield delay(600);
        const arcGISMap = yield call(arcGISMapService.getMap, 'invoice-relevant-locations-map-expand');
        if (!arcGISMap?.isLayerSet('invoice-relevant-locations-service-areas-expanded')) {
            arcGISMap.createServiceLayer({
                layerId: 'invoice-relevant-locations-service-areas-expanded',
                serviceURL: config.ARCGIS_CONTRACT_PARTNER_SERVICE_AREAS_LAYER_URL,
                featureEntityType: euaFeatureEntityTypes.CONTRACT_PARTNER_SERVICE_AREA,
                featureType: euaFeatureTypes.SERVICE_AREA,
                isVisible: false,
                areFeaturesSelectableOnClick: false,
                colorScheme: {
                    color: [0, 130, 185, 0.30],
                    outlineColor: '#004A52',
                },
            });
        }
        if (!arcGISMap?.isLayerSet('invoice-relevant-routes')) {
            arcGISMap.createLayer({
                layerId: 'invoice-relevant-routes',
                featureEntityType: euaFeatureEntityTypes.TOWING_DESTINATION_ROUTE,
                featureType: euaFeatureTypes.LOCATION_ROUTE,
                isVisible: false,
            });
        }

        yield take([invoiceActionTypes.DECLINE_EXPAND_MAP_FLOW]);
        yield* closeModal(modalIds.EXPANDED_MAP_MODAL);
    }
};

export default initiateExpandMapFlow;
