import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useStyles, Panel, ScrollableBlock, ActionMenu, ActionMenuOption} from '@ace-de/ui-components';
import {DropDownTrigger, withDropDownProvider} from '@ace-de/ui-components/overlays';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {InputCurrency, AutocompleteField, Option} from '@ace-de/ui-components/form';
import {ButtonPrimary} from '@ace-de/ui-components/buttons';
import {Icon, InteractiveIcon, plusIcon, moreIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import * as invoiceActionTypes from '../invoiceActionTypes';
import * as invoiceSelectors from '../invoiceSelectors';
import config from '../../config';
import {serviceIcons} from './serviceIcons';

const actionOptions = {
    EDIT: 'EDIT',
    DELETE: 'DELETE',
};

const ServiceOverviewPanel = props => {
    const {cx} = useStyles(props);
    const {initiateCreateInvoiceLineFlow, invoice, createInvoiceLine, services} = props;
    const {invoiceData, isBaseFormTouched, deleteInvoiceLine, editInvoiceLine} = props;
    const {createTranslateShorthand, translate, activeLocale} = useTranslate();
    const translatePanel = createTranslateShorthand('service_overview_panel');
    const [serviceSearchTerm, setServiceSearchTerm] = useState(null);
    const [service, setService] = useState(null);
    const [requestedAmount, setRequestedAmount] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [doesServiceExist, setDoesServiceExist] = useState(false);

    const getPrice = ({price, currency}) => {
        if (typeof price === 'number' && price >= 0) {
            return price.toLocaleString(activeLocale, {
                style: 'currency',
                currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
    };

    const findServiceByType = (services, serviceType) => {
        if (!serviceType) return;
        return [...services].find(({type}) => type === serviceType);
    };

    const handleOnOptionSelect = (action, invoiceLine) => {
        if (!invoiceLine) return;

        if (action === actionOptions.DELETE) {
            deleteInvoiceLine({
                invoiceId: invoice.id,
                lineNo: invoiceLine.lineNo,
            });
        }

        if (action === actionOptions.EDIT) {
            editInvoiceLine({
                lineNo: invoiceLine.lineNo,
            });
        }
    };

    const handleOnChange = value => {
        if (!value) setService(null);
        setServiceSearchTerm(value);
        const service = services.find(service => service.code === value);
        if (service) setService(service.type);
        setDoesServiceExist(!!service);
        (!service && value)
            ? setErrorMessage(translatePanel('error_message.unknown_code'))
            : setErrorMessage('');
    };

    const addNewInvoiceLine = () => {
        createInvoiceLine({
            invoiceId: invoice.id,
            invoiceLineData: {
                service,
                requestedAmount,
            },
            invoiceData,
            shouldUpdateInvoiceData: isBaseFormTouched,
            triggerINACheck: false,
        });
        setService(null);
        setServiceSearchTerm(null);
        setRequestedAmount('');
        setDoesServiceExist(false);
    };

    const sortedServices = services ? [...services]
        .sort((serviceA, serviceB) => {
            return serviceA.code - serviceB.code;
        }) : [];

    if (!invoice || !services) return null;

    return (
        <Panel
            title={translatePanel('title.service_overview')}
            className={cx('global!ace-u-height--750', 'ace-c-panel--full-bleed-content')}
            actions={(
                <ButtonPrimary onClick={initiateCreateInvoiceLineFlow}>
                    <Icon
                        icon={plusIcon}
                        className={cx([
                            'global!ace-u-margin--right-16',
                            'ace-c-icon--color-contrast',
                        ])}
                    />
                    {translatePanel('button_label.service')}
                </ButtonPrimary>
            )}
        >
            <ScrollableBlock isLabelDisabled={true} className={cx('ace-c-scrollable-block--full-bleed')}>
                <div
                    onKeyDown={event => {
                        if (event.key === 'Enter' && requestedAmount && service) {
                            addNewInvoiceLine();
                            setService('');
                            setRequestedAmount('');
                        }
                    }}
                >
                    <Table qaIdent="service-overview-panel">
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={7} qaIdentPart="service-code">
                                    <div className={cx('global!ace-u-grid')}>
                                        <AutocompleteField
                                            name="serviceAutocompleteField"
                                            className={cx('global!ace-u-grid-column--span-4', 'ace-c-autocomplete--small')}
                                            value={serviceSearchTerm || ''}
                                            onChange={handleOnChange}
                                            errors={errorMessage ? [errorMessage] : []}
                                        >
                                            {sortedServices.length > 0 && sortedServices.map(service => {
                                                return (
                                                    <Option
                                                        key={service.code}
                                                        name={`service-${service.code}`}
                                                        value={service.code}
                                                    >
                                                        {`${service.code} ${translate(`global.service_type.${service.type.toLowerCase()}`)}`}
                                                    </Option>
                                                );
                                            })}
                                        </AutocompleteField>
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-typography--align-center',
                                                'global!ace-u-flex--justify-flex-start',
                                                'global!ace-u-grid-column--span-8',
                                                {
                                                    'global!ace-u-typography--color-medium-emphasis': !doesServiceExist,
                                                    'global!ace-u-margin--bottom-24': errorMessage,
                                                },
                                            ])}
                                        >
                                            {doesServiceExist ? (
                                                <Fragment>
                                                    {serviceIcons[service]?.icon && (
                                                        <Icon
                                                            icon={serviceIcons[service].icon}
                                                            className={cx('global!ace-u-margin--right-8', 'global!ace-u-flex--shrink-0')}
                                                        />
                                                    )}
                                                    {translate(`global.service_type.${service.toLowerCase()}`)}
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    <Icon
                                                        icon={plusIcon}
                                                        className={cx([
                                                            'global!ace-u-margin--right-8',
                                                            'ace-c-icon--color-disabled',
                                                        ])}
                                                    />
                                                    {translatePanel('text_placeholder.service')}
                                                </Fragment>
                                            )}
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell colSpan={3} qaIdentPart="service-requested-amount">
                                    <div className={cx('global!ace-u-grid')}>
                                        <InputCurrency
                                            name="requestedAmountInput"
                                            className={cx([
                                                'global!ace-u-grid-column--span-6',
                                                'ace-c-input-currency--small',
                                                {'global!ace-u-margin--bottom-8': errorMessage},
                                            ])}
                                            value={requestedAmount || ''}
                                            onChange={value => setRequestedAmount(value)}
                                            inputClassName={cx([
                                                'global!ace-u-width--128',
                                                'global!ace-u-typography--align-right',
                                            ])}
                                            isDisabled={!service || !!errorMessage}
                                            placeholder={translatePanel('input_placeholder.price')}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="service-add-service">
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-typography--align-center',
                                            'global!ace-u-flex--justify-content-center',
                                        ])}
                                    >
                                        <InteractiveIcon
                                            icon={plusIcon}
                                            isDisabled={!service || !requestedAmount}
                                            className={cx([
                                                'global!ace-u-cursor--pointer',
                                                'ace-c-interactive-icon--primary',
                                                {'global!ace-u-margin--bottom-24': errorMessage},
                                            ])}
                                            name="add-service"
                                            onClick={addNewInvoiceLine}
                                        />
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                <Table className={cx('global!ace-u-padding--24')}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={7} qaIdentPart="service-type">
                                {translatePanel('data_row_label.service')}
                            </TableCell>
                            <TableCell
                                colSpan={3}
                                className={cx('global!ace-u-typography--align-right')}
                                qaIdentPart="service-requested-amount"
                            >
                                {translatePanel('data_row_label.requested')}
                            </TableCell>
                            <TableCell
                                colSpan={2}
                                className={cx('global!ace-u-typography--align-center')}
                                qaIdentPart="service-action-menu"
                            >
                                {translatePanel('data_row_label.action')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoice.lines?.length > 0 && Object.values(invoice.lines).map((invoiceLine, idx) => {
                            const service = findServiceByType(services, invoiceLine.service);

                            return (
                                <TableRow key={idx} qaIdentPart={idx}>
                                    <TableCell colSpan={8} qaIdentPart="service-type" qaIdentPartPostfix={idx}>
                                        {service ? (
                                            <div
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--align-center',
                                                ])}
                                            >
                                                {service?.code}
                                                {serviceIcons[service.type]?.icon && (
                                                    <Icon
                                                        icon={serviceIcons[service.type].icon}
                                                        className={cx('global!ace-u-margin--0-8-0-32', 'global!ace-u-flex--shrink-0')}
                                                    />
                                                )}
                                                {translate(`global.service_type.${service.type.toLowerCase()}`)}
                                            </div>
                                        ) : '-'}
                                    </TableCell>
                                    <TableCell
                                        colSpan={2}
                                        className={cx('global!ace-u-typography--align-right')}
                                        qaIdentPart="service-requested-amount"
                                        qaIdentPartPostfix={idx}
                                    >
                                        {getPrice({price: invoiceLine.requestedAmount, currency: config.CURRENCY}) || '-'}
                                    </TableCell>
                                    <TableCell
                                        colSpan={2}
                                        className={cx('global!ace-u-typography--align-center')}
                                        qaIdentPart="service-action-menu"
                                        qaIdentPartPostfix={idx}
                                    >
                                        <DropDownTrigger>
                                            <div>
                                                <InteractiveIcon
                                                    icon={moreIcon}
                                                    className={cx('ace-c-interactive-icon--primary')}
                                                />
                                            </div>
                                            <ActionMenu alignment="end">
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--direction-column',
                                                    ])}
                                                >
                                                    {Object.values(actionOptions).map(action => (
                                                        <div key={action}>
                                                            <ActionMenuOption
                                                                onClick={() => handleOnOptionSelect(
                                                                    action,
                                                                    invoiceLine,
                                                                )}
                                                            >
                                                                {translatePanel(`dropdown_option_label.${action.toLowerCase()}`)}
                                                            </ActionMenuOption>
                                                        </div>
                                                    ))}
                                                </div>
                                            </ActionMenu>
                                        </DropDownTrigger>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </ScrollableBlock>
        </Panel>
    );
};

ServiceOverviewPanel.propTypes = {
    invoice: PropTypes.object,
    services: PropTypes.array,
    initiateCreateInvoiceLineFlow: PropTypes.func.isRequired,
    createInvoiceLine: PropTypes.func.isRequired,
    isBaseFormTouched: PropTypes.bool,
    deleteInvoiceLine: PropTypes.func.isRequired,
    editInvoiceLine: PropTypes.func.isRequired,
    invoiceData: PropTypes.object,
};

ServiceOverviewPanel.defaultProps = {
    invoice: null,
    services: [],
    isBaseFormTouched: false,
    invoiceData: null,
};

const mapStateToProps = (state, props) => {
    const getInvoice = invoiceSelectors.createInvoicesSelector();
    return {
        invoice: getInvoice(state, props),
        services: invoiceSelectors.getServices(state),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateCreateInvoiceLineFlow: payload => dispatch({
        type: invoiceActionTypes.INITIATE_CREATE_INVOICE_LINE_FLOW,
        payload,
    }),
    createInvoiceLine: payload => dispatch({
        type: invoiceActionTypes.CREATE_INVOICE_LINE,
        payload,
    }),
    deleteInvoiceLine: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_LINE_DELETE_FLOW,
        payload,
    }),
    editInvoiceLine: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_LINE_EDIT_FLOW,
        payload,
    }),
    submitInvoiceBasicDataForm: payload => dispatch({
        type: invoiceActionTypes.SUBMIT_INVOICE_BASIC_DATA_FORM,
        payload,
    }),
});

export default withDropDownProvider(withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceOverviewPanel)));
