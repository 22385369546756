/**
 * Command action types
 */
export const FILTER_CASE_LOGS = '[case-logs] FILTER_CASE_LOGS';
export const SUBMIT_DOCUMENT_DOWNLOAD_FLOW = '[case-logs] SUBMIT_DOCUMENT_DOWNLOAD_FLOW';
export const SUBMIT_EXPORT_CASE_LOG_PDF = '[case-logs] SUBMIT_EXPORT_CASE_LOG_PDF';

/**
 * Event action types
 */
export const FILTER_CASE_LOGS_REQUEST = '[case-logs] FILTER_CASE_LOGS_REQUEST';
export const FILTER_CASE_LOGS_REQUEST_SENT = '[case-logs] FILTER_CASE_LOGS_REQUEST_SENT';
export const FILTER_CASE_LOGS_REQUEST_SUCCEEDED = '[case-logs] FILTER_CASE_LOGS_REQUEST_SUCCEEDED';
export const FILTER_CASE_LOGS_REQUEST_FAILED = '[case-logs] FILTER_CASE_LOGS_REQUEST_FAILED';

export const DOWNLOAD_DOCUMENT_REQUEST = '[case-logs] DOWNLOAD_DOCUMENT_REQUEST';
export const DOWNLOAD_DOCUMENT_REQUEST_SENT = '[case-logs] DOWNLOAD_DOCUMENT_REQUEST_SENT';
export const DOWNLOAD_DOCUMENT_REQUEST_SUCCEEDED = '[case-logs] DOWNLOAD_DOCUMENT_REQUEST_SUCCEEDED';
export const DOWNLOAD_DOCUMENT_REQUEST_FAILED = '[case-logs] DOWNLOAD_DOCUMENT_REQUEST_FAILED';

export const EXPORT_CASE_LOG_PDF_REQUEST = '[case-logs] EXPORT_CASE_LOG_PDF_REQUEST';
export const EXPORT_CASE_LOG_PDF_REQUEST_SENT = '[case-logs] EXPORT_CASE_LOG_PDF_REQUEST_SENT';
export const EXPORT_CASE_LOG_PDF_REQUEST_SUCCEEDED = '[case-logs] EXPORT_CASE_LOG_PDF_REQUEST_SUCCEEDED';
export const EXPORT_CASE_LOG_PDF_REQUEST_FAILED = '[case-logs] EXPORT_CASE_LOG_PDF_REQUEST_FAILED';

export const FETCH_CASE_LOG_SERVICES_REQUEST = '[case-logs] FETCH_CASE_LOG_SERVICES_REQUEST';
export const FETCH_CASE_LOG_SERVICES_REQUEST_SENT = '[case-logs] FETCH_CASE_LOG_SERVICES_REQUEST_SENT';
export const FETCH_CASE_LOG_SERVICES_REQUEST_SUCCEEDED = '[case-logs] FETCH_CASE_LOG_SERVICES_REQUEST_SUCCEEDED';
export const FETCH_CASE_LOG_SERVICES_REQUEST_FAILED = '[case-logs] FETCH_CASE_LOG_SERVICES_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_CASE_LOGS = '[case-logs] STORE_CASE_LOGS';
export const STORE_CASE_LOG_SERVICES = '[case-logs] STORE_CASE_LOG_SERVICES';
