import {put, select, take, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceActionTypes from '../invoiceActionTypes';
import * as invoiceSelectors from '../invoiceSelectors';

const loadServices = function* loadServices() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    const services = yield select(invoiceSelectors.getServices);

    if (services.length !== 0) return;

    yield fork(
        fetchRequest,
        invoiceActionTypes.FETCH_SERVICES_REQUEST,
        leaAssignmentFlowService.getServices,
    );

    const responseAction = yield take([
        invoiceActionTypes.FETCH_SERVICES_REQUEST_SUCCEEDED,
        invoiceActionTypes.FETCH_SERVICES_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {serviceDTOs} = response;

        yield put({
            type: invoiceActionTypes.STORE_SERVICES,
            payload: {serviceDTOs: serviceDTOs},
        });
    }
};

export default loadServices;
