import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Paginator, Panel, useStyles, NoResultsBlock} from '@ace-de/ui-components';
import {downloadIcon, Icon, InteractiveIcon, outgoingMessageIcon, incomingMessageIcon, historyIcon} from '@ace-de/ui-components/icons';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {alfMessageChannelTypes, alfMessageTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import * as communicationActionTypes from '../communicationActionTypes';
import * as memberSelectors from '../../members/memberSelectors';
import config from '../../config';
import serviceManager from '../../serviceManager';
import useBeforeUnloadEffect from '../../useBeforeUnloadEffect';

const generateMessagePreview = (messageContent, isFullContent) => {
    if (!messageContent) return;
    if (isFullContent || messageContent.length <= config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT) {
        return messageContent.replaceAll(/<[^>]*>/g, ' ').trim();
    }
    let characters = '';
    [...messageContent].forEach((char, index) => {
        if (index <= config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT - 1) characters += char;
    });
    return `${characters.replaceAll(/<[^>]*>/g, ' ').trim()}... `;
};

const MessagesPanel = props => {
    const {cx} = useStyles();
    const {hasPagination, title, actions, member} = props;
    const {initiateDownloadAllDocuments, updateMessageAsRead, initiateRedirectToInvoicePage, searchMessages} = props;
    const {messagesSearchTotalCount, messagesSearchResultsPage, messagesSearchResults} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateComponent = createTranslateShorthand('messages_panel');
    const [messages, setMessages] = useState(messagesSearchResults || []);
    const [readMessageIds, setReadMessageIds] = useState([]);
    const [expandedMessageIds, setExpandedMessageIds] = useState([]);
    const shouldMarkMessageAsRead = useRef(false);

    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    const markMessageAsRead = message => {
        if (!message) return;
        if (!message.isRead && !readMessageIds.includes(message.id)) {
            shouldMarkMessageAsRead.current = true;
            setReadMessageIds(prevState => ([
                ...prevState,
                message.id,
            ]));
        }
    };

    const handleOnMessageContentExpansion = (messageId, event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const newExtendedMessageIdsArray = !expandedMessageIds.includes(messageId)
            ? [...expandedMessageIds, messageId]
            : [...expandedMessageIds].filter(id => messageId !== id);

        setExpandedMessageIds([...newExtendedMessageIdsArray]);

        const openedMessage = messagesSearchResults.find(message => message.id === Number(messageId));
        if (!openedMessage) return;

        if (!openedMessage.isRead) {
            setReadMessageIds(prevState => ([
                ...(prevState.filter(id => id !== openedMessage.id)),
                openedMessage.id,
            ]));
            shouldMarkMessageAsRead.current = true;
        }

        setMessages(prevState => {
            return prevState.map(message => {
                if (message.id === Number(messageId)) return {
                    ...message,
                    messageContentPreview: generateMessagePreview(openedMessage.content, true),
                };
                return message;
            });
        });
    };

    useEffect(() => {
        setMessages(messagesSearchResults);
    }, [messagesSearchResults, setMessages]);

    useEffect(() => {
        if (readMessageIds?.length === 0 || !shouldMarkMessageAsRead.current) return;
        updateMessageAsRead({messageIds: readMessageIds});
        shouldMarkMessageAsRead.current = true;
    }, [readMessageIds, updateMessageAsRead]);

    const redirectToInvoiceDetails = invoiceId => {
        initiateRedirectToInvoicePage({invoiceId});
    };

    useBeforeUnloadEffect(event => {
        if (readMessageIds?.length === 0 || shouldMarkMessageAsRead.current) return;
        return {
            request: leaAssignmentFlowService.updateMessagesAsRead,
            requestData: {
                messageIds: readMessageIds,
            },
        };
    });

    const handleOnPaginationChange = page => {
        if (readMessageIds?.length > 0 && shouldMarkMessageAsRead.current) {
            updateMessageAsRead({messageIds: readMessageIds});
        }
        searchMessages({
            page,
            ...(member ? {contactId: member.membershipNo} : {}),
            size: config.DEFAULT_PAGE_SIZE,
        });
    };

    return (
        <Panel
            title={title}
            actions={actions || null}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <Table qaIdent="messages-overview-table">
                {messages.length === 0 && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={historyIcon}
                                />
                            )}
                            description={translateComponent('no_results.description')}
                            message={translateComponent('no_results.message')}
                        />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="message-type" colSpan={2} />
                        <TableCell qaIdentPart="created-date" colSpan={3}>
                            {translateComponent('table_header.created_date')}
                        </TableCell>
                        <TableCell qaIdentPart="invoice-id" colSpan={2}>
                            {translateComponent('table_header.invoice_id')}
                        </TableCell>
                        <TableCell qaIdentPart="channel" colSpan={3}>
                            {translateComponent('table_header.communication_channel')}
                        </TableCell>
                        <TableCell qaIdentPart="message-subject" colSpan={9}>
                            {translateComponent('table_header.subject')}
                        </TableCell>
                        <TableCell qaIdentPart="contact-name" colSpan={4}>
                            {translateComponent('table_header.contact_name')}
                        </TableCell>
                        <TableCell qaIdentPart="user" colSpan={3}>
                            {translateComponent('table_header.user')}
                        </TableCell>
                        <TableCell qaIdentPart="attachment" colSpan={2}>
                            {translateComponent('table_header.attachment')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {messages?.length > 0 && messages.map(message => {
                        return (
                            <TableRow
                                key={`${message.serviceCaseId}-${message.id}`}
                                qaIdentPart={message.serviceCaseId}
                            >
                                <TableCell
                                    qaIdentPart="message-type"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={2}
                                >
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-flex--justify-content-center',
                                        ])}
                                    >
                                        <Icon
                                            icon={message.messageType === alfMessageTypes.OUTGOING
                                                ? outgoingMessageIcon
                                                : incomingMessageIcon}
                                            className={cx('global!ace-u-flex--shrink-0', {
                                                'ace-c-icon--color-success': message.messageType === alfMessageTypes.INCOMING,
                                                'ace-c-icon--color-warning-dark': message.messageType === alfMessageTypes.OUTGOING,
                                            })}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell
                                    qaIdentPart="created-date"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={3}
                                >
                                    {message.createdAt || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="invoice-id"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={2}
                                >
                                    <div
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                            {
                                                'global!ace-u-typography--color-highlighted': message.invoiceId,
                                                'global!ace-u-cursor--pointer': message.invoiceId,
                                            },
                                        ])}
                                        onClick={() => redirectToInvoiceDetails(message.invoiceId)}
                                    >
                                        {message.invoiceId || '-'}
                                    </div>
                                </TableCell>
                                <TableCell
                                    qaIdentPart="channel"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={3}
                                >
                                    {translate(`global.communication_channel_option.${message.channel.toLowerCase()}`) || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="message-subject"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={9}
                                    className={cx([
                                        'ace-c-table__cell--overflow-visible',
                                        'ace-c-table__cell--white-space-normal',
                                    ])}
                                >
                                    {message.channel === alfMessageChannelTypes.ELLA
                                    && message.messageType === alfMessageTypes.INCOMING
                                        ? (
                                            <div>
                                                {message.messageContentPreview}
                                                {message.content?.length > config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT
                                                    ? (
                                                        <span
                                                            className={cx([
                                                                'global!ace-u-typography--variant-body-bold',
                                                                'global!ace-u-typography--color-highlighted',
                                                                'global!ace-u-cursor--pointer',
                                                            ])}
                                                            onClick={event => handleOnMessageContentExpansion(
                                                                message.id,
                                                                event,
                                                            )}
                                                        >
                                                            [{expandedMessageIds.includes(message.id)
                                                            ? translateComponent('table_body.read_less')
                                                            : translateComponent('table_body.read_more')}]
                                                        </span>
                                                    ) : !message.isRead
                                                        && message.messageType === alfMessageTypes.INCOMING && (
                                                            <span
                                                                className={cx([
                                                                    'global!ace-u-typography--variant-body-bold',
                                                                    'global!ace-u-margin--left-4',
                                                                    {
                                                                        'global!ace-u-typography--color-disabled': readMessageIds.includes(message.id),
                                                                        'global!ace-u-typography--color-highlighted': !readMessageIds.includes(message.id),
                                                                        'global!ace-u-cursor--pointer': !readMessageIds.includes(message.id),
                                                                    },
                                                                ])}
                                                                onClick={() => { markMessageAsRead(message); }}
                                                            >
                                                                [{translateComponent('table_body.read')}]
                                                            </span>
                                                    )}
                                            </div>
                                        )
                                        : message.subject || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="contact-name"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={4}
                                >
                                    {message.messageType === alfMessageTypes.OUTGOING
                                        ? message.recipient || '-'
                                        : message.sender || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="user"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={3}
                                >
                                    {message.creatorName || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="attachment"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={2}
                                >
                                    {message.attachments.length > 0 && (
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-flex--justify-content-center',
                                            ])}
                                        >
                                            <InteractiveIcon
                                                icon={downloadIcon}
                                                className={cx([
                                                    'ace-c-interactive-icon--primary',
                                                    'global!ace-u-flex--shrink-0',
                                                    'global!ace-u-cursor--pointer',
                                                ])}
                                                onClick={event => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    const fileName = `${message.invoiceId ? `${message.invoiceId}-` : ''}${message.category ? `${translate(`global.message_category_type.${message.category.toLowerCase()}`)}-` : ''}${translateComponent('file_name_text.attachment')}.zip`;
                                                    initiateDownloadAllDocuments({
                                                        attachments: message.attachments,
                                                        fileName: fileName.replaceAll(/\s+/g, ''),
                                                    });
                                                }}
                                            />
                                        </div>
                                    )}
                                    {message.attachments.length === 0 && (
                                        '-'
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {hasPagination && !!messagesSearchTotalCount && (
                <Paginator
                    page={messagesSearchResultsPage}
                    count={Math.ceil(messagesSearchTotalCount / config.DEFAULT_PAGE_SIZE)}
                    onClick={page => handleOnPaginationChange(page)}
                />
            )}
        </Panel>
    );
};

MessagesPanel.propTypes = {
    initiateDownloadAllDocuments: PropTypes.func.isRequired,
    updateMessageAsRead: PropTypes.func.isRequired,
    initiateRedirectToInvoicePage: PropTypes.func.isRequired,
    searchMessages: PropTypes.func.isRequired,
    messagesSearchTotalCount: PropTypes.number,
    messagesSearchResults: PropTypes.arrayOf(PropTypes.object),
    hasPagination: PropTypes.bool,
    title: PropTypes.string.isRequired,
    actions: PropTypes.node,
    messagesSearchResultsPage: PropTypes.number,
    member: PropTypes.object,
};

MessagesPanel.defaultProps = {
    messagesSearchTotalCount: 0,
    messagesSearchResults: [],
    messagesSearchResultsPage: 0,
    hasPagination: true,
    actions: null,
    member: null,
};

const mapStateToProps = (state, props) => {
    const memberByMembershipNoSelector = memberSelectors.createMemberByMembershipNoSelector();

    return {
        messagesSearchTotalCount: state.communication.messagesSearchTotalCount,
        messagesSearchResults: state.communication.messagesSearchResults,
        messagesSearchResultsPage: state.communication.messagesSearchResultsPage,
        member: memberByMembershipNoSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateDownloadAllDocuments: payload => dispatch({
        type: communicationActionTypes.INITIATE_MESSAGE_ATTACHMENTS_DOWNLOAD_FLOW,
        payload,
    }),
    updateMessageAsRead: payload => dispatch({
        type: communicationActionTypes.UPDATE_MESSAGE_AS_READ,
        payload,
    }),
    initiateRedirectToInvoicePage: payload => dispatch({
        type: communicationActionTypes.INITIATE_REDIRECT_TO_INVOICE_PAGE,
        payload,
    }),
    searchMessages: payload => dispatch({
        type: communicationActionTypes.SEARCH_MESSAGES,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessagesPanel));
