import {take, put} from 'redux-saga/effects';
import * as invoiceActionTypes from '../invoiceActionTypes';

/**
 * Update Invoice status
 */
const updateInvoiceStatus = function* updateInvoiceStatus(params) {
    const {caller, invoiceId, invoiceStatusData} = params;

    if (!invoiceId) return;

    yield put({
        type: invoiceActionTypes.BATCH_UPDATE_INVOICE,
        payload: {
            invoiceId,
            invoiceStatusData,
            isStatusUpdate: true,
        },
        meta: {caller},
    });

    let batchUpdateInvoiceResponse;

    while (true) {
        const batchUpdateInvoiceAction = yield take(
            invoiceActionTypes.BATCH_UPDATE_INVOICE_COMPLETED,
        );
        const {meta = {}} = batchUpdateInvoiceAction;
        if (typeof meta.caller !== 'undefined' && meta.caller !== caller) continue;

        batchUpdateInvoiceResponse = batchUpdateInvoiceAction;
        break;
    }

    return batchUpdateInvoiceResponse;
};

export default updateInvoiceStatus;
