import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {kebabCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {arrowDownIcon, arrowUpIcon, DataRow, InteractiveIcon, Panel, useStyles} from '@ace-de/ui-components';

const MIAFormAccommodationServicePanel = ({service, isDefaultExpanded = false}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translatePanel = createTranslateShorthand('mia_form_accommodation_service_panel');
    const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);

    if (!service) return null;

    return (
        <Panel
            title={(
                <div className={cx('global!ace-u-flex')}>
                    <p className={cx('global!ace-u-typography--variant-h2', 'global!ace-u-margin--right-32')}>
                        {translate(`global.service_type.${service.type.toLowerCase()}`)}
                    </p>
                    <InteractiveIcon
                        name={`expend-section-arrow-${kebabCase(service.type.toLowerCase())}`}
                        icon={isExpanded ? arrowUpIcon : arrowDownIcon}
                        onClick={() => setIsExpanded(!isExpanded)}
                    />
                </div>
            )}
        >
            {isExpanded && (
                <div>
                    <h2 className={cx('global!ace-u-padding--24-0')}>
                        {translatePanel('subtitle.details')}
                    </h2>
                    <DataRow
                        label={translatePanel('data_row_label.number_of_people')}
                        qaIdent="number-of-people"
                    >
                        {service.numberOfPeople || '-'}
                    </DataRow>
                    <DataRow
                        label={translatePanel('data_row_label.accommodation_name')}
                        qaIdent="accommodation-name"
                    >
                        {service.name || '-'}
                    </DataRow>
                </div>
            )}
        </Panel>
    );
};

MIAFormAccommodationServicePanel.propTypes = {
    service: PropTypes.object,
    isDefaultExpanded: PropTypes.bool,
};

MIAFormAccommodationServicePanel.defaultProps = {
    service: null,
    isDefaultExpanded: false,
};

export default MIAFormAccommodationServicePanel;
