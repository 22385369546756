import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {Divider, Panel, useStyles} from '@ace-de/ui-components';
import * as memberSelectors from '../memberSelectors';

const MemberInformationPanel = ({member}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('member_screen');
    const {contactDetails} = member;

    return (
        <Panel
            title={translateScreen('member_information_panel_title.member_info')}
            qaIdent="member-information"
        >
            <p
                className={cx([
                    'global!ace-u-margin--bottom-8',
                    'global!ace-u-typography--variant-body-bold',
                ])}
            >
                {translateScreen('member_information_panel_section_title.contact')}
            </p>
            <div
                className={cx([
                    'global!ace-u-margin--bottom-8',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'ace-u-typography--variant-body',
                ])}
            >
                <span className={cx('global!ace-u-width--64')}>
                    {translateScreen('member_information_panel_label.phone')}:
                </span>
                <a
                    href={`tel:${contactDetails.phoneNo || contactDetails.phoneNumberPrivate}`}
                    className={cx([
                        'global!ace-u-margin--left-8',
                        'global!ace-u-typography--color-highlighted',
                        'global!ace-u-typography--variant-body',
                    ])}
                >
                    {contactDetails.phoneNo || contactDetails.phoneNumberPrivate || '-'}
                </a>
            </div>
            <div
                className={cx([
                    'global!ace-u-margin--bottom-8',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'ace-u-typography--variant-body',
                ])}
            >
                <span className={cx('global!ace-u-width--64')}>
                    {translateScreen('member_information_panel_label.mobile')}:
                </span>
                <a
                    href={`tel:${contactDetails.mobilePhoneNumber || contactDetails.mobilePhoneNumberPrivate}`}
                    className={cx([
                        'global!ace-u-margin--left-8',
                        'global!ace-u-typography--color-highlighted',
                        'global!ace-u-typography--variant-body',
                    ])}
                >
                    {contactDetails.mobilePhoneNumber || contactDetails.mobilePhoneNumberPrivate || '-'}
                </a>
            </div>
            <div
                className={cx([
                    'global!ace-u-margin--bottom-8',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'ace-u-typography--variant-body',
                ])}
            >
                <span className={cx('global!ace-u-width--64')}>
                    {translateScreen('member_information_panel_label.email')}:
                </span>
                <a
                    href={`mailto:${contactDetails.email || contactDetails.email2}`}
                    className={cx([
                        'global!ace-u-margin--left-8',
                        'global!ace-u-typography--color-highlighted',
                        'global!ace-u-typography--variant-body',
                    ])}
                >
                    {contactDetails.email || contactDetails.email2 || '-'}
                </a>
            </div>
            <Divider className={cx('global!ace-u-margin--16-0')} />
            <div
                className={cx([
                    'global!ace-u-margin--bottom-8',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'ace-u-typography--variant-body',
                ])}
            >
                <span className={cx('global!ace-u-width--192')}>
                    {translateScreen('member_information_panel_label.membership_start')}:
                </span>
                <span className={cx('global!ace-u-margin--left-8')}>
                    {member.beginMembership
                        ? moment(member.beginMembership).format('DD.MM.YYYY')
                        : '-'}
                </span>
            </div>
            <div
                className={cx([
                    'global!ace-u-margin--bottom-8',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'ace-u-typography--variant-body',
                ])}
            >
                <span className={cx('global!ace-u-width--192')}>
                    {translateScreen('member_information_panel_label.membership_end')}:
                </span>
                <span className={cx('global!ace-u-margin--left-8')}>
                    {member.endMembership
                        ? moment(member.endMembership).format('DD.MM.YYYY')
                        : '-'}
                </span>
            </div>
            <div
                className={cx([
                    'global!ace-u-margin--bottom-8',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'ace-u-typography--variant-body',
                ])}
            >
                <span className={cx('global!ace-u-width--192')}>
                    {translateScreen('member_information_panel_label.birthday')}:
                </span>
                <span className={cx('global!ace-u-margin--left-8')}>
                    {member.personalDetails.birthday
                        ? moment(member.personalDetails.birthday).format('DD.MM.YYYY')
                        : '-'}
                </span>
            </div>
            <Divider className={cx('global!ace-u-margin--16-0')} />
            <div
                className={cx([
                    'global!ace-u-margin--bottom-8',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'ace-u-typography--variant-body',
                ])}
            >
                <span className={cx('global!ace-u-width--64')}>
                    {translateScreen('member_information_panel_label.account')}:
                </span>
                <span className={cx('global!ace-u-margin--left-8')}>
                    {member.bank?.iban || '-'}
                </span>
            </div>
            <Divider className={cx('global!ace-u-margin--16-0')} />
        </Panel>
    );
};

MemberInformationPanel.propTypes = {
    member: PropTypes.object,
};

MemberInformationPanel.defaultProps = {
    member: null,
};

const mapStateToProps = (state, props) => {
    const memberByMembershipNoSelector = memberSelectors.createMemberByMembershipNoSelector();
    return {
        member: memberByMembershipNoSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps)(MemberInformationPanel));
