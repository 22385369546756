import {produce} from 'immer';
import {LeistContact, AccountParty, persistenceStates, Address} from '@ace-de/eua-entity-types';
import * as contactActionTypes from './contactActionTypes';

const initialState = {
    isContactSearchPerformed: false,
    contactsSearchResults: [],
    contactsSearchResultsCount: 0,
    isContactsSearchError: false,
    contacts: [],
    accountParty: {},
    errorDescription: '',
    searchPersistenceState: persistenceStates.READY,
    addressCandidates: [],
    cityCandidates: [],
    postCodeCandidates: [],
    cityErrorMessage: '',
    postCodeErrorMessage: '',
    addressErrorMessage: '',
};

/**
 * Contact reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const contactReducer = produce((draft, action) => {
    switch (action.type) {
        case contactActionTypes.SEARCH_CONTACTS: {
            draft.isContactSearchPerformed = true;
            break;
        }

        case contactActionTypes.RESET_CONTACT_SEARCH: {
            draft.isContactSearchPerformed = false;
            draft.contactsSearchResults = [];
            draft.contactsSearchResultsCount = 0;
            break;
        }

        case contactActionTypes.STORE_CONTACTS_SEARCH_RESULTS: {
            const {contactDTOs, totalCount} = action.payload;
            draft.contactsSearchResults = contactDTOs.map(contactDTO => (
                new LeistContact().fromDTO(contactDTO)
            ));
            draft.contactsSearchResultsCount = typeof totalCount === 'number' ? totalCount : 0;
            break;
        }

        case contactActionTypes.STORE_CONTACTS_SEARCH_ERROR: {
            const {isContactsSearchError} = action.payload;
            draft.isContactsSearchError = isContactsSearchError;
            break;
        }

        case contactActionTypes.STORE_CONTACTS: {
            const {contactDTOs} = action.payload;
            draft.contacts = contactDTOs.map(contactDTO => (
                new LeistContact().fromDTO(contactDTO)
            ));
            break;
        }

        case contactActionTypes.STORE_ACCOUNT_PARTY: {
            const {accountPartyDTO} = action.payload;
            draft.accountParty = new AccountParty().fromDTO(accountPartyDTO);
            break;
        }

        case contactActionTypes.SET_BC_ERROR_MESSAGE: {
            const {errorDescription} = action.payload;
            const {exceptionMessage} = errorDescription;
            let errorDescriptionString = exceptionMessage;

            if (exceptionMessage && exceptionMessage.includes('message')) {
                const regex = /(?:<message>(.*?)<\/message>)|(?:["']?message["']?\s*:\s*["']?\s*([^'"]+)\s*["']?)/;
                const match = exceptionMessage.match(regex);

                if (match && match.length > 1) {
                    errorDescriptionString = match[1] || match[2];
                }
            }
            draft.errorDescription = errorDescriptionString;
            break;
        }
        case contactActionTypes.RESET_BC_ERROR_MESSAGE: {
            draft.errorDescription = initialState.errorDescription;
            break;
        }

        case contactActionTypes.SET_CONTACTS_SEARCH_PERSISTENCE_STATE: {
            const {persistenceState} = action.payload;
            draft.searchPersistenceState = persistenceState;
            break;
        }

        case contactActionTypes.SET_CONTACT_ADDRESS_CANDIDATES: {
            const {addressCandidateDTOs, key} = action.payload;
            const candidates = addressCandidateDTOs
                ? addressCandidateDTOs.map(addressCandidateDTO => new Address().fromDTO(addressCandidateDTO))
                : [];

            switch (key) {
                case 'postCode':
                    draft.postCodeCandidates = candidates;
                    break;
                case 'street':
                    draft.addressCandidates = candidates;
                    break;
                case 'city':
                    draft.cityCandidates = candidates;
                    break;
                default:
                    break;
            }
            break;
        }

        case contactActionTypes.RESET_CONTACT_ADDRESS_CANDIDATES: {
            const {key} = action.payload;
            switch (key) {
                case 'postCode':
                    draft.postCodeCandidates = [];
                    break;
                case 'street':
                    draft.addressCandidates = [];
                    break;
                case 'city':
                    draft.cityCandidates = [];
                    break;
                default:
                    break;
            }
            break;
        }

        case contactActionTypes.SET_CONTACT_ADDRESS_CANDIDATES_SEARCH_ERROR: {
            const {errorMessage, key} = action.payload;
            switch (key) {
                case 'postCode':
                    draft.postCodeErrorMessage = errorMessage;
                    break;
                case 'street':
                    draft.addressErrorMessage = errorMessage;
                    break;
                case 'city':
                    draft.cityErrorMessage = errorMessage;
                    break;
                default:
                    break;
            }
            break;
        }

        case contactActionTypes.RESET_CONTACT_ADDRESS_CANDIDATES_SEARCH_ERROR: {
            const {key} = action.payload;
            switch (key) {
                case 'postCode':
                    draft.postCodeErrorMessage = '';
                    break;
                case 'street':
                    draft.addressErrorMessage = '';
                    break;
                case 'city':
                    draft.cityErrorMessage = '';
                    break;
                default:
                    break;
            }
            break;
        }

        default:
        //  no-op
    }
    return draft;
}, initialState);

export default contactReducer;
