import {call, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import modalIds from '../../modalIds';
import updateInvoice from '../../invoices/sagas/updateInvoice';

const serviceCaseDocumentUploadFlow = function* serviceCaseDocumentUploadFlow() {
    while (true) {
        yield take([serviceCaseActionTypes.INITIATE_DOCUMENT_UPLOAD_FLOW]);
        yield* openModal(modalIds.SERVICE_CASE_DOCUMENT_UPLOAD_MODAL);

        const chosenAction = yield take([
            serviceCaseActionTypes.DECLINE_SERVICE_CASE_DOCUMENT_UPLOAD,
            serviceCaseActionTypes.CONFIRM_SERVICE_CASE_DOCUMENT_UPLOAD,
        ]);

        if (chosenAction.type === serviceCaseActionTypes.CONFIRM_SERVICE_CASE_DOCUMENT_UPLOAD) {
            const {attachments} = chosenAction.payload;
            const invoiceIds = attachments.map(attachment => attachment.invoiceId).reduce((accumulator, invoiceId) => {
                if (!accumulator.includes(invoiceId)) {
                    accumulator.push(invoiceId);
                }
                return accumulator;
            }, []);
            const {serviceCaseDocuments} = yield select(state => state.serviceCases);

            yield* invoiceIds.map(invoiceId => {
                const invoiceAttachments = attachments.filter(attachment => (
                    attachment.invoiceId === invoiceId
                ));
                const existingAttachments = serviceCaseDocuments.filter(document => document.invoiceId === invoiceId);
                return call(updateInvoice, {
                    caller: serviceCaseActionTypes.SUBMIT_UPLOADED_DOCUMENTS,
                    invoiceId,
                    invoiceData: {attachments: [...existingAttachments, ...invoiceAttachments]},
                });
            });
        }

        yield* closeModal(modalIds.SERVICE_CASE_DOCUMENT_UPLOAD_MODAL);
    }
};

export default serviceCaseDocumentUploadFlow;
