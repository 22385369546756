import {put} from 'redux-saga/effects';
import * as memberActionTypes from '../memberActionTypes';
import config from '../../config';

export const loadMemberSearchResults = function* loadMemberSearchResults({payload}) {
    const {location} = payload;
    const searchQueryParams = new URLSearchParams(location.search);
    const searchQuery = searchQueryParams.get('searchQuery');
    if (searchQuery && searchQuery.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
        yield put({
            type: memberActionTypes.SEARCH_MEMBERS,
            payload: {searchQueryParams},
        });
    }
};

export default loadMemberSearchResults;
