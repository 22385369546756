import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import updateServiceCase from './updateServiceCase';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

/**
 * Update service case vehicle data flow
 * updates the vehicle snapshot on a service case level
 */
const updateServiceCaseVehicleFlow = function* updateServiceCaseVehicleFlow() {
    while (true) {
        const {payload} = yield take(serviceCaseActionTypes.SUBMIT_SERVICE_CASE_VEHICLE_DATA_FORM);
        const {serviceCaseData, serviceCaseId} = payload;

        // if no service case data, continue execution of the loop with the next iteration
        if (!serviceCaseData) continue;

        yield* updateServiceCase({
            caller: serviceCaseActionTypes.SUBMIT_SERVICE_CASE_VEHICLE_DATA_FORM,
            serviceCaseId,
            serviceCaseData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: serviceCaseActionTypes.SUBMIT_SERVICE_CASE_VEHICLE_DATA_FORM},
        });
    }
};

export default updateServiceCaseVehicleFlow;
