import {take, select, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as caseLogActionTypes from '../caseLogActionTypes';

const exportCaseLogPDF = function* exportCaseLogPDF() {
    const {serviceManager} = yield select(state => state.application);
    const ecsHistoryManagementService = serviceManager.loadService('ecsHistoryManagementService');

    while (true) {
        const {payload} = yield take(caseLogActionTypes.SUBMIT_EXPORT_CASE_LOG_PDF);
        const {serviceCaseId, searchQueryParams} = payload;

        yield fork(fetchRequest, caseLogActionTypes.EXPORT_CASE_LOG_PDF_REQUEST,
            ecsHistoryManagementService.createCaseLogPDF, {
                searchQueryParams,
                serviceCaseId,
            });

        yield take([
            caseLogActionTypes.EXPORT_CASE_LOG_PDF_REQUEST_SUCCEEDED,
            caseLogActionTypes.EXPORT_CASE_LOG_PDF_REQUEST_FAILED,
        ]);
    }
};

export default exportCaseLogPDF;
