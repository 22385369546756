import {put, select} from 'redux-saga/effects';
import {replace, resolveRoute, parseSearchQueryParams} from '@computerrock/formation-router';
import routePaths from '../../routePaths';

const redirectServiceCaseScreen = function* redirectServiceCaseScreen({payload}) {
    const {match, location} = payload;
    const {params} = match;
    const {serviceCaseId} = params;
    const {isEditModeActive} = parseSearchQueryParams(location.search);

    if (!serviceCaseId || (!!location.state?.modalId && !location.state?.isModalClosed)) return;

    const serviceCase = yield select(state => state.serviceCases.serviceCases[serviceCaseId]);

    if (!isEditModeActive && serviceCase?.invoicesInfo?.invoicesCount) {
        location.state
            ? yield put(replace(resolveRoute(routePaths.SERVICE_CASES_SEARCH)))
            : yield put(replace(resolveRoute(routePaths.MEMBER, {
                membershipNo: serviceCase.member.membershipNo,
            })));
    }
};

export default redirectServiceCaseScreen;
