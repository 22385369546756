import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {alfOverpaymentReasonTypes, alfPartialPaymentReasonTypes} from '@ace-de/eua-entity-types';
import {useStyles, Modal, HighlightCircle, TextAreaField, Button, ButtonPrimary, SelectField, Option} from '@ace-de/ui-components';
import {Icon, infoIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import config from '../../config';
import * as invoiceActionTypes from '../invoiceActionTypes';
import invoiceApprovalReasonModalTypes from './invoiceApprovalReasonModalTypes';

const InvoiceApprovalReasonModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, confirmInvoiceApproval, declineInvoiceApproval, location} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('invoice_approval_reason_modal');
    const [approveReason, setApproveReason] = useState('');
    const [approveDescription, setApproveDescription] = useState('');
    const modalType = location?.query?.type;

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape') declineInvoiceApproval();
    }, [declineInvoiceApproval]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            contentClassName={cx('global!ace-u-modal-content-size--xs')}
            className={cx([
                'global!ace-u-padding--left-64',
                'global!ace-u-padding--right-64',
            ])}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-full-width',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'ace-c-highlight-circle--primary-highlight',
                        'global!ace-u-margin--bottom-24',
                    ])}
                >
                    <Icon
                        icon={infoIcon}
                        className={cx('ace-c-icon--xl', 'global!ace-c-icon--color-highlight')}
                    />
                </HighlightCircle>
                <p className={cx('global!ace-u-typography--variant-h2', 'global!ace-u-margin--bottom-16')}>
                    {translateModal('title.are_you_sure')}
                </p>
                <p className={cx('global!ace-u-margin--bottom-16', 'global!ace-u-typography--align-center')}>
                    {translateModal(`body_text.${modalType.toLowerCase()}`)}
                </p>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--justify-center',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-padding--0-32',
                    ])}
                >
                    <SelectField
                        name="approveReason"
                        className={cx('global!ace-u-full-width', 'global!ace-u-margin--bottom-48')}
                        label={`${translateModal('select_field_label.approve_reason_category')}*`}
                        placeholder={translateModal('select_field_placeholder.please_select')}
                        value={approveReason}
                        onChange={setApproveReason}
                    >
                        {modalType === invoiceApprovalReasonModalTypes.OVERPAYMENT
                            ? Object.values(alfOverpaymentReasonTypes).map(alfOverpaymentReasonType => (
                                <Option
                                    name={`${alfOverpaymentReasonType}Option`}
                                    value={alfOverpaymentReasonType}
                                    key={alfOverpaymentReasonType}
                                >
                                    {translate(`global.invoice_approve_reason.${alfOverpaymentReasonType.toLowerCase()}`)}
                                </Option>
                            ))
                            : Object.values(alfPartialPaymentReasonTypes).map(alfPartialPaymentReasonType => (
                                <Option
                                    name={`${alfPartialPaymentReasonType}Option`}
                                    value={alfPartialPaymentReasonType}
                                    key={alfPartialPaymentReasonType}
                                >
                                    {translate(`global.invoice_approve_reason.${alfPartialPaymentReasonType.toLowerCase()}`)}
                                </Option>
                            ))}
                    </SelectField>
                    <TextAreaField
                        name="approveDescription"
                        className={cx('global!ace-u-margin--bottom-16')}
                        label={translateModal('text_area_label.free_text')}
                        placeholder={translateModal('text_area_placeholder.please_elaborate')}
                        value={approveDescription}
                        onChange={setApproveDescription}
                        isResizable={false}
                        maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                        cols={50}
                    />
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-row',
                        'global!ace-u-flex--justify-center',
                        'global!ace-u-full-width',
                    ])}
                >
                    <Button
                        className={cx('global!ace-u-margin--right-24')}
                        onClick={declineInvoiceApproval}
                    >
                        {translateModal('button_label.back')}
                    </Button>
                    <ButtonPrimary
                        isDisabled={!approveReason}
                        onClick={() => {
                            confirmInvoiceApproval({
                                approveReason,
                                approveDescription,
                            });
                        }}
                    >
                        {translateModal('button_label.yes')}
                    </ButtonPrimary>
                </div>
            </div>
        </Modal>
    );
};

InvoiceApprovalReasonModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    location: PropTypes.object,
    confirmInvoiceApproval: PropTypes.func.isRequired,
    declineInvoiceApproval: PropTypes.func.isRequired,
};

InvoiceApprovalReasonModal.defaultProps = {
    hasBackdrop: true,
    location: null,
};

const mapDispatchToProps = dispatch => {
    return {
        confirmInvoiceApproval: payload => dispatch({
            type: invoiceActionTypes.CONFIRM_INVOICE_APPROVAL,
            payload,
        }),
        declineInvoiceApproval: () => dispatch({
            type: invoiceActionTypes.DECLINE_INVOICE_APPROVAL,
        }),
    };
};

export default connect(null, mapDispatchToProps)(InvoiceApprovalReasonModal);
