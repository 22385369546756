import {takeEvery} from 'redux-saga/effects';
import * as invoiceActionTypes from '../invoiceActionTypes';
import updateInvoiceServiceLines from './updateInvoiceServiceLines';

const updateInvoiceServiceLinesFlow = function* updateInvoiceServiceLinesFlow() {
    yield takeEvery(
        invoiceActionTypes.SUBMIT_INVOICE_SERVICE_LINES,
        updateInvoiceServiceLines,
    );
};

export default updateInvoiceServiceLinesFlow;
