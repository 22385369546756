import React from 'react';
import PropTypes from 'prop-types';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {HighlightCircle, Icon, acceptedIcon, useStyles} from '@ace-de/ui-components';

const SuccessMessageContent = props => {
    const {cx} = useStyles();
    const {location} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateComponent = createTranslateShorthand('success_message_modal');
    const successMessageType = location?.query?.successMessageType;

    return (
        <div
            className={cx([
                'global!ace-u-flex',
                'global!ace-u-full-width',
                'global!ace-u-flex--direction-column',
                'global!ace-u-flex--align-center',
                'global!ace-u-flex--justify-content-center',
            ])}
        >
            <HighlightCircle
                className={cx([
                    'ace-c-highlight-circle--medium',
                    'ace-c-highlight-circle--success',
                ])}
            >
                <Icon
                    icon={acceptedIcon}
                    className={cx([
                        'ace-c-icon--xxl',
                        'ace-c-icon--color-success',
                    ])}
                />
            </HighlightCircle>
            <h1 className={cx('global!ace-u-margin--24-0')}>
                {translateComponent('heading.success')}
            </h1>
            {successMessageType && typeof successMessageType === 'string' && (
                <p className={cx(['global!ace-u-margin--bottom-24', 'global!ace-u-typography--align-center'])}>
                    {translateComponent(`success_message.${snakeCase(successMessageType)}`)}
                </p>
            )}
        </div>
    );
};

SuccessMessageContent.propTypes = {
    location: PropTypes.object,
};

SuccessMessageContent.defaultProps = {
    location: null,
};

export default withRouter(SuccessMessageContent);
