import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {alfIncomingMessageCategoryTypes, alfMessageChannelTypes, alfMessageTypes, persistenceStates} from '@ace-de/eua-entity-types';
import {ButtonPrimary, HighlightCircle, InputField, Modal, Option, SelectField, useStyles} from '@ace-de/ui-components';
import {InteractiveIcon, Icon, closeIcon, uploadIcon, plusIcon, deleteIcon, fileIcon, infoAlertIcon} from '@ace-de/ui-components/icons';
import {FileAssetsDropZone, FileAssetsInput, useFileAssets} from '@ace-de/eua-file-assets';
import * as invoiceSelectors from '../../invoices/invoiceSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import allowedAttachmentMIMETypes from '../../invoices/allowedAttachmentMIMETypes';
import config from '../../config';
import styles from './CommunicationModals.module.scss';
import * as communicationActionTypes from '../communicationActionTypes';

const UploadReplyModal = props => {
    const {cx} = useStyles(props, styles);
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('sc_reply_upload_modal');
    const {declineReplyUpload, confirmReplyUpload, hasBackdrop, persistenceState} = props;
    const {serviceCaseInvoices, serviceCase} = props;
    const [formData, setFormData] = useState(null);
    const isUploadInProgress = persistenceState === persistenceStates.PENDING;

    const {configure: configureFileAssetsService, fileList, deleteFile} = useFileAssets({
        service: 'alfFileAssetsService',
        fileList: 'service-case-incoming-messages',
    });

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape' && !isUploadInProgress) declineReplyUpload();
    }, [declineReplyUpload, isUploadInProgress]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    useEffect(() => {
        configureFileAssetsService({
            maxFileSize: config.MAXIMUM_FILE_ASSETS_FILE_SIZE_BYTES,
            allowedMIMETypes: allowedAttachmentMIMETypes,
        });
    }, [configureFileAssetsService]);

    const senderOptions = serviceCaseInvoices.map(invoice => (
        invoice?.accountParty
    )).concat([{
        id: serviceCase?.member?.membershipNo,
        name: serviceCase?.member?.personalDetails.name,
    }]).filter(value => !!value).reduce((accumulator, current) => {
        if (!accumulator.find(item => item.id === current.id)) accumulator.push(current);
        return accumulator;
    }, []);

    const handleOnChange = (field, value) => {
        setFormData(prevState => ({
            ...prevState,
            ...(field === 'category' && {subject: translateModal(`option_label.${value.toLowerCase()}`)}),
            [field]: value,
        }));
    };


    const handleOnSubmit = () => {
        const {sender} = formData;
        const contact = senderOptions?.find(senderOption => senderOption.name === sender);

        confirmReplyUpload({
            messageData: {
                ...formData,
                attachments: [...fileList.fileAssets.values()],
                messageType: alfMessageTypes.INCOMING,
                accountParty: sender,
                serviceCaseId: serviceCase.id,
                ...(contact ? {contactId: contact.id} : {}),
            },
        });
    };

    const isSubmitButtonDisabled = !formData?.sender || !formData?.channel
        || !formData?.subject || [...fileList.fileAssets.values()].length === 0;

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineReplyUpload}
                    isDisabled={isUploadInProgress}
                />
            )}
            hasBackdrop={hasBackdrop}
            hasColoredHeader={true}
            title={translateModal('modal_title.upload_reply')}
            contentClassName={cx([
                'ace-c-modal__content--scrollable',
                'global!ace-u-modal-content-size--m',
            ])}
        >
            <div
                className={cx([
                    'global!ace-u-width--full',
                    'global!ace-u-grid',
                ])}
            >
                <div className={cx('global!ace-u-grid-column--span-3')}>
                    <SelectField
                        name="sender"
                        label={`${translateModal('select_field_label.sender')}*`}
                        className={cx('global!ace-u-width--full')}
                        value={formData?.sender || ''}
                        onChange={value => handleOnChange('sender', value)}
                        isDisabled={isUploadInProgress}
                    >
                        {senderOptions.length > 0 && senderOptions.map(senderOption => (
                            <Option
                                key={senderOption.id}
                                name={`senderOption-${senderOption.id}`}
                                value={senderOption.name}
                            >
                                {senderOption.name}
                            </Option>
                        ))}
                    </SelectField>
                    <SelectField
                        name="channel"
                        label={`${translateModal('select_field_label.channel')}*`}
                        className={cx([
                            'global!ace-u-margin--top-32',
                            'global!ace-u-width--full',
                        ])}
                        value={formData?.channel || ''}
                        onChange={value => handleOnChange('channel', value)}
                        isDisabled={isUploadInProgress}
                    >
                        {Object.values(alfMessageChannelTypes).filter(channel => (
                            channel !== alfMessageChannelTypes.ELLA
                        )).map(channel => (
                            <Option
                                key={channel}
                                name={channel}
                                value={channel}
                            >
                                {translateModal(`option_label.${channel.toLowerCase()}`)}
                            </Option>
                        ))}
                    </SelectField>
                    <SelectField
                        name="category"
                        label={`${translateModal('select_field_label.category')}`}
                        className={cx([
                            'global!ace-u-margin--top-32',
                            'global!ace-u-width--full',
                        ])}
                        value={formData?.category || ''}
                        onChange={value => handleOnChange('category', value)}
                        isDisabled={isUploadInProgress}
                    >
                        {Object.values(alfIncomingMessageCategoryTypes).map(category => (
                            <Option
                                key={category}
                                name={category}
                                value={category}
                            >
                                {translateModal(`option_label.${category.toLowerCase()}`)}
                            </Option>
                        ))}
                    </SelectField>
                </div>
                <div className={cx('global!ace-u-grid-column--span-9')}>
                    <InputField
                        name="subject"
                        label={`${translateModal('input_field_label.subject')}*`}
                        className={cx([
                            'global!ace-u-margin--bottom-16',
                            'global!ace-u-width--full',
                        ])}
                        value={formData?.subject || ''}
                        onChange={value => handleOnChange('subject', value)}
                        isDisabled={isUploadInProgress}
                    />
                    <div
                        className={cx([
                            'global!ace-u-margin--top-40',
                            'global!ace-u-grid',
                        ])}
                    >
                        {[...fileList.fileAssets.values()].length > 0
                                && (
                                    <div className={cx('global!ace-u-grid-column--span-3')}>
                                        {[...fileList.fileAssets.values()].map(fileAsset => (
                                            <div
                                                key={fileAsset.id}
                                                className={cx([
                                                    'global!ace-u-padding--16',
                                                    'global!ace-u-margin--top-16',
                                                    'global!ace-u-grid',
                                                    'global!ace-u-box-skin--pending',
                                                ])}
                                            >
                                                <Icon
                                                    icon={fileIcon}
                                                />
                                                <p
                                                    className={cx([
                                                        'global!ace-u-grid-column--span-5',
                                                        'ace-c-communication-modal__file-name',
                                                    ])}
                                                >
                                                    {fileAsset.fileName}
                                                </p>
                                                <Icon
                                                    onClick={() => deleteFile(
                                                        fileAsset,
                                                        config.SHOULD_SEND_DOCUMENT_DELETE_REQUEST,
                                                    )}
                                                    icon={deleteIcon}
                                                    className={cx([
                                                        'ace-c-icon--xs',
                                                        'ace-c-icon--color-medium-emphasis',
                                                    ])}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                        <div
                            className={cx([
                                'global!ace-u-width--full',
                                'global!ace-u-flex',
                                'global!ace-u-flex--direction-column',
                            ], {
                                'global!ace-u-grid-column--span-6': [...fileList.fileAssets.values()].length > 0,
                                'global!ace-u-grid-column--span-9': [...fileList.fileAssets.values()].length === 0,
                            })}
                        >
                            <FileAssetsDropZone
                                service="alfFileAssetsService"
                                fileList="service-case-incoming-messages"
                                className={cx([
                                    'global!ace-u-padding--40',
                                    'global!ace-u-width--full',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-column',
                                    'global!ace-u-flex--justify-center',
                                    'global!ace-u-flex--align-center',
                                ])}
                                isDisabled={isUploadInProgress}
                            >
                                <HighlightCircle
                                    className={cx([
                                        'global!ace-u-height--64',
                                        'global!ace-u-width--64',
                                        'ace-c-highlight-circle--primary-highlight',
                                    ])}
                                >
                                    <Icon
                                        icon={uploadIcon}
                                        className={cx([
                                            'ace-c-icon--l',
                                        ])}
                                    />
                                </HighlightCircle>
                                <div
                                    className={cx([
                                        'global!ace-u-margin--top-16',
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--direction-column',
                                        'global!ace-u-flex--align-center',
                                    ])}
                                >
                                    <p className={cx('global!ace-u-typography--variant-body-bold')}>
                                        {translateModal('file_drop_zone_title.document_upload')}
                                    </p>
                                    <FileAssetsInput
                                        service="alfFileAssetsService"
                                        fileList="service-case-incoming-messages"
                                        className={cx([
                                            'global!ace-u-margin--16-0',
                                            'ace-c-file-input--no-chrome',
                                        ])}
                                        isDisabled={isUploadInProgress}
                                    >
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-flex--justify-center',
                                                'global!ace-u-cursor--pointer',
                                            ])}
                                        >
                                            <Icon
                                                icon={plusIcon}
                                                className={cx([
                                                    'global!ace-u-margin--right-8',
                                                    'ace-c-icon--color-highlight-pressed',
                                                ])}
                                            />
                                            <p
                                                className={cx([
                                                    'global!ace-u-typography--variant-highlight',
                                                    'global!ace-u-typography--color-higlighted-active',
                                                ])}
                                            >
                                                {translateModal('file_drop_zone_label.search_documents')}
                                            </p>
                                        </div>
                                    </FileAssetsInput>
                                </div>
                            </FileAssetsDropZone>
                            {fileList.errors.length > 0 && (
                                <div className={cx('global!ace-u-margin--8-0')}>
                                    {fileList.errors.map((errorInfo, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--align-center',
                                                    'global!ace-u-margin--4-0',
                                                    'global!ace-u-typography--variant-caption',
                                                    'global!ace-u-typography--color-warning',
                                                ])}
                                            >
                                                <Icon
                                                    icon={infoAlertIcon}
                                                    className={cx([
                                                        'global!ace-u-margin--right-4',
                                                        'ace-c-icon--s',
                                                        'ace-c-icon--color-warning',
                                                    ])}
                                                />
                                                {translateModal('upload_error.message')}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                        <div
                            className={cx([
                                'global!ace-u-grid-column--span-3',
                                'global!ace-u-flex',
                                'global!ace-u-flex--direction-column',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-flex--justify-flex-end',
                            ])}
                        >
                            <ButtonPrimary
                                onClick={handleOnSubmit}
                                isDisabled={isSubmitButtonDisabled || isUploadInProgress}
                            >
                                <Icon
                                    icon={uploadIcon}
                                    className={cx([
                                        'global!ace-u-margin--right-16',
                                        'ace-c-icon--color-contrast',
                                    ])}
                                />
                                {translateModal('button_label.save')}
                            </ButtonPrimary>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

UploadReplyModal.propTypes = {
    declineReplyUpload: PropTypes.func.isRequired,
    confirmReplyUpload: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
    serviceCaseInvoices: PropTypes.array,
    serviceCase: PropTypes.object,
    persistenceState: PropTypes.string.isRequired,
};

UploadReplyModal.defaultProps = {
    hasBackdrop: false,
    serviceCaseInvoices: [],
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseInvoicesSelector = invoiceSelectors.createServiceCaseInvoicesSelector();
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();

    return {
        serviceCaseInvoices: serviceCaseInvoicesSelector(state, props),
        serviceCase: getServiceCase(state, props),
        persistenceState: state.communication.persistenceState,
    };
};

const mapDispatchToProps = dispatch => ({
    declineReplyUpload: () => dispatch({
        type: communicationActionTypes.DECLINE_UPLOAD_REPLY,
    }),
    confirmReplyUpload: payload => dispatch({
        type: communicationActionTypes.CONFIRM_UPLOAD_REPLY,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadReplyModal);
