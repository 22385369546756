import React, {Fragment, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {Modal, useStyles, HighlightCircle, Button, ButtonPrimary} from '@ace-de/ui-components';
import {Icon, checkmarkIcon, warningIcon, findCaseIcon} from '@ace-de/ui-components/icons';
import {alfInvoiceStatusTypes} from '@ace-de/eua-entity-types';
import * as invoiceActionTypes from '../invoiceActionTypes';
import inaCheckResponseTypes from '../inaCheckResponseTypes';
import routePaths from '../../routePaths';

const INACheckResponseModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('ina_check_response_modal');
    const {hasBackdrop, location, handleINAResponseAction, match} = props;
    const checkStatus = location?.query?.checkStatus;
    const {serviceCaseId, invoiceId} = match?.params;

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape' && checkStatus === inaCheckResponseTypes.INA_API_FAILED) handleINAResponseAction(
            invoiceActionTypes.DECLINE_INA_CHECK,
            null,
        );
    }, [handleINAResponseAction, checkStatus]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            contentClassName={cx('global!ace-u-modal-content-size--m')}
        >
            <div className={cx([
                'global!ace-u-flex',
                'global!ace-u-flex--direction-column',
                'global!ace-u-flex--align-center',
                'global!ace-u-full-width',
            ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'ace-u-margin--bottom-32',
                        'ace-c-highlight-circle--primary-highlight',
                    ])}
                >
                    {checkStatus === inaCheckResponseTypes.INA_CHECK_PASSED && (
                        <Icon
                            icon={checkmarkIcon}
                            className={cx([
                                'ace-c-icon--xl',
                                'ace-c-icon--color-success',
                            ])}
                        />
                    )}
                    {checkStatus === inaCheckResponseTypes.INA_CHECK_FAILED && (
                        <Icon
                            icon={findCaseIcon}
                            className={cx([
                                'ace-c-icon--xl',
                                'ace-c-icon--color-highlight',
                            ])}
                        />
                    )}
                    {checkStatus === inaCheckResponseTypes.INA_API_FAILED && (
                        <Icon
                            icon={warningIcon}
                            className={cx([
                                'ace-c-icon--xl',
                                'ace-c-icon--color-highlight',
                            ])}
                        />
                    )}

                </HighlightCircle>
                <p
                    className={cx([
                        'global!ace-u-typography--variant-h2',
                        'global!ace-u-margin--bottom-24',
                    ])}
                >
                    {translateModal(`check_status.${checkStatus.toLowerCase()}`)}
                </p>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-row',
                    ])}
                >
                    {checkStatus === inaCheckResponseTypes.INA_CHECK_PASSED && (
                        <Fragment>
                            <Button
                                name="buttonDashboardPassed"
                                className={cx('global!ace-u-margin--right-16')}
                                onClick={() => {
                                    handleINAResponseAction(
                                        invoiceActionTypes.REDIRECT_AFTER_INA_CHECK,
                                        {destination: routePaths.DASHBOARD_SECTION},
                                    );
                                }}
                            >
                                {translateModal('button_label.dashboard')}
                            </Button>
                            <ButtonPrimary
                                name="buttonOverviewPassed"
                                onClick={() => {
                                    handleINAResponseAction(
                                        invoiceActionTypes.REDIRECT_AFTER_INA_CHECK,
                                        {destination: routePaths.SERVICE_CASES_OVERVIEW,
                                            queryParams: {serviceCaseId}},
                                    );
                                }}
                            >
                                {translateModal('button_label.case_overview')}
                            </ButtonPrimary>
                        </Fragment>
                    )}
                    {checkStatus === inaCheckResponseTypes.INA_CHECK_FAILED && (
                        <Fragment>
                            <Button
                                className={cx('global!ace-u-margin--right-16')}
                                name="buttonDashboardFailed"
                                onClick={() => {
                                    handleINAResponseAction(
                                        invoiceActionTypes.REDIRECT_AFTER_INA_CHECK,
                                        {destination: routePaths.DASHBOARD_SECTION},
                                    );
                                }}
                            >
                                {translateModal('button_label.dashboard')}
                            </Button>
                            <Button
                                className={cx('global!ace-u-margin--right-16')}
                                name="buttonOverviewFailed"
                                onClick={() => {
                                    handleINAResponseAction(
                                        invoiceActionTypes.REDIRECT_AFTER_INA_CHECK,
                                        {
                                            destination: routePaths.SERVICE_CASES_OVERVIEW,
                                            queryParams: {
                                                serviceCaseId,
                                                invoiceId,
                                            },
                                        },
                                    );
                                }}
                            >
                                {translateModal('button_label.case_overview')}
                            </Button>
                            <ButtonPrimary
                                name="buttonManualCheck"
                                onClick={() => {
                                    handleINAResponseAction(
                                        invoiceActionTypes.REDIRECT_AFTER_INA_CHECK,
                                        {
                                            destination: routePaths.INVOICE_OVERVIEW,
                                            queryParams: {serviceCaseId, invoiceId},
                                        },
                                    );
                                }}
                            >
                                {translateModal('button_label.manual_check')}
                            </ButtonPrimary>
                        </Fragment>
                    )}
                    {checkStatus === inaCheckResponseTypes.INA_API_FAILED && (
                        <Fragment>
                            <Button
                                name="buttonReturn"
                                className={cx('global!ace-u-margin--right-16')}
                                onClick={() => {
                                    handleINAResponseAction(
                                        invoiceActionTypes.DECLINE_INA_CHECK,
                                        null,
                                    );
                                }}
                            >
                                {translateModal('button_label.return')}
                            </Button>
                            <Button
                                name="buttonChangeStatusToManualCheck"
                                className={cx('global!ace-u-margin--right-16')}
                                onClick={() => {
                                    handleINAResponseAction(
                                        invoiceActionTypes.UPDATE_INVOICE_STATUS_TO_MANUAL_CHECK,
                                        {
                                            serviceCaseId,
                                            invoiceStatusData: {
                                                status: alfInvoiceStatusTypes.MANUAL_CHECK,
                                            },
                                        },
                                    );
                                }}
                            >
                                {translateModal('button_label.manual_check')}
                            </Button>
                            <ButtonPrimary
                                name="buttonRetry"
                                onClick={() => {
                                    handleINAResponseAction(
                                        invoiceActionTypes.RETRY_INA_CHECK,
                                        null,
                                    );
                                }}
                            >
                                {translateModal('button_label.retry')}
                            </ButtonPrimary>
                        </Fragment>
                    )}
                </div>
            </div>
        </Modal>
    );
};

INACheckResponseModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    handleINAResponseAction: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

INACheckResponseModal.defaultProps = {
    hasBackdrop: true,
};

const mapDispatchToProps = dispatch => {
    return {
        handleINAResponseAction: (type, payload) => dispatch({
            type,
            payload,
        }),
    };
};

export default connect(null, mapDispatchToProps)(INACheckResponseModal);
