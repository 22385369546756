import {fork, put, select, take} from 'redux-saga/effects';
import {alfMessageChannelTypes, alfMessageTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as communicationActionTypes from '../communicationActionTypes';

const updateMessageAsRead = function* updateMessageAsRead() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(communicationActionTypes.UPDATE_MESSAGE_AS_READ);
        const {messageIds} = payload;

        yield fork(
            fetchRequest,
            communicationActionTypes.UPDATE_MESSAGE_AS_READ_REQUEST,
            leaAssignmentFlowService.updateMessagesAsRead,
            {messageIds},
        );

        const responseAction = yield take([
            communicationActionTypes.UPDATE_MESSAGE_AS_READ_REQUEST_SUCCEEDED,
            communicationActionTypes.UPDATE_MESSAGE_AS_READ_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const searchQueryParams = new URLSearchParams();
            searchQueryParams.append('messageChannel', alfMessageChannelTypes.ELLA);
            searchQueryParams.append('messageType', alfMessageTypes.INCOMING);
            searchQueryParams.append('isRead', 'false');

            yield fork(fetchRequest,
                communicationActionTypes.FETCH_MESSAGES_REQUEST,
                leaAssignmentFlowService.getMessages,
                {searchQueryParams});

            const fetchCaseMessagesAction = yield take([
                communicationActionTypes.FETCH_MESSAGES_REQUEST_SUCCEEDED,
                communicationActionTypes.FETCH_MESSAGES_REQUEST_FAILED,
            ]);

            if (!fetchCaseMessagesAction.error) {
                const {response} = fetchCaseMessagesAction.payload;
                const {messageDTOs} = response;

                yield put({
                    type: communicationActionTypes.STORE_ELLA_UNREAD_MESSAGES,
                    payload: {messageDTOs},
                });
            }
        }
    }
};

export default updateMessageAsRead;
