import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {ButtonPrimary, Panel, useStyles, TextArea, LinkedList, LinkedListItem} from '@ace-de/ui-components';
import {Icon, plusIcon} from '@ace-de/ui-components/icons';
import * as invoiceActionTypes from '../invoiceActionTypes';
import config from '../../config';
import {formatInvoiceServiceLine} from '../formatInvoiceServiceLine';

const InternalNotesPanel = props => {
    const {cx} = useStyles(props);
    const {createTranslateShorthand, translate} = useTranslate();
    const translatePanel = createTranslateShorthand('internal_notes_panel');
    const {invoice, submitCreateInternalNote, invoiceServices} = props;
    const [internalNoteDescription, setInternalNoteDescription] = useState('');

    const handleOnSubmit = () => {
        if (internalNoteDescription) {
            const invoiceServiceLines = formatInvoiceServiceLine(invoiceServices, invoice.lines);

            submitCreateInternalNote({
                invoiceId: invoice?.id,
                internalNoteData: {
                    description: internalNoteDescription,
                },
                invoiceServiceLines,
            });

            setInternalNoteDescription('');
        }
    };

    if (!invoice) return null;

    return (
        <Panel title={translatePanel('panel_title.internal_notes')}>
            <div className={cx('global!ace-u-grid')}>
                <div
                    className={cx([
                        'global!ace-u-grid-column--span-6',
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                    ])}
                >
                    <TextArea
                        isResizable={false}
                        value={internalNoteDescription}
                        onChange={setInternalNoteDescription}
                        placeholder={translatePanel('text_area_placeholder.enter_new_note')}
                        maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                        name="invoice-internal-notes"
                    />
                    <p
                        className={cx([
                            'global!ace-u-margin--4-0',
                            'global!ace-u-typography--variant-caption',
                            'global!ace-u-typography--color-medium-emphasis',
                            'global!ace-u-typography--align-right',
                        ])}
                    >
                        {`${internalNoteDescription ? internalNoteDescription.length : 0}/${config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH} ${translate('global.unit.characters_left')}`}
                    </p>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-flex-end',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <ButtonPrimary
                            isDisabled={!internalNoteDescription}
                            onClick={handleOnSubmit}
                            name="submit-invoice-internal-notes"
                        >
                            <Icon
                                icon={plusIcon}
                                className={cx([
                                    'global!ace-u-margin--right-8',
                                    'ace-c-icon--color-contrast',
                                ])}
                            />
                            {translatePanel('button_label.add')}
                        </ButtonPrimary>
                    </div>
                </div>
                <div className={cx('global!ace-u-grid-column--span-6')}>
                    <LinkedList
                        followElement={invoice.internalNotes}
                        hasShadow={false}
                    >
                        {invoice.internalNotes?.length > 0
                            && [...invoice.internalNotes].sort((internalNoteA, internalNoteB) => (
                                moment(internalNoteA.createdAt).isSameOrAfter(moment(internalNoteB.createdAt)) ? 1 : -1
                            )).map(internalNote => {
                                return (
                                    <LinkedListItem
                                        key={internalNote.id}
                                        className={cx('global!ace-u-margin--0-32')}
                                    >
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--justify-space-between',
                                                'global!ace-u-padding--8',
                                            ])}
                                        >
                                            <p
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--direction-column',
                                                ])}
                                            >
                                                <span
                                                    className={cx([
                                                        'global!ace-u-typography--color-medium-emphasis',
                                                        'global!ace-u-typography--variant-caption',
                                                        'global!ace-u-margin--bottom-4',
                                                    ])}
                                                >
                                                    <span>
                                                        {internalNote.creatorName || ''}
                                                    </span>,&ensp;
                                                    {internalNote.createdAt && moment(internalNote.createdAt).format('DD.MM.YYYY[,] HH:mm')}
                                                </span>
                                                <span
                                                    className={cx('global!ace-u-typography--variant-body')}
                                                >
                                                    {internalNote.description}
                                                </span>
                                            </p>
                                        </div>
                                    </LinkedListItem>
                                );
                            })}
                    </LinkedList>
                </div>
            </div>
        </Panel>
    );
};

InternalNotesPanel.propTypes = {
    invoice: PropTypes.object,
    invoiceServices: PropTypes.array,
    submitCreateInternalNote: PropTypes.func.isRequired,
};

InternalNotesPanel.defaultProps = {
    invoice: null,
    invoiceServices: [],
};

const mapDispatchToProps = dispatch => ({
    submitCreateInternalNote: payload => dispatch({
        type: invoiceActionTypes.SUBMIT_CREATE_INTERNAL_NOTE,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(InternalNotesPanel);
