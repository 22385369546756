import {useSelector} from 'react-redux';
import vehicleTypesMapping from './vehicleTypesMapping';

// get manufacturer name: returns either manufacturer name or null
export const getManufacturerNameById = (vehicleInfos, vehicleData = {}) => {
    if (typeof vehicleData !== 'object') return null;
    const {type: vehicleType, manufacturer: manufacturerId} = vehicleData;
    if (!vehicleType || !manufacturerId) return null;

    const manufacturersMap = vehicleInfos[vehicleTypesMapping[vehicleType]];
    if (manufacturersMap && manufacturersMap[manufacturerId]) {
        return manufacturersMap[manufacturerId].name || null;
    }
    return null;
};

// get manufacturer id: returns either manufacturer id or null
export const getManufacturerIdByName = (vehicleInfos, vehicleData = {}) => {
    if (typeof vehicleData !== 'object') return null;
    const {type: vehicleType, manufacturer: manufacturerName} = vehicleData;
    if (!vehicleType || !manufacturerName) return null;

    const manufacturersMap = vehicleInfos[vehicleTypesMapping[vehicleType]];
    if (!manufacturersMap) return null;

    for (const [manufacturerId, manufacturerData] of Object.entries(manufacturersMap)) {
        if (manufacturerData.name === manufacturerName) {
            return manufacturerId;
        }
    }
    return null;
};

// get model name: returns either model name or null
export const getModelNameById = (vehicleInfos, vehicleData = {}) => {
    if (typeof vehicleData !== 'object') return null;
    const {type: vehicleType, manufacturer: manufacturerId, model: modelId} = vehicleData;
    if (!vehicleType || !manufacturerId || !modelId) return null;

    const manufacturersMap = vehicleInfos[vehicleTypesMapping[vehicleType]];
    const modelsMap = manufacturersMap ? manufacturersMap[manufacturerId]?.models : null;
    if (modelsMap && modelsMap[modelId]) {
        return modelsMap[modelId].name || null;
    }
    return null;
};

// get model id: returns either model id or null
export const getModelIdByName = (vehicleInfos, vehicleData = {}) => {
    if (typeof vehicleData !== 'object') return null;
    const {type: vehicleType, manufacturer: manufacturerName, model: modelName} = vehicleData;
    if (!vehicleType || !manufacturerName || !modelName) return null;

    const manufacturerId = getManufacturerIdByName(vehicleInfos, {type: vehicleType, manufacturer: manufacturerName});
    if (!manufacturerId) return null;

    const modelsMap = vehicleInfos[vehicleTypesMapping[vehicleType]][manufacturerId]?.models;
    if (modelsMap) {
        for (const [modelId, modelData] of Object.entries(modelsMap)) {
            if (modelData.name === modelName) {
                return modelId;
            }
        }
    }

    return null;
};

// get variant name: returns either variant name or null
export const getVariantNameById = (vehicleInfos, vehicleData = {}) => {
    if (typeof vehicleData !== 'object') return null;
    const {type: vehicleType, manufacturer: manufacturerId, model: modelId, variant: variantId} = vehicleData;
    if (!vehicleType || !manufacturerId || !modelId || !variantId) return null;

    const manufacturersMap = vehicleInfos[vehicleTypesMapping[vehicleType]];
    const modelsMap = manufacturersMap ? manufacturersMap[manufacturerId]?.models : null;
    const variantsMap = modelsMap ? modelsMap[modelId]?.variants : null;
    if (variantsMap && variantsMap[variantId]) {
        return variantsMap[variantId].name || null;
    }
    return null;
};

// get variant id: returns either variant id or null
export const getVariantIdByName = (vehicleInfos, vehicleData = {}) => {
    if (typeof vehicleData !== 'object') return null;
    const {type: vehicleType, manufacturer: manufacturerName, model: modelName, variant: variantName} = vehicleData;
    if (!vehicleType || !manufacturerName || !modelName || !variantName) return null;

    const manufacturerId = getManufacturerIdByName(vehicleInfos, {type: vehicleType, manufacturer: manufacturerName});
    if (!manufacturerId) return null;

    const modelId = getModelIdByName(
        vehicleInfos,
        {type: vehicleType, manufacturer: manufacturerName, model: modelName},
    );
    if (!modelId) return null;

    const variantsMap = vehicleInfos[vehicleTypesMapping[vehicleType]][manufacturerId].models[modelId]?.variants;
    if (variantsMap) {
        for (const [variantId, variantData] of Object.entries(variantsMap)) {
            if (variantData.name === variantName) {
                return variantId;
            }
        }
    }

    return null;
};

// get manufacturer list for the given vehicle type (sort optionally)
export const getManufacturerList = (vehicleInfos, vehicleData = {}, sortAscByName = false) => {
    if (typeof vehicleData !== 'object') return [];
    const {type: vehicleType} = vehicleData;
    if (!vehicleType) return [];

    if (vehicleInfos[vehicleTypesMapping[vehicleType]]) {
        const manufacturerList = Object.keys(vehicleInfos[vehicleTypesMapping[vehicleType]]) || [];

        return sortAscByName
            ? manufacturerList.sort((manufacturerKeyA, manufacturerKeyB) => {
                const manufacturerA = vehicleInfos[vehicleTypesMapping[vehicleType]][manufacturerKeyA].name;
                const manufacturerB = vehicleInfos[vehicleTypesMapping[vehicleType]][manufacturerKeyB].name;
                return manufacturerA > manufacturerB ? 1 : -1;
            }) : manufacturerList;
    }

    return [];
};

// get model list for the given manufacturer (sort optionally)
export const getModelList = (vehicleInfos, vehicleData = {}, sortAscByName = false) => {
    if (typeof vehicleData !== 'object') return [];
    const {type: vehicleType, manufacturer: manufacturerId} = vehicleData;
    if (!vehicleType || !manufacturerId) return [];

    const manufacturersMap = vehicleInfos[vehicleTypesMapping[vehicleType]];
    if (manufacturersMap && manufacturersMap[manufacturerId]?.models) {
        const modelList = Object.keys(manufacturersMap[manufacturerId].models) || [];

        return sortAscByName
            ? modelList.sort((modelKeyA, modelKeyB) => {
                const modelA = manufacturersMap[manufacturerId].models[modelKeyA].name;
                const modelB = manufacturersMap[manufacturerId].models[modelKeyB].name;
                return modelA > modelB ? 1 : -1;
            }) : modelList;
    }

    return [];
};

// get variant list for the given model (sort optionally)
export const getVariantList = (vehicleInfos, vehicleData = {}, sortAscByName = false) => {
    if (typeof vehicleData !== 'object') return [];
    const {type: vehicleType, manufacturer: manufacturerId, model: modelId} = vehicleData;
    if (!vehicleType || !manufacturerId || !modelId) return [];

    const manufacturersMap = vehicleInfos[vehicleTypesMapping[vehicleType]];
    const modelsMap = manufacturersMap ? manufacturersMap[manufacturerId]?.models : [];
    if (modelsMap && modelsMap[modelId]?.variants) {
        const variantList = Object.keys(modelsMap[modelId].variants) || [];

        return sortAscByName
            ? variantList.sort((variantKeyA, variantKeyB) => {
                const variantA = modelsMap[modelId].variants[variantKeyA].name;
                const variantB = modelsMap[modelId].variants[variantKeyB].name;
                return variantA > variantB ? 1 : -1;
            }) : variantList;
    }

    return [];
};

const useVehicleInfo = () => {
    const vehicleInfos = useSelector(state => state.serviceCases.vehicleInfos);
    if (!vehicleInfos) return {
        getManufacturerNameById: () => null,
        getManufacturerIdByName: () => null,
        getModelNameById: () => null,
        getModelIdByName: () => null,
        getVariantIdByName: () => null,
        getVariantNameById: () => null,
        getManufacturerList: () => [],
        getModelList: () => [],
        getVariantList: () => [],
    };

    return {
        getManufacturerNameById: params => getManufacturerNameById(vehicleInfos, params),
        getManufacturerIdByName: params => getManufacturerIdByName(vehicleInfos, params),
        getModelNameById: params => getModelNameById(vehicleInfos, params),
        getModelIdByName: params => getModelIdByName(vehicleInfos, params),
        getVariantNameById: params => getVariantNameById(vehicleInfos, params),
        getVariantIdByName: params => getVariantIdByName(vehicleInfos, params),
        getManufacturerList: params => getManufacturerList(vehicleInfos, params),
        getModelList: params => getModelList(vehicleInfos, params),
        getVariantList: params => getVariantList(vehicleInfos, params),
    };
};

export default useVehicleInfo;
