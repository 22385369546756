import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

/**
 * Search service cases
 */
const searchServiceCases = function* searchServiceCases({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    const {searchQueryParams} = payload;

    yield fork(fetchRequest,
        serviceCaseActionTypes.SEARCH_SERVICE_CASES_REQUEST,
        leaAssignmentFlowService.getServiceCases, {
            searchQueryParams,
        });

    const searchServiceCasesResponseAction = yield take([
        serviceCaseActionTypes.SEARCH_SERVICE_CASES_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.SEARCH_SERVICE_CASES_REQUEST_FAILED,
    ]);

    if (!searchServiceCasesResponseAction.error) {
        const {response} = searchServiceCasesResponseAction.payload;
        const {serviceCaseDTOs, totalCount} = response;

        yield put({
            type: serviceCaseActionTypes.STORE_SERVICE_CASE_SEARCH_RESULTS,
            payload: {serviceCaseDTOs, totalCount},
        });
    }
};

export default searchServiceCases;
