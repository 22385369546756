import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {useStyles, ContentBlock, ContentItem, Panel, HighlightCircle} from '@ace-de/ui-components';
import {Icon, lockIcon, calendarIcon} from '@ace-de/ui-components/icons';
import MemberDataPanel from './ui-elements/MemberDataPanel';
import VehicleDataPanel from './ui-elements/VehicleDataPanel';
import DriverAndOwnerPanel from './ui-elements/DriverAndOwnerPanel';
import DamageLocationPanel from './ui-elements/DamageLocationPanel';
import * as serviceCaseSelectors from './serviceCaseSelectors';

const SCBaseDataTab = ({serviceCase}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sc_base_data_tab');

    if (!serviceCase) return null;
    const {damage} = serviceCase;

    return (
        <ContentBlock>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <MemberDataPanel />
                <VehicleDataPanel />
                <DriverAndOwnerPanel />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <DamageLocationPanel />
                <Panel title={translateTab('damage_description_and_date_panel.title')}>
                    <div className={cx('global!ace-u-flex', 'global!ace-u-flex--direction-column')}>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-typography--variant-body-medium',
                                'ace-u-margin--bottom-16',
                            ])}
                        >
                            <HighlightCircle
                                className={cx([
                                    'ace-c-highlight-circle--small',
                                    'global!ace-u-margin--right-16',
                                    'ace-c-highlight-circle--primary-highlight',
                                ])}
                            >
                                <Icon
                                    icon={calendarIcon}
                                    className={cx('global!ace-c-icon--color-highlight')}
                                />
                            </HighlightCircle>
                            <span>
                                {damage?.date
                                    ? moment(damage.date).format('DD.MM.YYYY')
                                    : serviceCase?.createdAt
                                        ? serviceCase?.createdAt
                                        : '-'}
                            </span>
                        </div>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-typography--variant-body-medium',
                            ])}
                        >
                            <HighlightCircle
                                className={cx([
                                    'ace-c-highlight-circle--small',
                                    'ace-c-highlight-circle--primary-highlight',
                                    'global!ace-u-margin--right-16',
                                    'global!ace-u-flex--shrink-0',
                                ])}
                            >
                                <Icon
                                    icon={lockIcon}
                                    className={cx('global!ace-c-icon--color-highlight')}
                                />
                            </HighlightCircle>
                            <p>{damage?.description || '-'}</p>
                        </div>
                    </div>
                </Panel>
            </ContentItem>
        </ContentBlock>
    );
};

SCBaseDataTab.propTypes = {
    serviceCase: PropTypes.object,
};

SCBaseDataTab.defaultProps = {
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();

    return {
        serviceCase: getServiceCase(state, props),
    };
};
export default withRouter(connect(mapStateToProps, null)(SCBaseDataTab));
