import {select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as invoiceActionTypes from '../invoiceActionTypes';
import modalIds from '../../modalIds';
import updateInvoice from './updateInvoice';

const invoiceDocumentUploadFlow = function* invoiceDocumentUploadFlow() {
    while (true) {
        const {payload} = yield take(invoiceActionTypes.INITIATE_INVOICE_DOCUMENT_UPLOAD_FLOW);
        const {invoiceId} = payload;

        yield* openModal(modalIds.INVOICE_DOCUMENT_MODAL);

        const chosenModalAction = yield take([
            invoiceActionTypes.DECLINE_INVOICE_DOCUMENT_UPLOAD,
            invoiceActionTypes.CONFIRM_INVOICE_DOCUMENT_UPLOAD,
        ]);

        if (chosenModalAction.type === invoiceActionTypes.CONFIRM_INVOICE_DOCUMENT_UPLOAD) {
            const {payload} = chosenModalAction;
            const {attachments} = payload;
            const {invoices} = yield select(state => state.invoices);
            const invoice = invoices[invoiceId];
            yield* updateInvoice({
                caller: invoiceActionTypes.SUBMIT_INVOICE_UPLOADED_DOCUMENTS,
                invoiceId,
                invoiceData: {attachments: [...invoice.attachments, ...attachments]},
            });
        }

        yield* closeModal(modalIds.INVOICE_DOCUMENT_MODAL);
    }
};

export default invoiceDocumentUploadFlow;
