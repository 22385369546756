/**
 * @typedef {string} tabId
 */

/**
 * invoice overview screen tabs
 *
 * @enum {tabId}
 */
export default {
    AUDITING: 'auditing',
    CASE_OVERVIEW: 'case-overview',
    LOG: 'log',
    MIA_FORM: 'mia-form',
    COMMUNICATION: 'communication',
    TASKS: 'tasks',
};
