import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {FileAssetsDropZone, FileAssetsInput, useFileAssets} from '@ace-de/eua-file-assets';
import {ButtonPrimary, Form, HighlightCircle, Modal, useStyles} from '@ace-de/ui-components';
import {InteractiveIcon, Icon, closeIcon, uploadIcon, plusIcon, infoAlertIcon, saveIcon} from '@ace-de/ui-components/icons';
import * as invoiceActionTypes from '../invoiceActionTypes';
import config from '../../config';
import allowedAttachmentMIMETypes from '../allowedAttachmentMIMETypes';
import InvoiceDocumentItem from '../ui-elements/InvoiceDocumentItem';

const InvoiceDocumentModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('invoice_document_modal');
    const {hasBackdrop, location, selectedFileAsset} = props;
    const {declineDocumentUpload, confirmDocumentUpload, declineDocumentEdit, confirmDocumentEdit} = props;
    const isEditModeActive = location?.query?.mode === 'edit';
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(!isEditModeActive);
    const [deletedFilesIds, setDeletedFilesIds] = useState([]);
    const [isFormTouched, setIsFormTouched] = useState(false);

    const {configure: configureFileAssetsService, fileList, deleteFile} = useFileAssets({
        service: 'alfFileAssetsService',
        fileList: 'invoice-documents',
    });

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape') {
            if (!isEditModeActive) {
                declineDocumentUpload();
                return;
            }
            declineDocumentEdit();
        }
    }, [declineDocumentUpload, declineDocumentEdit, isEditModeActive]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    useEffect(() => {
        configureFileAssetsService({
            maxFileSize: config.MAXIMUM_FILE_ASSETS_FILE_SIZE_BYTES,
            allowedMIMETypes: allowedAttachmentMIMETypes,
        });
    }, [configureFileAssetsService]);

    useEffect(() => {
        if (!isEditModeActive && [...fileList.fileAssets.values()].length === 0) {
            setIsSubmitDisabled(true);
            return;
        }

        if (!isFormTouched) {
            let isSubmitButtonDisabled = false;
            [...fileList.fileAssets.values()].forEach(fileAsset => {
                if (!fileAsset.fileName?.split('.')[0]) isSubmitButtonDisabled = true;
            });
            setIsSubmitDisabled(isSubmitButtonDisabled);
        }
    }, [fileList, isEditModeActive, isFormTouched]);

    const handleOnSubmit = formValues => {
        const attachments = Object.entries(formValues).map(([formKey, fileAsset]) => {
            if (!fileAsset || deletedFilesIds.includes(formKey)) return null;
            return {
                ...fileAsset,
                ...(!isEditModeActive && !fileAsset.description && {description: fileList.fileAssets.get(formKey).fileName.split('.')[0]}),
                id: formKey,
                fileName: isEditModeActive ? selectedFileAsset.fileName : fileList.fileAssets.get(formKey).fileName,
            };
        }).filter(value => !!value);

        if (isEditModeActive) {
            confirmDocumentEdit({
                editedAttachment: attachments[0],
            });
            return;
        }

        confirmDocumentUpload({
            attachments,
        });
    };

    const handleOnChange = formValues => {
        if (Object.values(formValues).length === 0) return;
        let isSubmitButtonDisabled = false;

        Object.values(formValues).forEach(fileAsset => {
            if (!fileAsset || deletedFilesIds.includes(fileAsset.id)) return;
            if (Object.values(fileAsset).length > 0 && !fileAsset.description) isSubmitButtonDisabled = true;
        });
        setIsSubmitDisabled(isSubmitButtonDisabled);
        setIsFormTouched(true);
    };

    const handleOnDeleteFile = fileAsset => {
        deleteFile(
            fileAsset,
            config.SHOULD_SEND_DOCUMENT_DELETE_REQUEST,
        );
        setDeletedFilesIds(prevState => ([
            ...prevState,
            fileAsset.id,
        ]));
    };

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={!isEditModeActive ? declineDocumentUpload : declineDocumentEdit}
                />
            )}
            hasBackdrop={hasBackdrop}
            hasColoredHeader={true}
            title={!isEditModeActive ? translateModal('modal_title.document_upload') : translateModal('modal_title.edit_document')}
            contentClassName={cx([
                'ace-c-modal__content--scrollable',
                'global!ace-u-modal-content-size--m',
            ])}
        >
            {!isEditModeActive && (
                <FileAssetsDropZone
                    service="alfFileAssetsService"
                    fileList="invoice-documents"
                    className={cx([
                        'global!ace-u-padding--32',
                        'global!ace-u-margin--32-256',
                        'global!ace-u-width--full',
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-center',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <HighlightCircle
                        className={cx([
                            'global!ace-u-margin--right-32',
                            'ace-c-highlight-circle--medium',
                            'ace-c-highlight-circle--primary-highlight',
                        ])}
                    >
                        <Icon
                            icon={uploadIcon}
                            className={cx([
                                'ace-c-icon--xxl',
                                'ace-c-icon--color-highlight',
                            ])}
                        />
                    </HighlightCircle>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--direction-column',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <h3 className={cx('global!ace-u-typography--variant-h3')}>
                            {translateModal('title.document_upload')}
                        </h3>
                        <FileAssetsInput
                            service="alfFileAssetsService"
                            fileList="invoice-documents"
                            className={cx([
                                'global!ace-u-margin--16-0',
                                'ace-c-file-input--no-chrome',
                            ])}
                        >
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-typography--variant-highlight',
                                    'global!ace-u-typography--color-highlighted',
                                    'global!ace-u-cursor--pointer',
                                ])}
                            >
                                <Icon
                                    icon={plusIcon}
                                    className={cx([
                                        'global!ace-u-margin--right-8',
                                        'ace-c-icon--color-highlight',
                                    ])}
                                />
                                {translateModal('label.search_documents')}
                            </div>
                        </FileAssetsInput>
                    </div>
                </FileAssetsDropZone>
            )}
            {!isEditModeActive && fileList.errors.length > 0 && (
                <div className={cx('global!ace-u-margin--8-0')}>
                    {fileList.errors.map((errorInfo, index) => {
                        return (
                            <div
                                key={index}
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-margin--4-0',
                                    'global!ace-u-typography--variant-caption',
                                    'global!ace-u-typography--color-warning',
                                ])}
                            >
                                <Icon
                                    icon={infoAlertIcon}
                                    className={cx([
                                        'global!ace-u-margin--right-4',
                                        'ace-c-icon--s',
                                        'ace-c-icon--color-warning',
                                    ])}
                                />
                                {translateModal('upload_error.message')}
                            </div>
                        );
                    })}
                </div>
            )}
            <div className={cx('global!ace-u-width--full')}>
                <Form name="invoiceFileAssetsForm" onChange={handleOnChange} onSubmit={handleOnSubmit}>
                    {formValues => (
                        <>
                            {!isEditModeActive
                                ? [...fileList.fileAssets.values()].length > 0
                                && [...fileList.fileAssets.values()].map(fileAsset => (
                                    <InvoiceDocumentItem
                                        key={fileAsset.id}
                                        formValues={formValues}
                                        fileAsset={fileAsset}
                                        deleteFile={handleOnDeleteFile}
                                    />
                                ))
                                : (
                                    <InvoiceDocumentItem
                                        fileAsset={selectedFileAsset}
                                        formValues={formValues}
                                    />
                                )
                            }
                            <div
                                className={cx([
                                    'global!ace-u-width--full',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--justify-flex-end',
                                ])}
                            >
                                <ButtonPrimary
                                    name="confirm-invoice-document-upload"
                                    isDisabled={isSubmitDisabled}
                                    className={cx('global!ace-u-margin--top-32')}
                                    type="submit"
                                >
                                    <Icon
                                        icon={saveIcon}
                                        className={cx(['ace-c-icon--color-contrast', 'global!ace-u-margin--right-8'])}
                                    />
                                    {translateModal('button_label.save')}
                                </ButtonPrimary>
                            </div>
                        </>
                    )}
                </Form>
            </div>
        </Modal>
    );
};

InvoiceDocumentModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    declineDocumentUpload: PropTypes.func.isRequired,
    confirmDocumentUpload: PropTypes.func.isRequired,
    declineDocumentEdit: PropTypes.func.isRequired,
    confirmDocumentEdit: PropTypes.func.isRequired,
    selectedFileAsset: PropTypes.object,
    location: PropTypes.object,
};

InvoiceDocumentModal.defaultProps = {
    hasBackdrop: false,
    selectedFileAsset: null,
    location: {},
};

const mapStateToProps = state => ({
    selectedFileAsset: state.invoices.selectedInvoiceUploadedDocument,
});

const mapDispatchToProps = dispatch => ({
    declineDocumentUpload: () => dispatch({
        type: invoiceActionTypes.DECLINE_INVOICE_DOCUMENT_UPLOAD,
    }),
    confirmDocumentUpload: payload => dispatch({
        type: invoiceActionTypes.CONFIRM_INVOICE_DOCUMENT_UPLOAD,
        payload,
    }),
    declineDocumentEdit: () => dispatch({
        type: invoiceActionTypes.DECLINE_INVOICE_DOCUMENT_EDIT,
    }),
    confirmDocumentEdit: payload => dispatch({
        type: invoiceActionTypes.CONFIRM_INVOICE_DOCUMENT_EDIT,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDocumentModal);
