import {ampVehicleFuelTypes, ampVehicleLicensePlateCountryTypes, ampVehicleSpecialFeatureTypes, ampVehicleTypes} from '@ace-de/eua-entity-types';

const createDefaultVehicle = () => {
    return {
        type: ampVehicleTypes.CAR,
        manufacturer: '',
        model: '',
        variant: '',
        numberOfSeats: '',
        length: '',
        width: '',
        licensePlateCountryFlag: ampVehicleLicensePlateCountryTypes.GERMANY,
        licensePlateNumber: '',
        color: '',
        weight: '',
        height: '',
        buildYear: '',
        fuelType: ampVehicleFuelTypes.UNKNOWN,
        specialFeatures: [ampVehicleSpecialFeatureTypes.UNKNOWN], // NOTE: should be wrapped in array, BE
        vehicleLicensePlateNumber1: '',
        vehicleLicensePlateNumber2: '',
        vehicleLicensePlateNumber3: '',
    };
};

export default createDefaultVehicle;
