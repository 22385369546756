import {ampVehicleTypes} from '@ace-de/eua-entity-types';

const vehicleTypesMapping = {
    [ampVehicleTypes.CAR]: 1,
    [ampVehicleTypes.TRUCK]: 4,
    [ampVehicleTypes.CAMPER]: 2,
    [ampVehicleTypes.MOTORBIKE]: 3,
};

export default vehicleTypesMapping;
