import {put, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as invoiceActionTypes from '../invoiceActionTypes';
import modalIds from '../../modalIds';
import updateInvoice from './updateInvoice';

const invoiceDocumentEditFlow = function* invoiceDocumentEditFlow() {
    while (true) {
        const {payload} = yield take(invoiceActionTypes.INITIATE_INVOICE_DOCUMENT_EDIT_FLOW);
        const {attachment, invoiceId} = payload;

        yield put({
            type: invoiceActionTypes.STORE_SELECTED_INVOICE_UPLOADED_DOCUMENT,
            payload: {attachment},
        });

        yield* openModal(modalIds.INVOICE_DOCUMENT_MODAL, {mode: 'edit'});

        const chosenModalAction = yield take([
            invoiceActionTypes.DECLINE_INVOICE_DOCUMENT_EDIT,
            invoiceActionTypes.CONFIRM_INVOICE_DOCUMENT_EDIT,
        ]);

        if (chosenModalAction.type === invoiceActionTypes.CONFIRM_INVOICE_DOCUMENT_EDIT) {
            const {payload} = chosenModalAction;
            const {editedAttachment} = payload;
            const {invoices} = yield select(state => state.invoices);
            const invoice = invoices[invoiceId];

            yield* updateInvoice({
                caller: invoiceActionTypes.SUBMIT_INVOICE_UPLOADED_DOCUMENTS,
                invoiceId,
                invoiceData: {
                    attachments: invoice.attachments.map(attachment => {
                        return attachment.id !== editedAttachment.id
                            ? attachment
                            : editedAttachment;
                    }),
                },
            });
        }

        yield* closeModal(modalIds.INVOICE_DOCUMENT_MODAL, {mode: 'edit'});
    }
};

export default invoiceDocumentEditFlow;
