import {take, put, select, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as communicationActionTypes from '../communicationActionTypes';

const fetchAccountPartyDetails = function* fetchAccountPartyDetails() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take([communicationActionTypes.FETCH_ACCOUNT_PARTY_CONTACT_DETAILS]);
        const {searchQueryParams} = payload;

        if (searchQueryParams) {
            yield fork(
                fetchRequest,
                communicationActionTypes.FETCH_ACCOUNT_PARTY_CONTACT_DETAILS_REQUEST,
                leaAssignmentFlowService.getContacts,
                {searchQueryParams},
            );

            const responseAction = yield take([
                communicationActionTypes.FETCH_ACCOUNT_PARTY_CONTACT_DETAILS_REQUEST_SUCCEEDED,
                communicationActionTypes.FETCH_ACCOUNT_PARTY_CONTACT_DETAILS_REQUEST_FAILED,
            ]);
            if (!responseAction.error) {
                const {payload: fetchResultsPayload} = responseAction;
                const {contactDTOs} = fetchResultsPayload.response;

                yield put({
                    type: communicationActionTypes.STORE_ACCOUNT_PARTY_CONTACT_DETAILS,
                    payload: {contactDTOs},
                });
            }

            if (responseAction.error) {
                yield put({
                    type: communicationActionTypes.STORE_ACCOUNT_PARTY_CONTACT_DETAILS,
                    payload: {contactDTOs: []},
                });
            }
        }
    }
};

export default fetchAccountPartyDetails;
