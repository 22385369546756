/**
 * * Command action types
 * */
export const INITIATE_SEND_MESSAGE_FLOW = '[communication] INITIATE_SEND_MESSAGE_FLOW';
export const CONFIRM_SEND_MESSAGE = '[communication] CONFIRM_SEND_MESSAGE';
export const DECLINE_SEND_MESSAGE = '[communication] DECLINE_SEND_MESSAGE';
export const FETCH_ACCOUNT_PARTY_CONTACT_DETAILS = '[communication] SEARCH_ACCOUNT_PARTY_CONTACT_DETAILS';
export const INITIATE_UPLOAD_REPLY_FLOW = '[communication] INITIATE_UPLOAD_REPLY_FLOW';
export const DECLINE_UPLOAD_REPLY = '[communication] DECLINE_UPLOAD_REPLY';
export const CONFIRM_UPLOAD_REPLY = '[communication] CONFIRM_UPLOAD_REPLY';
export const INITIATE_MESSAGE_ATTACHMENTS_DOWNLOAD_FLOW = '[communication] INITIATE_MESSAGE_ATTACHMENTS_DOWNLOAD_FLOW';
export const UPDATE_MESSAGE_AS_READ = '[communication] UPDATE_MESSAGE_AS_READ';
export const INITIATE_REDIRECT_TO_INVOICE_PAGE = '[communication] INITIATE_REDIRECT_TO_INVOICE_PAGE';
export const SEARCH_MESSAGES = '[communication] SEARCH_MESSAGES';
export const RETRY_MESSAGE_SEND = '[communication] RETRY_MESSAGE_SEND';
export const CANCEL_RETRY_MESSAGE_SEND = '[communication] CANCEL_RETRY_MESSAGE_SEND';
export const RETRY_MESSAGE_UPLOAD = '[communication] RETRY_MESSAGE_UPLOAD';
export const CANCEL_RETRY_MESSAGE_UPLOAD = '[communication] CANCEL_RETRY_MESSAGE_UPLOAD';

/**
 * Event action types
 */
export const FETCH_COMMUNICATION_SENDER_ACCOUNTS_REQUEST = '[communication] FETCH_COMMUNICATION_SENDER_ACCOUNTS_REQUEST';
export const FETCH_COMMUNICATION_SENDER_ACCOUNTS_REQUEST_SENT = '[communication] FETCH_COMMUNICATION_SENDER_ACCOUNTS_REQUEST_SENT';
export const FETCH_COMMUNICATION_SENDER_ACCOUNTS_REQUEST_SUCCEEDED = '[communication] FETCH_COMMUNICATION_SENDER_ACCOUNTS_REQUEST_SUCCEEDED';
export const FETCH_COMMUNICATION_SENDER_ACCOUNTS_REQUEST_FAILED = '[communication] FETCH_COMMUNICATION_SENDER_ACCOUNTS_REQUEST_FAILED';

export const SEND_MESSAGE_REQUEST = '[communication] SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_REQUEST_SENT = '[communication] SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_REQUEST_SUCCEEDED = '[communication] SEND_MESSAGE_REQUEST_SUCCEEDED';
export const SEND_MESSAGE_REQUEST_FAILED = '[communication] SEND_MESSAGE_REQUEST_FAILED';

export const FETCH_ACCOUNT_PARTY_CONTACT_DETAILS_REQUEST = '[communication] FETCH_ACCOUNT_PARTY_CONTACT_DETAILS_REQUEST';
export const FETCH_ACCOUNT_PARTY_CONTACT_DETAILS_REQUEST_SENT = '[communication] FETCH_ACCOUNT_PARTY_CONTACT_DETAILS_REQUEST_SENT';
export const FETCH_ACCOUNT_PARTY_CONTACT_DETAILS_REQUEST_SUCCEEDED = '[communication] FETCH_ACCOUNT_PARTY_CONTACT_DETAILS_REQUEST_SUCCEEDED';
export const FETCH_ACCOUNT_PARTY_CONTACT_DETAILS_REQUEST_FAILED = '[communication] FETCH_ACCOUNT_PARTY_CONTACT_DETAILS_REQUEST_FAILED';

export const FETCH_MESSAGES_REQUEST = '[communication] FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_REQUEST_SENT = '[communication] FETCH_MESSAGES_REQUEST_SENT';
export const FETCH_MESSAGES_REQUEST_SUCCEEDED = '[communication] FETCH_MESSAGES_REQUEST_SUCCEEDED';
export const FETCH_MESSAGES_REQUEST_FAILED = '[communication] FETCH_MESSAGES_REQUEST_FAILED';

export const DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST = '[communication] DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST';
export const DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST_SENT = '[communication] DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST_SENT';
export const DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST_SUCCEEDED = '[communication] DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST_SUCCEEDED';
export const DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST_FAILED = '[communication] DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST_FAILED';

export const DOWNLOAD_MESSAGE_PDF_REQUEST = '[communication] DOWNLOAD_MESSAGE_PDF_REQUEST';
export const DOWNLOAD_MESSAGE_PDF_REQUEST_SENT = '[communication] DOWNLOAD_MESSAGE_PDF_REQUEST_SENT';
export const DOWNLOAD_MESSAGE_PDF_REQUEST_SUCCEEDED = '[communication] DOWNLOAD_MESSAGE_PDF_REQUEST_SUCCEEDED';
export const DOWNLOAD_MESSAGE_PDF_REQUEST_FAILED = '[communication] DOWNLOAD_MESSAGE_PDF_REQUEST_FAILED';

export const UPLOAD_REPLY_MESSAGE_REQUEST = '[communication] UPLOAD_REPLY_MESSAGE_REQUEST';
export const UPLOAD_REPLY_MESSAGE_REQUEST_SENT = '[communication] UPLOAD_REPLY_MESSAGE_REQUEST_SENT';
export const UPLOAD_REPLY_MESSAGE_REQUEST_SUCCEEDED = '[communication] UPLOAD_REPLY_MESSAGE_REQUEST_SUCCEEDED';
export const UPLOAD_REPLY_MESSAGE_REQUEST_FAILED = '[communication] UPLOAD_REPLY_MESSAGE_REQUEST_FAILED';

export const UPDATE_MESSAGE_AS_READ_REQUEST = '[communication] UPDATE_MESSAGE_AS_READ_REQUEST';
export const UPDATE_MESSAGE_AS_READ_REQUEST_SENT = '[communication] UPDATE_MESSAGE_AS_READ_REQUEST_SENT';
export const UPDATE_MESSAGE_AS_READ_REQUEST_SUCCEEDED = '[communication] UPDATE_MESSAGE_AS_READ_REQUEST_SUCCEEDED';
export const UPDATE_MESSAGE_AS_READ_REQUEST_FAILED = '[communication] UPDATE_MESSAGE_AS_READ_REQUEST_FAILED';

export const SEARCH_MEMBER_MESSAGES_REQUEST = '[contacts] SEARCH_MEMBER_MESSAGES_REQUEST';
export const SEARCH_MEMBER_MESSAGES_REQUEST_SENT = '[contacts] SEARCH_MEMBER_MESSAGES_REQUEST_SENT';
export const SEARCH_MEMBER_MESSAGES_REQUEST_SUCCEEDED = '[contacts] SEARCH_MEMBER_MESSAGES_REQUEST_SUCCEEDED';
export const SEARCH_MEMBER_MESSAGES_REQUEST_FAILED = '[contacts] SEARCH_MEMBER_MESSAGES_REQUEST_FAILED';

export const SEARCH_MESSAGES_REQUEST = '[contacts] SEARCH_MESSAGES_REQUEST';
export const SEARCH_MESSAGES_REQUEST_SENT = '[contacts] SEARCH_MESSAGES_REQUEST_SENT';
export const SEARCH_MESSAGES_REQUEST_SUCCEEDED = '[contacts] SEARCH_MESSAGES_REQUEST_SUCCEEDED';
export const SEARCH_MESSAGES_REQUEST_FAILED = '[contacts] SEARCH_MESSAGES_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_COMMUNICATION_SENDER_ACCOUNTS = '[communication] STORE_COMMUNICATION_SENDER_ACCOUNTS';
export const STORE_ACCOUNT_PARTY_CONTACT_DETAILS = '[communication] STORE_ACCOUNT_PARTY_CONTACT_DETAILS';
export const RESET_ACCOUNT_PARTY_CONTACT_DETAILS = '[communication] RESET_ACCOUNT_PARTY_CONTACT_DETAILS';
export const STORE_MESSAGES = '[communication] STORE_MESSAGES';
export const STORE_ELLA_UNREAD_MESSAGES = '[communication] STORE_ELLA_UNREAD_MESSAGES';
export const SET_PERSISTENCE_STATE = '[communication] SET_PERSISTENCE_STATE';
export const STORE_MESSAGE_SEARCH_RESULTS = '[communication] STORE_MESSAGE_SEARCH_RESULTS';
export const RESET_MESSAGE_SEARCH_RESULTS = '[communication] RESET_MESSAGE_SEARCH_RESULTS';
