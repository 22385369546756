import {fork} from 'redux-saga/effects';
import applicationWatcher from './application/applicationWatcher';
import keycloakAuth from './keycloakAuth';
import memberWatcher from './members/memberWatcher';
import arcGISAuth from './arcGISAuth';
import serviceCaseWatcher from './service-cases/serviceCaseWatcher';
import invoiceWatcher from './invoices/invoiceWatcher';
import contactWatcher from './contacts/contactWatcher';
import communicationWatcher from './communication/communicationWatcher';
import caseLogWatcher from './case-logs/caseLogWatcher';
import taskWatcher from './tasks/taskWatcher';

// application sagas
export default [
    fork(applicationWatcher),
    fork(keycloakAuth.watcher),
    fork(arcGISAuth.watcher),
    fork(memberWatcher),
    fork(serviceCaseWatcher),
    fork(invoiceWatcher),
    fork(contactWatcher),
    fork(communicationWatcher),
    fork(caseLogWatcher),
    fork(taskWatcher),
];
