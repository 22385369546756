import {
    markerDestinationPNG,
    markerDamagePNG,
    markerServicePNG,
    markerServiceProviderPNG,
    markerHomeVariantDarkBluePNG,
    markerCombinedServiceProviderAndHomePNG,
    markerCombinedHomeAndDestinationPNG,
    markerCombinedServiceAndDestinationPNG,
    markerCombinedHomeAndDamagePNG,
    markerCombinedServiceProviderAndDamagePNG,
    markerCombinedServiceProviderAndDestinationPNG,
    markerECSTowingDestinationPNG,
    markerCombinedDamageAndServicePNG,
} from '@ace-de/eua-arcgis-map';
import {alfRelevantLocationTypes} from '@ace-de/eua-entity-types';

export const combinedIconTypes = {
    COMBINED_ACE_PARTNER_AND_MEMBER: 'COMBINED_ACE_PARTNER_AND_MEMBER',
    COMBINED_ACE_PARTNER_AND_DESTINATION: 'COMBINED_ACE_PARTNER_AND_DESTINATION',
    COMBINED_TOWING_AND_ACE_PARTNER: 'COMBINED_TOWING_AND_ACE_PARTNER',
    COMBINED_FINAL_TOWING_AND_ACE_PARTNER: 'COMBINED_FINAL_TOWING_AND_ACE_PARTNER',
    COMBINED_DAMAGE_AND_ACE_PARTNER: 'COMBINED_DAMAGE_AND_ACE_PARTNER',
    COMBINED_DAMAGE_AND_MEMBER: 'COMBINED_DAMAGE_AND_MEMBER',
    COMBINED_DAMAGE_AND_SERVICE: 'COMBINED_DAMAGE_AND_SERVICE',
    COMBINED_SERVICE_AND_TOWING: 'COMBINED_SERVICE_AND_TOWING',
    COMBINED_SERVICE_AND_FINAL_TOWING: 'COMBINED_SERVICE_AND_FINAL_TOWING',
    COMBINED_TOWING_AND_MEMBER: 'COMBINED_TOWING_AND_MEMBER',
    COMBINED_FINAL_TOWING_AND_MEMBER: 'COMBINED_FINAL_TOWING_AND_MEMBER',
    COMBINED_DESTINATION_AND_MEMBER: 'COMBINED_DESTINATION_AND_MEMBER',
};

export const relevantLocationIcons = {
    [alfRelevantLocationTypes.TOWING]: markerECSTowingDestinationPNG,
    [alfRelevantLocationTypes.FINAL_TOWING]: markerDestinationPNG,
    [alfRelevantLocationTypes.DAMAGE]: markerDamagePNG,
    [alfRelevantLocationTypes.SERVICE]: markerServicePNG,
    [alfRelevantLocationTypes.MEMBER]: markerHomeVariantDarkBluePNG,
    [alfRelevantLocationTypes.ACE_PARTNER]: markerServiceProviderPNG,
    [combinedIconTypes.COMBINED_ACE_PARTNER_AND_MEMBER]: markerCombinedServiceProviderAndHomePNG,
    [combinedIconTypes.COMBINED_ACE_PARTNER_AND_DESTINATION]: markerCombinedServiceProviderAndDestinationPNG,
    [combinedIconTypes.COMBINED_TOWING_AND_ACE_PARTNER]: markerCombinedServiceProviderAndDestinationPNG,
    [combinedIconTypes.COMBINED_FINAL_TOWING_AND_ACE_PARTNER]: markerCombinedServiceProviderAndDestinationPNG,
    [combinedIconTypes.COMBINED_DAMAGE_AND_ACE_PARTNER]: markerCombinedServiceProviderAndDamagePNG,
    [combinedIconTypes.COMBINED_DAMAGE_AND_MEMBER]: markerCombinedHomeAndDamagePNG,
    [combinedIconTypes.COMBINED_DAMAGE_AND_SERVICE]: markerCombinedDamageAndServicePNG,
    [combinedIconTypes.COMBINED_SERVICE_AND_TOWING]: markerCombinedServiceAndDestinationPNG,
    [combinedIconTypes.COMBINED_SERVICE_AND_FINAL_TOWING]: markerCombinedServiceAndDestinationPNG,
    [combinedIconTypes.COMBINED_TOWING_AND_MEMBER]: markerCombinedHomeAndDestinationPNG,
    [combinedIconTypes.COMBINED_FINAL_TOWING_AND_MEMBER]: markerCombinedHomeAndDestinationPNG,
    [combinedIconTypes.COMBINED_DESTINATION_AND_MEMBER]: markerCombinedHomeAndDestinationPNG,
    [alfRelevantLocationTypes.DESTINATION]: markerDestinationPNG,
    [alfRelevantLocationTypes.DROP_OFF]: markerDestinationPNG,
    [alfRelevantLocationTypes.PICK_UP]: markerServicePNG,
};
export const relevantLocationsHierarchy = {
    [alfRelevantLocationTypes.SERVICE]: 1,
    [alfRelevantLocationTypes.TOWING]: 2,
    [alfRelevantLocationTypes.FINAL_TOWING]: 2,
    [alfRelevantLocationTypes.ACE_PARTNER]: 3,
    [alfRelevantLocationTypes.MEMBER]: 4,
    [alfRelevantLocationTypes.DESTINATION]: 3,
    // For the combined places take the hierarchy of the higher ranked as primary
    [combinedIconTypes.COMBINED_ACE_PARTNER_AND_MEMBER]: 3,
    [combinedIconTypes.COMBINED_TOWING_AND_ACE_PARTNER]: 2,
    [combinedIconTypes.COMBINED_FINAL_TOWING_AND_ACE_PARTNER]: 2,
    [combinedIconTypes.COMBINED_DAMAGE_AND_ACE_PARTNER]: 1,
    [combinedIconTypes.COMBINED_DAMAGE_AND_MEMBER]: 1,
    [combinedIconTypes.COMBINED_SERVICE_AND_TOWING]: 1,
    [combinedIconTypes.COMBINED_SERVICE_AND_FINAL_TOWING]: 1,
    [combinedIconTypes.COMBINED_TOWING_AND_MEMBER]: 2,
    [combinedIconTypes.COMBINED_FINAL_TOWING_AND_MEMBER]: 2,
    [combinedIconTypes.COMBINED_DESTINATION_AND_MEMBER]: 2,
};
