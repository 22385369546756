import moment from 'moment';

export const formatDurationTime = (time, translate) => {
    if (time < 0) return;
    const duration = moment.duration(time);

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) - (hours * 60);
    const days = Math.floor(duration.asHours() / 24);

    return hours < 24
        ? moment({hours: hours, minutes: minutes}).format('HH:mm')
            ? `${moment({hours: hours, minutes: minutes}).format('HH:mm')} ${translate('global.time_unit.hour_short')}`
            : undefined
        : `${days} ${translate('global.time_unit.day_short')}`;
};
