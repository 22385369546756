import {take, put, fork, select} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as invoiceActionTypes from '../../invoices/invoiceActionTypes';
import config from '../../config';

const loadServiceCaseInvoices = function* loadServiceCaseInvoices({payload}) {
    const {match} = payload;
    const {serviceCaseId} = match.params;
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    if (!serviceCaseId) return;

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('serviceCaseId', serviceCaseId);
    searchQueryParams.append('size', config.MAXIMUM_INVOICE_SIZE_COUNT);

    yield fork(fetchRequest,
        serviceCaseActionTypes.FETCH_SERVICE_CASE_INVOICES_REQUEST,
        leaAssignmentFlowService.getInvoices,
        {
            searchQueryParams,
        });

    const fetchCaseInvoicesAction = yield take([
        serviceCaseActionTypes.FETCH_SERVICE_CASE_INVOICES_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.FETCH_SERVICE_CASE_INVOICES_REQUEST_FAILED,
    ]);

    if (!fetchCaseInvoicesAction.error) {
        const {response} = fetchCaseInvoicesAction.payload;
        const {invoiceDTOs} = response;

        yield put({
            type: invoiceActionTypes.STORE_INVOICES,
            payload: {invoiceDTOs: invoiceDTOs},
        });

        yield put({type: serviceCaseActionTypes.SET_INVOICES,
            payload: {
                invoiceDTOs: invoiceDTOs,
                serviceCaseId,
            }});

        yield put({
            type: serviceCaseActionTypes.STORE_ACCOUNT_PARTY_OPTIONS_IDS,
            payload: {
                accountPartyOptionsIds: [...new Set(invoiceDTOs.map(invoiceDTO => (
                    invoiceDTO.accountParty?.id
                )).filter(value => !!value))],
            },
        });
    }
};

export default loadServiceCaseInvoices;
