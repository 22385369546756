import {take, fork, put, select} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as communicationActionTypes from '../communicationActionTypes';

const searchMessagesFlow = function* searchMessagesFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(communicationActionTypes.SEARCH_MESSAGES);
        const searchQueryParams = new URLSearchParams();

        Object.keys(payload).forEach(key => {
            searchQueryParams.append(key, payload[key]);
        });

        yield fork(
            fetchRequest,
            communicationActionTypes.SEARCH_MESSAGES_REQUEST,
            leaAssignmentFlowService.getMessages,
            {searchQueryParams},
        );

        const responseAction = yield take([
            communicationActionTypes.SEARCH_MESSAGES_REQUEST_SUCCEEDED,
            communicationActionTypes.SEARCH_MESSAGES_REQUEST_FAILED,
        ]);

        if (responseAction.error) {
            // todo handle error
            continue;
        }

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {messageDTOs, total} = response;

            yield put({
                type: communicationActionTypes.STORE_MESSAGE_SEARCH_RESULTS,
                payload: {
                    pageNumber: payload['page'],
                    messagesSearchTotalCount: total,
                    messageDTOs,
                },
            });
        }
    }
};

export default searchMessagesFlow;
