import bcModalContentTypes from './bcModalContentTypes';

/**
 * BC modal config
 */
export default {
    [bcModalContentTypes.INVOICE_CONTACT_SEARCH]: {
        name: 'invoice-contact-search',
        hasBackdrop: true,
        title: 'invoice_contact_search_modal.modal_title.contact_search',
        hasColoredHeader: true,
        hasContentPadding: false,
        contentClassName: ['ace-c-modal__content--scrollable', 'global!ace-u-padding-0'],
    },
    [bcModalContentTypes.CONTACT_DATA]: {
        name: 'contact-data',
        hasBackdrop: true,
        title: 'contact_data_modal.modal_title.',
        hasColoredHeader: true,
        hasContentPadding: true,
        contentClassName: ['global!ace-u-modal-content-size--s', 'ace-c-modal__content--scrollable'],
    },
    [bcModalContentTypes.LOADER]: {
        name: 'loader',
        hasBackdrop: true,
        title: '',
        hasColoredHeader: false,
        hasContentPadding: true,
        contentClassName: [
            'global!ace-u-modal-content-size--s',
            'global!ace-u-padding--128-0',
            'global!ace-u-flex',
            'global!ace-u-flex--direction-column',
            'global!ace-u-flex--align-center',
            'global!ace-u-flex--justify-center',
        ],
    },
    [bcModalContentTypes.BC_ACTION_FAILED]: {
        name: 'bc-action-failed',
        hasBackdrop: true,
        title: '',
        hasColoredHeader: false,
        hasContentPadding: true,
        contentClassName: ['global!ace-u-modal-content-size--s'],
    },
    [bcModalContentTypes.CONTACT_ACCOUNT_PARTY_DATA]: {
        name: 'contact-account-party-data',
        hasBackdrop: true,
        title: 'contact_account_party_data_modal.modal_title.',
        hasColoredHeader: true,
        hasContentPadding: true,
        contentClassName: ['global!ace-u-modal-content-size--m', 'ace-c-modal__content--scrollable'],
    },
    [bcModalContentTypes.COMMUNICATION]: {
        name: 'communication',
        hasBackdrop: true,
        title: 'send_message_modal.modal_title.send_message',
        hasColoredHeader: true,
        hasContentPadding: true,
        contentClassName: ['ace-c-modal__content--scrollable', 'global!ace-u-modal-content-size--l'],
    },
};
