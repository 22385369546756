import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, resolveRoute, prepareSearchQueryParams} from '@computerrock/formation-router';
import {ButtonPrimary, NoResultsBlock, Panel, useStyles} from '@ace-de/ui-components';
import {historyIcon, Icon, InteractiveIcon, linkIcon, plusIcon, editIcon} from '@ace-de/ui-components/icons';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {useTranslate} from '@computerrock/formation-i18n';
import * as memberSelectors from '../memberSelectors';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import routePaths from '../../routePaths';
import config from '../../config';
import {leaFeatures, leaFeatureActions} from '../../application/leaFeatures';
import leaAccessControl from '../../leaAccessControl';

const MemberCaseHistoryPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate, activeLocale} = useTranslate();
    const translateScreen = createTranslateShorthand('member_screen');
    const {memberServiceCases, memberServiceCasesTotalCount, member, history, createNewServiceCase} = props;
    const isCaseCreationAllowed = leaAccessControl.grantFeatureAccess(
        leaFeatures.SERVICE_CASE,
        leaFeatureActions.CREATE,
    );
    const isCaseUpdateAllowed = leaAccessControl.grantFeatureAccess(
        leaFeatures.SERVICE_CASE,
        leaFeatureActions.UPDATE,
    );

    const handleRedirectToServiceCasesScreen = () => {
        const {pathname, search} = resolveRoute(routePaths.SERVICE_CASES_SEARCH, {},
            {search: prepareSearchQueryParams({membershipNo: member.membershipNo})});
        window.open(pathname + search, '_blank');
    };

    const openServiceCaseOverviewScreen = serviceCaseId => {
        history.push(resolveRoute(routePaths.SERVICE_CASES_OVERVIEW, {serviceCaseId}));
    };

    const handleRedirectToServiceCase = (event, serviceCaseId) => {
        event.stopPropagation();
        history.push(resolveRoute(routePaths.SERVICE_CASE, {serviceCaseId}, {search: prepareSearchQueryParams({isEditModeActive: 'true'})}));
    };

    const getPrice = ({price, currency}) => {
        if (typeof price === 'number' && price >= 0) {
            return price.toLocaleString(activeLocale, {
                style: 'currency',
                currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
    };

    return (
        <Panel
            title={translateScreen('case_history_panel_title.case_history')}
            className={cx('ace-c-panel--full-bleed-content')}
            actions={isCaseCreationAllowed && (
                <ButtonPrimary onClick={() => { createNewServiceCase({member}); }}>
                    <Icon
                        className={cx([
                            'global!ace-c-icon--color-contrast',
                            'global!ace-c-icon--s',
                        ])}
                        icon={plusIcon}
                    />
                    {translateScreen('case_history_panel_button.create_case')}
                </ButtonPrimary>
            )}
            qaIdent="member-case-history"
        >
            <Table className={cx('global!ace-u-padding--24')}>
                {memberServiceCases.length === 0 && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={historyIcon}
                                />
                            )}
                            description={translateScreen('case_history_panel_no_results.description')}
                            message={translateScreen('case_history_panel_no_results.message')}
                        />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} qaIdentPart="created-at">
                            {translateScreen('case_history_panel_data_row.created_at')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="service-case-id">
                            {translateScreen('case_history_panel_data_row.case_id')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="damage-location">
                            {translateScreen('case_history_panel_data_row.damage_location')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="license-plate-no">
                            {translateScreen('case_history_panel_data_row.license_plate')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="initial-service">
                            {translateScreen('case_history_panel_data_row.initial_service')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="attached-invoices-no">
                            {translateScreen('case_history_panel_data_row.attached_invoices_number')}
                        </TableCell>
                        <TableCell
                            colSpan={2}
                            qaIdentPart="total-sum"
                            className={cx('global!ace-u-typography--align-right')}
                        >
                            {translateScreen('case_history_panel_data_row.total_sum')}
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {memberServiceCases.map(serviceCase => {
                        const {vehicle, damage} = serviceCase;

                        return (
                            <TableRow
                                key={serviceCase.id}
                                onClick={() => openServiceCaseOverviewScreen(serviceCase.id)}
                            >
                                <TableCell colSpan={2} qaIdentPart="created-at" qaIdentPartPostfix={serviceCase.id}>
                                    {serviceCase.createdAt || '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="service-case-id" qaIdentPartPostfix={serviceCase.id}>
                                    {`${serviceCase.prefix}-${serviceCase.id}` || '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="damage-location" qaIdentPartPostfix={serviceCase.id}>
                                    {damage && damage.location?.address
                                        ? `${damage.location.address.postCode} ${damage.location.address.city}`
                                        : '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="license-plate-no" qaIdentPartPostfix={serviceCase.id}>
                                    {vehicle?.licensePlateNumber ? vehicle.licensePlateNumber : '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="initial-service" qaIdentPartPostfix={serviceCase.id}>
                                    {serviceCase.invoicesInfo?.initialService
                                        ? translate(`global.service_type.${serviceCase.invoicesInfo.initialService.toLowerCase()}`)
                                        : '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="attached-invoices-no" qaIdentPartPostfix={serviceCase.id}>
                                    {serviceCase.invoicesInfo?.invoicesCount || '-'}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="total-sum"
                                    qaIdentPartPostfix={serviceCase.id}
                                    className={cx('global!ace-u-typography--align-right')}
                                >
                                    {getPrice({
                                        price: serviceCase.invoicesInfo?.invoicesBalance,
                                        currency: config.CURRENCY,
                                    }) || '-'}
                                </TableCell>
                                <TableCell>
                                    {serviceCase.isEditable && isCaseUpdateAllowed && (
                                        <InteractiveIcon
                                            icon={editIcon}
                                            onClick={event => handleRedirectToServiceCase(event, serviceCase.id)}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <div
                className={cx([
                    'global!ace-u-margin--24',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-space-between',
                ])}
            >
                <p className={cx('global!ace-u-typography--variant-body-bold')}>
                    {`${memberServiceCases.length} ${translateScreen('case_history_panel_case_indicator.von')} ${memberServiceCasesTotalCount}`}
                </p>
                <InteractiveIcon
                    className={cx([
                        'ace-c-interactive-icon--highlight',
                        'ace-c-interactive-icon--primary',
                    ])}
                    icon={linkIcon}
                    onClick={handleRedirectToServiceCasesScreen}
                >
                    {translateScreen('case_history_panel_link_out.all_cases').toUpperCase()}
                </InteractiveIcon>
            </div>
        </Panel>
    );
};

MemberCaseHistoryPanel.propTypes = {
    memberServiceCases: PropTypes.array,
    memberServiceCasesTotalCount: PropTypes.number,
    member: PropTypes.object,
    history: PropTypes.object,
    createNewServiceCase: PropTypes.func.isRequired,
};

MemberCaseHistoryPanel.defaultProps = {
    memberServiceCases: [],
    memberServiceCasesTotalCount: 0,
    member: null,
    history: {},
};

const mapStateToProps = (state, props) => {
    const memberServiceCasesSelector = memberSelectors.createMemberServiceCasesSelector();
    const memberByMembershipNoSelector = memberSelectors.createMemberByMembershipNoSelector();
    return {
        memberServiceCases: memberServiceCasesSelector(state, props).slice(0, 5),
        member: memberByMembershipNoSelector(state, props),
        memberServiceCasesTotalCount: state.serviceCases.serviceCasesTotalCount,
    };
};

const mapDispatchToProps = dispatch => ({
    createNewServiceCase: payload => dispatch({
        type: serviceCaseActionTypes.CREATE_NEW_SERVICE_CASE,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberCaseHistoryPanel));
