import {put, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as invoiceActionTypes from '../invoiceActionTypes';
import modalIds from '../../modalIds';
import updateInvoice from './updateInvoice';
import deleteModalLocationTypes from '../../application/modals/deleteModalLocationTypes';

const invoiceDocumentDeleteFlow = function* invoiceDocumentDeleteFlow() {
    while (true) {
        const {payload} = yield take(invoiceActionTypes.INITIATE_INVOICE_DOCUMENT_DELETE_FLOW);
        const {attachment, invoiceId} = payload;

        yield put({
            type: invoiceActionTypes.STORE_SELECTED_INVOICE_UPLOADED_DOCUMENT,
            payload: {attachment},
        });

        yield* openModal(modalIds.DELETE_MODAL, {type: deleteModalLocationTypes.DOCUMENT});

        const chosenModalAction = yield take([
            invoiceActionTypes.DECLINE_DELETE,
            invoiceActionTypes.CONFIRM_INVOICE_DOCUMENT_DELETE,
        ]);

        if (chosenModalAction.type === invoiceActionTypes.CONFIRM_INVOICE_DOCUMENT_DELETE) {
            const {payload} = chosenModalAction;
            const {fileAsset} = payload;
            const {invoices} = yield select(state => state.invoices);
            const invoice = invoices[invoiceId];

            if (!invoice) return;

            const attachments = invoices[invoiceId].attachments.filter(attachment => attachment.id !== fileAsset.id);

            yield* updateInvoice({
                caller: invoiceActionTypes.DELETE_INVOICE_UPLOADED_DOCUMENTS,
                invoiceId,
                invoiceData: {attachments},
            });
        }

        yield* closeModal(modalIds.DELETE_MODAL, {type: deleteModalLocationTypes.DOCUMENT});
    }
};

export default invoiceDocumentDeleteFlow;
