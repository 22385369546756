import {produce} from 'immer';
import {LeistContact, Message, persistenceStates} from '@ace-de/eua-entity-types';
import * as communicationActionTypes from './communicationActionTypes';

const initialState = {
    senderAccounts: [],
    accountPartyContactDetails: null,
    messages: [],
    ellaUnreadMessages: [],
    persistenceState: persistenceStates.READY,
    messagesSearchTotalCount: 0,
    messagesSearchResults: [],
    messagesSearchResultsPage: 0,
};

/**
 * Communication reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const communicationReducer = produce((draft, action) => {
    switch (action.type) {
        case communicationActionTypes.STORE_COMMUNICATION_SENDER_ACCOUNTS: {
            const {senderAccountDTOs} = action.payload;
            draft.senderAccounts = senderAccountDTOs;
            break;
        }

        case communicationActionTypes.STORE_MESSAGES: {
            const {messageDTOs} = action.payload;
            draft.messages = messageDTOs.map(messageDTO => {
                return new Message().fromDTO(messageDTO);
            });
            break;
        }

        case communicationActionTypes.STORE_ACCOUNT_PARTY_CONTACT_DETAILS: {
            const {contactDTOs} = action.payload;
            if (contactDTOs.length > 0) {
                draft.accountPartyContactDetails = new LeistContact().fromDTO(contactDTOs[0]);
            }
            if (contactDTOs.length === 0) {
                draft.accountPartyContactDetails = null;
            }
            break;
        }

        case communicationActionTypes.RESET_ACCOUNT_PARTY_CONTACT_DETAILS: {
            draft.accountPartyContactDetails = null;
            break;
        }

        case communicationActionTypes.STORE_ELLA_UNREAD_MESSAGES: {
            const {messageDTOs} = action.payload;
            draft.ellaUnreadMessages = messageDTOs.map(messageDTO => (
                new Message().fromDTO(messageDTO)
            ));
            break;
        }

        case communicationActionTypes.SET_PERSISTENCE_STATE: {
            const {newPersistenceState} = action.payload;
            draft.persistenceState = newPersistenceState;
            break;
        }

        case communicationActionTypes.STORE_MESSAGE_SEARCH_RESULTS: {
            const {messageDTOs, messagesSearchTotalCount, pageNumber} = action.payload;
            draft.messagesSearchResults = messageDTOs.map(messageDTO => {
                return new Message().fromDTO(messageDTO);
            });
            draft.messagesSearchTotalCount = messagesSearchTotalCount;
            draft.messagesSearchResultsPage = pageNumber;
            break;
        }

        case communicationActionTypes.RESET_MESSAGE_SEARCH_RESULTS: {
            draft.messagesSearchResults = [];
            draft.messagesSearchTotalCount = 0;
            draft.messagesSearchResultsPage = 0;
            break;
        }

        default: {
            break;
        }
    }
}, initialState);

export default communicationReducer;
