import {fork, put, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as memberActionTypes from '../memberActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';

const loadMemberServiceCases = function* loadMemberServiceCases({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    const {match, location} = payload;
    const {membershipNo} = match.params;
    const searchQueryParams = new URLSearchParams(location.search);
    searchQueryParams.append('membershipNo', membershipNo);
    searchQueryParams.append('sort', 'createdAt,desc');
    yield fork(
        fetchRequest,
        memberActionTypes.FETCH_MEMBER_SERVICE_CASES_REQUEST,
        leaAssignmentFlowService.getServiceCases,
        {searchQueryParams},
    );

    const responseAction = yield take([
        memberActionTypes.FETCH_MEMBER_SERVICE_CASES_REQUEST_SUCCEEDED,
        memberActionTypes.FETCH_MEMBER_SERVICE_CASES_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {serviceCaseDTOs, totalCount} = response;

        const serviceCaseIds = serviceCaseDTOs.map(serviceCaseDTO => serviceCaseDTO.id);

        yield put({
            type: memberActionTypes.STORE_MEMBER_SERVICE_CASES,
            payload: {membershipNo, serviceCaseIds},
        });

        yield put({
            type: serviceCaseActionTypes.STORE_SERVICE_CASES,
            payload: {serviceCaseDTOs, totalCount},
        });
    }
};

export default loadMemberServiceCases;
