import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {ButtonPrimary, Divider, HighlightCircle, Modal, useStyles} from '@ace-de/ui-components';
import {Icon, InteractiveIcon, closeIcon, checkmarkIcon, emailForwardIcon} from '@ace-de/ui-components/icons';
import {Checkbox, CheckboxGroup} from '@ace-de/ui-components/form';
import * as invoiceSelectors from '../invoiceSelectors';
import * as invoiceActionTypes from '../invoiceActionTypes';

const VKRInvoiceCreationModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('vkr_invoice_creation_modal');
    const {hasBackdrop, declineVKRInvoiceCreation, invoices, location, confirmVKRInvoiceCreation} = props;
    const invoiceId = location?.query?.invoiceId;
    const [selectedInvoices, setSelectedInvoices] = useState([invoiceId]);

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape') declineVKRInvoiceCreation();
    }, [declineVKRInvoiceCreation]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            contentClassName={cx([
                'global!ace-u-padding--32',
                'global!ace-u-modal-content-size--s',
                'global!ace-u-flex',
                'global!ace-u-flex--direction-column',
            ])}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineVKRInvoiceCreation}
                />
            )}
        >
            <HighlightCircle
                className={cx([
                    'ace-c-highlight-circle--medium',
                    'ace-c-highlight-circle--primary-highlight',
                    'global!ace-u-margin--bottom-24',
                    'global!ace-u-flex--align-self-center',
                ])}
            >
                <Icon
                    className={cx(['ace-c-icon--xl', 'global!ace-c-icon--color-highlight'])}
                    icon={emailForwardIcon}
                />
            </HighlightCircle>
            <p className={cx(['global!ace-u-typography--variant-h2', 'global!ace-u-margin--bottom-16'])}>
                {translateModal('modal_title.calculate_bills')}
            </p>
            <CheckboxGroup
                name="invoices"
                value={selectedInvoices}
                onChange={value => setSelectedInvoices(value)}
            >
                {invoices.map(invoice => (
                    <div key={invoice.id}>
                        <div
                            className={cx([
                                'global!ace-u-padding--16',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                            ])}
                        >
                            <Checkbox
                                name={`invoice-${invoice.id}`}
                                value={invoice.id}
                                isDisabled={!!invoice.salesInvoiceId}
                            />
                            <p
                                className={cx([
                                    'global!ace-u-margin--left-24',
                                    'global!ace-u-typography--variant-body', {
                                        'global!ace-u-typography--color-disabled': !!invoice.salesInvoiceId,
                                    },
                                ])}
                            >
                                {invoice.id} - {invoice.accountParty?.name} - {translate(`global.service_type.${invoice.lines[0].service.toLowerCase()}`)}
                                {invoice.lines.length > 1 && ` +${invoice.lines.length - 1}`}
                            </p>
                        </div>
                        <Divider />
                    </div>
                ))}
            </CheckboxGroup>
            <ButtonPrimary
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-self-flex-end',
                    'global!ace-u-margin--top-24',
                ])}
                isDisabled={selectedInvoices.length === 0}
                onClick={() => confirmVKRInvoiceCreation({invoiceIds: selectedInvoices})}
            >
                <Icon icon={checkmarkIcon} className={cx('ace-c-icon--color-contrast')} />
                {translateModal('button_label.confirm')}
            </ButtonPrimary>
        </Modal>
    );
};

VKRInvoiceCreationModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    invoices: PropTypes.array,
    declineVKRInvoiceCreation: PropTypes.func.isRequired,
    confirmVKRInvoiceCreation: PropTypes.func.isRequired,
    location: PropTypes.object,
};

VKRInvoiceCreationModal.defaultProps = {
    hasBackdrop: false,
    invoices: [],
    location: {},
};

const mapStateToProps = (state, props) => {
    const invoiceSelector = invoiceSelectors.createInvoicesForForwardingSelector();
    return {
        invoices: invoiceSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineVKRInvoiceCreation: () => dispatch({
        type: invoiceActionTypes.DECLINE_VKR_INVOICE_CREATION,
    }),
    confirmVKRInvoiceCreation: payload => dispatch({
        type: invoiceActionTypes.CONFIRM_VKR_INVOICE_CREATION,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(VKRInvoiceCreationModal);
