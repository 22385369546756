import {take, put, call} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {push, resolveRoute} from '@computerrock/formation-router';
import {alfInvoiceStatusTypes} from '@ace-de/eua-entity-types';
import updateInvoiceStatus from './updateInvoiceStatus';
import * as invoiceActionTypes from '../invoiceActionTypes';
import modalIds from '../../modalIds';
import routePaths from '../../routePaths';
import invoiceSuccessModalActionTypes from '../modals/invoiceSuccessModalActionTypes';
import fetchInvoiceReadOnlyApprovalData from './fetchInvoiceReadOnlyApprovalData';
import updateInvoice from './updateInvoice';

/**
 * Update Invoice status flow
 */
const initiateManualInvoiceStatusUpdateFlow = function* initiateManualInvoiceStatusUpdateFlow() {
    while (true) {
        const {payload} = yield take(invoiceActionTypes.INITIATE_MANUAL_INVOICE_STATUS_UPDATE_FLOW);
        const {invoiceId, serviceCaseId, invoiceStatusData, isVKRInvoiceCreation, invoiceData} = payload;

        if (isVKRInvoiceCreation && !!invoiceData) {
            const updateVKRInvoiceResponseAction = yield* updateInvoice({
                caller: invoiceActionTypes.SUBMIT_INVOICE_BASIC_DATA_FORM,
                invoiceId,
                invoiceData,
                triggerINACheck: false,
            });

            if (updateVKRInvoiceResponseAction.error) {
                continue;
            }
        }

        const updateInvoiceStatusResponseAction = yield* updateInvoiceStatus({
            caller: invoiceActionTypes.INITIATE_MANUAL_INVOICE_STATUS_UPDATE_FLOW,
            invoiceId,
            invoiceStatusData,
        });

        if (!updateInvoiceStatusResponseAction.error && invoiceStatusData.status === alfInvoiceStatusTypes.APPROVED) {
            yield call(fetchInvoiceReadOnlyApprovalData, {
                payload: {
                    invoiceId,
                    serviceCaseId,
                },
            });

            yield* openModal(modalIds.INVOICE_SUCCESS_MODAL, {
                action: isVKRInvoiceCreation
                    ? invoiceSuccessModalActionTypes.SALES_INVOICE_CREATED
                    : invoiceSuccessModalActionTypes.INVOICE_APPROVED,
            });

            const chosenModalOption = yield take([
                invoiceActionTypes.REDIRECT_TO_CASE_OVERVIEW,
                invoiceActionTypes.REDIRECT_TO_DASHBOARD,
            ]);

            (chosenModalOption.type === invoiceActionTypes.REDIRECT_TO_CASE_OVERVIEW)
                ? yield put(push(resolveRoute(routePaths.SERVICE_CASES_OVERVIEW, {serviceCaseId})))
                : yield put(push(resolveRoute(routePaths.DASHBOARD)));

            yield* closeModal(modalIds.INVOICE_SUCCESS_MODAL, {
                action: isVKRInvoiceCreation
                    ? invoiceSuccessModalActionTypes.SALES_INVOICE_CREATED
                    : invoiceSuccessModalActionTypes.INVOICE_APPROVED,
            });
        }

        if (invoiceStatusData.status === alfInvoiceStatusTypes.IN_CREATION) {
            yield put(push(resolveRoute(routePaths.INVOICES, {serviceCaseId, invoiceId})));
        }
    }
};

export default initiateManualInvoiceStatusUpdateFlow;
