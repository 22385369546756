import React from 'react';
import PropTypes from 'prop-types';
import {useStyles, HighlightCircle} from '@ace-de/ui-components';
import {Icon, closeIcon, burgerIcon} from '@ace-de/ui-components/icons';

const Burger = React.forwardRef((props, ref) => {
    const {cx} = useStyles(props);
    const {isDropDownOpen, onClick} = props;

    return (
        <div
            ref={ref}
            className={cx('global!ace-u-cursor--pointer', 'global!ace-u-margin--right-32')}
            onClick={onClick}
            data-qa="burger-menu-trigger"
        >
            <HighlightCircle
                className={cx([
                    'ace-c-highlight-circle--small',
                    'ace-c-highlight-circle--primary',
                ])}
            >
                <Icon
                    icon={isDropDownOpen ? closeIcon : burgerIcon}
                    className={cx([
                        'ace-c-icon--m',
                        'ace-c-icon--color-contrast',
                        {
                            'global!ace-u-margin--top-8': !isDropDownOpen,
                            'global!ace-u-margin--left-4': !isDropDownOpen,
                        },
                    ])}
                />
            </HighlightCircle>
        </div>
    );
});

Burger.propTypes = {
    onClick: PropTypes.func,
    isDropDownOpen: PropTypes.bool,
};

Burger.defaultProps = {
    onClick: () => {},
    isDropDownOpen: false,
};

export default Burger;
