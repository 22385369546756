import {take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as applicationActionTypes from '../applicationActionTypes';
import modalIds from '../../modalIds';

const warningMessageFlow = function* warningMessageFlow() {
    while (true) {
        const {payload} = yield take(applicationActionTypes.INITIATE_WARNING_MESSAGE_FLOW);
        const {warningMessageType} = payload;

        yield* openModal(modalIds.WARNING_MESSAGE_MODAL, {warningMessageType});

        yield take([
            applicationActionTypes.DECLINE_WARNING_MESSAGE_FLOW,
        ]);

        yield* closeModal(modalIds.WARNING_MESSAGE_MODAL, {warningMessageType});
    }
};

export default warningMessageFlow;
