import React, {useState, useContext, Fragment} from 'react';
import {useHistory, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {Panel, useStyles, ToggleTab, ToggleTabSwitch, RadioButtonGroup, RadioButton, Paginator} from '@ace-de/ui-components';
import {SearchBox} from '@ace-de/ui-components/text-inputs';
import {ampMembershipTypes} from '@ace-de/eua-entity-types';
import dashboardSearchTabs from '../dashboard/dashboardSearchTabs';
import MemberSearchContext from './MemberSearchContext';
import MemberSearchResultsTable from './ui-elements/MemberSearchResultsTable';
import config from '../config';
import routePaths from '../routePaths';

const MemberSearchScreen = () => {
    const [selectedTab, setSelectedTab] = useState(dashboardSearchTabs.MEMBER);
    const [memberType, setMemberType] = useState(ampMembershipTypes.PRIVATE);
    const {translate, createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('member_search_screen');
    const history = useHistory();
    const {cx} = useStyles();

    const {searchQueryString, setSearchQueryString} = useContext(MemberSearchContext);
    const {searchResultsPrivate, searchResultsCorporate} = useContext(MemberSearchContext);
    const {searchResultsCountPrivate, setSearchResultsPagePrivate} = useContext(MemberSearchContext);
    const {searchResultsCountCorporate, setSearchResultsPageCorporate} = useContext(MemberSearchContext);

    const paginatorCountPrivate = Math.ceil(searchResultsCountPrivate / config.DEFAULT_PAGE_SIZE);
    const paginatorCountCorporate = Math.ceil(searchResultsCountCorporate / config.DEFAULT_PAGE_SIZE);
    const queryParams = new URLSearchParams(history.location.search);

    const handleOnTabSwitch = value => {
        if (value === dashboardSearchTabs.CASE) {
            history.push(resolveRoute(
                routePaths.SERVICE_CASES_SEARCH,
                {},
            ));
        }
        setSelectedTab(value);
    };

    return (
        <Panel
            title={(
                <p className={cx('global!ace-u-margin--24')}>
                    {translateScreen('search_box_panel_title.search')}
                </p>
            )}
            className={cx('ace-c-panel--full-bleed')}
        >
            <div className={cx('global!ace-u-margin--0-24-24')}>
                <ToggleTabSwitch
                    name="memberCaseSearch"
                    className={cx('global!ace-u-margin--bottom-24')}
                    onChange={handleOnTabSwitch}
                    value={selectedTab}
                >
                    {Object.values(dashboardSearchTabs).map(tab => (
                        <ToggleTab
                            key={tab}
                            name={`${tab.toLowerCase()}Tab`}
                            value={tab}
                        >
                            {translate(`global.search_type.${tab.toLowerCase()}`).toUpperCase()}
                        </ToggleTab>
                    ))}
                </ToggleTabSwitch>
                {selectedTab === dashboardSearchTabs.MEMBER && (
                    <Fragment>
                        <SearchBox
                            name="memberSearchBox"
                            className={cx([
                                'global!ace-u-margin--24-0',
                                'global!ace-u-width--full',
                            ])}
                            value={searchQueryString}
                            onChange={value => {
                                setSearchQueryString(value);
                                setSearchResultsPagePrivate(0);
                                setSearchResultsPageCorporate(0);
                            }}
                            isDropDownDisabled={true}
                            isButtonDisabled={searchQueryString?.length > 0
                                    && searchQueryString?.length < config.MINIMUM_SEARCH_QUERY_LENGTH}
                            placeholder={translateScreen('search_box_placeholder.member_search')}
                        />
                        <div className={cx('global!ace-u-flex', 'global!ace-u-flex--align-center')}>
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--right-24',
                                ])}
                            >
                                {translateScreen('panel_subtitle.results')}
                            </p>
                            <RadioButtonGroup
                                name="memberType"
                                value={memberType}
                                onChange={value => setMemberType(value)}
                            >
                                <RadioButton
                                    name="memberTypePrivate"
                                    value={ampMembershipTypes.PRIVATE}
                                    className={cx('global!ace-u-margin--right-48')}
                                >
                                    {translateScreen('radio_button_label.private', {count: searchResultsCountPrivate})}
                                </RadioButton>
                                <RadioButton
                                    name="memberTypeCorporate"
                                    value={ampMembershipTypes.CORPORATE}
                                    className={cx('global!ace-u-margin--right-48')}
                                >
                                    {translateScreen('radio_button_label.corporate', {count: searchResultsCountCorporate})}
                                </RadioButton>
                            </RadioButtonGroup>
                        </div>
                    </Fragment>
                )}
            </div>
            {selectedTab === dashboardSearchTabs.MEMBER && (
                <Fragment>
                    {memberType === ampMembershipTypes.PRIVATE && (
                        <Fragment>
                            <MemberSearchResultsTable
                                memberResults={searchResultsPrivate}
                                resultsType={ampMembershipTypes.PRIVATE}
                            />
                            {searchResultsPrivate.length > 0 && (
                                <Paginator
                                    page={+queryParams.get('pagePrivate')}
                                    count={paginatorCountPrivate}
                                    onClick={page => setSearchResultsPagePrivate(page)}
                                />
                            )}
                        </Fragment>
                    )}
                    {memberType === ampMembershipTypes.CORPORATE && (
                        <Fragment>
                            <MemberSearchResultsTable
                                memberResults={searchResultsCorporate}
                                resultsType={ampMembershipTypes.CORPORATE}
                            />
                            {searchResultsCorporate.length > 0 && (
                                <Paginator
                                    page={+queryParams.get('pageCorporate')}
                                    count={paginatorCountCorporate}
                                    onClick={page => setSearchResultsPageCorporate(page)}
                                />
                            )}
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Panel>
    );
};

export default MemberSearchScreen;
