import {fork, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as caseLogActionTypes from '../caseLogActionTypes';

const downloadCaseLogDocument = function* downloadCaseLogDocument() {
    const {serviceManager} = yield select(state => state.application);
    const alfFileAssetsService = serviceManager.loadService('alfFileAssetsService');

    while (true) {
        const {payload} = yield take(caseLogActionTypes.SUBMIT_DOCUMENT_DOWNLOAD_FLOW);
        const {fileName, url} = payload;

        yield fork(fetchRequest, caseLogActionTypes.DOWNLOAD_DOCUMENT_REQUEST,
            alfFileAssetsService.downloadFile, {
                fileName,
                url,
            });

        yield take([
            caseLogActionTypes.DOWNLOAD_DOCUMENT_REQUEST_SUCCEEDED,
            caseLogActionTypes.DOWNLOAD_DOCUMENT_REQUEST_FAILED,
        ]);
    }
};

export default downloadCaseLogDocument;
