import {take, select, fork, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {alfMessageChannelTypes, persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as communicationActionTypes from '../communicationActionTypes';
import * as contactActionTypes from '../../contacts/contactActionTypes';
import modalIds from '../../modalIds';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import successMessageTypes from '../../application/successMessageTypes';
import errorTypes from '../../application/errorTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';

const initiateSendMessageFlow = function* initiateSendMessageFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    const alfFileAssetsService = serviceManager.loadService('alfFileAssetsService');
    let shouldWaitForAction = true;
    let shouldRetryMessageSend = false;
    let retryMessageSendData = null;

    while (true) {
        let sendMessageConfirmAction;
        let initiateSendMessageData;

        if (shouldWaitForAction) {
            const {payload} = yield take([communicationActionTypes.INITIATE_SEND_MESSAGE_FLOW]);
            initiateSendMessageData = payload;
            const {invoiceId, selectedContact, serviceCaseId} = initiateSendMessageData;
            yield put({type: communicationActionTypes.RESET_ACCOUNT_PARTY_CONTACT_DETAILS});

            if (selectedContact) {
                yield put({
                    type: contactActionTypes.STORE_CONTACTS,
                    payload: {contactDTOs: [selectedContact]},
                });
            }
            yield* openModal(modalIds.SEND_MESSAGE_MODAL, {
                ...(invoiceId ? {invoiceId} : {}),
                ...(selectedContact ? {contactId: selectedContact.id} : {}),
            });

            yield put({
                type: serviceCaseActionTypes.FETCH_SERVICE_CASE_DOCUMENTS,
                payload: {serviceCaseId},
            });

            sendMessageConfirmAction = yield take([
                communicationActionTypes.DECLINE_SEND_MESSAGE,
                communicationActionTypes.CONFIRM_SEND_MESSAGE,
            ]);
        }

        if (shouldRetryMessageSend || sendMessageConfirmAction.type === communicationActionTypes.CONFIRM_SEND_MESSAGE) {
            const messageData = !shouldRetryMessageSend && sendMessageConfirmAction?.payload
                ? sendMessageConfirmAction.payload
                : retryMessageSendData;

            if (messageData) {
                yield put({
                    type: communicationActionTypes.SET_PERSISTENCE_STATE,
                    payload: {newPersistenceState: persistenceStates.PENDING},
                });

                yield fork(
                    fetchRequest,
                    communicationActionTypes.SEND_MESSAGE_REQUEST,
                    leaAssignmentFlowService.sendMessage,
                    {
                        messageData,
                    },
                );

                const sendMessageResponseAction = yield take([
                    communicationActionTypes.SEND_MESSAGE_REQUEST_FAILED,
                    communicationActionTypes.SEND_MESSAGE_REQUEST_SUCCEEDED,
                ]);

                if (sendMessageResponseAction.error) {
                    // check if error occurs again, after retry loader modal should be closed
                    if (shouldRetryMessageSend && !shouldWaitForAction) {
                        yield* closeModal(modalIds.LOADER);
                    }

                    if (!shouldRetryMessageSend && shouldWaitForAction) {
                        yield* closeModal(modalIds.SEND_MESSAGE_MODAL, {invoiceId: messageData.invoiceId});
                    }

                    yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.MESSAGE_SEND_FAILED});

                    const nextAction = yield take([
                        communicationActionTypes.RETRY_MESSAGE_SEND,
                        communicationActionTypes.CANCEL_RETRY_MESSAGE_SEND,
                    ]);
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});

                    if (nextAction.type === communicationActionTypes.CANCEL_RETRY_MESSAGE_SEND) {
                        shouldRetryMessageSend = false;
                        shouldWaitForAction = true;
                        retryMessageSendData = null;
                    }
                    if (nextAction.type === communicationActionTypes.RETRY_MESSAGE_SEND) {
                        shouldRetryMessageSend = true;
                        shouldWaitForAction = false;
                        retryMessageSendData = messageData;

                        yield* openModal(modalIds.LOADER);
                        continue;
                    }
                }

                if (!sendMessageResponseAction.error) {
                    const {response} = sendMessageResponseAction.payload;
                    const {messageDataDTO} = response;

                    if (messageDataDTO.channel === alfMessageChannelTypes.MAIL
                    && messageDataDTO.attachments?.length > 0) {
                        const relevantAttachments = messageDataDTO.attachments.filter(attachment => (
                            !!attachment.isInternal
                        ));
                        const {fileName, url} = relevantAttachments[0];
                        yield fork(
                            fetchRequest,
                            communicationActionTypes.DOWNLOAD_MESSAGE_PDF_REQUEST,
                            alfFileAssetsService.downloadFile,
                            {
                                fileName,
                                url,
                                shouldOpenFile: true,
                            },
                        );
                        yield take([
                            communicationActionTypes.DOWNLOAD_MESSAGE_PDF_REQUEST_SUCCEEDED,
                            communicationActionTypes.DOWNLOAD_MESSAGE_PDF_REQUEST_FAILED,
                        ]);

                        if (messageDataDTO.attachments.length > 1) {
                            yield fork(
                                fetchRequest,
                                communicationActionTypes.DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST,
                                leaAssignmentFlowService.downloadMultipleFiles,
                                {
                                    fileIds: messageDataDTO.attachments
                                        .filter(attachment => !attachment.isInternal)
                                        .map(attachment => attachment.id),
                                    fileName: messageData.zipFileName,
                                },
                            );

                            yield take([
                                communicationActionTypes.DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST_SUCCEEDED,
                                communicationActionTypes.DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST_FAILED,
                            ]);
                        }
                    }

                    // check if error occur then loader modal should be closed
                    if (shouldRetryMessageSend && !shouldWaitForAction) {
                        yield* closeModal(modalIds.LOADER);
                    }

                    if (!shouldRetryMessageSend && shouldWaitForAction) {
                        yield* closeModal(modalIds.SEND_MESSAGE_MODAL, {invoiceId: messageData.invoiceId});
                    }

                    shouldRetryMessageSend = false;
                    shouldWaitForAction = true;
                    retryMessageSendData = null;

                    yield put({
                        type: communicationActionTypes.SET_PERSISTENCE_STATE,
                        payload: {newPersistenceState: persistenceStates.READY},
                    });

                    yield put({
                        type: applicationActionTypes.INITIATE_SUCCESS_MESSAGE_FLOW,
                        payload: {successMessageType: successMessageTypes.MESSAGE_SENT},
                    });
                    continue;
                }
            }
        }
        yield* closeModal(modalIds.SEND_MESSAGE_MODAL, {invoiceId: initiateSendMessageData?.invoiceId});
        yield put({
            type: communicationActionTypes.SET_PERSISTENCE_STATE,
            payload: {newPersistenceState: persistenceStates.READY},
        });
    }
};

export default initiateSendMessageFlow;
