import {fork, select, take, put} from 'redux-saga/effects';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import modalIds from '../../modalIds';
import * as communicationActionTypes from '../communicationActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import successMessageTypes from '../../application/successMessageTypes';
import errorTypes from '../../application/errorTypes';

const initiateUploadReplyFlow = function* serviceCaseReplyUploadFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    let shouldWaitForAction = true;
    let shouldRetryMessageUpload = false;
    let retryMessageUploadData = null;
    let invoiceId;

    while (true) {
        let chosenModalOption;
        if (shouldWaitForAction) {
            const {payload} = yield take([communicationActionTypes.INITIATE_UPLOAD_REPLY_FLOW]);
            invoiceId = payload?.invoiceId;
            yield* openModal(modalIds.UPLOAD_REPLY_MODAL);

            chosenModalOption = yield take([
                communicationActionTypes.DECLINE_UPLOAD_REPLY,
                communicationActionTypes.CONFIRM_UPLOAD_REPLY,
            ]);
        }


        if (shouldRetryMessageUpload || chosenModalOption?.type === communicationActionTypes.CONFIRM_UPLOAD_REPLY) {
            const messageData = shouldRetryMessageUpload
                ? retryMessageUploadData
                : chosenModalOption.payload;

            if (invoiceId) messageData.invoiceId = invoiceId;
            yield put({
                type: communicationActionTypes.SET_PERSISTENCE_STATE,
                payload: {newPersistenceState: persistenceStates.PENDING},
            });

            yield fork(
                fetchRequest,
                communicationActionTypes.UPLOAD_REPLY_MESSAGE_REQUEST,
                leaAssignmentFlowService.sendMessage,
                {...messageData},
            );

            const responseAction = yield take([
                communicationActionTypes.UPLOAD_REPLY_MESSAGE_REQUEST_SUCCEEDED,
                communicationActionTypes.UPLOAD_REPLY_MESSAGE_REQUEST_FAILED,
            ]);

            if (responseAction.error) {
                if (shouldWaitForAction && !shouldRetryMessageUpload) {
                    yield* closeModal(modalIds.UPLOAD_REPLY_MODAL);
                }

                if (!shouldWaitForAction && shouldRetryMessageUpload) {
                    yield* closeModal(modalIds.LOADER);
                }

                yield put({
                    type: communicationActionTypes.SET_PERSISTENCE_STATE,
                    payload: {newPersistenceState: persistenceStates.READY},
                });

                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.MESSAGE_UPLOAD_FAILED});

                const nextAction = yield take([
                    communicationActionTypes.RETRY_MESSAGE_UPLOAD,
                    communicationActionTypes.CANCEL_RETRY_MESSAGE_UPLOAD,
                ]);

                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});

                if (nextAction.type === communicationActionTypes.CANCEL_RETRY_MESSAGE_UPLOAD) {
                    shouldWaitForAction = true;
                    shouldRetryMessageUpload = false;
                    continue;
                }

                if (nextAction.type === communicationActionTypes.RETRY_MESSAGE_UPLOAD) {
                    shouldWaitForAction = false;
                    shouldRetryMessageUpload = true;
                    retryMessageUploadData = messageData;

                    yield* openModal(modalIds.LOADER);
                    continue;
                }
            }

            if (!responseAction.error) {
                yield* closeModal(modalIds.UPLOAD_REPLY_MODAL);

                yield put({
                    type: applicationActionTypes.INITIATE_SUCCESS_MESSAGE_FLOW,
                    payload: {successMessageType: successMessageTypes.MESSAGE_UPLOADED_SUCCESSFULLY},
                });

                shouldWaitForAction = true;
                shouldRetryMessageUpload = false;
                yield put({
                    type: communicationActionTypes.SET_PERSISTENCE_STATE,
                    payload: {newPersistenceState: persistenceStates.READY},
                });
                continue;
            }
        }

        yield* closeModal(modalIds.UPLOAD_REPLY_MODAL);
    }
};

export default initiateUploadReplyFlow;
