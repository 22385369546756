import {all, fork, takeLatest} from 'redux-saga/effects';
import searchContacts from './sagas/searchContacts';
import editAccountPartyFlow from './sagas/editAccountPartyFlow';
import fetchAccountParty from './sagas/fetchAccountParty';
import contactCreationModalFlow from './sagas/contactCreationModalFlow';
import editContactModalFlow from './sagas/editContactModalFlow';
import accountPartyCreationModalFlow from './sagas/accountPartyCreationModalFlow';
import startCommunicationModalFlow from './sagas/startCommunicationModalFlow';
import searchContactAddressGeolocation from './sagas/searchContactAddressGeolocation';
import selectContactAddressGeolocation from './sagas/selectContactAddressGeolocation';
import * as contactActionTypes from './contactActionTypes';

/**
 *  Contact watcher saga
 */
const contactWatcher = function* contactWatcher() {
    yield all([
        fork(searchContacts),
        fork(editAccountPartyFlow),
        fork(fetchAccountParty),
        fork(contactCreationModalFlow),
        fork(editContactModalFlow),
        fork(accountPartyCreationModalFlow),
        fork(startCommunicationModalFlow),
        takeLatest(contactActionTypes.SEARCH_CONTACT_ADDRESS_GEOLOCATION, searchContactAddressGeolocation),
        takeLatest(contactActionTypes.SELECT_CONTACT_ADDRESS_GEOLOCATION, selectContactAddressGeolocation),
    ]);
};

export default contactWatcher;
