import {take, fork, put, select, actionChannel, all} from 'redux-saga/effects';
import {buffers} from 'redux-saga';
import {persistenceStates, LeistServiceCase} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

/**
 * Batch update ServiceCase flow
 */
const batchUpdateServiceCaseFlow = function* batchUpdateServiceCaseFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        let nextBatchUpdateServiceCaseAction = yield take(
            serviceCaseActionTypes.BATCH_UPDATE_SERVICE_CASE,
        );

        const {payload} = nextBatchUpdateServiceCaseAction;
        const {serviceCaseId} = payload;
        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_PERSISTENCE_STATE,
            payload: {serviceCaseId, persistenceState: persistenceStates.PENDING},
        });

        const expandingBuffer = buffers.expanding(10);
        const batchUpdateChannel = yield actionChannel(
            serviceCaseActionTypes.BATCH_UPDATE_SERVICE_CASE,
            expandingBuffer,
        );
        const completedBatchUpdateActions = [];
        while (true) {
            const {payload, meta} = nextBatchUpdateServiceCaseAction;
            const {serviceCaseData, serviceCaseId} = payload;

            yield fork(
                fetchRequest,
                serviceCaseActionTypes.UPDATE_SERVICE_CASE_REQUEST,
                leaAssignmentFlowService.updateServiceCase,
                {
                    serviceCaseId,
                    serviceCasePatchDTO: LeistServiceCase.objectToPatchDTO(serviceCaseData),
                },
            );

            const batchUpdateResponseAction = yield take([
                serviceCaseActionTypes.UPDATE_SERVICE_CASE_REQUEST_SUCCEEDED,
                serviceCaseActionTypes.UPDATE_SERVICE_CASE_REQUEST_FAILED,
            ]);

            const {payload: responsePayload, error: responseError} = batchUpdateResponseAction;
            completedBatchUpdateActions.push({
                type: serviceCaseActionTypes.BATCH_UPDATE_SERVICE_CASE_COMPLETED,
                payload: responsePayload,
                error: responseError,
                meta,
            });

            // exit if no more update requests, or continue processing
            if (expandingBuffer.isEmpty()) break;
            nextBatchUpdateServiceCaseAction = yield take(batchUpdateChannel);
        }

        yield fork(
            fetchRequest,
            serviceCaseActionTypes.FETCH_SERVICE_CASE_REQUEST,
            leaAssignmentFlowService.getServiceCase,
            {serviceCaseId},
        );

        const fetchServiceCaseResponseAction = yield take([
            serviceCaseActionTypes.FETCH_SERVICE_CASE_REQUEST_SUCCEEDED,
            serviceCaseActionTypes.FETCH_SERVICE_CASE_REQUEST_FAILED,
        ]);

        if (!fetchServiceCaseResponseAction.error) {
            const {response} = fetchServiceCaseResponseAction.payload;
            const {serviceCaseDTO} = response;

            yield put({
                type: serviceCaseActionTypes.STORE_SERVICE_CASES,
                payload: {serviceCaseDTOs: [serviceCaseDTO]},
            });
        }

        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_PERSISTENCE_STATE,
            payload: {serviceCaseId, persistenceState: persistenceStates.READY},
        });

        // signal completed batch updates
        yield all(completedBatchUpdateActions
            .map(batchUpdateAction => put(batchUpdateAction)));
    }
};

export default batchUpdateServiceCaseFlow;
