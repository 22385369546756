const errorTypes = {
    CREDITOR_MISSING: 'CREDITOR_MISSING',
    DEBTOR_MISSING: 'DEBTOR_MISSING',
    CASE_CREATION_FAILED: 'CASE_CREATION_FAILED',
    INVOICE_CREATION_FAILED: 'INVOICE_CREATION_FAILED',
    SERVICE_CASE_VEHICLE_CREATION_FAILED: 'SERVICE_CASE_VEHICLE_CREATION_FAILED',
    TASK_CREATION_FAILED: 'TASK_CREATION_FAILED',
    TASK_EDIT_FAILED: 'TASK_EDIT_FAILED',
    MESSAGE_SEND_FAILED: 'MESSAGE_SEND_FAILED',
    MESSAGE_UPLOAD_FAILED: 'MESSAGE_UPLOAD_FAILED',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

export default errorTypes;
