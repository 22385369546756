import {all, fork, takeLatest} from 'redux-saga/effects';
import createServiceCaseFlow from './sagas/createServiceCaseFlow';
import * as serviceCaseActionTypes from './serviceCaseActionTypes';
import searchServiceCaseDamageLocationGeolocation from './sagas/searchServiceCaseDamageLocationGeolocation';
import batchUpdateServiceCaseFlow from './sagas/batchUpdateServiceCaseFlow';
import updateServiceCaseMandatoryInfo from './sagas/updateServiceCaseMandatoryInfo';
import serviceCaseDeleteFlow from './sagas/serviceCaseDeleteFlow';
import searchServiceCases from './sagas/searchServiceCases';
import serviceCaseDocumentUploadFlow from './sagas/serviceCaseDocumentUploadFlow';
import downloadServiceCaseDocument from './sagas/downloadServiceCaseDocument';
import updateServiceCaseVehicleFlow from './sagas/updateServiceCaseVehicleFlow';
import selectServiceCaseVehicleFlow from './sagas/selectServiceCaseVehicleFlow';
import createServiceCaseVehicleFlow from './sagas/createServiceCaseVehicleFlow';
import editServiceCaseVehicleFlow from './sagas/editServiceCaseVehicleFlow';
import fetchVehicleManufacturersFlow from './sagas/fetchVehicleManufacturersFlow';
import fetchVehicleModelsFlow from './sagas/fetchVehicleModelsFlow';
import fetchVehicleVariantsFlow from './sagas/fetchVehicleVariantsFlow';
import updateServiceCaseTrailerFlow from './sagas/updateServiceCaseTrailerFlow';
import redirectServiceCaseScreen from './sagas/redirectServiceCaseScreen';
import fetchServiceCaseDocuments from './sagas/fetchServiceCaseDocuments';

/**
 *  Service case watcher saga
 */
const serviceCaseWatcher = function* serviceCaseWatcher() {
    yield all([
        fork(createServiceCaseFlow),
        takeLatest(
            serviceCaseActionTypes.SEARCH_SERVICE_CASE_DAMAGE_LOCATION_GEOLOCATION,
            searchServiceCaseDamageLocationGeolocation,
        ),
        takeLatest(
            serviceCaseActionTypes.SEARCH_SERVICE_CASES,
            searchServiceCases,
        ),
        takeLatest(
            serviceCaseActionTypes.INITIATE_SERVICE_CASE_SCREEN_REDIRECTION,
            redirectServiceCaseScreen,
        ),
        takeLatest(
            serviceCaseActionTypes.FETCH_SERVICE_CASE_DOCUMENTS,
            fetchServiceCaseDocuments,
        ),
        fork(batchUpdateServiceCaseFlow),
        fork(updateServiceCaseMandatoryInfo),
        fork(serviceCaseDeleteFlow),
        fork(serviceCaseDocumentUploadFlow),
        fork(downloadServiceCaseDocument),
        fork(updateServiceCaseVehicleFlow),
        fork(selectServiceCaseVehicleFlow),
        fork(createServiceCaseVehicleFlow),
        fork(editServiceCaseVehicleFlow),
        fork(fetchVehicleManufacturersFlow),
        fork(fetchVehicleModelsFlow),
        fork(fetchVehicleVariantsFlow),
        fork(updateServiceCaseTrailerFlow),
    ]);
};

export default serviceCaseWatcher;
