import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import InvoiceDetailsPanel from './ui-elements/InvoiceDetailsPanel';
import * as invoiceSelectors from '../invoices/invoiceSelectors';

const SCOverviewTab = props => {
    const {groupedInvoices, match} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateComponent = createTranslateShorthand('invoice_details_panel');

    if (!groupedInvoices) return null;

    return (
        <div>
            {groupedInvoices.map(([accountPartyId, invoiceGroup], key) => {
                const accountPartyName = accountPartyId === 'undefined'
                    ? translateComponent('title.no_account_party')
                    : invoiceGroup[0].accountParty?.name;

                return (
                    <InvoiceDetailsPanel
                        key={`${invoiceGroup[0].id}-${key}`}
                        index={key + 1}
                        invoiceGroup={invoiceGroup}
                        accountPartyId={
                            (accountPartyId === 'undefined'
                                || accountPartyId === 'null'
                                || !accountPartyId)
                                ? ''
                                : accountPartyId
                        }
                        accountPartyName={accountPartyName}
                        hasNewTabLinkout={!!match?.params['invoiceId']}
                        serviceCaseId={match?.params['serviceCaseId']}
                    />
                );
            })}
        </div>
    );
};

SCOverviewTab.propTypes = {
    groupedInvoices: PropTypes.array,
    match: PropTypes.object.isRequired,
};

SCOverviewTab.defaultProps = {
    groupedInvoices: [],
};

const mapStateToProps = (state, props) => {
    const groupedInvoicesSelector = invoiceSelectors.createGroupedInvoicesByAccountPartySelector();

    return {
        groupedInvoices: groupedInvoicesSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(SCOverviewTab));
