import {put, select, take, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as caseLogActionTypes from '../caseLogActionTypes';
import invoiceOverviewScreenTabs from '../../invoices/invoiceOverviewScreenTabs';

const loadCaseLogServices = function* loadCaseLogServices({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const ecsHistoryManagementService = serviceManager.loadService('ecsHistoryManagementService');
    const {location, match} = payload;
    const {serviceCaseId} = match.params;

    if (location.query.activeTab !== invoiceOverviewScreenTabs.LOG) return;

    yield fork(
        fetchRequest,
        caseLogActionTypes.FETCH_CASE_LOG_SERVICES_REQUEST,
        ecsHistoryManagementService.getServiceTypes,
        {serviceCaseId},
    );

    const responseAction = yield take([
        caseLogActionTypes.FETCH_CASE_LOG_SERVICES_REQUEST_SUCCEEDED,
        caseLogActionTypes.FETCH_CASE_LOG_SERVICES_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {serviceTypes} = response;

        yield put({
            type: caseLogActionTypes.STORE_CASE_LOG_SERVICES,
            payload: {serviceTypes: serviceTypes},
        });
    }
};

export default loadCaseLogServices;
