import {put} from 'redux-saga/effects';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import scOverviewScreenTabs from '../scOverviewScreenTabs';

const loadServiceCaseDocuments = function* loadServiceCaseDocuments({payload}) {
    const {match, location} = payload;
    const {serviceCaseId} = match.params;

    if (location.query.activeTab !== scOverviewScreenTabs.DOCUMENT) return;

    yield put({
        type: serviceCaseActionTypes.FETCH_SERVICE_CASE_DOCUMENTS,
        payload: {serviceCaseId},
    });
};

export default loadServiceCaseDocuments;
