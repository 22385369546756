import {put} from 'redux-saga/effects';
import config from '../../config';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

const loadServiceCaseSearchResults = function* loadServiceCaseSearchResults(payload) {
    if (!payload) return;
    const {location} = payload.payload;
    const searchQueryParams = new URLSearchParams(location.search);
    searchQueryParams.append('size', `${config.DEFAULT_PAGE_SIZE}`);

    yield put({
        type: serviceCaseActionTypes.SEARCH_SERVICE_CASES,
        payload: {searchQueryParams},
    });
};

export default loadServiceCaseSearchResults;
