import React, {useState, useCallback, useEffect, useRef, useMemo, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import debounce from 'lodash.debounce';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {europeanCountries, alfClientTypes, alfBusinessRelationTypes, alfSalutationTypes, alfContactTypes, Address} from '@ace-de/eua-entity-types';
import {useStyles, Form, SelectField, InputField, ButtonPrimary, Divider, Option, AutocompleteField, locationIcon, AutosuggestField} from '@ace-de/ui-components';
import {Icon, saveIcon, arrowDownIcon} from '@ace-de/ui-components/icons';
import * as invoiceSelectors from '../../invoices/invoiceSelectors';
import * as contactSelectors from '../contactSelectors';
import * as contactActionTypes from '../contactActionTypes';
import contactDataModalTypes from '../modals/contactDataModalTypes';
import config from '../../config';

const ContactData = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('contact_data_modal');
    const {location, invoice, selectedContact} = props;
    const searchQueryParams = new URLSearchParams(location?.search || '');
    const {confirmContactCreation, resetContactAddressCandidates, selectContactAddressGeolocation} = props;
    const {confirmEditContact, searchContactAddressGeolocation} = props;
    const {addressCandidates, cityCandidates, postCodeCandidates} = props;
    const {addressErrorMessage, cityErrorMessage, postCodeErrorMessage} = props;

    const [contactFormData, setContactFormData] = useState({...selectedContact} || null);
    const [client, setClient] = useState(searchQueryParams.get('client') || '');
    const [country, setCountry] = useState(selectedContact?.address?.country || '');
    const [countryErrorMessage, setCountryErrorMessage] = useState('');

    const [addressQueryParamsMap, setAddressQueryParamsMap] = useState(new Map());
    const [hasStreetError, setHasStreetError] = useState(false);
    const [hasCityError, setHasCityError] = useState(false);
    const [hasPostCodeError, setHasPostCodeError] = useState(false);
    const [lastAddressSearchQuery, setLastAddressSearchQuery] = useState(null);
    const [newContactAddress, setNewContactAddress] = useState({
        street: selectedContact?.address?.street || '',
        postCode: selectedContact?.address?.postCode || '',
        city: selectedContact?.address?.city || '',
    });

    const currentAddress = useRef('');

    const isCountryGermany = country === europeanCountries['DE'].name;
    const sortedCountries = Object.entries(europeanCountries)
        .map(([countryCode, country]) => [
            countryCode,
            {id: country.id, name: translate(`global.country.${snakeCase(country.name)}`)},
        ])
        .sort(([countryCodeA, countryA], [countryCodeB, countryB]) => {
            return countryA.name.localeCompare(countryB.name);
        });

    const validateCountry = useCallback(() => {
        if (country && !Object.values(europeanCountries).find(europeanCountry => europeanCountry.name === country)) {
            setCountryErrorMessage(translateModal('error_message.select_option_from_dropdown'));
            return;
        }
        setCountryErrorMessage('');
    }, [country, setCountryErrorMessage, translateModal]);

    const searchAddressGeolocationDebounced = useMemo(
        () => debounce(searchContactAddressGeolocation, config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER),
        [searchContactAddressGeolocation],
    );

    const filteredBusinessRelationTypes = selectedContact?.businessRelations?.includes(alfBusinessRelationTypes.MEMBER)
    || selectedContact?.businessRelations?.includes(alfBusinessRelationTypes.CONTRACT_PARTNER)
        ? Object.values(alfBusinessRelationTypes)
        : Object.values(alfBusinessRelationTypes)
            .filter(businessRelation => {
                return businessRelation !== alfBusinessRelationTypes.MEMBER
                   && businessRelation !== alfBusinessRelationTypes.CONTRACT_PARTNER;
            });

    useEffect(() => {
        validateCountry();
    }, [validateCountry]);

    const handleOnFormChange = formValues => {
        if (!formValues) return;
        const {city, postCode, street, client, ...restContactData} = formValues;

        setContactFormData({
            ...restContactData,
            address: {
                ...new Address({
                    city,
                    country,
                    postCode,
                    street,
                }),
            },
        });
    };

    const handleOnSubmit = () => {
        if (!contactFormData) return;

        const errors = ['city', 'postCode', 'street'].map(key => handleDatafactoryValidation(key));

        if (isCountryGermany
            && (errors.some(error => !!error) || !!cityErrorMessage
            || !!postCodeErrorMessage || !!addressErrorMessage)) return;

        const contactData = {
            ...contactFormData,
            type: selectedContact ? undefined : contactFormData.type,
        };

        if (selectedContact) {
            confirmEditContact({
                client,
                invoiceId: invoice?.id,
                contactId: selectedContact.id,
                contactData,
            });
            return;
        }

        confirmContactCreation({
            client,
            invoiceId: invoice?.id,
            contactData,
        });
    };

    const handleAddressSearchQueryChange = (searchQueryString, key) => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchAddressGeolocationDebounced({
                key,
                searchQueryString,
                addressQueryParamsMap,
            });
        }
        if (!searchQueryString) {
            setNewContactAddress({
                ...newContactAddress,
                [key]: '',
            });
            setContactFormData({
                ...contactFormData,
                address: {
                    ...contactFormData.address,
                    [key]: '',
                },
            });
            addressQueryParamsMap.delete(key);
            resetContactAddressCandidates({key});
        }

        setLastAddressSearchQuery({
            ...lastAddressSearchQuery,
            [key]: searchQueryString || '',
        });
    };

    const updateErrorStateByKey = (key, value) => {
        switch (key) {
            case 'city':
                setHasCityError(value);
                break;
            case 'postCode':
                setHasPostCodeError(value);
                break;
            case 'street':
                setHasStreetError(value);
                break;
            default:
                break;
        }
    };

    const validateAddress = (address, key) => {
        if (!address[key]) {
            updateErrorStateByKey(key, true);
            return true;
        }

        updateErrorStateByKey(key, false);
        return false;
    };

    const handleAddressCandidateSelect = (locationCandidate, key) => {
        currentAddress.current = locationCandidate[key];
        setLastAddressSearchQuery({
            ...lastAddressSearchQuery,
            [key]: locationCandidate[key],
        });

        setAddressQueryParamsMap(new Map(addressQueryParamsMap.set(
            key,
            key === 'street'
                ? locationCandidate[key].replace(/[^a-zA-Z]+/g, '')
                : locationCandidate[key],
        )));

        validateAddress(locationCandidate, key);

        setNewContactAddress({
            ...newContactAddress,
            [key]: locationCandidate[key],
        });

        setContactFormData({
            ...contactFormData,
            address: {
                ...contactFormData.address,
                [key]: locationCandidate[key],
            },
        });

        selectContactAddressGeolocation({
            key,
            uuid: locationCandidate.uuid,
            selectedFields: addressQueryParamsMap?.size ? Array.from(addressQueryParamsMap.keys()) : [],
        });
    };

    const handleOnAddressFocus = key => {
        resetContactAddressCandidates({key});
        if (addressQueryParamsMap?.size) {
            searchAddressGeolocationDebounced({
                key,
                addressQueryParamsMap,
            });
        }
    };

    const handleDatafactoryValidation = key => {
        if ((key === 'postCode' && !!postCodeErrorMessage)
            || (key === 'city' && !!cityErrorMessage)
            || (key === 'street' && !!addressErrorMessage)) {
            updateErrorStateByKey(key, true);
            return true;
        }

        if (!lastAddressSearchQuery || !Object.keys(lastAddressSearchQuery).includes(key)) return;

        if (!lastAddressSearchQuery[key]) {
            updateErrorStateByKey(key, false);
            return false;
        }

        if (lastAddressSearchQuery[key] === newContactAddress[key]) {
            return validateAddress(contactFormData.address, key);
        }

        updateErrorStateByKey(key, true);
        return true;
    };

    const isSaveCTADisabled = !client || (selectedContact && !contactFormData?.type)
        || (!selectedContact && contactFormData?.businessRelations?.length === 0) || !contactFormData?.name
        || !contactFormData?.address?.street || !contactFormData?.address?.postCode
        || !contactFormData?.address?.city || !contactFormData?.address?.country
        || !!countryErrorMessage;

    return (
        <div>
            <Form name="contactForm" onChange={handleOnFormChange} onSubmit={handleOnSubmit}>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-full-width',
                    ])}
                >
                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--bottom-24')}>
                        <SelectField
                            name="client"
                            label={`${translateModal('select_field_label.client')}*`}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={client || ''}
                            onChange={setClient}
                        >
                            {Object.values(alfClientTypes).map((client, idx) => (
                                <Option
                                    key={`${client}-${idx}`}
                                    name={client}
                                    value={client}
                                >
                                    {client}
                                </Option>
                            ))}
                        </SelectField>
                        <SelectField
                            name="type"
                            label={`${translateModal('select_field_label.contact_type')}*`}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={selectedContact?.type || ''}
                            isDisabled={!!selectedContact}
                        >
                            {Object.values(alfContactTypes).map((contactType, idx) => (
                                <Option
                                    key={`${contactType}-${idx}`}
                                    name={contactType}
                                    value={contactType}
                                >
                                    {translateModal(`select_option_label.${contactType.toLowerCase()}`)}
                                </Option>
                            ))}
                        </SelectField>
                        <SelectField
                            name="businessRelations"
                            label={`${translateModal('select_field_label.business_relation')}*`}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={selectedContact?.businessRelations}
                            isDisabled={searchQueryParams.get('type') === contactDataModalTypes.EDIT_CONTACT}
                            isMultipleChoice={true}
                        >
                            {filteredBusinessRelationTypes.map((businessRelation, idx) => (
                                <Option
                                    key={`${businessRelation}-${idx}`}
                                    name={businessRelation}
                                    value={businessRelation}
                                >
                                    {translate(`global.business_relation_type.${businessRelation.toLowerCase()}`)}
                                </Option>
                            ))}
                        </SelectField>
                    </div>
                    <Divider />
                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--top-24')}>
                        <SelectField
                            name="salutation"
                            label={translateModal('select_field_label.salutation')}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={selectedContact?.salutation || null}
                        >
                            {Object.values(alfSalutationTypes).map((salutation, idx) => (
                                <Option
                                    key={`${salutation}-${idx}`}
                                    name={salutation}
                                    value={salutation}
                                >
                                    {translate(`global.salutation_type.${salutation.toLowerCase()}`)}
                                </Option>
                            ))}
                        </SelectField>
                        <InputField
                            name="name"
                            label={`${translateModal('input_field_label.name')}*`}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={selectedContact?.name || ''}
                        />
                        {!isCountryGermany && country ? (
                            <InputField
                                name="city"
                                label={`${translateModal('input_field_label.city')}*`}
                                className={cx('global!ace-u-grid-column--span-4')}
                                value={contactFormData?.address?.city || ''}
                            />
                        ) : (
                            <AutosuggestField
                                name="city"
                                label={`${translateModal('input_field_label.city')}*`}
                                className={cx('global!ace-u-grid-column--span-4')}
                                value={contactFormData?.address?.city || ''}
                                optionValueSelector={addressCandidate => {
                                    return addressCandidate.city;
                                }}
                                onChange={value => handleAddressSearchQueryChange(value, 'city')}
                                onOptionSelect={value => handleAddressCandidateSelect(value, 'city')}
                                onFocus={() => handleOnAddressFocus('city')}
                                errors={hasCityError ? cityErrorMessage
                                    ? [cityErrorMessage] : [translateModal('error_message.select_option_from_suggestions')]
                                    : []}
                            >
                                {cityCandidates?.slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                    .map((locationCandidate, index) => {
                                        return (
                                            <Option
                                                key={index}
                                                name={`city-candidate-${index}`}
                                                value={locationCandidate}
                                            >
                                                <Icon
                                                    icon={locationIcon}
                                                    className={cx('global!ace-u-margin--right-16')}
                                                />
                                                {locationCandidate.city}
                                            </Option>
                                        );
                                    })
                                }
                            </AutosuggestField>
                        )}
                    </div>
                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--top-24')}>
                        {!isCountryGermany && country ? (
                            <Fragment>
                                <InputField
                                    name="postCode"
                                    label={`${translateModal('input_field_label.post_code')}*`}
                                    className={cx('global!ace-u-grid-column--span-4')}
                                    value={contactFormData?.address?.postCode || ''}
                                />
                                <InputField
                                    name="street"
                                    label={`${translateModal('input_field_label.address')}*`}
                                    className={cx('global!ace-u-grid-column--span-4')}
                                    value={contactFormData?.address?.street || ''}
                                />
                            </Fragment>
                        ) : (
                            <Fragment>
                                <AutosuggestField
                                    name="postCode"
                                    label={`${translateModal('input_field_label.post_code')}*`}
                                    className={cx('global!ace-u-grid-column--span-4')}
                                    value={contactFormData?.address?.postCode || ''}
                                    optionValueSelector={addressCandidate => {
                                        return addressCandidate.postCode;
                                    }}
                                    onChange={value => handleAddressSearchQueryChange(value, 'postCode')}
                                    onOptionSelect={value => handleAddressCandidateSelect(value, 'postCode')}
                                    onFocus={() => handleOnAddressFocus('postCode')}
                                    errors={hasPostCodeError ? postCodeErrorMessage
                                        ? [postCodeErrorMessage] : [translateModal('error_message.select_option_from_suggestions')]
                                        : []}
                                >
                                    {postCodeCandidates?.slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                        .map((locationCandidate, index) => {
                                            return (
                                                <Option
                                                    key={index}
                                                    name={`post-code-candidate-${index}`}
                                                    value={locationCandidate}
                                                >
                                                    <Icon
                                                        icon={locationIcon}
                                                        className={cx('global!ace-u-margin--right-16')}
                                                    />
                                                    {locationCandidate.postCode}
                                                </Option>
                                            );
                                        })
                                    }
                                </AutosuggestField>
                                <AutosuggestField
                                    name="street"
                                    label={`${translateModal('input_field_label.address')}*`}
                                    className={cx('global!ace-u-grid-column--span-4')}
                                    value={contactFormData?.address?.street || ''}
                                    optionValueSelector={addressCandidate => {
                                        return addressCandidate.street;
                                    }}
                                    onChange={value => handleAddressSearchQueryChange(value, 'street')}
                                    onOptionSelect={value => handleAddressCandidateSelect(value, 'street')}
                                    onFocus={() => handleOnAddressFocus('street')}
                                    errors={hasStreetError ? addressErrorMessage
                                        ? [addressErrorMessage] : [translateModal('error_message.select_option_from_suggestions')]
                                        : []}
                                >
                                    {addressCandidates?.slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                        .map((locationCandidate, index) => {
                                            return (
                                                <Option
                                                    key={index}
                                                    name={`street-candidate-${index}`}
                                                    value={locationCandidate}
                                                >
                                                    <Icon
                                                        icon={locationIcon}
                                                        className={cx('global!ace-u-margin--right-16')}
                                                    />
                                                    {locationCandidate.street}
                                                </Option>
                                            );
                                        })
                                    }
                                </AutosuggestField>
                            </Fragment>
                        )}
                        <AutocompleteField
                            name="country"
                            label={`${translateModal('select_field_label.country')}*`}
                            placeholder={translate('global.select.placeholder')}
                            icon={arrowDownIcon}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={country || ''}
                            onChange={setCountry}
                            errors={countryErrorMessage ? [countryErrorMessage] : []}
                            isDisabled={!!selectedContact?.vatRegistrationNo}
                        >
                            {sortedCountries.length > 0 && sortedCountries
                                .map(([countryCode, country]) => {
                                    return (
                                        <Option
                                            key={country.id}
                                            name={`country-${countryCode}`}
                                            value={europeanCountries[countryCode].name}
                                        >
                                            {country.name}
                                        </Option>
                                    );
                                })}
                        </AutocompleteField>
                    </div>
                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--24-0')}>
                        <InputField
                            name="email"
                            label={translateModal('input_field_label.email')}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={selectedContact?.email || ''}
                        />
                        <InputField
                            name="phoneNo"
                            label={translateModal('input_field_label.phone_no')}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={selectedContact?.phoneNo || ''}
                        />
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-flex-end',
                            'global!ace-u-margin--top-24',
                        ])}
                    >
                        <ButtonPrimary name="saveButton" type="submit" isDisabled={isSaveCTADisabled}>
                            <Icon
                                icon={saveIcon}
                                className={cx([
                                    'global!ace-c-icon--color-contrast',
                                    'global!ace-u-margin--right-8',
                                ])}
                            />
                            {translateModal('button_label.save')}
                        </ButtonPrimary>
                    </div>
                </div>
            </Form>
        </div>
    );
};

ContactData.propTypes = {
    location: PropTypes.object,
    invoice: PropTypes.object,
    selectedContact: PropTypes.object,
    confirmContactCreation: PropTypes.func.isRequired,
    confirmEditContact: PropTypes.func.isRequired,
    searchContactAddressGeolocation: PropTypes.func.isRequired,
    selectContactAddressGeolocation: PropTypes.func.isRequired,
    resetContactAddressCandidates: PropTypes.func.isRequired,
    addressCandidates: PropTypes.array,
    cityCandidates: PropTypes.array,
    postCodeCandidates: PropTypes.array,
    addressErrorMessage: PropTypes.string,
    cityErrorMessage: PropTypes.string,
    postCodeErrorMessage: PropTypes.string,
};

ContactData.defaultProps = {
    location: null,
    invoice: null,
    selectedContact: null,
    addressCandidates: [],
    cityCandidates: [],
    postCodeCandidates: [],
    addressErrorMessage: '',
    cityErrorMessage: '',
    postCodeErrorMessage: '',
};

const mapStateToProps = (state, props) => {
    const getInvoice = invoiceSelectors.createInvoicesSelector();
    const getSelectedContact = contactSelectors.createContactSelector();

    return {
        invoice: getInvoice(state, props),
        selectedContact: getSelectedContact(state, props),
        addressCandidates: state.contacts.addressCandidates,
        cityCandidates: state.contacts.cityCandidates,
        postCodeCandidates: state.contacts.postCodeCandidates,
        cityErrorMessage: state.contacts.cityErrorMessage,
        addressErrorMessage: state.contacts.addressErrorMessage,
        postCodeErrorMessage: state.contacts.postCodeErrorMessage,
    };
};

const mapDispatchToProps = dispatch => ({
    confirmContactCreation: payload => dispatch({
        type: contactActionTypes.CONFIRM_CREATE_CONTACT,
        payload,
    }),
    confirmEditContact: payload => dispatch({
        type: contactActionTypes.CONFIRM_EDIT_CONTACT,
        payload,
    }),
    searchContactAddressGeolocation: payload => dispatch({
        type: contactActionTypes.SEARCH_CONTACT_ADDRESS_GEOLOCATION,
        payload,
    }),
    selectContactAddressGeolocation: payload => dispatch({
        type: contactActionTypes.SELECT_CONTACT_ADDRESS_GEOLOCATION,
        payload,
    }),
    resetContactAddressCandidates: payload => dispatch({
        type: contactActionTypes.RESET_CONTACT_ADDRESS_CANDIDATES,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactData));
