import {put} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import {matchPath} from '@computerrock/formation-router';
import {etmTaskTypes} from '@ace-de/eua-entity-types';
import * as taskActionTypes from '../taskActionTypes';
import invoiceOverviewScreenTabs from '../../invoices/invoiceOverviewScreenTabs';
import routePaths from '../../routePaths';
import config from '../../config';

const loadTaskSearchResults = function* loadTaskSearchResults(payload) {
    if (!payload) return;
    const {location} = payload.payload;
    const taskSearchScreenMatch = matchPath(location.pathname, {path: routePaths.TASKS_SEARCH, exact: true});
    const {activeTab} = yield* selectSearchQueryParams();

    if ((activeTab !== invoiceOverviewScreenTabs.TASKS && !taskSearchScreenMatch)
        || location?.state?.isModalOpen) return;

    const searchQueryParams = new URLSearchParams(location.search);
    searchQueryParams.append('taskType', etmTaskTypes.LEA);
    searchQueryParams.append('size', `${config.DEFAULT_PAGE_SIZE}`);

    yield put({
        type: taskActionTypes.SEARCH_TASKS,
        payload: {searchQueryParams},
    });
};

export default loadTaskSearchResults;
