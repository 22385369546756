import {fork, put, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {push, resolveRoute} from '@computerrock/formation-router';
import * as invoiceActionTypes from '../invoiceActionTypes';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import routePaths from '../../routePaths';

const invoiceForwardingFlow = function* invoiceForwardingFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(invoiceActionTypes.INITIATE_INVOICE_FORWARD_FLOW);
        const {invoiceId} = payload;

        yield* openModal(modalIds.VKR_INVOICE_CREATION_MODAL, {invoiceId});

        const chosenModalAction = yield take([
            invoiceActionTypes.DECLINE_VKR_INVOICE_CREATION,
            invoiceActionTypes.CONFIRM_VKR_INVOICE_CREATION,
        ]);

        if (chosenModalAction.type === invoiceActionTypes.CONFIRM_VKR_INVOICE_CREATION) {
            const {payload: confirmationPayload} = chosenModalAction;
            const {invoiceIds} = confirmationPayload;

            yield fork(
                fetchRequest,
                invoiceActionTypes.CREATE_VKR_INVOICE_REQUEST,
                leaAssignmentFlowService.createVKRInvoice,
                {invoiceIds},
            );

            const responseAction = yield take([
                invoiceActionTypes.CREATE_VKR_INVOICE_REQUEST_SUCCEEDED,
                invoiceActionTypes.CREATE_VKR_INVOICE_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {invoiceDTO} = response;
                yield put(push(resolveRoute(routePaths.INVOICES, {
                    serviceCaseId: invoiceDTO.serviceCaseId,
                    invoiceId: invoiceDTO.id,
                })));
            }
        }

        yield* closeModal(modalIds.VKR_INVOICE_CREATION_MODAL, {invoiceId});
    }
};

export default invoiceForwardingFlow;
