import {put, select, take, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as memberActionTypes from '../../members/memberActionTypes';

const loadMemberVehicles = function* loadMemberVehicles({payload}) {
    const {match} = payload;
    const {serviceCaseId} = match.params;
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');
    const {serviceCases} = yield select(state => state.serviceCases);
    const serviceCase = serviceCases[serviceCaseId];
    const membershipNo = serviceCase?.member?.membershipNo;

    if (!membershipNo) return;

    yield fork(fetchRequest,
        memberActionTypes.FETCH_MEMBER_REQUEST,
        membersService.getMember, {
            membershipNo,
        });

    const fetchMemberResponceAction = yield take([
        memberActionTypes.FETCH_MEMBER_REQUEST_SUCCEEDED,
        memberActionTypes.FETCH_MEMBER_REQUEST_FAILED,
    ]);

    if (!fetchMemberResponceAction.error) {
        const {response} = fetchMemberResponceAction.payload;
        const {memberDTO} = response;

        yield put({
            type: memberActionTypes.STORE_MEMBER,
            payload: {memberDTO},
        });

        yield fork(
            fetchRequest,
            memberActionTypes.FETCH_MEMBER_VEHICLES_REQUEST,
            membersService.getMemberVehicles,
            {membershipNo},
        );

        const fetchMemberVehiclesResponseAction = yield take([
            memberActionTypes.FETCH_MEMBER_VEHICLES_REQUEST_SUCCEEDED,
            memberActionTypes.FETCH_MEMBER_VEHICLES_REQUEST_FAILED,
        ]);

        if (!fetchMemberVehiclesResponseAction.error) {
            const {memberVehicleDTOs} = fetchMemberVehiclesResponseAction.payload.response;

            yield put({
                type: memberActionTypes.STORE_MEMBER_VEHICLES,
                payload: {
                    membershipNo,
                    memberVehicleDTOs,
                },
            });
        }
    }
};
export default loadMemberVehicles;
