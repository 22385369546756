import {put, take, select, fork} from 'redux-saga/effects';
import moment from 'moment';
import {apsPriceTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceActionTypes from '../invoiceActionTypes';

const loadFixedPriceInformation = function* loadFixedPriceInformation({payload}) {
    const {match} = payload;
    const {invoiceId, serviceCaseId} = match?.params;

    if (!invoiceId || !serviceCaseId) return;

    const {serviceCases} = yield select(state => state.serviceCases);
    const {invoices} = yield select(state => state.invoices);

    const invoice = invoices[invoiceId];
    const serviceCase = serviceCases[serviceCaseId];

    if (!invoice || !serviceCase) return;

    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const searchQueryParams = new URLSearchParams();

    const contractPartner = invoice.accountParty?.id;
    if (!invoice.bookingDate) return;
    const requiredDate = moment(invoice.bookingDate).format('YYYY-MM-DD');

    searchQueryParams.append('contractPartner', contractPartner);
    searchQueryParams.append('validOn', requiredDate);
    searchQueryParams.append('type', apsPriceTypes.CONTRACT_PARTNER_FIXED_PRICE);

    yield fork(
        fetchRequest,
        invoiceActionTypes.FETCH_INVOICE_FIXED_PRICE_FOR_CP_REQUEST,
        pricingManagementService.getValidPrice,
        {searchQueryParams},
    );

    const responseAction = yield take([
        invoiceActionTypes.FETCH_INVOICE_FIXED_PRICE_FOR_CP_REQUEST_SUCCEEDED,
        invoiceActionTypes.FETCH_INVOICE_FIXED_PRICE_FOR_CP_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {pricePeriodDTOs} = response;
        const fixedPrice = pricePeriodDTOs[0]?.value || null;
        yield put({
            type: invoiceActionTypes.SET_INVOICE_FIXED_PRICE,
            payload: {invoiceId, fixedPrice},
        });
    }
};

export default loadFixedPriceInformation;
