import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Modal, HighlightCircle, ContentBlock, ContentItem} from '@ace-de/ui-components';
import {ButtonPrimary, ButtonSecondary} from '@ace-de/ui-components/buttons';
import {Icon, closeIcon, warningOutlinedIcon} from '@ace-de/ui-components/icons';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

const ConfirmVehicleSelectionModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('confirm_vehicle_selection_modal');
    const {confirmVehicleSelection, declineVehicleSelection, hasBackdrop} = props;

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape') declineVehicleSelection();
    }, [declineVehicleSelection]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    return (
        <Modal
            action={(<Icon icon={closeIcon} onClick={declineVehicleSelection} />)}
            hasBackdrop={hasBackdrop}
        >
            <ContentBlock
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <ContentItem
                    className={cx([
                        'ace-c-content-item--large-span-11',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--direction-column',
                    ])}
                >
                    <HighlightCircle
                        className={cx([
                            'ace-c-highlight-circle--medium',
                            'global!ace-u-margin--bottom-32',
                            'ace-c-highlight-circle--primary-highlight',
                        ])}
                    >
                        <Icon
                            icon={warningOutlinedIcon}
                            className={cx([
                                'ace-c-icon--xxl',
                                'ace-c-icon--color-highlight-pressed',
                            ])}
                        />
                    </HighlightCircle>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-32',
                            'global!ace-u-typography--variant-h3',
                        ])}
                    >
                        {translateModal('title.select_vehicle')}
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-32',
                            'global!ace-u-typography--align-center',
                        ])}
                    >
                        {translateModal('heading.select_vehicle')}
                    </p>
                </ContentItem>
                <ContentItem
                    className={cx([
                        'global!ace-u-flex--direction-column',
                        'global!ace-u--margin-0',
                    ])}
                >
                    <ButtonPrimary
                        className={cx(['global!ace-u-margin--bottom-24', 'global!ace-u-full-width'])}
                        onClick={confirmVehicleSelection}
                    >
                        {translateModal('button_label.yes')}
                    </ButtonPrimary>
                    <ButtonSecondary
                        className={cx('global!ace-u-full-width')}
                        onClick={declineVehicleSelection}
                    >
                        {translateModal('button_label.abort')}
                    </ButtonSecondary>
                </ContentItem>
            </ContentBlock>
        </Modal>
    );
};

ConfirmVehicleSelectionModal.propTypes = {
    declineVehicleSelection: PropTypes.func.isRequired,
    confirmVehicleSelection: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
};

ConfirmVehicleSelectionModal.defaultProps = {
    hasBackdrop: true,
};

const mapDispatchToProps = dispatch => ({
    declineVehicleSelection: () => dispatch({type: serviceCaseActionTypes.DECLINE_SERVICE_CASE_VEHICLE_SELECTION}),
    confirmVehicleSelection: () => dispatch({type: serviceCaseActionTypes.CONFIRM_SERVICE_CASE_VEHICLE_SELECTION}),
});

export default connect(null, mapDispatchToProps)(ConfirmVehicleSelectionModal);
