import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, ButtonPrimary, Button, ActionMenu} from '@ace-de/ui-components';
import {Icon, checkmarkIcon, editIcon, closedIcon, communicationIcon, plusIcon, completelyBackIcon, canceledIcon, emailForwardIcon, resetIcon} from '@ace-de/ui-components/icons';
import {DropDownTrigger, withDropDownProvider} from '@ace-de/ui-components/overlays';
import {alfInvoiceChannelTypes, alfInvoiceStatusTypes, alfInvoiceTypes} from '@ace-de/eua-entity-types';
import Burger from './Burger';
import * as invoiceActionTypes from '../invoiceActionTypes';
import * as communicationActionTypes from '../../communication/communicationActionTypes';
import * as taskActionTypes from '../../tasks/taskActionTypes';
import routePaths from '../../routePaths';
import invoiceOverviewScreenTabs from '../invoiceOverviewScreenTabs';

const BurgerMenu = props => {
    const {cx} = useStyles();
    const {invoice, initiateManualInvoiceStatusUpdateFlow, initiateDeclineInvoiceFlow, handleInvoiceApproval} = props;
    const {initiateSendMessage, initiateTaskCreation, initiateInvoiceCorrection, forwardInvoice} = props;
    const {initiateInvoiceEstimation, updateInvoiceStatus, initiateClosingOfBBZ, serviceCaseId} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('invoice_overview_screen');

    if (!invoice) return null;

    return (
        <DropDownTrigger>
            <Burger />
            <ActionMenu alignment="end">
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-width--full',
                        'global!ace-u-padding--24',
                    ])}
                >
                    {((invoice.channel === alfInvoiceChannelTypes.LEA
                    && ![alfInvoiceStatusTypes.DECLINED, alfInvoiceStatusTypes.APPROVED].includes(invoice.status))
                    || ((invoice.channel === alfInvoiceChannelTypes.MIA
                                || invoice.channel === alfInvoiceChannelTypes.ECS)
                            && invoice.status === alfInvoiceStatusTypes.MANUAL_CHECK)) && (
                            <Button
                                name="editInvoiceButton"
                                className={cx('global!ace-u-margin--bottom-24', 'global!ace-u-width--full')}
                                onClick={() => initiateManualInvoiceStatusUpdateFlow({
                                    invoiceId: invoice.id,
                                    serviceCaseId: invoice.serviceCaseId,
                                    invoiceStatusData: {
                                        status: alfInvoiceStatusTypes.IN_CREATION,
                                    },
                                })}
                            >
                                <Icon
                                    icon={editIcon}
                                    className={cx([
                                        'ace-c-icon--color-highlight',
                                        'global!ace-u-margin--right-8',
                                    ])}
                                />
                                {translateScreen('action_menu_button_label.edit_invoice')}
                            </Button>
                    )}
                    <Button
                        name="startCommunicationButton"
                        className={cx('global!ace-u-margin--bottom-24', 'global!ace-u-width--full')}
                        onClick={() => initiateSendMessage({serviceCaseId})}
                    >
                        <Icon
                            icon={communicationIcon}
                            className={cx([
                                'global!ace-u-margin--right-8',
                            ])}
                        />
                        {translateScreen('action_menu_button_label.communication')}
                    </Button>
                    {[alfInvoiceChannelTypes.ECS, alfInvoiceChannelTypes.LEA].includes(invoice.channel) && (
                        <Button
                            name="createTaskButton"
                            className={cx('global!ace-u-margin--bottom-24', 'global!ace-u-width--full')}
                            onClick={initiateTaskCreation}
                        >
                            <Icon
                                icon={plusIcon}
                                className={cx('ace-c-icon--color-highlight', 'global!ace-u-margin--right-8')}
                            />
                            {translateScreen('action_menu_button_label.new_task')}
                        </Button>
                    )}
                    {[alfInvoiceStatusTypes.MANUAL_CHECK, alfInvoiceStatusTypes.BC_ERROR].includes(invoice.status) && (
                        <Fragment>
                            <Button
                                name="declineInvoiceButton"
                                className={cx([
                                    'global!ace-u-margin--bottom-24',
                                    'global!ace-u-width--full',
                                ])}
                                onClick={() => {
                                    initiateDeclineInvoiceFlow({
                                        invoiceId: invoice.id,
                                        serviceCaseId: invoice.serviceCaseId,
                                    });
                                }}
                            >
                                <Icon
                                    icon={closedIcon}
                                    className={cx([
                                        'ace-c-icon--color-highlight',
                                        'global!ace-u-margin--right-8',
                                    ])}
                                />
                                {translateScreen('action_menu_button_label.decline_invoice')}
                            </Button>
                            <ButtonPrimary
                                name="approveButton"
                                className={cx('global!ace-u-width--full')}
                                onClick={() => {
                                    handleInvoiceApproval({
                                        invoiceId: invoice.id,
                                        serviceCaseId: invoice.serviceCaseId,
                                        invoiceStatusData: {
                                            status: alfInvoiceStatusTypes.APPROVED,
                                        },
                                    });
                                }}
                            >
                                <Icon
                                    icon={checkmarkIcon}
                                    className={cx([
                                        'ace-c-icon--color-contrast',
                                        'global!ace-u-margin--right-8',
                                    ])}
                                />
                                {translateScreen('action_menu_button_label.approve')}
                            </ButtonPrimary>
                        </Fragment>
                    )}
                    {invoice.status === alfInvoiceStatusTypes.DECLINED && (
                        <Button
                            name="resetDeclinedButton"
                            className={cx('global!ace-u-margin--bottom-24', 'global!ace-u-width--full')}
                            onClick={() => initiateManualInvoiceStatusUpdateFlow({
                                invoiceId: invoice.id,
                                serviceCaseId: invoice.serviceCaseId,
                                invoiceStatusData: {
                                    status: alfInvoiceStatusTypes.MANUAL_CHECK,
                                },
                            })}
                        >
                            <Icon
                                icon={editIcon}
                                className={cx([
                                    'ace-c-icon--color-highlight',
                                    'global!ace-u-margin--right-8',
                                ])}
                            />
                            {translateScreen('action_menu_button_label.reset_declined_invoice')}
                        </Button>
                    )}
                    {[
                        alfInvoiceStatusTypes.PAID,
                        alfInvoiceStatusTypes.BOOKED,
                    ].includes(invoice.status)
                        && [alfInvoiceTypes.EKR, alfInvoiceTypes.VKR].includes(invoice.type)
                        && !invoice.hasRelatedInvoice && (
                            <Button
                                name="invoiceCorrection"
                                className={cx('global!ace-u-margin--bottom-24', 'global!ace-u-width--full')}
                                onClick={() => initiateInvoiceCorrection({
                                    redirectionPath: routePaths.INVOICE_OVERVIEW,
                                    activeTab: invoiceOverviewScreenTabs.AUDITING,
                                    invoiceId: invoice.id,
                                })}
                            >
                                <Icon
                                    icon={completelyBackIcon}
                                    className={cx([
                                        'ace-c-icon--color-highlight',
                                        'global!ace-u-margin--right-8',
                                        'ace-c-icon--s',
                                    ])}
                                />
                                {translateScreen('action_menu_button_label.invoice_correction')}
                            </Button>
                    )}
                    {[alfInvoiceStatusTypes.PAID,
                        alfInvoiceStatusTypes.APPROVED,
                        alfInvoiceStatusTypes.BOOKED].includes(invoice.status)
                        && invoice.type === alfInvoiceTypes.EKR && !invoice.salesInvoiceId && (
                            <Button
                                name="forwardInvoice"
                                className={cx('global!ace-u-margin--bottom-24', 'global!ace-u-width--full')}
                                onClick={() => forwardInvoice({invoiceId: invoice.id})}
                            >
                                <Icon
                                    icon={emailForwardIcon}
                                    className={cx([
                                        'ace-c-icon--color-highlight',
                                        'global!ace-u-margin--right-8',
                                    ])}
                                />
                                {translateScreen('action_menu_button_label.forward_invoice')}
                            </Button>
                    )}
                    {invoice.type === alfInvoiceTypes.BBZ && !invoice.hasRelatedInvoice && (
                        <Button
                            name="resolveEstimation"
                            className={cx('global!ace-u-margin--bottom-24', 'global!ace-u-width--full')}
                            onClick={() => {
                                initiateInvoiceEstimation({
                                    redirectionPath: routePaths.INVOICES,
                                    invoiceId: invoice.id,
                                });
                            }}
                        >
                            <Icon
                                icon={canceledIcon}
                                className={cx([
                                    'ace-c-icon--color-highlight',
                                    'global!ace-u-margin--right-8',
                                ])}
                            />
                            {translateScreen('action_menu_button_label.resolve_estimation')}
                        </Button>
                    )}
                    {invoice.status === alfInvoiceStatusTypes.APPROVED
                        && invoice.type === alfInvoiceTypes.BBZ && (
                        <Button
                            name="closeBBZ"
                            className={cx('global!ace-u-margin--bottom-24', 'global!ace-u-width--full')}
                            onClick={() => initiateClosingOfBBZ({invoiceId: invoice.id})}
                        >
                            <Icon
                                icon={closedIcon}
                                className={cx([
                                    'ace-c-icon--color-highlight',
                                    'global!ace-u-margin--right-8',
                                ])}
                            />
                            {translateScreen('action_menu_button_label.close_bbz')}
                        </Button>
                    )}
                    {invoice.status === alfInvoiceStatusTypes.CLOSED
                        && invoice.type !== alfInvoiceTypes.BBZ && (
                            <Button
                                name="resetInvoice"
                                className={cx('global!ace-u-margin--bottom-24', 'global!ace-u-width--full')}
                                onClick={() => updateInvoiceStatus({
                                    invoice,
                                    invoiceStatusData: {
                                        status: alfInvoiceStatusTypes.OPEN,
                                    },
                                })}
                            >
                                <Icon
                                    icon={resetIcon}
                                    className={cx([
                                        'ace-c-icon--color-highlight',
                                        'global!ace-u-margin--right-8',
                                    ])}
                                />
                                {translateScreen('action_menu_button_label.reset_invoice')}
                            </Button>
                    )}
                </div>
            </ActionMenu>
        </DropDownTrigger>
    );
};

BurgerMenu.propTypes = {
    invoice: PropTypes.object,
    initiateManualInvoiceStatusUpdateFlow: PropTypes.func.isRequired,
    initiateDeclineInvoiceFlow: PropTypes.func.isRequired,
    handleInvoiceApproval: PropTypes.func.isRequired,
    initiateSendMessage: PropTypes.func.isRequired,
    initiateInvoiceCorrection: PropTypes.func.isRequired,
    initiateTaskCreation: PropTypes.func.isRequired,
    forwardInvoice: PropTypes.func.isRequired,
    initiateInvoiceEstimation: PropTypes.func.isRequired,
    updateInvoiceStatus: PropTypes.func.isRequired,
    initiateClosingOfBBZ: PropTypes.func.isRequired,
    serviceCaseId: PropTypes.string,
};

BurgerMenu.defaultProps = {
    invoice: null,
    serviceCaseId: '',
};

const mapDispatchToProps = dispatch => ({
    initiateManualInvoiceStatusUpdateFlow: payload => dispatch({
        type: invoiceActionTypes.INITIATE_MANUAL_INVOICE_STATUS_UPDATE_FLOW,
        payload,
    }),
    initiateDeclineInvoiceFlow: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_DECLINE_FLOW,
        payload,
    }),
    initiateSendMessage: payload => dispatch({
        type: communicationActionTypes.INITIATE_SEND_MESSAGE_FLOW,
        payload,
    }),
    initiateTaskCreation: () => dispatch({
        type: taskActionTypes.INITIATE_TASK_CREATION_FLOW,
    }),
    initiateInvoiceCorrection: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_CORRECTION_FLOW,
        payload,
    }),
    forwardInvoice: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_FORWARD_FLOW,
        payload,
    }),
    initiateInvoiceEstimation: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_ESTIMATION_FLOW,
        payload,
    }),
    updateInvoiceStatus: payload => dispatch({
        type: invoiceActionTypes.UPDATE_INVOICE_STATUS,
        payload,
    }),
    initiateClosingOfBBZ: payload => dispatch({
        type: invoiceActionTypes.INITIATE_CLOSING_OF_BBZ_FLOW,
        payload,
    }),
});

export default withRouter(withDropDownProvider(connect(null, mapDispatchToProps)(BurgerMenu)));
