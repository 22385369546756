import {put, fork, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {push, resolveRoute} from '@computerrock/formation-router';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceActionTypes from '../invoiceActionTypes';
import modalIds from '../../modalIds';
import routePaths from '../../routePaths';
import deleteModalLocationTypes from '../../application/modals/deleteModalLocationTypes';

const invoiceDeleteFlow = function* invoiceDeleteFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take([invoiceActionTypes.INITIATE_INVOICE_DELETE_FLOW]);
        const {invoiceId} = payload;

        if (!invoiceId) continue;

        const {invoices} = yield select(state => state.invoices);
        const invoice = invoices[invoiceId];

        yield* openModal(modalIds.DELETE_MODAL, {type: deleteModalLocationTypes.INVOICE});

        const chosenModalAction = yield take([
            invoiceActionTypes.DECLINE_DELETE,
            invoiceActionTypes.CONFIRM_INVOICE_DELETE,
        ]);

        if (chosenModalAction.type === invoiceActionTypes.CONFIRM_INVOICE_DELETE) {
            yield fork(fetchRequest,
                invoiceActionTypes.DELETE_INVOICE_REQUEST,
                leaAssignmentFlowService.deleteInvoice,
                {invoiceId});

            const serviceCaseDeleteActionResponse = yield take([
                invoiceActionTypes.DELETE_INVOICE_REQUEST_SUCCEEDED,
                invoiceActionTypes.DELETE_INVOICE_REQUEST_FAILED,
            ]);

            if (!serviceCaseDeleteActionResponse.error && invoice.serviceCaseId) {
                yield put({
                    type: invoiceActionTypes.REMOVE_DELETED_INVOICE,
                    payload: {invoiceId},
                });
                yield put(push(resolveRoute(
                    routePaths.SERVICE_CASES_OVERVIEW,
                    {serviceCaseId: invoice.serviceCaseId},
                )));
            }
        }

        yield* closeModal(modalIds.DELETE_MODAL, {type: deleteModalLocationTypes.INVOICE});
    }
};

export default invoiceDeleteFlow;
