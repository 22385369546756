/**
 * @typedef {string} tabId
 */

/**
 * Service case overview screen tabs
 *
 * @enum {tabId}
 */
export default {
    OVERVIEW: 'overview',
    BASIC_DATA: 'basic-data',
    LOG: 'log',
    COMMUNICATION: 'communication',
    DOCUMENT: 'document',
    TASKS: 'tasks',
};
