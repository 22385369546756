import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as memberActionTypes from '../memberActionTypes';

/**
 * Search member vehicles
 */
const searchMemberVehicles = function* searchMemberVehicles({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');
    const {searchQueryString, membershipNo} = payload;

    yield fork(fetchRequest, memberActionTypes.SEARCH_MEMBER_VEHICLES_REQUEST,
        membersService.searchVehicles, {
            searchQueryString,
            membershipNo,
        });

    const searchMemberVehiclesResponseAction = yield take([
        memberActionTypes.SEARCH_MEMBER_VEHICLES_REQUEST_SUCCEEDED,
        memberActionTypes.SEARCH_MEMBER_VEHICLES_REQUEST_FAILED,
    ]);

    if (!searchMemberVehiclesResponseAction.error) {
        const {response} = searchMemberVehiclesResponseAction.payload;
        const {results: memberVehicleSearchResults} = response;

        yield put({
            type: memberActionTypes.STORE_MEMBER_VEHICLES_SEARCH_RESULTS,
            payload: {memberVehicleSearchResults},
        });
    }
};

export default searchMemberVehicles;
