import {take, put, fork, select, delay} from 'redux-saga/effects';
import {closeModal} from '@computerrock/formation-router/sagas';
import {alfMessageChannelTypes, persistenceStates} from '@ace-de/eua-entity-types';
import {replace, resolveRoute} from '@computerrock/formation-router';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contactActionTypes from '../contactActionTypes';
import * as communicationActionTypes from '../../communication/communicationActionTypes';
import modalIds from '../../modalIds';
import bcModalContentTypes from '../modals/bcModalContentTypes';
import successMessageTypes from '../../application/successMessageTypes';
import bcActionFailedModalTypes from '../modals/bcActionFailedModalTypes';

const startCommunicationModalFlow = function* startCommunicationModalFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    const alfFileAssetsService = serviceManager.loadService('alfFileAssetsService');
    let shouldWaitForAction = true;
    let shouldRetryMessageSend = false;
    let retryMessageSendData = null;
    let currentParams;
    let searchParams;
    let location;

    while (true) {
        let modalAction;

        if (shouldWaitForAction) {
            yield take(contactActionTypes.INITIATE_CONTACT_COMMUNICATION_MODAL_FLOW);

            location = yield select(state => state.router.location);
            currentParams = new URLSearchParams(location.search);
            searchParams = new URLSearchParams();
            searchParams.set('modal', currentParams.get('modal'));
            searchParams.set('contentType', bcModalContentTypes.COMMUNICATION);
            searchParams.set('contactId', currentParams.get('contactId'));

            yield put(replace(resolveRoute(location.pathname, {}, {search: searchParams.toString()})));

            modalAction = yield take([
                contactActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW,
                communicationActionTypes.CONFIRM_SEND_MESSAGE,
            ]);
        }

        if (shouldRetryMessageSend || modalAction?.type === communicationActionTypes.CONFIRM_SEND_MESSAGE) {
            const messageData = !shouldRetryMessageSend && modalAction?.payload
                ? modalAction.payload
                : retryMessageSendData;

            if (messageData) {
                yield put({
                    type: communicationActionTypes.SET_PERSISTENCE_STATE,
                    payload: {newPersistenceState: persistenceStates.PENDING},
                });

                yield fork(
                    fetchRequest,
                    communicationActionTypes.SEND_MESSAGE_REQUEST,
                    leaAssignmentFlowService.sendMessage,
                    {
                        messageData,
                    },
                );

                const sendMessageResponseAction = yield take([
                    communicationActionTypes.SEND_MESSAGE_REQUEST_FAILED,
                    communicationActionTypes.SEND_MESSAGE_REQUEST_SUCCEEDED,
                ]);

                if (sendMessageResponseAction.error) {
                    const errorSearchParams = new URLSearchParams();
                    errorSearchParams.set('modal', currentParams.get('modal'));
                    errorSearchParams.set('contentType', bcModalContentTypes.BC_ACTION_FAILED);
                    errorSearchParams.set('type', bcActionFailedModalTypes.SEND_MESSAGE);
                    yield put(replace(resolveRoute(location.pathname, {}, {search: errorSearchParams.toString()})));

                    const nextAction = yield take([
                        communicationActionTypes.RETRY_MESSAGE_SEND,
                        contactActionTypes.CANCEL_ACTION,
                        contactActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW,
                    ]);

                    if (nextAction.type === communicationActionTypes.RETRY_MESSAGE_SEND) {
                        shouldRetryMessageSend = true;
                        shouldWaitForAction = false;
                        retryMessageSendData = messageData;

                        const reloadSearchParams = new URLSearchParams();
                        reloadSearchParams.set('modal', currentParams.get('modal'));
                        reloadSearchParams.set('contentType', bcModalContentTypes.LOADER);

                        yield put(replace(resolveRoute(
                            location.pathname,
                            {},
                            {search: reloadSearchParams.toString()},
                        )));
                        continue;
                    }

                    if (nextAction.type === contactActionTypes.CANCEL_ACTION) {
                        yield put({
                            type: communicationActionTypes.SET_PERSISTENCE_STATE,
                            payload: {newPersistenceState: persistenceStates.READY},
                        });
                        yield put({
                            type: contactActionTypes.RELOAD_CREATE_ACCOUNT_MODAL_FLOW,
                            payload: null,
                        });
                        shouldRetryMessageSend = false;
                        shouldWaitForAction = true;
                        yield put(replace(resolveRoute(location.pathname, {}, {search: currentParams.toString()})));
                        continue;
                    }
                }

                if (!sendMessageResponseAction.error) {
                    const {response} = sendMessageResponseAction.payload;
                    const {messageDataDTO} = response;

                    if (messageDataDTO.channel === alfMessageChannelTypes.MAIL
                        && messageDataDTO.attachments?.length > 0) {
                        const relevantAttachments = messageDataDTO.attachments.filter(attachment => (
                            !!attachment.isInternal
                        ));
                        const {fileName, url} = relevantAttachments[0];
                        yield fork(
                            fetchRequest,
                            communicationActionTypes.DOWNLOAD_MESSAGE_PDF_REQUEST,
                            alfFileAssetsService.downloadFile,
                            {
                                fileName,
                                url,
                                shouldOpenFile: true,
                            },
                        );
                        yield take([
                            communicationActionTypes.DOWNLOAD_MESSAGE_PDF_REQUEST_SUCCEEDED,
                            communicationActionTypes.DOWNLOAD_MESSAGE_PDF_REQUEST_FAILED,
                        ]);
                        if (messageDataDTO.attachments.length > 1) {
                            yield fork(
                                fetchRequest,
                                communicationActionTypes.DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST,
                                leaAssignmentFlowService.downloadMultipleFiles,
                                {
                                    fileIds: messageDataDTO.attachments
                                        .filter(attachment => !attachment.isInternal)
                                        .map(attachment => attachment.id),
                                    fileName: messageData.zipFileName,
                                },
                            );

                            yield take([
                                communicationActionTypes.DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST_SUCCEEDED,
                                communicationActionTypes.DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST_FAILED,
                            ]);
                        }
                    }
                    // show success message 4.5 seconds
                    const successSearchParams = new URLSearchParams();
                    successSearchParams.set('modal', currentParams.get('modal'));
                    successSearchParams.set('contentType', bcModalContentTypes.SUCCESS_MESSAGE);
                    successSearchParams.set('successMessageType', successMessageTypes.MESSAGE_SENT);
                    yield put(replace(resolveRoute(location.pathname, {}, {search: successSearchParams.toString()})));

                    yield delay(4500);
                    yield put(replace(resolveRoute(location.pathname, {}, {search: currentParams.toString()})));

                    shouldRetryMessageSend = false;
                    shouldWaitForAction = true;
                    yield put({
                        type: communicationActionTypes.SET_PERSISTENCE_STATE,
                        payload: {newPersistenceState: persistenceStates.READY},
                    });
                    continue;
                }
            }
        }

        yield put({
            type: contactActionTypes.RELOAD_CREATE_ACCOUNT_MODAL_FLOW,
            payload: null,
        });

        yield* closeModal(modalIds.BC_CONTACT_MODAL, {
            contactId: '',
            contentType: '',
        });
        shouldRetryMessageSend = false;
        shouldWaitForAction = true;

        yield put({
            type: communicationActionTypes.SET_PERSISTENCE_STATE,
            payload: {newPersistenceState: persistenceStates.READY},
        });
    }
};

export default startCommunicationModalFlow;
