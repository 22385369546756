import React from 'react';
import {useStyles} from '@ace-de/ui-components';
import styles from './LEALogo.module.scss';

const LEALogo = React.forwardRef((props, ref) => {
    const {cx} = useStyles(props, styles);

    return (
        <div ref={ref} className={cx('ace-c-eua-logo')} />
    );
});

export default LEALogo;
