import {take, fork, put, select, actionChannel, all} from 'redux-saga/effects';
import {buffers} from 'redux-saga';
import {persistenceStates, Invoice} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceActionTypes from '../invoiceActionTypes';

/**
 * Batch update Invoice flow
 */
const batchUpdateInvoiceFlow = function* batchUpdateInvoiceFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        let nextBatchUpdateServiceAssignmentAction = yield take(
            invoiceActionTypes.BATCH_UPDATE_INVOICE,
        );

        const {payload} = nextBatchUpdateServiceAssignmentAction;
        const {invoiceId} = payload;

        yield put({
            type: invoiceActionTypes.SET_INVOICE_PERSISTENCE_STATE,
            payload: {invoiceId, persistenceState: persistenceStates.PENDING},
        });

        const expandingBuffer = buffers.expanding(10);
        const batchUpdateChannel = yield actionChannel(
            invoiceActionTypes.BATCH_UPDATE_INVOICE,
            expandingBuffer,
        );
        const completedBatchUpdateActions = [];

        while (true) {
            const {payload, meta} = nextBatchUpdateServiceAssignmentAction;
            const {invoiceId, isStatusUpdate, triggerINACheck} = payload;
            let batchUpdateResponseAction = {};

            if (!isStatusUpdate) {
                const {invoiceData} = payload;

                yield fork(
                    fetchRequest,
                    invoiceActionTypes.UPDATE_INVOICE_REQUEST,
                    leaAssignmentFlowService.updateInvoice,
                    {
                        invoiceId,
                        invoicePatchDTO: Invoice.objectToPatchDTO(invoiceData),
                    },
                );

                batchUpdateResponseAction = yield take([
                    invoiceActionTypes.UPDATE_INVOICE_REQUEST_SUCCEEDED,
                    invoiceActionTypes.UPDATE_INVOICE_REQUEST_FAILED,
                ]);

                if (!batchUpdateResponseAction.error && triggerINACheck) {
                    yield put({
                        type: invoiceActionTypes.INITIATE_INA_CHECK,
                        payload: {invoiceId},
                    });
                }
            }

            if (isStatusUpdate) {
                const {invoiceStatusData} = payload;

                yield fork(
                    fetchRequest,
                    invoiceActionTypes.UPDATE_INVOICE_STATUS_REQUEST,
                    leaAssignmentFlowService.updateInvoiceStatus,
                    {
                        invoiceId,
                        invoiceStatusData,
                    },
                );

                batchUpdateResponseAction = yield take([
                    invoiceActionTypes.UPDATE_INVOICE_STATUS_REQUEST_SUCCEEDED,
                    invoiceActionTypes.UPDATE_INVOICE_STATUS_REQUEST_FAILED,
                ]);
            }

            const {payload: responsePayload, error: responseError} = batchUpdateResponseAction;

            completedBatchUpdateActions.push({
                type: invoiceActionTypes.BATCH_UPDATE_INVOICE_COMPLETED,
                payload: responsePayload,
                error: responseError,
                meta,
            });

            // exit if no more update requests, or continue processing
            if (expandingBuffer.isEmpty()) break;
            nextBatchUpdateServiceAssignmentAction = yield take(batchUpdateChannel);
        }

        yield fork(
            fetchRequest,
            invoiceActionTypes.FETCH_INVOICE_REQUEST,
            leaAssignmentFlowService.getInvoice,
            {invoiceId},
        );

        const fetchServiceAssignmentResponseAction = yield take([
            invoiceActionTypes.FETCH_INVOICE_REQUEST_SUCCEEDED,
            invoiceActionTypes.FETCH_INVOICE_REQUEST_FAILED,
        ]);

        if (!fetchServiceAssignmentResponseAction.error) {
            const {response} = fetchServiceAssignmentResponseAction.payload;
            const {invoiceDTO} = response;

            yield put({
                type: invoiceActionTypes.STORE_INVOICES,
                payload: {invoiceDTOs: [invoiceDTO]},
            });
        }

        yield put({
            type: invoiceActionTypes.SET_INVOICE_PERSISTENCE_STATE,
            payload: {invoiceId, persistenceState: persistenceStates.READY},
        });

        // signal completed batch updates
        yield all(completedBatchUpdateActions
            .map(batchUpdateAction => put(batchUpdateAction)));
    }
};

export default batchUpdateInvoiceFlow;
