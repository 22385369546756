import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {efServiceCaseTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, DataRow, Divider} from '@ace-de/ui-components';
import * as serviceCaseSelectors from '../serviceCaseSelectors';

const VehicleDataPanel = ({serviceCase}) => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('sc_base_data_tab');

    if (!serviceCase) return null;
    const {vehicle} = serviceCase;

    return (
        <Panel
            title={translateTab('vehicle_data_panel.title')}
            qaIdent="vehicle-data"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('vehicle_data_panel_row_label.type')}
                        qaIdent="vehicle-type"
                    >
                        {vehicle?.type
                            ? translate(`global.vehicle_type.${vehicle?.type.toLowerCase()}`)
                            : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('vehicle_data_panel_row_label.manufacturer')}
                        qaIdent="vehicle-manufacturer"
                    >
                        {vehicle?.manufacturer || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('vehicle_data_panel_row_label.model')}
                        qaIdent="vehicle-model"
                    >
                        {`${vehicle?.model} ${vehicle?.variant}` || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('vehicle_data_panel_row_label.license_plate')}
                        qaIdent="vehicle-license-plate"
                    >
                        {vehicle?.licensePlateNumber || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('vehicle_data_panel_row_label.build_year')}
                        qaIdent="vehicle-build-year"
                    >
                        {vehicle?.buildYear || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('vehicle_data_panel_row_label.color')}
                        qaIdent="vehicle-color"
                    >
                        {vehicle?.color ? translate(`global.vehicle_color.${vehicle.color.toLowerCase()}`) : ''}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('vehicle_data_panel_row_label.measurements')}
                        qaIdent="vehicle-measurements"
                        className={cx('ace-c-data-row--full-bleed-content')}
                        contentClassName={cx([
                            'global!ace-u-flex--direction-column',
                            'global!ace-u-flex--align-items-stretch',
                        ])}
                    >
                        <div className={cx('global!ace-u-flex', 'global!ace-u-padding--16')}>
                            <p>{translateTab('vehicle_data_panel_input_label.length')}</p>
                            <p className={cx('global!ace-u-flex--grow-1', 'global!ace-u-typography--align-center')}>
                                {vehicle?.length?.toLocaleString(activeLocale) || '-'}
                            </p>
                        </div>
                        <Divider />
                        <div className={cx('global!ace-u-flex', 'global!ace-u-padding--16')}>
                            <p>{translateTab('vehicle_data_panel_input_label.width')}</p>
                            <p className={cx('global!ace-u-flex--grow-1', 'global!ace-u-typography--align-center')}>
                                {vehicle?.width?.toLocaleString(activeLocale) || '-'}
                            </p>
                        </div>
                        <Divider />
                        <div className={cx('global!ace-u-flex', 'global!ace-u-padding--16')}>
                            <p>{translateTab('vehicle_data_panel_input_label.height')}</p>
                            <p className={cx('global!ace-u-flex--grow-1', 'global!ace-u-typography--align-center')}>
                                {vehicle?.height?.toLocaleString(activeLocale) || '-'}
                            </p>
                        </div>
                        <Divider />
                        <div className={cx('global!ace-u-flex', 'global!ace-u-padding--16')}>
                            <p>{translateTab('vehicle_data_panel_input_label.weight')}</p>
                            <p className={cx('global!ace-u-flex--grow-1', 'global!ace-u-typography--align-center')}>
                                {vehicle?.weight?.toLocaleString(activeLocale) || '-'}
                            </p>
                        </div>
                    </DataRow>
                    <DataRow
                        label={translateTab('vehicle_data_panel_row_label.other_information')}
                        qaIdent="vehicle-other-information"
                    >
                        {serviceCase.type === efServiceCaseTypes.VEHICLE
                            ? serviceCase?.damage?.vehicleDamageNotes || '-'
                            : serviceCase?.damage?.additionalNotes || '-'}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

VehicleDataPanel.propTypes = {
    serviceCase: PropTypes.object,
};

VehicleDataPanel.defaultProps = {
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: getServiceCase(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(VehicleDataPanel));
