import {put, take} from 'redux-saga/effects';
import * as invoiceActionTypes from '../invoiceActionTypes';
import leaAccessControl from '../../leaAccessControl';
import {leaFeatureActions, leaFeatures} from '../../application/leaFeatures';

/**
 * Update Invoice service lines flow
 */
const updateInvoiceServiceLines = function* updateInvoiceServiceLines(action) {
    const {caller, invoiceId, invoiceServiceLineData, lineNo, location} = action.payload;

    if (location?.state?.isModalOpen) return;

    const isInvoiceUpdateAvailable = leaAccessControl.grantFeatureAccess(
        leaFeatures.INVOICE,
        leaFeatureActions.UPDATE,
    );

    if (!invoiceId || !isInvoiceUpdateAvailable) return;

    yield put({
        type: invoiceActionTypes.BATCH_UPDATE_INVOICE_SERVICE_LINE,
        payload: {invoiceId, invoiceServiceLineData, lineNo},
        meta: {caller},
    });

    let batchUpdateInvoiceServiceLineResponse;
    while (true) {
        const batchUpdateInvoiceServiceLineAction = yield take(
            invoiceActionTypes.BATCH_UPDATE_INVOICE_SERVICE_LINE_COMPLETED,
        );
        const {meta = {}} = batchUpdateInvoiceServiceLineAction;

        if (typeof meta.caller !== 'undefined' && meta.caller !== caller) continue;
        batchUpdateInvoiceServiceLineResponse = batchUpdateInvoiceServiceLineAction;
        break;
    }

    return batchUpdateInvoiceServiceLineResponse;
};

export default updateInvoiceServiceLines;
