import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

const searchServiceCaseDamageLocationGeolocation = function* searchServiceCaseDamageLocationGeolocation({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    const {serviceCaseId, searchQueryString} = payload;

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.SEARCH_SERVICE_CASE_DAMAGE_LOCATION_GEOLOCATION_REQUEST,
        arcGISRESTService.searchAddressSuggestions,
        {singleLine: searchQueryString},
    );

    const responseActionType = yield take([
        serviceCaseActionTypes.SEARCH_SERVICE_CASE_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.SEARCH_SERVICE_CASE_DAMAGE_LOCATION_GEOLOCATION_REQUEST_FAILED,
    ]);

    if (!responseActionType.error) {
        const {response} = responseActionType.payload;
        const {arcGISLocationCandidateDTOs} = response;

        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_DAMAGE_LOCATION_CANDIDATES,
            payload: {
                arcGISLocationCandidateDTOs,
                serviceCaseId,
                searchQueryString,
            },
        });
    }
};

export default searchServiceCaseDamageLocationGeolocation;
