import {produce} from 'immer';
import {persistenceStates, Task} from '@ace-de/eua-entity-types';
import * as taskActionTypes from './taskActionTypes';

const initialState = {
    taskSearchResults: [],
    taskSearchResultsCount: 0,
    taskCountIndicator: 0,
    persistenceState: persistenceStates.READY,
};

/**
 * Tasks reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */

const taskReducer = produce((draft, action) => {
    switch (action.type) {
        case taskActionTypes.STORE_TASK_SEARCH_RESULTS: {
            const {taskDTOs, taskSearchResultsCount} = action.payload;
            draft.taskSearchResults = taskDTOs.map(task => (new Task().fromDTO(task)));
            draft.taskSearchResultsCount = taskSearchResultsCount > 0 ? taskSearchResultsCount : 0;
            break;
        }

        case taskActionTypes.SET_TASK_COUNT_INDICATOR: {
            const {totalCount} = action.payload;
            draft.taskCountIndicator = totalCount > 0 ? totalCount : 0;
            break;
        }

        case taskActionTypes.RESET_TASK_COUNT_INDICATOR: {
            draft.taskCountIndicator = 0;
            break;
        }

        default:
        //  no-op
    }
    return draft;
}, initialState);

export default taskReducer;
