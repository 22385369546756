import {put} from 'redux-saga/effects';
import {alfClientTypes} from '@ace-de/eua-entity-types';
import * as contactActionTypes from '../contactActionTypes';

const loadContactSearchResults = function* loadContactSearchResults(payload) {
    if (!payload) return;
    const {location} = payload.payload;

    if (!location.search) {
        yield put({
            type: contactActionTypes.STORE_CONTACTS_SEARCH_RESULTS,
            payload: {contactDTOs: [], totalCount: 0},
        });
        return;
    }
    const searchQueryParams = new URLSearchParams(location.search);

    if (location?.state?.isModalOpen || !!searchQueryParams.get('modal')) return;

    if (!searchQueryParams.get('client')) searchQueryParams.append('client', alfClientTypes.ACE);

    if (searchQueryParams.size === 1 && searchQueryParams.has('client')) return;

    yield put({
        type: contactActionTypes.SEARCH_CONTACTS,
        payload: {searchQueryParams},
    });
};

export default loadContactSearchResults;
