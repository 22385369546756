export const leaFeatureActions = {
    CREATE: 'CREATE',
    READ: 'READ',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
};

export const leaFeatures = {
    MEMBER_DATA: 'MEMBER_DATA',
    SERVICE_CASE: 'SERVICE_CASE',
    INVOICE: 'INVOICE',
    CASE_STATUS: 'CASE_STATUS',
    INVOICE_STATUS: 'INVOICE_STATUS',
    MESSAGE: 'MESSAGE',
    CONTACT: 'CONTACT',
    TASKS: 'TASKS',
    DOCUMENT: 'DOCUMENT',
    KPI_INFO: 'KPI_INFO',
    STATUS_INVOICE_IN_CREATION: 'STATUS_INVOICE_IN_CREATION',
    STATUS_INVOICE_MANUAL_CHECK: 'STATUS_INVOICE_MANUAL_CHECK',
    STATUS_INVOICE_APPROVED: 'STATUS_INVOICE_APPROVED',
    STATUS_INVOICE_OPEN: 'STATUS_INVOICE_OPEN',
    STATUS_INVOICE_CLOSED: 'STATUS_INVOICE_CLOSED',
    STATUS_INVOICE_DECLINED: 'STATUS_INVOICE_DECLINED',
    STATUS_INVOICE_PAID: 'STATUS_INVOICE_PAID',
    STATUS_INVOICE_BC_ERROR: 'STATUS_INVOICE_BC_ERROR',
    STATUS_INVOICE_BOOKED: 'STATUS_INVOICE_BOOKED',
    STATUS_CASE_NEW: 'STATUS_CASE_NEW',
    STATUS_CASE_OPEN: 'STATUS_CASE_OPEN',
    STATUS_CASE_CLOSED: 'STATUS_CASE_CLOSED',
    INTERNAL_NOTES: 'INTERNAL_NOTES',
    INVOICE_ACTIONS: 'INVOICE_ACTIONS',
    ELLA_NOTIFICATIONS: 'ELLA_NOTIFICATIONS',
};
