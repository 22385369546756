import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {resolveRoute, Link, withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ButtonPrimary, NoResultsBlock, Panel, useStyles} from '@ace-de/ui-components';
import {historyIcon, Icon, plusIcon, InteractiveIcon, downloadIcon} from '@ace-de/ui-components/icons';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import * as serviceCaseActionTypes from './serviceCaseActionTypes';
import routePaths from '../routePaths';
import * as serviceCaseSelectors from './serviceCaseSelectors';

const SCDocumentTab = props => {
    const {cx} = useStyles();
    const {initiateDocumentUploadFlow, serviceCaseDocuments, serviceCase, initiateDocumentDownload} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('sc_document_tab');

    const handleDownloadDocument = document => {
        initiateDocumentDownload({fileName: document.fileName, url: document.url});
    };

    return (
        <Panel
            title={translateTab('documents_panel.title')}
            actions={(
                <ButtonPrimary onClick={() => initiateDocumentUploadFlow()}>
                    <Icon
                        icon={plusIcon}
                        className={cx([
                            'global!ace-u-margin--right-16',
                            'ace-c-icon--color-contrast',
                        ])}
                    />
                    {translateTab('button_label.upload')}
                </ButtonPrimary>
            )}
            className={cx('ace-c-panel--full-bleed-content')}
            qaIdent="service-case-documents"
        >
            <Table>
                {serviceCaseDocuments.length === 0 && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={historyIcon}
                                />
                            )}
                            description={translateTab('no_results.description')}
                            message={translateTab('no_results.message')}
                        />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell
                            colSpan={2}
                            qaIdentPart="uploaded-at"
                            className={cx('ace-u-padding--left-32')}
                        >
                            {translateTab('data_row.date_time')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="invoice-id">
                            {translateTab('data_row.invoice')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="attachment-type">
                            {translateTab('data_row.attachment_type')}
                        </TableCell>
                        <TableCell colSpan={3} qaIdentPart="description">
                            {translateTab('data_row.file_name')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="uploaded-by">
                            {translateTab('data_row.uploaded_by')}
                        </TableCell>
                        <TableCell
                            colSpan={1}
                            qaIdentPart="download"
                            className={cx('ace-u-padding--right-32')}
                        >
                            {translateTab('data_row.download')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {serviceCaseDocuments.map(document => (
                        <TableRow
                            key={document.id}
                            qaIdentPart={document.id}
                        >
                            <TableCell
                                colSpan={2}
                                qaIdentPartPostfix={document.id}
                                className={cx('ace-u-padding--left-32')}
                            >
                                {document.uploadedAt || '-'}
                            </TableCell>
                            <TableCell
                                colSpan={2}
                                qaIdentPartPostfix={document.id}
                            >
                                <Link
                                    to={resolveRoute(routePaths.INVOICES, {
                                        serviceCaseId: serviceCase?.id,
                                        invoiceId: document.invoiceId,
                                    })}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={cx([
                                        'global!ace-u-typography--variant-body-bold',
                                        'global!ace-u-typography--color-highlighted',
                                    ])}
                                >
                                    {document.invoiceId || '-'}
                                </Link>
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPartPostfix={document.id}>
                                {document.attachmentType
                                    ? translate(`global.document_type.${document.attachmentType.toLowerCase()}`)
                                    : '-'}
                            </TableCell>
                            <TableCell colSpan={3} qaIdentPartPostfix={document.id}>
                                {document.description || '-'}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPartPostfix={document.id}>
                                {document.uploadedBy || '-'}
                            </TableCell>
                            <TableCell
                                colSpan={1}
                                qaIdentPartPostfix={document.id}
                                className={cx('ace-u-padding--right-32')}
                            >
                                <InteractiveIcon
                                    icon={downloadIcon}
                                    onClick={() => handleDownloadDocument(document)}
                                    className={cx('ace-c-interactive-icon--primary')}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Panel>
    );
};

SCDocumentTab.propTypes = {
    initiateDocumentUploadFlow: PropTypes.func.isRequired,
    serviceCaseDocuments: PropTypes.array,
    serviceCase: PropTypes.object,
    initiateDocumentDownload: PropTypes.func.isRequired,
};

SCDocumentTab.defaultProps = {
    serviceCaseDocuments: [],
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: getServiceCase(state, props),
        serviceCaseDocuments: state.serviceCases.serviceCaseDocuments,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateDocumentUploadFlow: () => dispatch({
        type: serviceCaseActionTypes.INITIATE_DOCUMENT_UPLOAD_FLOW,
    }),
    initiateDocumentDownload: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_SERVICE_CASE_DOCUMENT_DOWNLOAD_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SCDocumentTab));
