import {select, call} from 'redux-saga/effects';
import {euaFeatureTypes, euaFeatureEntityTypes} from '@ace-de/eua-arcgis-feature-types';
import config from '../../config';

const loadInvoiceRelevantLocationsMapLayers = function* loadInvoiceRelevantLocationsMapLayers() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'invoice-relevant-locations-map');

    if (!arcGISMap) return;

    if (!arcGISMap?.isLayerSet('invoice-relevant-locations-service-areas')) {
        arcGISMap.createServiceLayer({
            layerId: 'invoice-relevant-locations-service-areas',
            serviceURL: config.ARCGIS_CONTRACT_PARTNER_SERVICE_AREAS_LAYER_URL,
            featureEntityType: euaFeatureEntityTypes.CONTRACT_PARTNER_SERVICE_AREA,
            featureType: euaFeatureTypes.SERVICE_AREA,
            isVisible: false,
            areFeaturesSelectableOnClick: false,
            colorScheme: {
                color: [0, 130, 185, 0.30],
                outlineColor: '#004A52',
            },
        });
    }
    // toDo add other layers for other assignments
};

export default loadInvoiceRelevantLocationsMapLayers;
