export const formatInvoiceServiceLine = (invoiceServiceLinesFormData, invoiceLines) => {
    return invoiceServiceLinesFormData.map(invoiceService => {
        const {partnerName, partnerAddress, partnerType, requestedAmount, ...restInvoiceService} = invoiceService;

        let isApprovedAmountChanged = false;
        invoiceLines.forEach(line => {
            if (line.lineNo === invoiceService.lineNo
                && Number(restInvoiceService?.approvedAmount?.replace(',', '.')).toFixed(2) !== Number(line.approvedAmount).toFixed(2)) {
                isApprovedAmountChanged = true;
            }
        });

        if (!isApprovedAmountChanged) return;

        return {
            ...restInvoiceService,
            amount: requestedAmount,
            approvedAmount: Math.round(Number(restInvoiceService.approvedAmount?.replace(',', '.')) * 100) / 100,
            ...(partnerName && {partnerName}),
            ...(partnerAddress && {partnerAddress}),
            ...(partnerType && {partnerType}),
        };
    }).filter(value => !!value);
};
