import {all, takeLatest, fork} from 'redux-saga/effects';
import filterCaseLogs from './sagas/filterCaseLogs';
import exportCaseLogPDF from './sagas/exportCaseLogPDF';
import downloadCaseLogDocument from './sagas/downloadCaseLogDocument';
import * as caseLogActionTypes from './caseLogActionTypes';

/**
 *  Case log watcher saga
 */
const caseLogWatcher = function* caseLogWatcher() {
    yield all([
        takeLatest(
            caseLogActionTypes.FILTER_CASE_LOGS,
            filterCaseLogs,
        ),
        fork(downloadCaseLogDocument),
        fork(exportCaseLogPDF),
    ]);
};

export default caseLogWatcher;
