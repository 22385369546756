import {createSelector} from 'reselect';

/**
 * General state selectors
 */
export const getServiceCases = state => state.serviceCases.serviceCases;
export const getMembersByMembershipNo = state => state.members.membersByMembershipNo;
export const getVehicles = state => state.members.vehicles;

/**
 * Returns selector for ServiceCase matched from route
 */
export const createServiceCaseSelector = () => createSelector([
    getServiceCases,
    (state, props) => {
        const {match} = props;
        return match?.params['serviceCaseId'];
    },
], (serviceCases, serviceCaseId) => {
    if (!serviceCases || !serviceCaseId) return null;
    return serviceCases[serviceCaseId];
});


/**
 * Returns member's Vehicles selector for ServiceCase matched from route
 */
export const createMemberVehiclesSelector = () => createSelector(
    [
        getMembersByMembershipNo,
        createServiceCaseSelector(),
        getVehicles,
    ],
    (membersByMembershipNo, serviceCase, vehicles) => {
        if (!serviceCase.member.membershipNo) return null;

        const member = membersByMembershipNo[serviceCase.member.membershipNo];
        const memberVehicles = [];
        if (!member) return memberVehicles;

        member.vehicleIds.forEach(vehicleId => {
            if (vehicles[vehicleId]) memberVehicles.push(vehicles[vehicleId]);
        });

        return memberVehicles;
    },
);
