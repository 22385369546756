import React, {useEffect, useState, useRef, useMemo, Fragment, useCallback} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {snakeCase, kebabCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {alfServiceCategoryTypes, alfInvoicePartnerTypes} from '@ace-de/eua-entity-types';
import {Modal, useStyles, RadioButtonGroup, RadioTile, Checkbox} from '@ace-de/ui-components';
import {ButtonIcon} from '@ace-de/ui-components/buttons';
import {Form, InputField, AutocompleteField, SelectField, AutosuggestField, Option, InputCurrencyField, FormField, DateField} from '@ace-de/ui-components/form';
import {Icon, InteractiveIcon, closeIcon, plusIcon, towingIcon, personIcon, otherIcon, searchIcon, locationIcon, commuteIcon, deerIcon, arrowDownIcon, calendarIcon} from '@ace-de/ui-components/icons';
import * as invoiceActionTypes from '../invoiceActionTypes';
import * as invoiceSelectors from '../invoiceSelectors';
import LocationInput from '../ui-elements/LocationInput';
import config from '../../config';
import styles from './InvoiceLineDataModal.module.scss';
import {formatDurationTime} from '../formatDurationTime';

const InvoiceLineDataModal = props => {
    const {cx} = useStyles({}, styles);
    const {translate, createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('invoice_line_data_modal');
    const {hasBackdrop, invoice, searchPartnerLocationGeolocation, services, location} = props;
    const {confirmCreateInvoiceLine, declineCreateInvoiceLine, declineEditInvoiceLine, confirmEditInvoiceLine} = props;

    const lineNo = location?.query?.lineNo;
    const editedInvoiceLine = invoice.lines.find(line => line.lineNo === Number(lineNo));

    const [invoiceLineData, setInvoiceLineData] = useState(lineNo && editedInvoiceLine
        ? {
            ...editedInvoiceLine,
            category: [...services].find(service => (
                service.type === editedInvoiceLine.service
            ))?.category,
            requestedAmount: editedInvoiceLine.requestedAmount?.toFixed(2),
        }
        : null);
    const [isCategorySelected, setIsCategorySelected] = useState(false);
    const [newLocation, setNewLocation] = useState(invoiceLineData ? {
        address: {
            formattedAddress: invoiceLineData.partnerAddress,
        },
    } : null);

    const [duration, setDuration] = useState(editedInvoiceLine
        ? editedInvoiceLine.serviceEndDateTime && editedInvoiceLine.serviceStartDateTime
            ? formatDurationTime(moment(editedInvoiceLine.serviceEndDateTime)
                .diff(editedInvoiceLine.serviceStartDateTime), translate)
            : undefined
        : undefined);

    const currentLocationAddress = useRef('');
    const lastLocationSearchQuery = useRef('');
    const groupedServices = useRef('');

    const searchPartnerLocationGeolocationDebounced = useMemo(() => {
        if (typeof searchPartnerLocationGeolocation === 'function') {
            return debounce(
                searchPartnerLocationGeolocation,
                config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER,
            );
        }
        return () => null;
    }, [searchPartnerLocationGeolocation]);


    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape') {
            if (lineNo) {
                declineEditInvoiceLine();
                return;
            }
            declineCreateInvoiceLine();
        }
    }, [lineNo, declineEditInvoiceLine, declineCreateInvoiceLine]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    useEffect(() => {
        if (!Array.isArray(services)) return;
        groupedServices.current = services.reduce((groupedArray, value) => {
            (groupedArray[value['category']] = groupedArray[value['category']] || []).push(value);
            return groupedArray;
        }, {});
    }, [services]);

    const handleLocationSearchQueryChange = searchQueryString => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchPartnerLocationGeolocationDebounced({
                searchQueryString,
                invoiceId: `${invoice.id}`,
            });
            lastLocationSearchQuery.current = searchQueryString;
        }
    };

    const handleLocationCandidateSelect = locationCandidate => {
        currentLocationAddress.current = locationCandidate.address.formattedAddress;
        setNewLocation(locationCandidate);
    };

    const handleOnChange = formValues => {
        if (!formValues) return;

        const newDuration = formValues.serviceEndDateTime && formValues.serviceStartDateTime
            ? formatDurationTime(moment(formValues.serviceEndDateTime).diff(formValues.serviceStartDateTime), translate)
            : undefined;

        if (formValues.category && invoiceLineData?.category !== formValues.category) {
            setIsCategorySelected(true);

            setInvoiceLineData({
                ...invoiceLineData,
                category: formValues.category,
                service: '',
            });

            if (newDuration !== duration) {
                setDuration(newDuration);
            }

            return;
        }

        const selectedService = [...services].find(service => (
            service.type === formValues.service
        ));

        if (selectedService && selectedService.category !== formValues.category) {
            setInvoiceLineData({
                ...invoiceLineData,
                category: selectedService.category,
            });
            if (newDuration !== duration) {
                setDuration(newDuration);
            }
            return;
        }

        setInvoiceLineData(formValues);
        if (newDuration !== duration) {
            setDuration(newDuration);
        }
    };

    const handleOnSubmit = formValues => {
        if (!formValues) return;
        const {requestedAmount, service, partnerName, partnerAddress, partnerType} = formValues;
        const additionalValues = {};

        const additionalFieldNames = services.find(serviceItem => {
            return serviceItem.type === service;
        })?.additionalStructure?.fields.map(field => field.name);

        if (additionalFieldNames?.length > 0) {
            additionalFieldNames.forEach(additionalFieldName => {
                additionalValues[additionalFieldName] = formValues[additionalFieldName];
            });
        }

        const invoiceLineData = {
            service,
            ...(!lineNo && {requestedAmount: Math.round(Number(requestedAmount.replace(',', '.')) * 100) / 100}),
            ...(lineNo && {amount: Math.round(Number(requestedAmount.replace(',', '.')) * 100) / 100}),
            ...(partnerName && {partnerName}),
            ...(partnerAddress && {partnerAddress}),
            ...(partnerType && {partnerType}),
            ...additionalValues,
        };

        if (lineNo) {
            confirmEditInvoiceLine({
                invoiceId: invoice.id,
                lineNo,
                invoiceLineData,
            });
            return;
        }
        confirmCreateInvoiceLine({
            invoiceId: invoice.id,
            invoiceLineData,
        });
    };

    const handleOnCurrencyInputBlur = value => {
        if (!value) return;
        setInvoiceLineData({
            ...invoiceLineData,
            requestedAmount: Number(value.replace(',', '.')).toFixed(2),
        });
    };

    if (!invoice || !services) return null;

    const {partnerLocationSearchQuery, partnerLocationCandidates} = invoice;

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={lineNo ? declineEditInvoiceLine : declineCreateInvoiceLine}
                />
            )}
            hasBackdrop={hasBackdrop}
            hasColoredHeader={true}
            title={translateModal('modal_title.choose_service')}
            className={cx('ace-c-invoice-line-modal')}
        >
            <Form name="invoiceLineData" onSubmit={handleOnSubmit} onChange={handleOnChange}>
                {invoiceLineFormValues => {
                    const sortedServices = (groupedServices.current && isCategorySelected && invoiceLineData?.category
                        ? [...groupedServices.current[invoiceLineData.category]] : [...services])
                        .sort((serviceA, serviceB) => {
                            return serviceA.code - serviceB.code;
                        });
                    const selectedServiceAdditionalFields = invoiceLineData?.service
                        ? sortedServices.find(service => service.type === invoiceLineData.service)?.additionalStructure
                        : null;

                    return (
                        <Fragment>
                            <RadioButtonGroup
                                name="category"
                                value={invoiceLineData?.category || ''}
                                isDisabled={!!lineNo}
                            >
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-full-width',
                                        'global!-ace-u-flex--align-center',
                                    ])}
                                >
                                    <RadioTile
                                        className={cx('ace-c-radio-button', 'global!ace-u-margin--right-24', 'global!ace-u-full-width')}
                                        name="vehicle-category"
                                        value={alfServiceCategoryTypes.VEHICLE}
                                        icon={towingIcon}
                                    >
                                        {translateModal(`service_category.${alfServiceCategoryTypes.VEHICLE.toLowerCase()}`)}
                                    </RadioTile>
                                    <RadioTile
                                        className={cx('ace-c-radio-button', 'global!ace-u-margin--right-24', 'global!ace-u-full-width')}
                                        name="journey-and-accommodation-category"
                                        value={alfServiceCategoryTypes.JOURNEY_ACCOMMODATION}
                                        icon={commuteIcon}
                                    >
                                        {translateModal(`service_category.${alfServiceCategoryTypes.JOURNEY_ACCOMMODATION.toLowerCase()}`)}
                                    </RadioTile>
                                    <RadioTile
                                        className={cx('ace-c-radio-button', 'global!ace-u-margin--right-24', 'global!ace-u-full-width')}
                                        name="person-category"
                                        value={alfServiceCategoryTypes.PERSON}
                                        icon={personIcon}
                                    >
                                        {translateModal(`service_category.${alfServiceCategoryTypes.PERSON.toLowerCase()}`)}
                                    </RadioTile>
                                    <RadioTile
                                        className={cx('ace-c-radio-button', 'global!ace-u-margin--right-24', 'global!ace-u-full-width')}
                                        name="aid-category"
                                        value={alfServiceCategoryTypes.AID}
                                        icon={deerIcon}
                                    >
                                        {translateModal(`service_category.${alfServiceCategoryTypes.AID.toLowerCase()}`)}
                                    </RadioTile>
                                    <RadioTile
                                        className={cx('ace-c-radio-button', 'global!ace-u-full-width')}
                                        name="other-category"
                                        value={alfServiceCategoryTypes.OTHER}
                                        icon={otherIcon}
                                    >
                                        {translateModal(`service_category.${alfServiceCategoryTypes.OTHER.toLowerCase()}`)}
                                    </RadioTile>
                                </div>
                            </RadioButtonGroup>
                            <div className={cx('global!ace-u-grid', 'ace-u-margin--top-24')}>
                                <AutocompleteField
                                    name="service"
                                    value={invoiceLineData?.service || ''}
                                    label={`${translateModal('select_label.choose_service')}*`}
                                    className={cx('ace-u-grid-column--span-5')}
                                    icon={arrowDownIcon}
                                    isDisabled={!!lineNo}
                                >
                                    {sortedServices.length > 0
                                        ? sortedServices.map(service => {
                                            return (
                                                <Option
                                                    key={service.code}
                                                    name={`service-${service.code}`}
                                                    value={service.type}
                                                >
                                                    {`${service.code} ${translate(`global.service_type.${service.type.toLowerCase()}`)}`}
                                                </Option>
                                            );
                                        }) : null}
                                </AutocompleteField>
                                <div className={cx(['ace-u-grid-column--span-3', 'global!ace-u-flex'])}>
                                    <InputCurrencyField
                                        name="requestedAmount"
                                        value={invoiceLineData?.requestedAmount || ''}
                                        label={`${translateModal('input_label.requested_amount')}*`}
                                        onBlur={handleOnCurrencyInputBlur}
                                    />
                                </div>
                            </div>
                            <div className={cx('global!ace-u-grid', 'ace-u-margin--top-24')}>
                                <SelectField
                                    name="partnerType"
                                    value={invoiceLineData?.partnerType || ''}
                                    label={translateModal('select_label.partner_type')}
                                    className={cx('ace-u-grid-column--span-2')}
                                >
                                    {Object.values(alfInvoicePartnerTypes).map((partnerType, index) => (
                                        <Option
                                            key={index}
                                            name={`partner-type-${index}`}
                                            value={partnerType}
                                        >
                                            {translateModal(`select_option_label.${partnerType.toLowerCase()}`)}
                                        </Option>
                                    ))}
                                </SelectField>
                                <InputField
                                    name="partnerName"
                                    value={invoiceLineData?.partnerName || ''}
                                    label={translateModal('input_label.partner_name')}
                                    className={cx('ace-u-grid-column--span-6')}
                                />
                                <AutosuggestField
                                    name="partnerAddress"
                                    icon={searchIcon}
                                    className={cx('ace-u-grid-column--span-4')}
                                    label={translateModal('input_label.partner_address')}
                                    value={newLocation?.address?.formattedAddress || ''}
                                    onChange={handleLocationSearchQueryChange}
                                    onOptionSelect={handleLocationCandidateSelect}
                                    optionValueSelector={locationCandidate => {
                                        return locationCandidate.address.formattedAddress;
                                    }}
                                >
                                    {(invoiceLineFormValues['partnerAddress'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                    && partnerLocationSearchQuery === lastLocationSearchQuery.current
                                        ? partnerLocationCandidates
                                            .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                            .map((locationCandidate, index) => {
                                                return (
                                                    <Option
                                                        key={index}
                                                        name={`partner-location-candidate-${index}`}
                                                        value={locationCandidate}
                                                    >
                                                        <Icon
                                                            icon={locationIcon}
                                                            className={cx('global!ace-u-margin--right-16')}
                                                        />
                                                        {locationCandidate.address.formattedAddress}
                                                    </Option>
                                                );
                                            }) : null}
                                </AutosuggestField>
                            </div>
                            <div className={cx('global!ace-u-grid', 'ace-u-margin--top-24')}>
                                {selectedServiceAdditionalFields && (
                                    selectedServiceAdditionalFields.fields.map(field => {
                                        switch (field.type) {
                                            case 'DATE_TIME_PICKER': {
                                                return field.name === 'serviceEndDateTime'
                                                    ? (
                                                        <Fragment key={field.name}>
                                                            <DateField
                                                                name={field.name}
                                                                icon={calendarIcon}
                                                                className={cx('ace-u-grid-column--span-3', 'ace-c-date-field__input--medium')}
                                                                label={translateModal(`datepicker_label.${snakeCase(field.name)}`)}
                                                                isTimeExist={true}
                                                                format="DD.MM.YYYY, HH:mm"
                                                                value={invoiceLineData?.[field.name]}
                                                            />
                                                            <div className={cx(`ace-u-grid-column--span-${selectedServiceAdditionalFields.fields.find(field => field.type === 'CHECKBOX') ? '1' : '2'}`)}>
                                                                <FormField label={translateModal('input_label.duration')}>
                                                                    {duration || '-'}
                                                                </FormField>
                                                            </div>
                                                        </Fragment>
                                                    )
                                                    : (
                                                        <DateField
                                                            key={field.name}
                                                            name={field.name}
                                                            icon={calendarIcon}
                                                            className={cx('ace-c-date-field__input--medium', 'ace-u-grid-column--span-3')}
                                                            label={translateModal(`datepicker_label.${snakeCase(field.name)}`)}
                                                            isTimeExist={true}
                                                            format="DD.MM.YYYY, HH:mm"
                                                            value={invoiceLineData?.[field.name]}
                                                        />
                                                    );
                                            }
                                            case 'MULTI_SELECT':
                                            case 'SELECT': {
                                                return (
                                                    <SelectField
                                                        key={field.name}
                                                        name={field.name}
                                                        value={invoiceLineData?.[field.name] || null}
                                                        label={translateModal(`select_label.${snakeCase(field.name)}`)}
                                                        className={cx('ace-u-grid-column--span-2')}
                                                        isMultipleChoice={field.type === 'MULTI_SELECT'}
                                                    >
                                                        {field.values.map((fieldValue, index) => (
                                                            <Option
                                                                key={`select-option-${kebabCase(fieldValue)}`}
                                                                name={`${kebabCase(field.name)}-${index}`}
                                                                value={fieldValue}
                                                            >
                                                                {translate(`global.${snakeCase(field.name)}.${fieldValue.toLowerCase()}`)}
                                                            </Option>
                                                        ))}
                                                    </SelectField>
                                                );
                                            }
                                            case 'CHECKBOX': {
                                                return (
                                                    <div key={field.name} className={cx('ace-u-grid-column--span-3', 'global!ace-u-flex', 'global!ace-u-flex--justify-flex-end')}>
                                                        <Checkbox
                                                            name={field.name}
                                                            value={true}
                                                            isSelected={invoiceLineData?.[field.name]}
                                                            className={cx('global!ace-u-margin--top-16')}
                                                        >
                                                            {translateModal(`checkbox_label.${snakeCase(field.name)}`)}
                                                        </Checkbox>
                                                    </div>

                                                );
                                            }
                                            default:
                                                return null;
                                        }
                                    })
                                )}
                            </div>
                            {selectedServiceAdditionalFields && (
                                selectedServiceAdditionalFields?.fields?.map(field => {
                                    if (field.type === 'LOCATION_INPUT') {
                                        return (
                                            <LocationInput
                                                name={field.name}
                                                invoice={invoice}
                                                invoiceLineData={invoiceLineData?.[field.name] || null}
                                                key={field.name}
                                            />
                                        );
                                    }
                                    return null;
                                })
                            )}
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--justify-flex-end',
                                ])}
                            >
                                <ButtonIcon
                                    name="confirmModalButton"
                                    type="submit"
                                    className={cx('global!ace-u-margin--top-32')}
                                    icon={plusIcon}
                                    isDisabled={!invoiceLineData?.service || !invoiceLineData?.requestedAmount}
                                >
                                    {translateModal('button_label.add')}
                                </ButtonIcon>
                            </div>
                        </Fragment>
                    );
                }}
            </Form>
        </Modal>
    );
};

InvoiceLineDataModal.propTypes = {
    confirmCreateInvoiceLine: PropTypes.func.isRequired,
    confirmEditInvoiceLine: PropTypes.func.isRequired,
    declineCreateInvoiceLine: PropTypes.func.isRequired,
    declineEditInvoiceLine: PropTypes.func.isRequired,
    searchPartnerLocationGeolocation: PropTypes.func.isRequired,
    invoice: PropTypes.object,
    services: PropTypes.array,
    hasBackdrop: PropTypes.bool,
    location: PropTypes.object,
};

InvoiceLineDataModal.defaultProps = {
    invoice: null,
    services: [],
    hasBackdrop: false,
    location: {},
};

const mapStateToProps = (state, props) => {
    const getInvoice = invoiceSelectors.createInvoicesSelector();
    return {
        invoice: getInvoice(state, props),
        services: invoiceSelectors.getServices(state),
    };
};

const mapDispatchToProps = dispatch => ({
    confirmCreateInvoiceLine: payload => dispatch({
        type: invoiceActionTypes.CONFIRM_CREATE_INVOICE_LINE,
        payload,
    }),
    declineCreateInvoiceLine: payload => dispatch({
        type: invoiceActionTypes.DECLINE_CREATE_INVOICE_LINE,
        payload,
    }),
    confirmEditInvoiceLine: payload => dispatch({
        type: invoiceActionTypes.CONFIRM_EDIT_INVOICE_LINE,
        payload,
    }),
    declineEditInvoiceLine: () => dispatch({
        type: invoiceActionTypes.DECLINE_EDIT_INVOICE_LINE,
    }),
    searchPartnerLocationGeolocation: payload => dispatch({
        type: invoiceActionTypes.SEARCH_INVOICE_PARTNER_LOCATION_GEOLOCATION,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceLineDataModal);
