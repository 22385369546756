import {take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import createInvoiceLine from './createInvoiceLine';
import * as invoiceActionTypes from '../invoiceActionTypes';
import modalIds from '../../modalIds';

const initiateCreateInvoiceLineFlow = function* initiateCreateInvoiceLineFlow() {
    while (true) {
        yield take(invoiceActionTypes.INITIATE_CREATE_INVOICE_LINE_FLOW);

        yield* openModal(modalIds.INVOICE_LINE_DATA);

        const chosenModalOption = yield take([
            invoiceActionTypes.DECLINE_CREATE_INVOICE_LINE,
            invoiceActionTypes.CONFIRM_CREATE_INVOICE_LINE,
        ]);

        if (chosenModalOption.type === invoiceActionTypes.CONFIRM_CREATE_INVOICE_LINE) {
            const {payload} = chosenModalOption;

            yield* createInvoiceLine({
                caller: invoiceActionTypes.INITIATE_CREATE_INVOICE_LINE_FLOW,
                payload,
            });
        }

        yield* closeModal(modalIds.INVOICE_LINE_DATA);
    }
};

export default initiateCreateInvoiceLineFlow;
