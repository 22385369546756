import {all, fork, takeLatest} from 'redux-saga/effects';
import * as memberActionTypes from './memberActionTypes';
import searchMembers from './sagas/searchMembers';
import searchMemberVehicles from './sagas/searchMemberVehicles';
import fetchMemberBCDetails from './sagas/fetchMemberBCDetails';

/**
 *  Members watcher saga
 */
const memberWatcher = function* memberWatcher() {
    yield all([
        takeLatest(memberActionTypes.SEARCH_MEMBERS, searchMembers),
        takeLatest(memberActionTypes.SEARCH_MEMBER_VEHICLES, searchMemberVehicles),
        fork(fetchMemberBCDetails),
    ]);
};

export default memberWatcher;
