import {take, put, select} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import updateServiceCase from './updateServiceCase';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import modalIds from '../../modalIds';

/**
 * Update service case trailer data flow
 * updates the trailer snapshot on a service case level
 */
const updateServiceCaseTrailerFlow = function* updateServiceCaseTrailerFlow() {
    while (true) {
        const {payload} = yield take(serviceCaseActionTypes.SUBMIT_SERVICE_CASE_TRAILER_DATA_FORM);
        const {location} = yield select(state => state.router);

        if (location?.state?.isModalOpen && [modalIds.CONFIRM_VEHICLE_SELECTION_MODAL, modalIds.VEHICLE_DATA_MODAL]
            .includes(location?.state?.modalId)
        ) continue;

        const {serviceCaseData, serviceCaseId} = payload;

        yield* updateServiceCase({
            caller: serviceCaseActionTypes.SUBMIT_SERVICE_CASE_TRAILER_DATA_FORM,
            serviceCaseId,
            serviceCaseData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: serviceCaseActionTypes.SUBMIT_SERVICE_CASE_TRAILER_DATA_FORM},
        });
    }
};

export default updateServiceCaseTrailerFlow;
