import {take, select, fork, put} from 'redux-saga/effects';
import {push, resolveRoute} from '@computerrock/formation-router';
import routePaths from '../../routePaths';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import errorTypes from '../../application/errorTypes';

const createServiceCaseFlow = function* createServiceCaseFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    while (true) {
        const {payload} = yield take([serviceCaseActionTypes.CREATE_NEW_SERVICE_CASE]);
        const {member} = payload;
        let memberForCase = member;

        if (!member) continue;

        if (member.personalDetails?.address
            && (!member.personalDetails?.coordinates?.latitude
                || !member.personalDetails?.coordinates?.longitude
            )
        ) {
            const {address} = member.personalDetails;
            const memberAddress = address.formattedAddress
                ? address.formattedAddress
                : `${address.street} ${address.city} ${address.postCode} ${address.country}`;

            if (memberAddress) {
                yield fork(
                    fetchRequest,
                    serviceCaseActionTypes.SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST,
                    arcGISRESTService.searchAddressLocation,
                    {
                        singleLine: memberAddress,
                        forStorage: true,
                    },
                );

                const searchMemberAddressGeolocation = yield take([
                    serviceCaseActionTypes.SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_SUCCEEDED,
                    serviceCaseActionTypes.SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_FAILED,
                ]);

                if (!searchMemberAddressGeolocation.error) {
                    const {response} = searchMemberAddressGeolocation.payload;
                    const {arcGISLocationCandidateDTOs} = response;

                    const arcGISLocationCandidateDTO = arcGISLocationCandidateDTOs[0];

                    if (arcGISLocationCandidateDTO) {
                        memberForCase = {
                            ...member,
                            personalDetails: {
                                ...member.personalDetails,
                                address: {
                                    ...member.personalDetails.address,
                                    countryCode: arcGISLocationCandidateDTOs.countryCode,
                                    coordinates: {
                                        longitude: arcGISLocationCandidateDTO.longitude,
                                        latitude: arcGISLocationCandidateDTO.latitude,
                                    },
                                },
                                coordinates: {
                                    longitude: arcGISLocationCandidateDTO.longitude,
                                    latitude: arcGISLocationCandidateDTO.latitude,
                                },
                            },
                        };
                    }
                }
            }
        }

        yield fork(
            fetchRequest,
            serviceCaseActionTypes.CREATE_NEW_SERVICE_CASE_REQUEST,
            leaAssignmentFlowService.createServiceCase,
            {
                member: memberForCase,
            },
        );

        const responseAction = yield take([
            serviceCaseActionTypes.CREATE_NEW_SERVICE_CASE_REQUEST_FAILED,
            serviceCaseActionTypes.CREATE_NEW_SERVICE_CASE_REQUEST_SUCCEEDED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {serviceCaseDTO} = response;
            const {id: serviceCaseId} = serviceCaseDTO;

            yield put({
                type: serviceCaseActionTypes.STORE_SERVICE_CASES,
                payload: {serviceCaseDTOs: [serviceCaseDTO]},
            });

            yield put(push(resolveRoute(routePaths.SERVICE_CASE, {serviceCaseId})));
        }
        if (responseAction.error) {
            yield put({
                type: applicationActionTypes.INITIATE_ERROR_MESSAGE_FLOW,
                payload: {errorType: errorTypes.CASE_CREATION_FAILED},
            });
        }
    }
};

export default createServiceCaseFlow;
