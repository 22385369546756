import {put, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {push, resolveRoute} from '@computerrock/formation-router';
import {alfInvoiceStatusTypes} from '@ace-de/eua-entity-types';
import updateInvoiceStatus from './updateInvoiceStatus';
import * as invoiceActionTypes from '../invoiceActionTypes';
import modalIds from '../../modalIds';
import invoiceSuccessModalActionTypes from '../modals/invoiceSuccessModalActionTypes';
import routePaths from '../../routePaths';

const initiateInvoiceDeclineFlow = function* initiateInvoiceDeclineFlow() {
    while (true) {
        const {payload} = yield take([invoiceActionTypes.INITIATE_INVOICE_DECLINE_FLOW]);
        const {serviceCaseId, invoiceId} = payload;
        if (!serviceCaseId || !invoiceId) continue;

        yield* openModal(modalIds.INVOICE_DECLINE_MODAL);

        const invoiceDeclineModalAction = yield take([
            invoiceActionTypes.CONFIRM_INVOICE_DECLINE_FLOW,
            invoiceActionTypes.DECLINE_INVOICE_DECLINE_FLOW,
        ]);

        if (invoiceDeclineModalAction.type === invoiceActionTypes.CONFIRM_INVOICE_DECLINE_FLOW) {
            const {declineDescription, declineReason} = invoiceDeclineModalAction.payload;

            yield* updateInvoiceStatus({
                caller: invoiceActionTypes.INITIATE_MANUAL_INVOICE_STATUS_UPDATE_FLOW,
                invoiceId,
                invoiceStatusData: {
                    status: alfInvoiceStatusTypes.DECLINED,
                    declineDescription,
                    declineReason,
                },
            });

            yield* openModal(modalIds.INVOICE_SUCCESS_MODAL, {
                action: invoiceSuccessModalActionTypes.INVOICE_DECLINED,
            });

            const chosenModalOption = yield take([
                invoiceActionTypes.REDIRECT_TO_CASE_OVERVIEW,
                invoiceActionTypes.REDIRECT_TO_DASHBOARD,
            ]);

            (chosenModalOption.type === invoiceActionTypes.REDIRECT_TO_CASE_OVERVIEW)
                ? yield put(push(resolveRoute(routePaths.SERVICE_CASES_OVERVIEW, {serviceCaseId})))
                : yield put(push(resolveRoute(routePaths.DASHBOARD)));

            yield* closeModal(modalIds.INVOICE_SUCCESS_MODAL, {
                action: invoiceSuccessModalActionTypes.INVOICE_DECLINED,
            });
        }

        yield* closeModal(modalIds.INVOICE_DECLINE_MODAL);
    }
};

export default initiateInvoiceDeclineFlow;
