/**
 * Allowed attachment MIME types for Invoices
 */
export default [
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/x-excel',
    'application/x-msexcel',
    'application/vnd.ms-outlook',
    '', // fallback value: when file mime type cannot be read - allow it
];
