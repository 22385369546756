import React from 'react';
import PropTypes from 'prop-types';
import {snakeCase} from 'change-case';
import {useHistory, resolveRoute} from '@computerrock/formation-router';
import {ampMembershipTypes, ampMemberStatusTypes, ampMemberTariffGroups} from '@ace-de/eua-entity-types';
import {Badge, NoResultsBlock, useStyles} from '@ace-de/ui-components';
import {Icon, findCaseIcon} from '@ace-de/ui-components/icons';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {useTranslate} from '@computerrock/formation-i18n';
import routePaths from '../../routePaths';

const MemberSearchResultsTable = ({resultsType, memberResults}) => {
    const {cx} = useStyles();
    const history = useHistory();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateComponent = createTranslateShorthand('member_search_results_table');

    const openMemberScreen = member => {
        history.push(resolveRoute(routePaths.MEMBER, {
            membershipNo: member.membershipNo,
        }));
    };

    return (
        <Table qaIdent={`member-search-results-${resultsType.toLowerCase()}`}>
            {memberResults.length === 0 && (
                <TableCaption>
                    <NoResultsBlock
                        icon={(
                            <Icon
                                icon={findCaseIcon}
                                className={cx('ace-c-icon--xxl')}
                            />
                        )}
                        message={translateComponent('no_search_results.message')}
                        description={translateComponent('no_search_results.description')}
                    />
                </TableCaption>
            )}
            <TableHead>
                <TableRow>
                    <TableCell
                        colSpan={2}
                        qaIdentPart="member-membership-number"
                        className={cx('ace-u-padding--left-24')}
                    >
                        {translateComponent('table_header.membership_no')}
                    </TableCell>
                    <TableCell colSpan={2} qaIdentPart="member-personal-details-name">
                        {translateComponent('table_header.name')}
                    </TableCell>
                    {resultsType === ampMembershipTypes.PRIVATE && (
                        <TableCell colSpan={2} qaIdentPart="member-personal-details-first-name">
                            {translateComponent('table_header.first_name')}
                        </TableCell>
                    )}
                    <TableCell colSpan={4} qaIdentPart="member-address">
                        {translateComponent('table_header.address')}
                    </TableCell>
                    <TableCell colSpan={2} qaIdentPart="member-tariff-group">
                        {translateComponent('table_header.tariff')}
                    </TableCell>
                    <TableCell colSpan={1} qaIdentPart="member-status">
                        {translateComponent('table_header.status')}
                    </TableCell>
                    <TableCell
                        colSpan={1}
                        qaIdentPart="member-service-case-count"
                        className={cx('ace-u-padding--right-24')}
                    >
                        {translateComponent('table_header.cases')}
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {memberResults.length > 0 && memberResults.map(member => {
                    return (
                        <TableRow
                            key={member.membershipNo}
                            onClick={() => { openMemberScreen(member); }}
                            qaIdentPart={member.id}
                        >
                            <TableCell
                                colSpan={2}
                                qaIdentPart="member-membership-number"
                                qaIdentPartPostfix={member.id}
                                className={cx('ace-u-padding--left-24')}
                            >
                                {member.membershipNo}
                            </TableCell>
                            <TableCell
                                colSpan={2}
                                qaIdentPart="member-personal-details-name"
                                qaIdentPartPostfix={member.id}
                            >
                                {resultsType === ampMembershipTypes.PRIVATE
                                    ? member.personalDetails?.surname
                                    : member.personalDetails?.name
                                }
                            </TableCell>
                            {resultsType === ampMembershipTypes.PRIVATE && (
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="member-personal-details-first-name"
                                    qaIdentPartPostfix={member.id}
                                >
                                    {member.personalDetails.firstName}
                                </TableCell>
                            )}
                            <TableCell
                                colSpan={4}
                                qaIdentPart="member-personal-details-address"
                                qaIdentPartPostfix={member.id}
                            >
                                {/*
                                 Note: member's personal data is corrupted
                                 and we don't always have "formatted address",
                                 therefore, we have to display address like this
                                 */}
                                {`${member.personalDetails.address.street} ${member.personalDetails.address.postCode} ${member.personalDetails.address.city}`}
                            </TableCell>
                            <TableCell
                                colSpan={2}
                                qaIdentPart="member-tariff-group"
                                qaIdentPartPostfix={member.id}
                                className={cx('global!ace-u-typography--variant-body-bold')}
                            >
                                {member.tariffDetails?.currentTariff
                                    ? translate(`global.member_tariff_group.${snakeCase(ampMemberTariffGroups[member.tariffDetails.tariffGroup])}`)
                                    : ''}
                            </TableCell>
                            <TableCell
                                colSpan={1}
                                qaIdentPart="member-status"
                                qaIdentPartPostfix={member.id}
                            >
                                {member.status !== ampMemberStatusTypes.EMPTY && (
                                    <Badge status={member.status.toLowerCase()}>
                                        {translate(`global.member_status.${snakeCase(ampMemberStatusTypes[member.status])}`)}
                                    </Badge>
                                )}
                            </TableCell>
                            <TableCell
                                colSpan={1}
                                qaIdentPart="member-case-count"
                                qaIdentPartPostfix={member.id}
                                className={cx('ace-u-padding--right-24')}
                            >
                                {member.leaCasesCount || '-'}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

MemberSearchResultsTable.propTypes = {
    resultsType: PropTypes.oneOf([ampMembershipTypes.PRIVATE, ampMembershipTypes.CORPORATE]).isRequired,
    memberResults: PropTypes.array.isRequired,
};

export default MemberSearchResultsTable;
