/**
 * Route paths
 */
export default {
    ROOT: '/',
    KEYCLOAK_AUTHORIZE: '/oidc/authorize',
    KEYCLOAK_AUTHENTICATE: '/oidc/authenticate',
    SYSTEM_ACCESS_DENIED: '/access/403',
    ROUTE_ACCESS_DENIED: '/403',
    ARCGIS_AUTHORIZE: '/oauth2/authorize',
    ARCGIS_AUTHENTICATE: '/oauth2/authenticate',
    DASHBOARD_SECTION: '/dashboard',
    DASHBOARD: '/dashboard',
    MEMBER_SEARCH: '/dashboard/member-search',
    MEMBER: '/members/:membershipNo',
    SERVICE_CASE_SECTION: '/service-cases/:serviceCaseId',
    SERVICE_CASE: '/service-cases/:serviceCaseId',
    SERVICE_CASES_SEARCH: '/dashboard/service-cases',
    SERVICE_CASES_OVERVIEW: '/service-cases/:serviceCaseId/overview',
    INVOICES: '/service-cases/:serviceCaseId/invoices/:invoiceId',
    INVOICE_OVERVIEW: '/service-cases/:serviceCaseId/invoice-overview/:invoiceId',
    INVOICES_SEARCH: '/dashboard/invoices-search',
    TASKS_SEARCH: '/dashboard/task-search',
    CONTACTS: '/dashboard/contacts',
};
