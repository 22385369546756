import {fork, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

const downloadServiceCaseDocument = function* downloadServiceCaseDocument() {
    const {serviceManager} = yield select(state => state.application);
    const alfFileAssetsService = serviceManager.loadService('alfFileAssetsService');

    while (true) {
        const {payload} = yield take(serviceCaseActionTypes.INITIATE_SERVICE_CASE_DOCUMENT_DOWNLOAD_FLOW);
        const {fileName, url} = payload;

        yield fork(
            fetchRequest,
            serviceCaseActionTypes.DOWNLOAD_SERVICE_CASE_DOCUMENT_REQUEST,
            alfFileAssetsService.downloadFile,
            {
                fileName,
                url,
                shouldOpenFile: true,
            },
        );
        yield take([
            serviceCaseActionTypes.DOWNLOAD_SERVICE_CASE_DOCUMENT_REQUEST_SUCCEEDED,
            serviceCaseActionTypes.DOWNLOAD_SERVICE_CASE_DOCUMENT_REQUEST_FAILED,
        ]);
    }
};

export default downloadServiceCaseDocument;
