import {take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {alfInvoiceStatusTypes} from '@ace-de/eua-entity-types';
import * as invoiceActionTypes from '../invoiceActionTypes';
import modalIds from '../../modalIds';
import updateInvoiceStatus from './updateInvoiceStatus';

const initiateClosingEstimationFlow = function* initiateClosingEstimationFlow() {
    while (true) {
        const {payload} = yield take(invoiceActionTypes.INITIATE_CLOSING_OF_BBZ_FLOW);
        const {invoiceId} = payload;

        if (!invoiceId) continue;

        yield* openModal(modalIds.CLOSING_ESTIMATION_MODAL);

        const chosenModalOption = yield take([
            invoiceActionTypes.DECLINE_CLOSING_OF_BBZ,
            invoiceActionTypes.CONFIRM_CLOSING_OF_BBZ,
        ]);

        if (chosenModalOption.type === invoiceActionTypes.CONFIRM_CLOSING_OF_BBZ) {
            yield* updateInvoiceStatus({
                caller: invoiceActionTypes.CONFIRM_CLOSING_OF_BBZ,
                invoiceId,
                invoiceStatusData: {status: alfInvoiceStatusTypes.CLOSED},
            });
        }

        yield* closeModal(modalIds.CLOSING_ESTIMATION_MODAL);
    }
};

export default initiateClosingEstimationFlow;
