const resourceEndpoints = {
    SEARCH_POST_CODES: 'SEARCH_POST_CODES',
    SEARCH_CITIES: 'SEARCH_CITIES',
    SEARCH_POST_CODES_CITIES: 'SEARCH_POST_CODES_CITIES',
    SEARCH_ADDRESSES: 'SEARCH_ADDRESSES',
    SELECT_POST_CODES: 'SELECT_POST_CODES',
    SELECT_CITIES: 'SELECT_CITIES',
    SELECT_POST_CODES_CITIES: 'SELECT_POST_CODES_CITIES',
    SELECT_ADDRESSES: 'SELECT_ADDRESSES',
};

const getResourceEndpointKey = (selectedAddressFields, key, datafactoryMethod = 'SEARCH') => {
    if (!selectedAddressFields) return;
    switch (selectedAddressFields.length) {
        case 0:
            return key === 'city'
                ? resourceEndpoints[`${datafactoryMethod}_CITIES`]
                : resourceEndpoints[`${datafactoryMethod}_POST_CODES`];
        case 1:
            return selectedAddressFields.includes('street')
                ? resourceEndpoints[`${datafactoryMethod}_ADDRESSES`]
                : resourceEndpoints[`${datafactoryMethod}_POST_CODES_CITIES`];
        case 2:
            return resourceEndpoints[`${datafactoryMethod}_ADDRESSES`];
        default:
            return;
    }
};

export default getResourceEndpointKey;
