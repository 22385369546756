/**
 * Command action types
 */
export const SEARCH_MEMBERS = '[members] SEARCH_MEMBERS';
export const MARK_SEARCH_RESULTS_AS_VALID = '[members] MARK_SEARCH_RESULTS_AS_VALID';
export const STORE_MEMBER_BC_DETAILS = '[members] STORE_MEMBER_BC_DETAILS';
export const FETCH_MEMBER_BC_DETAILS = '[members] FETCH_MEMBER_BC_DETAILS';
export const SEARCH_MEMBER_VEHICLES = '[members] SEARCH_MEMBER_VEHICLES';

/**
 * Event action types
 */
export const SEARCH_MEMBERS_REQUEST = '[members] SEARCH_MEMBERS_REQUEST';
export const SEARCH_MEMBERS_REQUEST_SENT = '[members] SEARCH_MEMBERS_REQUEST_SENT';
export const SEARCH_MEMBERS_REQUEST_SUCCEEDED = '[members] SEARCH_MEMBERS_REQUEST_SUCCEEDED';
export const SEARCH_MEMBERS_REQUEST_FAILED = '[members] SEARCH_MEMBERS_REQUEST_FAILED';

export const FETCH_MEMBER_REQUEST = '[members] FETCH_MEMBER_REQUEST';
export const FETCH_MEMBER_REQUEST_SENT = '[members] FETCH_MEMBER_REQUEST_SENT';
export const FETCH_MEMBER_REQUEST_SUCCEEDED = '[members] FETCH_MEMBER_REQUEST_SUCCEEDED';
export const FETCH_MEMBER_REQUEST_FAILED = '[members] FETCH_MEMBER_REQUEST_FAILED';

export const FETCH_MEMBER_SERVICE_CASES_REQUEST = '[members] FETCH_MEMBER_SERVICE_CASES_REQUEST';
export const FETCH_MEMBER_SERVICE_CASES_REQUEST_SENT = '[members] FETCH_MEMBER_SERVICE_CASES_REQUEST_SENT';
export const FETCH_MEMBER_SERVICE_CASES_REQUEST_SUCCEEDED = '[members] FETCH_MEMBER_SERVICE_CASES_REQUEST_SUCCEEDED';
export const FETCH_MEMBER_SERVICE_CASES_REQUEST_FAILED = '[members] FETCH_MEMBER_SERVICE_CASES_REQUEST_FAILED';

export const FETCH_MEMBER_BC_DETAILS_REQUEST = '[members] FETCH_MEMBER_BC_DETAILS_REQUEST';
export const FETCH_MEMBER_BC_DETAILS_REQUEST_SENT = '[members] FETCH_MEMBER_BC_DETAILS_REQUEST_SENT';
export const FETCH_MEMBER_BC_DETAILS_REQUEST_SUCCEEDED = '[members] FETCH_MEMBER_BC_DETAILS_REQUEST_SUCCEEDED';
export const FETCH_MEMBER_BC_DETAILS_REQUEST_FAILED = '[members] FETCH_MEMBER_BC_DETAILS_REQUEST_FAILED';

export const SEARCH_MEMBER_VEHICLES_REQUEST = '[members] SEARCH_MEMBER_VEHICLES_REQUEST';
export const SEARCH_MEMBER_VEHICLES_REQUEST_SENT = '[members] SEARCH_MEMBER_VEHICLES_REQUEST_SENT';
export const SEARCH_MEMBER_VEHICLES_REQUEST_SUCCEEDED = '[members] SEARCH_MEMBER_VEHICLES_REQUEST_SUCCEEDED';
export const SEARCH_MEMBER_VEHICLES_REQUEST_FAILED = '[members] SEARCH_MEMBER_VEHICLES_REQUEST_FAILED';

export const FETCH_MEMBER_VEHICLES_REQUEST = '[members] FETCH_MEMBER_VEHICLES_REQUEST';
export const FETCH_MEMBER_VEHICLES_REQUEST_SENT = '[members] FETCH_MEMBER_VEHICLES_REQUEST_SENT';
export const FETCH_MEMBER_VEHICLES_REQUEST_SUCCEEDED = '[members] FETCH_MEMBER_VEHICLES_REQUEST_SUCCEEDED';
export const FETCH_MEMBER_VEHICLES_REQUEST_FAILED = '[members] FETCH_MEMBER_VEHICLES_REQUEST_FAILED';

export const CREATE_MEMBER_VEHICLE_REQUEST = '[members] CREATE_MEMBER_VEHICLE_REQUEST';
export const CREATE_MEMBER_VEHICLE_REQUEST_SENT = '[members] CREATE_MEMBER_VEHICLE_REQUEST_SENT';
export const CREATE_MEMBER_VEHICLE_REQUEST_SUCCEEDED = '[members] CREATE_MEMBER_VEHICLE_REQUEST_SUCCEEDED';
export const CREATE_MEMBER_VEHICLE_REQUEST_FAILED = '[members] CREATE_MEMBER_VEHICLE_REQUEST_FAILED';

export const UPDATE_MEMBER_VEHICLE_REQUEST = '[members] UPDATE_MEMBER_VEHICLE_REQUEST';
export const UPDATE_MEMBER_VEHICLE_REQUEST_SENT = '[members] UPDATE_MEMBER_VEHICLE_REQUEST_SENT';
export const UPDATE_MEMBER_VEHICLE_REQUEST_SUCCEEDED = '[members] UPDATE_MEMBER_VEHICLE_REQUEST_SUCCEEDED';
export const UPDATE_MEMBER_VEHICLE_REQUEST_FAILED = '[members] UPDATE_MEMBER_VEHICLE_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_MEMBER_SEARCH_RESULTS = '[members] STORE_MEMBER_SEARCH_RESULTS';
export const SET_ARE_SEARCH_RESULTS_VALID = '[members] SET_ARE_SEARCH_RESULTS_VALID';
export const STORE_MEMBER = '[members] STORE_MEMBER';
export const STORE_MEMBER_SERVICE_CASES = '[members] STORE_MEMBER_SERVICE_CASES';
export const STORE_MEMBER_SERVICE_CASES_COUNT = '[members] STORE_MEMBER_SERVICE_CASES_COUNT';
export const SET_ARE_MEMBER_BC_DETAILS_LOADING = '[members] SET_ARE_MEMBER_BC_DETAILS_LOADING';
export const SET_IS_MEMBER_BC_DETAILS_ERROR = '[members] SET_IS_MEMBER_BC_DETAILS_ERROR';
export const STORE_MEMBER_VEHICLES_SEARCH_RESULTS = '[members] STORE_MEMBER_VEHICLES_SEARCH_RESULTS';
export const STORE_MEMBER_VEHICLES = '[members] STORE_MEMBER_VEHICLES';
export const STORE_MEMBER_VEHICLE = '[members] STORE_MEMBER_VEHICLE';
