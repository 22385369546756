import {fork, put, select, take} from 'redux-saga/effects';
import moment from 'moment';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as memberActionTypes from '../../members/memberActionTypes';
import invoiceOverviewScreenTabs from '../invoiceOverviewScreenTabs';

const loadInvoiceOverviewDetails = function* loadInvoiceOverviewDetails({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    const {match, location} = payload;
    const {serviceCaseId} = match.params;
    const {serviceCases} = yield select(state => state.serviceCases);

    if ([invoiceOverviewScreenTabs.LOG, invoiceOverviewScreenTabs.TASKS].includes(location.query.activeTab)) return;

    yield fork(fetchRequest, memberActionTypes.FETCH_MEMBER_REQUEST, membersService.getMember, {
        membershipNo: serviceCases[serviceCaseId].member.membershipNo,
    });

    const responseAction = yield take([
        memberActionTypes.FETCH_MEMBER_REQUEST_SUCCEEDED,
        memberActionTypes.FETCH_MEMBER_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {memberDTO} = response;

        yield put({
            type: memberActionTypes.STORE_MEMBER,
            payload: {memberDTO},
        });

        const {membershipNo} = memberDTO;
        const searchQueryParams = new URLSearchParams(location.search);
        searchQueryParams.append('membershipNo', membershipNo);
        searchQueryParams.append('createdAtBefore', moment().format());
        searchQueryParams.append('createdAtAfter', moment(new Date().setFullYear(new Date().getFullYear() - 1)).format());

        yield fork(
            fetchRequest,
            memberActionTypes.FETCH_MEMBER_SERVICE_CASES_REQUEST,
            leaAssignmentFlowService.getServiceCases, {
                searchQueryParams,
            },
        );

        const responseMemberServiceCasesAction = yield take([
            memberActionTypes.FETCH_MEMBER_SERVICE_CASES_REQUEST_SUCCEEDED,
            memberActionTypes.FETCH_MEMBER_SERVICE_CASES_REQUEST_FAILED,
        ]);

        if (!responseMemberServiceCasesAction.error) {
            const {response: memberServiceCasesResponse} = responseMemberServiceCasesAction.payload;
            const {totalCount} = memberServiceCasesResponse;

            yield put({
                type: memberActionTypes.STORE_MEMBER_SERVICE_CASES_COUNT,
                payload: {membershipNo, serviceCasesCount: totalCount},
            });
        }
    }
};

export default loadInvoiceOverviewDetails;
