import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as communicationActionTypes from '../communicationActionTypes';
import leaAccessControl from '../../leaAccessControl';
import {leaFeatures, leaFeatureActions} from '../../application/leaFeatures';

const loadCommunicationSenderAccounts = function* loadCommunicationSenderAccounts() {
    const isMessagingAvailable = leaAccessControl.grantFeatureAccess(leaFeatures.MESSAGE, leaFeatureActions.CREATE);
    if (!isMessagingAvailable) return;

    const {senderAccounts} = yield select(state => state.communication);
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    if (senderAccounts.length > 0) return;

    yield fork(
        fetchRequest,
        communicationActionTypes.FETCH_COMMUNICATION_SENDER_ACCOUNTS_REQUEST,
        leaAssignmentFlowService.getSenderAccounts,
    );

    const communicationSenderAccountsResponseAction = yield take([
        communicationActionTypes.FETCH_COMMUNICATION_SENDER_ACCOUNTS_REQUEST_SUCCEEDED,
        communicationActionTypes.FETCH_COMMUNICATION_SENDER_ACCOUNTS_REQUEST_FAILED,
    ]);

    if (!communicationSenderAccountsResponseAction.error) {
        const {response} = communicationSenderAccountsResponseAction.payload;
        const {senderAccountDTOs} = response;

        yield put({
            type: communicationActionTypes.STORE_COMMUNICATION_SENDER_ACCOUNTS,
            payload: {senderAccountDTOs: senderAccountDTOs.data},
        });
    }
};

export default loadCommunicationSenderAccounts;
