import {take, put, select, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as communicationActionTypes from '../../communication/communicationActionTypes';
import config from '../../config';

const loadMemberMessages = function* loadMemberMessages({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    const {match} = payload;
    const {membershipNo} = match.params;

    yield put({
        type: communicationActionTypes.RESET_MESSAGE_SEARCH_RESULTS,
        payload: null,
    });

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('page', 0);
    searchQueryParams.append('size', config.DEFAULT_PAGE_SIZE);
    searchQueryParams.append('contactId', membershipNo);

    yield fork(
        fetchRequest,
        communicationActionTypes.SEARCH_MEMBER_MESSAGES_REQUEST,
        leaAssignmentFlowService.getMessages,
        {searchQueryParams},
    );

    const responseAction = yield take([
        communicationActionTypes.SEARCH_MEMBER_MESSAGES_REQUEST_SUCCEEDED,
        communicationActionTypes.SEARCH_MEMBER_MESSAGES_REQUEST_FAILED,
    ]);

    if (responseAction.error) {
        // todo handle error
    }

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {messageDTOs, total} = response;

        yield put({
            type: communicationActionTypes.STORE_MESSAGE_SEARCH_RESULTS,
            payload: {
                pageNumber: 0,
                messagesSearchTotalCount: total,
                messageDTOs,
            },
        });
    }
};

export default loadMemberMessages;
