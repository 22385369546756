/**
 * Command action types
 */
export const INITIATE_TASK_CREATION_FLOW = '[tasks] INITIATE_TASK_CREATION_FLOW';
export const INITIATE_TASK_EDIT_FLOW = '[tasks] INITIATE_TASK_EDIT_FLOW';
export const CONFIRM_TASK_CREATION = '[tasks] CONFIRM_TASK_CREATION';
export const DECLINE_TASK_CREATION = '[tasks] DECLINE_TASK_CREATION';
export const CONFIRM_EDIT_TASK = '[tasks] CONFIRM_EDIT_TASK';
export const DECLINE_EDIT_TASK = '[tasks] DECLINE_EDIT_TASK';
export const COMPLETE_TASK = '[tasks] COMPLETE_TASK';
export const SEARCH_TASKS = '[tasks] SEARCH_TASKS';
export const CREATE_TASK = '[tasks] CREATE_TASK';
export const RETRY_TASK_CREATION = '[tasks] RETRY_TASK_CREATION';
export const CANCEL_RETRY_TASK_CREATION = '[tasks] CANCEL_RETRY_TASK_CREATION';
export const RETRY_TASK_EDIT = '[tasks] RETRY_TASK_EDIT';
export const CANCEL_RETRY_TASK_EDIT = '[tasks] CANCEL_RETRY_TASK_EDIT';

/**
 * Event action types
 */
export const CREATE_TASK_REQUEST = '[tasks] CREATE_TASK_REQUEST';
export const CREATE_TASK_REQUEST_SENT = '[tasks] CREATE_TASK_REQUEST_SENT';
export const CREATE_TASK_REQUEST_SUCCEEDED = '[tasks] CREATE_TASK_REQUEST_SUCCEEDED';
export const CREATE_TASK_REQUEST_FAILED = '[tasks] CREATE_TASK_REQUEST_FAILED';

export const SEARCH_TASKS_REQUEST = '[tasks] SEARCH_TASKS_REQUEST';
export const SEARCH_TASKS_REQUEST_SENT = '[tasks] SEARCH_TASKS_REQUEST_SENT';
export const SEARCH_TASKS_REQUEST_SUCCEEDED = '[tasks] SEARCH_TASKS_REQUEST_SUCCEEDED';
export const SEARCH_TASKS_REQUEST_FAILED = '[tasks] SEARCH_TASKS_REQUEST_FAILED';

export const UPDATE_TASK_REQUEST = '[tasks] UPDATE_TASK_REQUEST';
export const UPDATE_TASK_REQUEST_SENT = '[tasks] UPDATE_TASK_REQUEST_SENT';
export const UPDATE_TASK_REQUEST_SUCCEEDED = '[tasks] UPDATE_TASK_REQUEST_SUCCEEDED';
export const UPDATE_TASK_REQUEST_FAILED = '[tasks] UPDATE_TASK_REQUEST_FAILED';

export const FETCH_SERVICE_CASE_TASKS_REQUEST = '[tasks] FETCH_SERVICE_CASE_TASKS_REQUEST';
export const FETCH_SERVICE_CASE_TASKS_REQUEST_SENT = '[tasks] FETCH_SERVICE_CASE_TASKS_REQUEST_SENT';
export const FETCH_SERVICE_CASE_TASKS_REQUEST_SUCCEEDED = '[tasks] FETCH_SERVICE_CASE_TASKS_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_CASE_TASKS_REQUEST_FAILED = '[tasks] FETCH_SERVICE_CASE_TASKS_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_TASK_SEARCH_RESULTS = '[tasks] STORE_TASK_SEARCH_RESULTS';
export const SET_TASK_COUNT_INDICATOR = '[tasks] SET_TASK_COUNT_INDICATOR';
export const RESET_TASK_COUNT_INDICATOR = '[tasks] RESET_TASK_COUNT_INDICATOR';
