import React, {useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {camelCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Modal} from '@ace-de/ui-components';
import {InteractiveIcon, closeIcon} from '@ace-de/ui-components/icons';
import {ArcGISMapWidget, useArcGISMap, createMarkerGraphic} from '@ace-de/eua-arcgis-map';
import {relevantLocationIcons, relevantLocationsHierarchy} from '../relevantLocationContstans';
import InvoiceRelevantLocationsSidePanel from '../ui-elements/InvoiceRelevantLocationsSidePanel';
import * as invoiceSelectors from '../invoiceSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as invoiceActionTypes from '../invoiceActionTypes';
import config from '../../config';
import styles from './ExpandedMapModal.module.scss';

const ExpandedMapModal = props => {
    const {cx} = useStyles(props, styles);
    const {hasBackdrop, invoice, serviceCase, closeModal, initiateDrawingRouteAndPolygons} = props;
    const {locations, relevantLocationMapExtent, mapCenter} = invoice;
    const {activeLocale, createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('extended_map_modal');
    const mapName = 'invoice-relevant-locations-map-expand';
    const arcGISMap = useArcGISMap(mapName);

    const initiateRoutesAndPolygons = useCallback(invoiceId => {
        initiateDrawingRouteAndPolygons({invoiceId});
    }, [initiateDrawingRouteAndPolygons]);

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape') closeModal();
    }, [closeModal]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    useEffect(() => {
        if (!arcGISMap || !locations || locations?.length === 0 || !relevantLocationMapExtent) return;
        const sortedLocations = [...locations].filter(location => {
            return location.id && location.type && location.coordinates;
        }).sort((locationA, locationB) => {
            const locationHierarchyA = relevantLocationsHierarchy[locationA.type];
            const locationHierarchyB = relevantLocationsHierarchy[locationB.type];

            return locationHierarchyA >= locationHierarchyB ? -1 : 1;
        });

        arcGISMap.setGraphics({
            graphics: [
                ...(sortedLocations
                    ? sortedLocations.map(location => {
                        return createMarkerGraphic({
                            id: camelCase(location.type),
                            longitude: location.coordinates?.longitude,
                            latitude: location.coordinates?.latitude,
                            icon: relevantLocationIcons[location.type],
                            isDraggable: false,
                        });
                    })
                    : []
                ),
            ],
        });
        // setGraphics method by default sets map's center to the coordinates of the last location
        // therefore we need to reset it by setting map extent after graphics are created
        if (relevantLocationMapExtent) {
            arcGISMap.setMapExtent(relevantLocationMapExtent);
        }
        initiateRoutesAndPolygons(invoice.id);
    }, [arcGISMap, locations, mapCenter, relevantLocationMapExtent, invoice.id, initiateRoutesAndPolygons]);

    if (!invoice || !serviceCase) return null;

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            className={cx([
                'global!ace-u-modal-content-size--xl',
                'global!ace-u-height--full',
                'global!ace-u-padding--top-0',
            ])}
            contentClassName={cx([
                'ace-c-modal__content--full-bleed',
                'global!ace-u-height--full',
                'global!ace-u-grid',
            ])}
        >
            <div className={cx('global!ace-u-grid-column--span-9', 'global!ace-u-position--relative')}>
                <ArcGISMapWidget
                    tempArcGISMapId={config.TEMP_ARCGIS_MAP_ID}
                    locale={activeLocale}
                    name={mapName}
                    className={cx([
                        'ace-c-arcgis-map-widget--is-visible',
                        'ace-c-locations-map-expanded-modal__map',
                    ])}
                />
            </div>
            <div
                className={cx([
                    'global!ace-u-grid-column--span-3',
                    'global!ace-u-padding--right-32',
                    'ace-c-locations-map-expanded-modal__locations-panel',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-space-between',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-full-width',
                        'global!ace-u-margin--top-32',
                        'global!ace-u-margin--bottom-32',
                    ])}
                >
                    <h2>{translateModal('side_panel.map')}</h2>
                    <InteractiveIcon icon={closeIcon} onClick={closeModal} />
                </div>
                <InvoiceRelevantLocationsSidePanel serviceCase={serviceCase} invoice={invoice} locations={locations} />
            </div>
        </Modal>
    );
};

ExpandedMapModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    invoice: PropTypes.object,
    serviceCase: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    initiateDrawingRouteAndPolygons: PropTypes.func.isRequired,
};

ExpandedMapModal.defaultProps = {
    hasBackdrop: true,
    invoice: null,
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const invoiceSelector = invoiceSelectors.createInvoicesSelector();
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    return {
        invoice: invoiceSelector(state, props),
        serviceCase: serviceCaseSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch({
        type: invoiceActionTypes.DECLINE_EXPAND_MAP_FLOW,
        payload: null,
    }),
    initiateDrawingRouteAndPolygons: payload => dispatch({
        type: invoiceActionTypes.DRAW_MAP_ROUTES_AND_POLYGONS,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpandedMapModal);
