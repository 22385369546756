import {produce} from 'immer';
import {LeistContact, Member, Vehicle} from '@ace-de/eua-entity-types';
import * as memberActionTypes from './memberActionTypes';

const initialState = {
    areSearchResultsValid: false,
    memberSearchResults: [],
    memberSearchResultsCount: 0,
    members: {},
    membersByMembershipNo: {},
    memberSearchResultsPrivate: [],
    memberSearchResultsCountPrivate: 0,
    memberSearchResultsCorporate: [],
    memberSearchResultsCountCorporate: 0,
    memberBCDetails: {},
    areMemberBCDetailsLoading: false,
    isMemberBCDetailsError: false,
    memberVehicleSearchResults: [],
    vehicles: {},
};

/**
 * Members reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const memberReducer = produce((draft, action) => {
    switch (action.type) {
        case memberActionTypes.SET_ARE_SEARCH_RESULTS_VALID: {
            const {areSearchResultsValid} = action.payload;
            draft.areSearchResultsValid = areSearchResultsValid;
            break;
        }

        case memberActionTypes.STORE_MEMBER_SEARCH_RESULTS: {
            const {memberSearchResults, memberSearchResultsCount} = action.payload;
            const {memberSearchResultsPrivate, memberSearchResultsCountPrivate} = action.payload;
            const {memberSearchResultsCorporate, memberSearchResultsCountCorporate} = action.payload;
            // all
            draft.memberSearchResults = memberSearchResults.map(memberDTO => {
                return new Member().fromDTO(memberDTO);
            });
            draft.memberSearchResultsCount = memberSearchResultsCount;
            // private
            draft.memberSearchResultsPrivate = memberSearchResultsPrivate.map(memberDTO => {
                return new Member().fromDTO(memberDTO);
            });
            draft.memberSearchResultsCountPrivate = memberSearchResultsCountPrivate;
            // corporate
            draft.memberSearchResultsCorporate = memberSearchResultsCorporate.map(memberDTO => {
                return new Member().fromDTO(memberDTO);
            });
            draft.memberSearchResultsCountCorporate = memberSearchResultsCountCorporate;
            break;
        }

        case memberActionTypes.STORE_MEMBER: {
            const {memberDTO} = action.payload;
            let member = draft.members[memberDTO.id];
            if (!member) {
                const newMember = new Member().fromDTO(memberDTO);
                draft.members[newMember.id] = newMember;
                draft.membersByMembershipNo[newMember.membershipNo] = newMember;
                break;
            }
            member = member.fromDTO(memberDTO);
            draft.members[member.id] = member;
            draft.membersByMembershipNo[member.membershipNo] = member;
            break;
        }

        case memberActionTypes.STORE_MEMBER_SERVICE_CASES: {
            const {membershipNo, serviceCaseIds} = action.payload;
            const member = draft.membersByMembershipNo[membershipNo];
            if (member) {
                draft.members[member.id].serviceCaseIds = serviceCaseIds;
                draft.membersByMembershipNo[membershipNo].serviceCaseIds = serviceCaseIds;
            }
            break;
        }

        case memberActionTypes.STORE_MEMBER_SERVICE_CASES_COUNT: {
            const {membershipNo, serviceCasesCount} = action.payload;
            const member = draft.membersByMembershipNo[membershipNo];
            if (member) {
                draft.members[member.id].serviceCasesCount = serviceCasesCount;
                draft.membersByMembershipNo[membershipNo].serviceCasesCount = serviceCasesCount;
            }
            break;
        }

        case memberActionTypes.STORE_MEMBER_BC_DETAILS: {
            const {memberBCDetails} = action.payload;
            draft.memberBCDetails = new LeistContact(memberBCDetails);
            break;
        }

        case memberActionTypes.SET_ARE_MEMBER_BC_DETAILS_LOADING: {
            const {areMemberBCDetailsLoading} = action.payload;
            draft.areMemberBCDetailsLoading = areMemberBCDetailsLoading;
            break;
        }

        case memberActionTypes.SET_IS_MEMBER_BC_DETAILS_ERROR: {
            const {isMemberBCDetailsError} = action.payload;
            draft.isMemberBCDetailsError = isMemberBCDetailsError;
            break;
        }

        case memberActionTypes.STORE_MEMBER_VEHICLES_SEARCH_RESULTS: {
            const {memberVehicleSearchResults} = action.payload;
            draft.memberVehicleSearchResults = memberVehicleSearchResults.map(vehicleDTO => {
                return new Vehicle().fromDTO(vehicleDTO);
            });
            break;
        }

        case memberActionTypes.STORE_MEMBER_VEHICLES: {
            const {membershipNo, memberVehicleDTOs} = action.payload;
            const member = draft.membersByMembershipNo[membershipNo];

            memberVehicleDTOs.forEach(vehicleDTO => {
                const vehicle = new Vehicle().fromDTO(vehicleDTO);
                draft.vehicles[vehicle.id] = vehicle;
                if (!draft.members[member.id].vehicleIds.includes(vehicle.id)) {
                    draft.members[member.id].vehicleIds.push(vehicle.id);
                }
                if (!draft.membersByMembershipNo[member.membershipNo].vehicleIds.includes(vehicle.id)) {
                    draft.membersByMembershipNo[member.membershipNo].vehicleIds.push(vehicle.id);
                }
            });
            break;
        }

        case memberActionTypes.STORE_MEMBER_VEHICLE: {
            const {membershipNo, memberVehicleDTO} = action.payload;
            const member = draft.membersByMembershipNo[membershipNo];
            const vehicle = new Vehicle().fromDTO(memberVehicleDTO);

            draft.vehicles[vehicle.id] = vehicle;
            if (!draft.members[member.id].vehicleIds.includes(vehicle.id)) {
                draft.members[member.id].vehicleIds.push(vehicle.id);
            }
            if (!draft.membersByMembershipNo[member.membershipNo].vehicleIds.includes(vehicle.id)) {
                draft.membersByMembershipNo[member.membershipNo].vehicleIds.push(vehicle.id);
            }
            break;
        }

        default:
        //  no-op
    }
    return draft;
}, initialState);

export default memberReducer;
