import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {Modal, useStyles, HighlightCircle} from '@ace-de/ui-components';
import {ButtonPrimary, Button} from '@ace-de/ui-components/buttons';
import {Icon, checkmarkIcon, closeIcon} from '@ace-de/ui-components/icons';
import * as invoiceActionTypes from '../invoiceActionTypes';
import invoiceSuccessModalActionTypes from './invoiceSuccessModalActionTypes';

const actionIconsMapper = {
    [invoiceSuccessModalActionTypes.INVOICE_APPROVED]: checkmarkIcon,
    [invoiceSuccessModalActionTypes.SALES_INVOICE_CREATED]: checkmarkIcon,
    [invoiceSuccessModalActionTypes.INVOICE_DECLINED]: closeIcon,
};

const InvoiceSuccessfulActionModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('invoice_successful_action_modal');
    const {hasBackdrop, redirectToDashboard, redirectToCaseOverview, location} = props;
    const actionType = location?.query?.action;

    return (
        <Modal hasBackdrop={hasBackdrop} contentClassName={cx('global!ace-u-modal-content-size--s')}>
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-full-width',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-content-center',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'ace-c-highlight-circle--primary-highlight',
                    ])}
                >
                    <Icon
                        icon={actionIconsMapper[actionType]}
                        className={cx('ace-c-icon--xl', {
                            'ace-c-icon--color-success': [
                                invoiceSuccessModalActionTypes.INVOICE_APPROVED,
                                invoiceSuccessModalActionTypes.SALES_INVOICE_CREATED,
                            ].includes(actionType),
                            'ace-c-icon--color-warning': actionType === invoiceSuccessModalActionTypes.INVOICE_DECLINED,
                        })}
                    />
                </HighlightCircle>
                <h1 className={cx('global!ace-u-margin--24-0')}>
                    {translateModal(`heading.${actionType.toLowerCase()}`)}
                </h1>
                <div>
                    <Button onClick={redirectToDashboard} className={cx('global!ace-u-margin--right-16')}>
                        {translateModal('button_label.dashboard')}
                    </Button>
                    <ButtonPrimary onClick={redirectToCaseOverview}>
                        {translateModal('button_label.case_overview')}
                    </ButtonPrimary>
                </div>
            </div>
        </Modal>
    );
};

InvoiceSuccessfulActionModal.propTypes = {
    redirectToDashboard: PropTypes.func.isRequired,
    redirectToCaseOverview: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
    location: PropTypes.object.isRequired,
};

InvoiceSuccessfulActionModal.defaultProps = {
    hasBackdrop: false,
};

const mapDispatchToProps = dispatch => ({
    redirectToDashboard: payload => dispatch({
        type: invoiceActionTypes.REDIRECT_TO_DASHBOARD,
        payload,
    }),
    redirectToCaseOverview: payload => dispatch({
        type: invoiceActionTypes.REDIRECT_TO_CASE_OVERVIEW,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(InvoiceSuccessfulActionModal);
