import {put} from 'redux-saga/effects';
import invoiceOverviewScreenTabs from '../../invoices/invoiceOverviewScreenTabs';
import * as caseLogActionTypes from '../caseLogActionTypes';
import config from '../../config';

const loadCaseLogs = function* loadCaseLogs({payload}) {
    const {location, match} = payload;
    const {serviceCaseId} = match.params;
    const searchQueryParams = new URLSearchParams(location.search);

    if (location.query.activeTab !== invoiceOverviewScreenTabs.LOG) return;

    searchQueryParams.append('size', `${config.PROTOCOL_DEFAULT_PAGE_SIZE}`);
    if (!searchQueryParams.get('page')) {
        searchQueryParams.append('page', 0);
    }

    yield put({
        type: caseLogActionTypes.FILTER_CASE_LOGS,
        payload: {searchQueryParams, serviceCaseId},
    });
};

export default loadCaseLogs;
