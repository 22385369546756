import React from 'react';
import PropTypes from 'prop-types';
import {HighlightCircle, Modal, LoaderSpinner, useStyles} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';

const Loader = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('loader_modal');
    const {hasBackdrop, location} = props;
    const loaderType = location?.query?.type;

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            contentClassName={cx([
                'global!ace-u-modal-content-size--s',
                'global!ace-u-padding--128-0',
                'global!ace-u-flex',
                'global!ace-u-flex--direction-column',
                'global!ace-u-flex--align-center',
                'global!ace-u-flex--justify-center',
            ])}
        >
            <HighlightCircle
                className={cx([
                    'ace-c-highlight-circle--medium',
                    'ace-c-highlight-circle--primary-highlight',
                ])}
            >
                <LoaderSpinner />
            </HighlightCircle>
            {loaderType && (
                <p className={cx(['global!ace-u-typography--variant-h2', 'global!ace-u-margin--top-32'])}>
                    {translateModal(`modal_title.${loaderType.toLowerCase()}`)}
                </p>
            )}
        </Modal>
    );
};

Loader.propTypes = {
    location: PropTypes.object,
    hasBackdrop: PropTypes.bool,
};

Loader.defaultProps = {
    location: null,
    hasBackdrop: false,
};

export default Loader;
