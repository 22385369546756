import React, {Fragment, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {camelCase, snakeCase} from 'change-case';
import {useRouteUnmountEffect, withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampVehicleLicensePlateCountryTypes, efVehicleTrailerTypes} from '@ace-de/eua-entity-types';
import {Panel, useStyles, Option, Input, ToggleSwitch} from '@ace-de/ui-components';
import {Form, SelectField, InputField, NumberInputField} from '@ace-de/ui-components/form';
import {germanyFlagImage, otherFlagImage} from '@ace-de/ui-components/assets/flags';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as serviceCaseSelectors from '../serviceCaseSelectors';

// Note: These are hard-coded values per requirements
const manufacturerTypes = [
    'Bürstner',
    'Cabby',
    'Dethleffs',
    'Frendt',
    'Hobby',
    'Hymner',
    'Knaus',
    'LMC',
    'Sonstiges',
    'Tabbert',
    'T. E. C',
    'Weinsberg',
    'Wilk',
];

const CreateTrailerPanel = ({serviceCase, submitServiceCaseTrailerDataForm}) => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('create_trailer_panel');
    const {trailer} = serviceCase;

    const [hasTrailer, setHasTrailer] = useState(!!serviceCase?.trailer);
    const [formData, setFormData] = useState(
        trailer ? {
            ...trailer,
            trailerLicensePlateNumber1: trailer?.licensePlateCountry === ampVehicleLicensePlateCountryTypes.GERMANY
                ? trailer?.licensePlateNumber?.split(' ')?.[0].split('-')[0] : '',
            trailerLicensePlateNumber2: trailer?.licensePlateCountry === ampVehicleLicensePlateCountryTypes.GERMANY
                ? trailer?.licensePlateNumber?.split(' ')?.[0].split('-')[1] : '',
            trailerLicensePlateNumber3: trailer?.licensePlateCountry === ampVehicleLicensePlateCountryTypes.GERMANY
                ? trailer?.licensePlateNumber?.split(' ')?.[1] : '',
        } : null,
    );

    const handleOnTabChange = useCallback(formValues => {
        const serviceCaseData = {
            hasTrailer: hasTrailer,
            trailer: hasTrailer ? {
                ...formValues,
                licensePlateNumber: formValues?.licensePlateCountry === ampVehicleLicensePlateCountryTypes.GERMANY
                    ? (formValues?.trailerLicensePlateNumber1
                        && formValues?.trailerLicensePlateNumber2
                        && formValues?.trailerLicensePlateNumber3
                        ? `${formValues?.trailerLicensePlateNumber1}-${formValues?.trailerLicensePlateNumber2} ${formValues?.trailerLicensePlateNumber3}`
                        : ''
                    ) : formValues?.licensePlateNumber,
            } : null,
        };

        submitServiceCaseTrailerDataForm({
            serviceCaseId: serviceCase?.id,
            serviceCaseData,
        });
    }, [
        submitServiceCaseTrailerDataForm,
        serviceCase?.id,
        hasTrailer,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!formData) {
            completeRouteUnmountSideEffect({
                caller: serviceCaseActionTypes.SUBMIT_SERVICE_CASE_TRAILER_DATA_FORM,
            });
            return;
        }

        handleOnTabChange(formData);
    }, [formData, handleOnTabChange]);

    const toggleEditMode = () => {
        if (hasTrailer) setFormData(null);
        setHasTrailer(prevState => !prevState);
    };

    const handleOnChange = formValues => {
        if (!formValues) return;
        setFormData(formValues);
    };

    if (!serviceCase) return null;

    return (
        <Form name="trailerDetailsForm" onChange={handleOnChange}>
            {formValues => {
                return (
                    <Fragment>
                        <Panel
                            title={translatePanel('title.trailer')}
                            actions={(
                                <ToggleSwitch
                                    name="isAddingTrailerActive"
                                    value={true}
                                    isSelected={hasTrailer}
                                    onChange={toggleEditMode}
                                />
                            )}
                        >
                            {hasTrailer && (
                                <Fragment>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-flex-end',
                                            'global!ace-u-flex--column-gap-24',
                                            'global!ace-u-margin--bottom-48',
                                        ])}
                                    >
                                        <SelectField
                                            name="type"
                                            value={formData?.type || ''}
                                            label={translatePanel('input_label.kind')}
                                            placeholder={translate('global.select.placeholder')}
                                            className={cx('global!ace-u-flex--basis-25')}
                                        >
                                            {Object.keys(efVehicleTrailerTypes).map(key => (
                                                <Option
                                                    key={key}
                                                    name={`trailerType${camelCase(key)}`}
                                                    value={key}
                                                >
                                                    {translate(`global.trailer_type.${snakeCase(efVehicleTrailerTypes[key])}`)}
                                                </Option>
                                            ))}
                                        </SelectField>
                                        <SelectField
                                            name="manufacturer"
                                            value={formData?.manufacturer || ''}
                                            label={translatePanel('input_label.manufacturer')}
                                            placeholder={translate('global.select.placeholder')}
                                            className={cx('global!ace-u-flex--basis-25')}
                                        >
                                            {manufacturerTypes.map(manufacturerType => (
                                                <Option
                                                    key={manufacturerType}
                                                    name={`manufacturerType${manufacturerType}`}
                                                    value={manufacturerType}
                                                >
                                                    {manufacturerType}
                                                </Option>
                                            ))}
                                        </SelectField>
                                        <InputField
                                            name="model"
                                            value={formData?.model || ''}
                                            label={translatePanel('input_label.type_model')}
                                            placeholder={translate('global.select.placeholder')}
                                            className={cx('global!ace-u-flex--basis-30')}
                                        />
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-flex-end',
                                            'global!ace-u-flex--column-gap-24',
                                            'global!ace-u-margin--bottom-48',
                                        ])}
                                    >
                                        <SelectField
                                            name="licensePlateCountry"
                                            value={formData?.licensePlateCountry
                                                ? (formData?.licensePlateCountry !== ampVehicleLicensePlateCountryTypes.GERMANY // eslint-disable-line max-len
                                                    ? ampVehicleLicensePlateCountryTypes.OTHER
                                                    : ampVehicleLicensePlateCountryTypes.GERMANY
                                                ) : ampVehicleLicensePlateCountryTypes.GERMANY}
                                            label={translatePanel('input_label.license_plate_no')}
                                            placeholder={translate('global.select.placeholder')}
                                            className={cx('global!ace-u-flex--basis-10')}
                                        >
                                            <Option
                                                key="germanyFlagImage"
                                                name="germanyFlagImage"
                                                value={ampVehicleLicensePlateCountryTypes.GERMANY}
                                                selectedLabel={(<img src={germanyFlagImage} alt="germanyFlagImage" />)}
                                            >
                                                <img src={germanyFlagImage} alt="germanyFlagImage" />
                                                <span className={cx('global!ace-u-margin--left-8')}>
                                                    {translate(`global.country.germany`)}
                                                </span>
                                            </Option>
                                            <Option
                                                key="otherFlagImage"
                                                name="otherFlagImage"
                                                value={ampVehicleLicensePlateCountryTypes.OTHER}
                                                selectedLabel={(<img src={otherFlagImage} alt="otherFlagImage" />)}
                                            >
                                                <img src={otherFlagImage} alt="otherFlagImage" />
                                                <span className={cx('global!ace-u-margin--left-8')}>
                                                    {translate(`global.country.other`)}
                                                </span>
                                            </Option>
                                        </SelectField>
                                        {formValues.licensePlateCountry === ampVehicleLicensePlateCountryTypes.GERMANY // eslint-disable-line max-len
                                            ? (
                                                <Fragment>
                                                    <Input
                                                        name="trailerLicensePlateNumber1"
                                                        value={formData?.trailerLicensePlateNumber1 || ''}
                                                        className={cx('global!ace-u-flex--basis-10')}
                                                    />
                                                    <Input
                                                        name="trailerLicensePlateNumber2"
                                                        value={formData?.trailerLicensePlateNumber2 || ''}
                                                        className={cx('global!ace-u-flex--basis-10')}
                                                    />
                                                    <Input
                                                        name="trailerLicensePlateNumber3"
                                                        value={formData?.trailerLicensePlateNumber3 || ''}
                                                        className={cx('global!ace-u-flex--basis-10')}
                                                    />
                                                </Fragment>
                                            ) : (
                                                <Input
                                                    name="licensePlateNumber"
                                                    value={formData?.licensePlateNumber || ''}
                                                    className={cx('global!ace-u-flex--basis-30')}
                                                />
                                            )}
                                        <InputField
                                            name="notes"
                                            value={formData?.notes || ''}
                                            label={translatePanel('input_label.additional_comments')}
                                            placeholder={translate('global.select.placeholder')}
                                            className={cx('global!ace-u-flex--basis-60')}
                                        />
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-flex-end',
                                            'global!ace-u-flex--column-gap-24',
                                            'global!ace-u-margin--bottom-48',
                                        ])}
                                    >
                                        <NumberInputField
                                            name="weight"
                                            value={formData?.weight || ''}
                                            label={translatePanel('input_label.weight')}
                                            min={0}
                                        />
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-flex-end',
                                            'global!ace-u-flex--column-gap-24',
                                        ])}
                                    >
                                        <NumberInputField
                                            name="length"
                                            value={formData?.length || ''}
                                            label={translatePanel('input_label.length')}
                                            min={0}
                                        />
                                        <NumberInputField
                                            name="width"
                                            value={formData?.width || ''}
                                            label={translatePanel('input_label.width')}
                                            min={0}
                                        />
                                        <NumberInputField
                                            name="height"
                                            value={formData?.height || ''}
                                            label={translatePanel('input_label.height')}
                                            min={0}
                                        />
                                    </div>
                                </Fragment>
                            )}
                        </Panel>
                    </Fragment>
                );
            }}
        </Form>
    );
};

CreateTrailerPanel.propTypes = {
    serviceCase: PropTypes.object,
    submitServiceCaseTrailerDataForm: PropTypes.func.isRequired,
};

CreateTrailerPanel.defaultProps = {
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitServiceCaseTrailerDataForm: payload => dispatch({
        type: serviceCaseActionTypes.SUBMIT_SERVICE_CASE_TRAILER_DATA_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateTrailerPanel));
