import {take, select, fork, delay} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {etmTaskStatusTypes, Task} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as taskActionTypes from '../taskActionTypes';
import taskDataModalTypes from '../modals/taskDataModalTypes';
import errorTypes from '../../application/errorTypes';

const editTaskFlow = function* editTaskFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');
    let shouldWaitForAction = true;
    let shouldRetryTaskEdit = false;
    let retryTaskEditData = null;
    let payloadData = null;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(taskActionTypes.INITIATE_TASK_EDIT_FLOW);
            payloadData = payload;
        }

        const {taskId} = payloadData;
        let chosenModalOption;

        if (!shouldRetryTaskEdit && taskId) {
            yield* openModal(modalIds.TASK_DATA_MODAL, {type: taskDataModalTypes.EDIT_TASK, taskId: taskId.toString()});

            chosenModalOption = yield take([
                taskActionTypes.CONFIRM_EDIT_TASK,
                taskActionTypes.DECLINE_EDIT_TASK,
                taskActionTypes.COMPLETE_TASK,
            ]);
        }

        if (shouldRetryTaskEdit || chosenModalOption?.type === taskActionTypes.CONFIRM_EDIT_TASK) {
            const taskData = !shouldRetryTaskEdit && chosenModalOption?.payload?.taskData
                ? chosenModalOption?.payload?.taskData : retryTaskEditData;

            yield* openModal(modalIds.LOADER, {type: taskDataModalTypes.EDIT_TASK});


            yield fork(
                fetchRequest,
                taskActionTypes.UPDATE_TASK_REQUEST,
                ecsTaskManagementService.updateTask,
                {
                    taskId,
                    taskPatchDTO: Task.objectToPatchDTO({...taskData}),
                },
            );

            const responseAction = yield take([
                taskActionTypes.UPDATE_TASK_REQUEST_SUCCEEDED,
                taskActionTypes.UPDATE_TASK_REQUEST_FAILED,
            ]);

            yield delay(1300);
            yield* closeModal(modalIds.LOADER, {type: taskDataModalTypes.EDIT_TASK});
            yield delay(100);

            if (responseAction.type === taskActionTypes.UPDATE_TASK_REQUEST_SUCCEEDED) {
                shouldWaitForAction = true;
                shouldRetryTaskEdit = false;
                yield* closeModal(
                    modalIds.TASK_DATA_MODAL,
                    {type: taskDataModalTypes.EDIT_TASK, taskId: taskId.toString()},
                );
                continue;
            }

            if (responseAction.error) {
                yield* closeModal(
                    modalIds.TASK_DATA_MODAL,
                    {type: taskDataModalTypes.EDIT_TASK, taskId: taskId.toString()},
                );
                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.TASK_EDIT_FAILED});

                const nextAction = yield take([
                    taskActionTypes.RETRY_TASK_EDIT,
                    taskActionTypes.CANCEL_RETRY_TASK_EDIT,
                ]);

                if (nextAction.type === taskActionTypes.RETRY_TASK_EDIT) {
                    shouldRetryTaskEdit = true;
                    shouldWaitForAction = false;
                    retryTaskEditData = {...taskData};
                }

                if (nextAction.type === taskActionTypes.CANCEL_RETRY_TASK_EDIT) {
                    shouldWaitForAction = true;
                    shouldRetryTaskEdit = false;
                }

                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                continue;
            }
        }

        if (chosenModalOption && chosenModalOption.type === taskActionTypes.COMPLETE_TASK) {
            yield fork(
                fetchRequest,
                taskActionTypes.UPDATE_TASK_REQUEST,
                ecsTaskManagementService.updateTask,
                {
                    taskId,
                    taskPatchDTO: Task.objectToPatchDTO({status: etmTaskStatusTypes.DONE}),
                },
            );
        }

        yield* closeModal(modalIds.TASK_DATA_MODAL, {type: taskDataModalTypes.EDIT_TASK, taskId: taskId.toString()});
    }
};

export default editTaskFlow;
