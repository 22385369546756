import React from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Modal} from '@ace-de/ui-components';
import styles from './INACheckLoaderModal.module.scss';
import {inaCheckLoaderMP4, inaCheckLoaderWEBM} from '../../application/assets/videos';

const INACheckLoaderModal = props => {
    const {hasBackdrop} = props;
    const {cx} = useStyles({}, styles);
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('ina_check_loader_modal');

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            contentClassName={cx(
                [
                    'global!ace-u-modal-content-size--s',
                    'global!ace-u-padding--128-0',
                ],
            )}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-full-width',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <video autoPlay={true} loop className={cx('ace-c-loader-video-frame')}>
                    <source src={inaCheckLoaderMP4} type="video/mp4" />
                    <source src={inaCheckLoaderWEBM} type="video/webm" />
                </video>
                <p className={cx('global!ace-u-typography--variant-h3')}>
                    {translateModal('modal_text.in_progress')}
                </p>
            </div>
        </Modal>
    );
};

INACheckLoaderModal.propTypes = {
    hasBackdrop: PropTypes.bool,
};

INACheckLoaderModal.defaultProps = {
    hasBackdrop: true,
};

export default INACheckLoaderModal;
