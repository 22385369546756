import {take, put, fork, select} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as communicationActionTypes from '../communicationActionTypes';
import scOverviewScreenTabs from '../../service-cases/scOverviewScreenTabs';

const loadMessages = function* loadMessages({payload}) {
    const {match} = payload;
    const {serviceCaseId} = match.params;
    const {serviceManager} = yield select(state => state.application);
    const {location} = yield select(state => state.router);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    if (!serviceCaseId || location.query.activeTab !== scOverviewScreenTabs.COMMUNICATION) return;

    yield put({
        type: communicationActionTypes.STORE_MESSAGE_SEARCH_RESULTS,
        payload: {messageDTOs: [], messagesSearchTotalCount: 0, pageNumber: 0},
    });

    const searchQueryParams = new URLSearchParams(location.search);

    yield fork(fetchRequest,
        communicationActionTypes.FETCH_MESSAGES_REQUEST,
        leaAssignmentFlowService.getMessages,
        {searchQueryParams});

    const fetchCaseMessagesAction = yield take([
        communicationActionTypes.FETCH_MESSAGES_REQUEST_SUCCEEDED,
        communicationActionTypes.FETCH_MESSAGES_REQUEST_FAILED,
    ]);

    if (!fetchCaseMessagesAction.error) {
        const {response} = fetchCaseMessagesAction.payload;
        const {messageDTOs, total} = response;

        yield put({
            type: communicationActionTypes.STORE_MESSAGE_SEARCH_RESULTS,
            payload: {messageDTOs, messagesSearchTotalCount: total, pageNumber: 0},
        });
    }
};

export default loadMessages;
