import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {Button, ButtonPrimary, HighlightCircle, Icon, infoIcon, Modal, useStyles} from '@ace-de/ui-components';
import * as invoiceActionTypes from '../../invoices/invoiceActionTypes';
import deleteModalLocationTypes from './deleteModalLocationTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';

const DeleteModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('delete_modal');
    const {hasBackdrop, declineDelete, location} = props;
    const {fileAsset, confirmDocumentDelete} = props;
    const {serviceCase, confirmServiceCaseDelete} = props;
    const {confirmInvoiceLineDelete, confirmInvoiceDeletion} = props;
    const deleteType = location?.query?.type;

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape') declineDelete();
    }, [declineDelete]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    if ((deleteType === deleteModalLocationTypes.DOCUMENT && !fileAsset)
         || (deleteType === deleteModalLocationTypes.CASE && !serviceCase)) return;

    const handleOnDelete = () => {
        switch (deleteType) {
            case deleteModalLocationTypes.DOCUMENT: {
                confirmDocumentDelete({fileAsset});
                break;
            }
            case deleteModalLocationTypes.CASE: {
                confirmServiceCaseDelete({serviceCase});
                break;
            }
            case deleteModalLocationTypes.INVOICE_LINE: {
                confirmInvoiceLineDelete();
                break;
            }
            case deleteModalLocationTypes.INVOICE: {
                confirmInvoiceDeletion();
                break;
            }
            default:
                break;
        }
    };

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            contentClassName={cx([
                'global!ace-u-padding--32',
                'global!ace-u-width--750',
                'global!ace-u-flex',
                'global!ace-u-flex--direction-column',
                'global!ace-u-flex--align-center',
            ])}
        >
            <HighlightCircle
                className={cx([
                    'ace-c-highlight-circle--medium',
                    'ace-c-highlight-circle--primary-highlight',
                    'global!ace-u-margin--bottom-24',
                ])}
            >
                <Icon
                    className={cx(['ace-c-icon--xl', 'global!ace-c-icon--color-highlight'])}
                    icon={infoIcon}
                />
            </HighlightCircle>
            <p className={cx(['global!ace-u-typography--variant-h2', 'global!ace-u-margin--bottom-16'])}>
                {translateModal(`modal_title.delete_${deleteType.toLowerCase()}`)}
            </p>
            <p className={cx(['global!ace-u-typography--variant-body', 'global!ace-u-margin--bottom-16'])}>
                {translateModal(`modal_message.delete_${deleteType.toLowerCase()}`)}
            </p>
            <div className={cx('global!ace-u-grid')}>
                <ButtonPrimary
                    className={cx('global!ace-u-grid-column--span-6')}
                    onClick={handleOnDelete}
                >
                    {translateModal(`button_label.${deleteType === deleteModalLocationTypes.INVOICE
                        ? 'discard'
                        : 'yes'
                    }`)}
                </ButtonPrimary>
                <Button
                    className={cx('global!ace-u-grid-column--span-6')}
                    onClick={declineDelete}
                >
                    {translateModal('button_label.decline')}
                </Button>
            </div>
        </Modal>
    );
};

DeleteModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    location: PropTypes.object,
    fileAsset: PropTypes.object,
    serviceCase: PropTypes.object,
    declineDelete: PropTypes.func.isRequired,
    confirmDocumentDelete: PropTypes.func.isRequired,
    confirmServiceCaseDelete: PropTypes.func.isRequired,
    confirmInvoiceLineDelete: PropTypes.func.isRequired,
    confirmInvoiceDeletion: PropTypes.func.isRequired,
};

DeleteModal.defaultProps = {
    hasBackdrop: false,
    location: {},
    fileAsset: null,
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: getServiceCase(state, props),
        fileAsset: state.invoices.selectedInvoiceUploadedDocument,
    };
};

const mapDispatchToProps = dispatch => ({
    declineDelete: () => dispatch({
        type: invoiceActionTypes.DECLINE_DELETE,
    }),
    confirmDocumentDelete: payload => dispatch({
        type: invoiceActionTypes.CONFIRM_INVOICE_DOCUMENT_DELETE,
        payload,
    }),
    confirmServiceCaseDelete: payload => dispatch({
        type: serviceCaseActionTypes.CONFIRM_SERVICE_CASE_DELETE,
        payload,
    }),
    confirmInvoiceLineDelete: () => dispatch({
        type: invoiceActionTypes.CONFIRM_INVOICE_LINE_DELETE,
    }),
    confirmInvoiceDeletion: payload => dispatch({
        type: invoiceActionTypes.CONFIRM_INVOICE_DELETE,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
