import {take, put} from 'redux-saga/effects';
import * as invoiceActionTypes from '../invoiceActionTypes';
import {leaFeatureActions, leaFeatures} from '../../application/leaFeatures';
import leaAccessControl from '../../leaAccessControl';

/**
 * Update Invoice
 */
const updateInvoice = function* updateInvoice(params) {
    const {caller, invoiceId, invoiceData, triggerINACheck} = params;

    const isInvoiceUpdateAvailable = leaAccessControl.grantFeatureAccess(
        leaFeatures.INVOICE,
        leaFeatureActions.UPDATE,
    );

    if (!invoiceId || !isInvoiceUpdateAvailable) return;

    yield put({
        type: invoiceActionTypes.BATCH_UPDATE_INVOICE,
        payload: {invoiceId, invoiceData, triggerINACheck},
        meta: {caller},
    });

    let batchUpdateInvoiceResponse;
    while (true) {
        const batchUpdateInvoiceAction = yield take(
            invoiceActionTypes.BATCH_UPDATE_INVOICE_COMPLETED,
        );
        const {meta = {}} = batchUpdateInvoiceAction;

        if (typeof meta.caller !== 'undefined' && meta.caller !== caller) continue;
        batchUpdateInvoiceResponse = batchUpdateInvoiceAction;
        break;
    }

    return batchUpdateInvoiceResponse;
};

export default updateInvoice;
