import {select, take, fork, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import vehicleTypesMapping from '../vehicleTypesMapping';
import {getManufacturerIdByName, getModelIdByName} from '../useVehicleInfo';

const loadServiceCaseVehicleDetails = function* loadServiceCaseVehicleDetails({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const silverDATService = serviceManager.loadService('silverDATService');
    const {vehicleInfos, serviceCases} = yield select(state => state.serviceCases);

    const {match} = payload;
    const {serviceCaseId} = match.params;
    const serviceCase = serviceCases[serviceCaseId];

    if (!serviceCase) return;

    // CAR is default vehicle type in create vehicle form
    const vehicleType = serviceCase.vehicle?.type
        ? vehicleTypesMapping[serviceCase.vehicle.type]
        : vehicleTypesMapping.CAR;

    // if manufacturers already exist, do not load them again
    if (vehicleInfos[vehicleType]) return;

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.FETCH_VEHICLE_MANUFACTURERS_REQUEST,
        silverDATService.getVehicleManufacturers, {
            vehicleType,
        },
    );

    const responseAction = yield take([
        serviceCaseActionTypes.FETCH_VEHICLE_MANUFACTURERS_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.FETCH_VEHICLE_MANUFACTURERS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {vehicleManufacturers} = response;

        yield put({
            type: serviceCaseActionTypes.STORE_VEHICLE_MANUFACTURERS,
            payload: {vehicleManufacturers, vehicleType},
        });

        // get models if manufacturer exists
        if (serviceCase.vehicle?.type && serviceCase.vehicle?.manufacturer) {
            const manufacturerId = getManufacturerIdByName(
                {
                    [vehicleType]: vehicleManufacturers,
                },
                {type: serviceCase.vehicle.type, manufacturer: serviceCase.vehicle.manufacturer},
            );

            if (manufacturerId) {
                yield fork(
                    fetchRequest,
                    serviceCaseActionTypes.FETCH_VEHICLE_MODELS_REQUEST,
                    silverDATService.getVehicleModels, {
                        vehicleType,
                        manufacturer: manufacturerId,
                    },
                );

                const responseAction = yield take([
                    serviceCaseActionTypes.FETCH_VEHICLE_MODELS_REQUEST_SUCCEEDED,
                    serviceCaseActionTypes.FETCH_VEHICLE_MODELS_REQUEST_FAILED,
                ]);

                if (!responseAction.error) {
                    const {response} = responseAction.payload;
                    const {vehicleModels} = response;

                    yield put({
                        type: serviceCaseActionTypes.STORE_VEHICLE_MODELS,
                        payload: {vehicleModels, vehicleType, manufacturer: manufacturerId},
                    });

                    // get variants if model exists
                    if (serviceCase.vehicle?.model) {
                        const modelId = getModelIdByName(
                            {
                                [vehicleType]: {
                                    [manufacturerId]: {
                                        name: serviceCase.vehicle.manufacturer,
                                        models: vehicleModels,
                                    },
                                },
                            },
                            {
                                type: serviceCase.vehicle.type,
                                manufacturer: serviceCase.vehicle.manufacturer,
                                model: serviceCase.vehicle.model,
                            },
                        );
                        if (modelId) {
                            yield fork(
                                fetchRequest,
                                serviceCaseActionTypes.FETCH_VEHICLE_VARIANTS_REQUEST,
                                silverDATService.getVehicleVariants, {
                                    vehicleType,
                                    manufacturer: manufacturerId,
                                    baseModel: modelId,
                                },
                            );

                            const responseAction = yield take([
                                serviceCaseActionTypes.FETCH_VEHICLE_VARIANTS_REQUEST_SUCCEEDED,
                                serviceCaseActionTypes.FETCH_VEHICLE_VARIANTS_REQUEST_FAILED,
                            ]);

                            if (!responseAction.error) {
                                const {response} = responseAction.payload;
                                const {vehicleVariants} = response;

                                yield put({
                                    type: serviceCaseActionTypes.STORE_VEHICLE_VARIANTS,
                                    payload: {
                                        vehicleVariants,
                                        vehicleType,
                                        manufacturer: manufacturerId,
                                        baseModel: modelId,
                                    },
                                });
                            }
                        }
                    }
                }
            }
        }
    }
};

export default loadServiceCaseVehicleDetails;
