import {all, fork, takeLatest} from 'redux-saga/effects';
import initiateCreateTaskFlow from './sagas/initiateCreateTaskFlow';
import * as taskActionTypes from './taskActionTypes';
import searchTasks from './sagas/searchTasks';
import editTaskFlow from './sagas/editTaskFlow';
import createTask from './sagas/createTask';

/**
 *  Task watcher saga
 */
const taskWatcher = function* taskWatcher() {
    yield all([
        fork(initiateCreateTaskFlow),
        fork(editTaskFlow),
        takeLatest(
            taskActionTypes.SEARCH_TASKS,
            searchTasks,
        ),
        takeLatest(
            taskActionTypes.CREATE_TASK,
            createTask,
        ),
    ]);
};

export default taskWatcher;
