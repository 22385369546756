import {put, take, select, fork} from 'redux-saga/effects';
import {alfInvoiceChannelTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceActionTypes from '../invoiceActionTypes';

const loadInvoiceSubmission = function* loadInvoiceSubmission({payload}) {
    const {match} = payload;
    const {invoiceId} = match.params;
    const invoice = yield select(state => state.invoices.invoices[invoiceId]);
    const {invoiceSubmissionId} = invoice;

    const {serviceManager} = yield select(state => state.application);
    const aceMemberAssignmentFlowService = serviceManager.loadService('aceMemberAssignmentFlowService');

    if (!invoiceSubmissionId || invoice?.channel !== alfInvoiceChannelTypes.MIA) return;

    yield fork(
        fetchRequest,
        invoiceActionTypes.FETCH_INVOICE_SUBMISSION_REQUEST,
        aceMemberAssignmentFlowService.getInvoiceSubmission,
        {invoiceSubmissionId},
    );

    const invoiceSubmissionFetchActionResponse = yield take([
        invoiceActionTypes.FETCH_INVOICE_SUBMISSION_REQUEST_SUCCEEDED,
        invoiceActionTypes.FETCH_INVOICE_SUBMISSION_REQUEST_FAILED,
    ]);

    if (!invoiceSubmissionFetchActionResponse.error) {
        const {response} = invoiceSubmissionFetchActionResponse.payload;
        const {invoiceSubmissionDTO} = response;

        yield put({
            type: invoiceActionTypes.STORE_INVOICE_SUBMISSION,
            payload: {invoiceSubmissionDTO},
        });
    }
};

export default loadInvoiceSubmission;
