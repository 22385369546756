import {take} from 'redux-saga/effects';
import updateInvoice from './updateInvoice';
import * as invoiceActionTypes from '../invoiceActionTypes';

/**
 * Update Invoice basic data flow
 */
const updateInvoiceBasicDataFlow = function* updateInvoiceBasicDataFlow() {
    while (true) {
        const {payload} = yield take(invoiceActionTypes.SUBMIT_INVOICE_BASIC_DATA_FORM);
        const {invoiceData, invoiceId, triggerINACheck} = payload;

        yield* updateInvoice({
            caller: invoiceActionTypes.SUBMIT_INVOICE_BASIC_DATA_FORM,
            invoiceId,
            invoiceData,
            triggerINACheck,
        });
    }
};

export default updateInvoiceBasicDataFlow;
