import {fork, put, select, take} from 'redux-saga/effects';
import {replace, resolveRoute} from '@computerrock/formation-router';
import {alfInvoiceStatusTypes, alfAccountTypes, alfInvoiceTypes} from '@ace-de/eua-entity-types';
import * as invoiceActionTypes from '../invoiceActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';

const invoiceEstimationFlow = function* invoiceEstimationFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(invoiceActionTypes.INITIATE_INVOICE_ESTIMATION_FLOW);
        const {redirectionPath, invoiceId} = payload;

        yield fork(
            fetchRequest,
            invoiceActionTypes.CREATE_INVOICE_ESTIMATION_REQUEST,
            leaAssignmentFlowService.createInvoiceEstimation,
            {invoiceId},
        );

        const responseAction = yield take([
            invoiceActionTypes.CREATE_INVOICE_ESTIMATION_REQUEST_SUCCEEDED,
            invoiceActionTypes.CREATE_INVOICE_ESTIMATION_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {invoiceDTO} = response;

            yield put({
                type: invoiceActionTypes.STORE_INVOICES,
                payload: {invoiceDTOs: [invoiceDTO]},
            });

            // fetch account party options
            if (invoiceDTO.status === alfInvoiceStatusTypes.IN_CREATION) {
                const {serviceCases, accountPartyOptionsIds} = yield select(state => state.serviceCases);
                const serviceCase = serviceCases[invoiceDTO.serviceCaseId];
                if (serviceCase) {
                    yield put({
                        type: invoiceActionTypes.FETCH_ACCOUNT_PARTY_OPTIONS,
                        payload: {
                            accountPartyOptionsIds: [
                                ...accountPartyOptionsIds,
                                ...(!accountPartyOptionsIds.includes(serviceCase.member?.membershipNo)
                                    ? [serviceCase.member.membershipNo]
                                    : []),
                            ],
                            accountPartyType: invoiceDTO.type === alfInvoiceTypes.VKR
                                ? alfAccountTypes.DEBTOR
                                : alfAccountTypes.CREDITOR,
                            client: serviceCase.client,
                        },
                    });
                }
            }

            yield put(replace(resolveRoute(redirectionPath, {
                serviceCaseId: invoiceDTO.serviceCaseId,
                invoiceId: invoiceDTO.id,
            })));
        }
    }
};

export default invoiceEstimationFlow;
