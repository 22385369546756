import {take, put, fork, select, delay} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {replace, resolveRoute} from '@computerrock/formation-router';
import {alfINAStatusTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceActionTypes from '../invoiceActionTypes';
import modalIds from '../../modalIds';
import inaCheckResponseTypes from '../inaCheckResponseTypes';
import updateInvoiceStatus from './updateInvoiceStatus';
import routePaths from '../../routePaths';

const checkINARulesFlow = function* checkINARulesFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    let shouldWaitForAction = true;
    let payload = null;

    while (true) {
        if (shouldWaitForAction) {
            const {payload: actionPayload} = yield take([invoiceActionTypes.INITIATE_INA_CHECK]);
            payload = actionPayload;
        }

        if (!payload) return;

        const {invoiceId} = payload;

        if (!invoiceId) continue;

        yield* openModal(modalIds.INA_CHECK_LOADER_MODAL);
        yield fork(
            fetchRequest,
            invoiceActionTypes.CHECK_INA_RULES_REQUEST,
            leaAssignmentFlowService.checkINARules,
            {invoiceId},
        );

        const inaCheckResponse = yield take([
            invoiceActionTypes.CHECK_INA_RULES_REQUEST_SUCCEEDED,
            invoiceActionTypes.CHECK_INA_RULES_REQUEST_FAILED,
        ]);
        yield delay(1300);
        yield* closeModal(modalIds.INA_CHECK_LOADER_MODAL);

        if (inaCheckResponse.error) {
            yield* openModal(modalIds.INA_CHECK_RESPONSE_MODAL, {checkStatus: inaCheckResponseTypes.INA_API_FAILED});
        }

        if (!inaCheckResponse.error) {
            const {response} = inaCheckResponse.payload;
            const {inaCheckResponseDTO} = response;
            const checkStatus = inaCheckResponseDTO.status === alfINAStatusTypes.PASSED
                ? inaCheckResponseTypes.INA_CHECK_PASSED
                : inaCheckResponseTypes.INA_CHECK_FAILED;
            yield* openModal(modalIds.INA_CHECK_RESPONSE_MODAL, {checkStatus});
        }

        const nextAction = yield take([
            invoiceActionTypes.RETRY_INA_CHECK,
            invoiceActionTypes.REDIRECT_AFTER_INA_CHECK,
            invoiceActionTypes.DECLINE_INA_CHECK,
            invoiceActionTypes.UPDATE_INVOICE_STATUS_TO_MANUAL_CHECK,
        ]);

        yield* closeModal(modalIds.INA_CHECK_RESPONSE_MODAL, {checkStatus: null});

        const {type, payload: actionPayload} = nextAction;

        switch (type) {
            case invoiceActionTypes.REDIRECT_AFTER_INA_CHECK: {
                const {destination, queryParams} = actionPayload;
                yield put(replace(resolveRoute(
                    destination,
                    queryParams,
                )));
                shouldWaitForAction = true;
                break;
            }
            case invoiceActionTypes.DECLINE_INA_CHECK: {
                shouldWaitForAction = true;
                break;
            }
            case invoiceActionTypes.RETRY_INA_CHECK: {
                shouldWaitForAction = false;
                break;
            }
            case invoiceActionTypes.UPDATE_INVOICE_STATUS_TO_MANUAL_CHECK: {
                const {invoiceStatusData, serviceCaseId} = actionPayload;

                yield* updateInvoiceStatus({
                    caller: invoiceActionTypes.UPDATE_INVOICE_STATUS_TO_MANUAL_CHECK,
                    invoiceId,
                    invoiceStatusData,
                });

                yield put(replace(resolveRoute(routePaths.INVOICE_OVERVIEW, {
                    serviceCaseId,
                    invoiceId,
                })));

                break;
            }
            default:
                break;
        }
    }
};

export default checkINARulesFlow;
