import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {camelCase} from 'change-case';
import PropTypes from 'prop-types';
import {withRouter} from '@computerrock/formation-router';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {useStyles, Panel, ContentBlock, ContentItem, HighlightCircle, LoaderSpinner} from '@ace-de/ui-components';
import {InteractiveIcon, expandIcon} from '@ace-de/ui-components/icons';
import {ArcGISMapWidget, useArcGISMap, createMarkerGraphic} from '@ace-de/eua-arcgis-map';
import {useTranslate} from '@computerrock/formation-i18n';
import * as invoiceSelectors from '../invoiceSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as invoiceActionTypes from '../invoiceActionTypes';
import {relevantLocationIcons, relevantLocationsHierarchy} from '../relevantLocationContstans';
import InvoiceRelevantLocationsSidePanel from './InvoiceRelevantLocationsSidePanel';
import config from '../../config';

const InvoiceRelevantLocationsMap = props => {
    const {cx} = useStyles();
    const {invoice, serviceCase, initiateMapExpansion, searchPersistenceState} = props;
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('invoice_overview_auditing');
    const mapName = 'invoice-relevant-locations-map';
    const arcGISMap = useArcGISMap(mapName);

    const {locations, relevantLocationMapExtent, mapCenter} = invoice;
    const isSearchInProgress = searchPersistenceState === persistenceStates.PENDING;

    useEffect(() => {
        if (!arcGISMap || !locations || locations?.length === 0 || !relevantLocationMapExtent) return;
        const sortedLocations = [...locations].filter(location => {
            return location.id && location.type && location.coordinates;
        }).sort((locationA, locationB) => {
            const locationHierarchyA = relevantLocationsHierarchy[locationA.type];
            const locationHierarchyB = relevantLocationsHierarchy[locationB.type];

            return locationHierarchyA >= locationHierarchyB ? -1 : 1;
        });

        arcGISMap.setGraphics({
            graphics: [
                ...(sortedLocations
                    ? sortedLocations.map(location => {
                        return createMarkerGraphic({
                            id: camelCase(location.type),
                            longitude: location.coordinates?.longitude,
                            latitude: location.coordinates?.latitude,
                            icon: relevantLocationIcons[location.type],
                            isDraggable: false,
                        });
                    })
                    : []
                ),
            ],
        });
        // setGraphics method by default sets map's center to the coordinates of the last location
        // therefore we need to reset it by setting map extent after graphics are created
        if (relevantLocationMapExtent) {
            arcGISMap.setMapExtent(relevantLocationMapExtent);
        }
    }, [arcGISMap, locations, mapCenter, relevantLocationMapExtent]);

    if (!invoice || !serviceCase) return null;

    return (
        <Panel
            title={translateTab('panel_title.map')}
            contentClassName={cx('global!ace-u-padding--bottom-32')}
            actions={(
                <InteractiveIcon
                    icon={expandIcon}
                    onClick={initiateMapExpansion}
                />
            )}
        >
            <ContentBlock>
                <ContentItem className={cx('ace-c-content-item--span-6')}>
                    <ArcGISMapWidget
                        name={mapName}
                        tempArcGISMapId={config.TEMP_ARCGIS_MAP_ID}
                        locale={activeLocale}
                        className={cx('ace-c-arcgis-map-widget--is-visible')}
                    />
                </ContentItem>
                <ContentItem className={cx('ace-c-content-item--span-6')}>
                    {isSearchInProgress && (
                    <div
                        className={cx([
                            'global!ace-u-width--full',
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-center',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <HighlightCircle
                            className={cx([
                                'ace-c-highlight-circle--medium',
                                'ace-c-highlight-circle--primary-highlight',
                                'global!ace-u-margin--64-0',
                            ])}
                        >
                            <LoaderSpinner />
                        </HighlightCircle>
                    </div>
                    )}
                    {!isSearchInProgress && (
                        <InvoiceRelevantLocationsSidePanel
                            invoice={invoice}
                            serviceCase={serviceCase}
                            locations={locations}
                        />
                    )}
                </ContentItem>
            </ContentBlock>
        </Panel>
    );
};

InvoiceRelevantLocationsMap.propTypes = {
    invoice: PropTypes.object,
    serviceCase: PropTypes.object,
    initiateMapExpansion: PropTypes.func.isRequired,
    searchPersistenceState: PropTypes.string.isRequired,
};

InvoiceRelevantLocationsMap.defaultProps = {
    invoice: null,
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const invoiceSelector = invoiceSelectors.createInvoicesSelector();

    return {
        invoice: invoiceSelector(state, props),
        serviceCase: serviceCaseSelector(state, props),
        searchPersistenceState: state.invoices.searchPersistenceState,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateMapExpansion: payload => dispatch({
        type: invoiceActionTypes.INITIATE_EXPAND_MAP_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceRelevantLocationsMap));
