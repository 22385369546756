import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {Panel, useStyles, NoResultsBlock, Paginator, historyIcon, Icon, Divider} from '@ace-de/ui-components';
import {withRouter, resolveRoute} from '@computerrock/formation-router';
import config from '../config';
import routePaths from '../routePaths';
import LogEntry from './ui-elements/LogEntry';
import * as caseLogSelectors from './caseLogSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as invoiceSelectors from '../invoices/invoiceSelectors';

const CaseProtocolPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('case_protocol_panel');
    const {history, serviceCase, invoice} = props;
    const {caseLogFilterResults, caseLogFilterResultsCount, caseLogFilterResultsPage} = props;

    const handleOnPaginationChange = page => {
        const searchParams = new URLSearchParams(history.location.search);
        searchParams.set('page', page);
        const queryParamsString = searchParams.toString();
        history.replace(resolveRoute(routePaths.INVOICE_OVERVIEW,
            {
                serviceCaseId: serviceCase.id,
                invoiceId: invoice.id,
            },
            {search: queryParamsString}));
    };

    return (
        <Fragment>
            <Panel title={translatePanel('panel_title.case_protocol')}>
                <Divider />
                <div className={cx('global!ace-u-padding--16-24-32')}>
                    {caseLogFilterResults?.length === 0 ? (
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={historyIcon}
                                />
                            )}
                            message={translatePanel('no_results.message')}
                            description={translatePanel('no_results.description')}
                        />
                    ) : caseLogFilterResults?.map((caseLog, idx) => (
                        <LogEntry key={idx} caseLog={caseLog} />
                    ))}
                </div>
            </Panel>
            <div>
                {!!caseLogFilterResultsCount && (
                    <Paginator
                        page={caseLogFilterResultsPage}
                        count={Math.ceil(caseLogFilterResultsCount / config.PROTOCOL_DEFAULT_PAGE_SIZE)}
                        onClick={page => handleOnPaginationChange(page)}
                    />
                )}
            </div>
        </Fragment>
    );
};

CaseProtocolPanel.propTypes = {
    serviceCase: PropTypes.object,
    history: PropTypes.object,
    invoice: PropTypes.object,
    caseLogFilterResults: PropTypes.array,
    caseLogFilterResultsCount: PropTypes.number,
    caseLogFilterResultsPage: PropTypes.number,
};

CaseProtocolPanel.defaultProps = {
    serviceCase: null,
    history: null,
    invoice: null,
    caseLogFilterResults: [],
    caseLogFilterResultsCount: 0,
    caseLogFilterResultsPage: 0,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    const getInvoice = invoiceSelectors.createInvoicesSelector();

    return {
        serviceCase: getServiceCase(state, props),
        caseLogFilterResults: caseLogSelectors.getCaseLogFilterResults(state),
        caseLogFilterResultsCount: caseLogSelectors.getCaseLogFilterResultsCount(state),
        caseLogFilterResultsPage: caseLogSelectors.getCaseLogFilterResultsPage(state),
        invoice: getInvoice(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CaseProtocolPanel));
