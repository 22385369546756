import {fork, put, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceActionTypes from '../invoiceActionTypes';

const loadAccountPartyOptions = function* loadAccountPartyOptions({payload}) {
    const {accountPartyOptionsIds, client, accountPartyType} = payload;

    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    if (accountPartyOptionsIds.length === 0) return;

    yield fork(
        fetchRequest,
        invoiceActionTypes.FETCH_ACCOUNT_PARTY_OPTIONS_REQUEST,
        leaAssignmentFlowService.getBulkAccountParties,
        {bulkAccountPartyIds: accountPartyOptionsIds, client, accountPartyType},
    );

    const actionResponse = yield take([
        invoiceActionTypes.FETCH_ACCOUNT_PARTY_OPTIONS_REQUEST_SUCCEEDED,
        invoiceActionTypes.FETCH_ACCOUNT_PARTY_OPTIONS_REQUEST_FAILED,
    ]);

    if (!actionResponse.error) {
        const {payload: searchResultsPayload} = actionResponse;
        const {accountPartyDTOs} = searchResultsPayload.response;

        const accPartyData = accountPartyOptionsIds.map(accountPartyOptionsId => {
            const accPartyDTO = accountPartyDTOs.find(accountParty => {
                return accountParty['accountPartyDTO'].id === accountPartyOptionsId;
            });
            return accPartyDTO ? accPartyDTO.accountPartyDTO : {id: accountPartyOptionsId};
        });

        yield put({
            type: invoiceActionTypes.STORE_ACCOUNT_PARTY_OPTIONS,
            payload: {accountPartyDTOs: accPartyData},
        });
    }
};

export default loadAccountPartyOptions;
