import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {Divider, HighlightCircle, Panel, ToggleTab, ToggleTabSwitch, LoaderSpinner, useStyles} from '@ace-de/ui-components';
import {infoAlertIcon, Icon} from '@ace-de/ui-components/icons';
import {alfClientTypes} from '@ace-de/eua-entity-types';
import config from '../../config';
import * as memberActionTypes from '../memberActionTypes';
import * as memberSelectors from '../memberSelectors';

const MemberCreditInformationPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateScreen = createTranslateShorthand('member_screen');
    const {memberBCDetails, member, fetchMemberBCDetails, areMemberBCDetailsLoading, isMemberBCDetailsError} = props;
    const {memberServiceCasesTotalCount} = props;
    const [clientBC, setClientBC] = useState(alfClientTypes.WACE);

    const getPrice = ({price, currency}) => {
        if (typeof price === 'number' && price >= 0) {
            return price.toLocaleString(activeLocale, {
                style: 'currency',
                currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
    };

    const handleToggleSwitch = value => {
        const searchQueryParams = new URLSearchParams();
        searchQueryParams.append('client', value);
        searchQueryParams.append('ids', member.membershipNo);
        fetchMemberBCDetails({searchQueryParams});
        setClientBC(value);
    };

    return (
        <Panel
            title={translateScreen('member_credit_information_panel_title.credit_information', {clientBC})}
            actions={(
                <ToggleTabSwitch
                    name="clientBC"
                    value={clientBC}
                    onChange={value => handleToggleSwitch(value)}
                >
                    {Object.values(alfClientTypes).map(tab => (
                        <ToggleTab
                            key={tab}
                            name={`${tab.toLowerCase()}Tab`}
                            value={tab}
                        >
                            {tab}
                        </ToggleTab>
                    ))}
                </ToggleTabSwitch>
            )}
        >
            <div
                className={cx([
                    'global!ace-u-margin--top-40',
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                ], {
                    'global!ace-u-flex--justify-center': areMemberBCDetailsLoading,
                })}
            >
                {areMemberBCDetailsLoading && (
                    <LoaderSpinner />
                )}
                {!areMemberBCDetailsLoading && isMemberBCDetailsError && (
                    <div
                        className={cx([
                            'global!ace-u-width--full',
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-center',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <HighlightCircle
                            className={cx([
                                'ace-c-highlight-circle--medium',
                                'ace-c-highlight-circle--primary-highlight',
                                'global!ace-u-margin--right-24',
                            ])}
                        >
                            <Icon
                                icon={infoAlertIcon}
                                className={cx([
                                    'ace-c-icon--xl',
                                    'global!ace-c-icon--color-highlight',
                                ])}
                            />
                        </HighlightCircle>
                        <h2>
                            {translateScreen('member_credit_information_panel_title.bc_error')}
                        </h2>
                    </div>
                )}
                {!areMemberBCDetailsLoading && !isMemberBCDetailsError && (
                    <div className={cx('global!ace-u-flex--basis-60')}>
                        <div
                            className={cx([
                                'global!ace-u-margin--bottom-24',
                                'global!ace-u-grid',
                                'global!ace-u-typography--variant-body',
                            ])}
                        >
                            <p className={cx('global!ace-u-grid-column--span-3')}>
                                {translateScreen('member_credit_information_panel_label.dunning_level')}:
                            </p>
                            <p className={cx('global!ace-u-grid-column--span-3')}>
                                {memberBCDetails?.dunningLevel || '-'}
                            </p>
                            <p className={cx('global!ace-u-grid-column--span-4')}>
                                {translateScreen('member_credit_information_panel_label.number_of_cases')}:
                            </p>
                            <p className={cx('global!ace-u-grid-column--span-2')}>
                                {memberServiceCasesTotalCount || '-'}
                            </p>
                        </div>
                        <Divider />
                        <div
                            className={cx([
                                'global!ace-u-margin--24-0',
                                'global!ace-u-grid',
                                'global!ace-u-typography--variant-body',
                            ])}
                        >
                            <p className={cx('global!ace-u-grid-column--span-3')}>
                                {translateScreen('member_credit_information_panel_label.debtor_balance')}:
                            </p>
                            <p
                                className={cx(
                                    'global!ace-u-grid-column--span-3',
                                    'global!ace-u-typography--variant-body-bold', {
                                        'global!ace-u-typography--color-success': memberBCDetails?.debtorBalance <= 0,
                                        'global!ace-u-typography--color-warning': memberBCDetails?.debtorBalance > 0,
                                    },
                                )}
                            >
                                {typeof memberBCDetails?.debtorBalance === 'number'
                                    ? getPrice({
                                        price: memberBCDetails?.debtorBalance,
                                        currency: config.CURRENCY,
                                    })
                                    : '-'}
                            </p>
                            <p className={cx('global!ace-u-grid-column--span-4')}>
                                {translateScreen('member_credit_information_panel_label.creditor_balance')}:
                            </p>
                            <p
                                className={cx(
                                    'global!ace-u-grid-column--span-2',
                                    'global!ace-u-typography--variant-body-bold', {
                                        'global!ace-u-typography--color-success': memberBCDetails?.creditorBalance <= 0,
                                        'global!ace-u-typography--color-warning': memberBCDetails?.creditorBalance > 0,
                                    },
                                )}
                            >
                                {typeof memberBCDetails?.creditorBalance === 'number'
                                    ? getPrice({
                                        price: memberBCDetails?.creditorBalance,
                                        currency: config.CURRENCY,
                                    })
                                    : '-'}
                            </p>
                        </div>
                        <Divider />
                    </div>
                )}
            </div>
        </Panel>
    );
};

MemberCreditInformationPanel.propTypes = {
    memberBCDetails: PropTypes.object,
    fetchMemberBCDetails: PropTypes.func.isRequired,
    member: PropTypes.object,
    areMemberBCDetailsLoading: PropTypes.bool,
    isMemberBCDetailsError: PropTypes.bool,
    memberServiceCasesTotalCount: PropTypes.number,
};

MemberCreditInformationPanel.defaultProps = {
    memberBCDetails: null,
    member: null,
    areMemberBCDetailsLoading: false,
    isMemberBCDetailsError: false,
    memberServiceCasesTotalCount: 0,
};

const mapStateToProps = (state, props) => {
    const memberByMembershipNoSelector = memberSelectors.createMemberByMembershipNoSelector();
    return {
        member: memberByMembershipNoSelector(state, props),
        memberBCDetails: state.members.memberBCDetails,
        areMemberBCDetailsLoading: state.members.areMemberBCDetailsLoading,
        isMemberBCDetailsError: state.members.isMemberBCDetailsError,
        memberServiceCasesTotalCount: state.serviceCases.serviceCasesTotalCount,
    };
};

const mapDispatchToProps = dispatch => ({
    fetchMemberBCDetails: payload => dispatch({
        type: memberActionTypes.FETCH_MEMBER_BC_DETAILS,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberCreditInformationPanel));
