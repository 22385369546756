import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Paginator, ButtonPrimary, NoResultsBlock, Panel, useStyles} from '@ace-de/ui-components';
import {downloadIcon, Icon, InteractiveIcon, outgoingMessageIcon, uploadIcon, incomingMessageIcon, historyIcon} from '@ace-de/ui-components/icons';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {alfMessageChannelTypes, alfMessageTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter, resolveRoute} from '@computerrock/formation-router';
import * as invoiceSelectors from './invoiceSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as communicationActionTypes from '../communication/communicationActionTypes';
import config from '../config';
import useBeforeUnloadEffect from '../useBeforeUnloadEffect';
import serviceManager from '../serviceManager';
import invoiceOverviewScreenTabs from './invoiceOverviewScreenTabs';
import routePaths from '../routePaths';
import {leaFeatureActions, leaFeatures} from '../application/leaFeatures';
import leaAccessControl from '../leaAccessControl';

const generateMessagePreview = (messageContent, isFullContent) => {
    if (!messageContent) return;
    if (isFullContent || messageContent.length <= config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT) {
        return messageContent.replaceAll(/<[^>]*>/g, ' ').trim();
    }
    let characters = '';
    [...messageContent].forEach((char, index) => {
        if (index <= config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT - 1) characters += char;
    });
    return `${characters.replaceAll(/<[^>]*>/g, ' ').trim()}... `;
};

const InvoiceCommunicationTab = props => {
    const {cx} = useStyles();
    const {initiateReplyUploadFlow, initiateDownloadAllDocuments, updateMessageAsRead, searchMessages} = props;
    const {caseMessages, invoice, caseMessagesPage, caseMessagesTotalCount, history, serviceCase} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('invoice_communication_tab');
    const [messages, setMessages] = useState([]);
    const [readMessageIds, setReadMessageIds] = useState([]);
    const [expandedMessageIds, setExpandedMessageIds] = useState([]);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    const queryParams = new URLSearchParams(history.location.search);
    const shouldMarkMessageAsRead = useRef(false);
    const isMessageUploadAllowed = leaAccessControl.grantFeatureAccess(
        leaFeatures.MESSAGE,
        leaFeatureActions.CREATE,
    );

    const markMessageAsRead = message => {
        if (!message) return;
        if (!message.isRead && !readMessageIds.includes(message.id)) {
            shouldMarkMessageAsRead.current = true;
            setReadMessageIds(prevState => ([
                ...prevState,
                message.id,
            ]));
        }
    };

    const handleOnMessageContentExpansion = (messageContent, messageId, event) => {
        event.preventDefault();
        event.stopPropagation();

        const newExtendedMessageIdsArray = !expandedMessageIds.includes(messageId)
            ? [...expandedMessageIds, messageId]
            : [...expandedMessageIds].filter(id => messageId !== id);

        setExpandedMessageIds([...newExtendedMessageIdsArray]);

        const messageContentPreview = generateMessagePreview(
            messageContent,
            newExtendedMessageIdsArray.includes(messageId),
        );
        const openedMessage = messages.find(message => message.id === messageId);
        if (!openedMessage) return;

        openedMessage.messageContentPreview = messageContentPreview;
        const openedMessages = messages.map(message => {
            if (message.id === messageId) return openedMessage;
            return message;
        });

        if (!openedMessage.isRead) {
            setReadMessageIds(prevState => ([
                ...prevState,
                openedMessage.id,
            ]));
            shouldMarkMessageAsRead.current = true;
        }

        setMessages(openedMessages);
    };

    useEffect(() => {
        if (queryParams.get('activeTab') === invoiceOverviewScreenTabs.COMMUNICATION
            && !queryParams.get('serviceCaseId')
            && !queryParams.get('size')
            && !queryParams.get('page')
        ) {
            queryParams.append('serviceCaseId', serviceCase.id);
            queryParams.append('size', config.DEFAULT_PAGE_SIZE);
            queryParams.append('page', 0);

            history.replace(resolveRoute(routePaths.INVOICE_OVERVIEW, {
                serviceCaseId: serviceCase.id,
                invoiceId: invoice.id,
            }, {search: queryParams.toString()}));
        }
    });

    useEffect(() => {
        if (readMessageIds?.length === 0 || !shouldMarkMessageAsRead.current) return;
        updateMessageAsRead({messageIds: readMessageIds});
        shouldMarkMessageAsRead.current = true;
    }, [readMessageIds, updateMessageAsRead]);

    useEffect(() => {
        if (caseMessages.length === 0) return;
        setMessages(caseMessages.map(message => ({
            ...message,
            ...(message.channel === alfMessageChannelTypes.ELLA && {
                messageContentPreview: generateMessagePreview(message.content, false),
            }),
        })));
    }, [caseMessages]);

    useBeforeUnloadEffect(event => {
        if (readMessageIds.length === 0 || shouldMarkMessageAsRead.current) return;
        return {
            request: leaAssignmentFlowService.updateMessagesAsRead,
            requestData: {
                messageIds: readMessageIds,
            },
        };
    });

    const handleOnPaginationChange = page => {
        if (readMessageIds?.length > 0 && shouldMarkMessageAsRead.current) {
            updateMessageAsRead({messageIds: readMessageIds});
        }
        searchMessages({
            page,
            serviceCaseId: invoice?.serviceCaseId,
            size: config.DEFAULT_PAGE_SIZE,
        });
    };

    if (!invoice) return null;

    return (
        <Panel
            title={translateTab('messages_panel.title')}
            className={cx('ace-c-panel--full-bleed-content')}
            actions={isMessageUploadAllowed && (
                <ButtonPrimary onClick={() => initiateReplyUploadFlow({invoiceId: invoice.id})}>
                    <Icon
                        icon={uploadIcon}
                        className={cx([
                            'global!ace-u-margin--right-16',
                            'ace-c-icon--color-contrast',
                        ])}
                    />
                    {translateTab('button_label.upload_reply')}
                </ButtonPrimary>
            )}
        >
            <Table qaIdent="messages-overview-table">
                {messages.length === 0 && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={historyIcon}
                                />
                        )}
                            description={translateTab('no_results.description')}
                            message={translateTab('no_results.message')}
                        />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell
                            qaIdentPart="message-type"
                            colSpan={2}
                            className={cx('ace-u-padding--left-32')}
                        />
                        <TableCell qaIdentPart="created-date" colSpan={3}>
                            {translateTab('table_header.created_date')}
                        </TableCell>
                        <TableCell qaIdentPart="channel" colSpan={3}>
                            {translateTab('table_header.communication_channel')}
                        </TableCell>
                        <TableCell qaIdentPart="message-subject" colSpan={9}>
                            {translateTab('table_header.subject')}
                        </TableCell>
                        <TableCell qaIdentPart="contact-name" colSpan={4}>
                            {translateTab('table_header.contact_name')}
                        </TableCell>
                        <TableCell qaIdentPart="user" colSpan={3}>
                            {translateTab('table_header.user')}
                        </TableCell>
                        <TableCell
                            qaIdentPart="attachment"
                            colSpan={2}
                            className={cx('ace-u-padding--right-32')}
                        >
                            {translateTab('table_header.attachment')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {messages.length > 0 && messages.map(message => {
                        return (
                            <TableRow
                                key={`${message.invoiceId}-${message.id}`}
                                qaIdentPart={message.invoiceId}
                            >
                                <TableCell
                                    qaIdentPart="message-type"
                                    qaIdentPartPostfix={message.invoiceId}
                                    colSpan={2}
                                    className={cx('ace-u-padding--left-32')}
                                >
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-left',
                                            'global!ace-u-flex--justify-content-start',
                                        ])}
                                    >
                                        <Icon
                                            icon={message.messageType === alfMessageTypes.OUTGOING
                                                ? outgoingMessageIcon
                                                : incomingMessageIcon}
                                            className={cx('global!ace-u-flex--shrink-0', {
                                                'ace-c-icon--color-success': message.messageType === alfMessageTypes.INCOMING,
                                                'ace-c-icon--color-warning-dark': message.messageType === alfMessageTypes.OUTGOING,
                                            })}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell
                                    qaIdentPart="created-date"
                                    qaIdentPartPostfix={message.invoiceId}
                                    colSpan={3}
                                >
                                    {message.createdAt || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="channel"
                                    qaIdentPartPostfix={message.invoiceId}
                                    colSpan={3}
                                >
                                    {translate(`global.communication_channel_option.${message.channel.toLowerCase()}`) || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="message-subject"
                                    qaIdentPartPostfix={message.invoiceId}
                                    colSpan={9}
                                    className={cx([
                                        'ace-c-table__cell--overflow-visible',
                                        'ace-c-table__cell--white-space-normal',
                                    ])}
                                >
                                    {message.channel === alfMessageChannelTypes.ELLA ? (
                                        <div>
                                            {message.messageContentPreview}
                                            {message.content?.length > config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT
                                                ? (
                                                    <span
                                                        className={cx([
                                                            'global!ace-u-typography--variant-body-bold',
                                                            'global!ace-u-typography--color-highlighted',
                                                            'global!ace-u-cursor--pointer',
                                                        ])}
                                                        onClick={event => handleOnMessageContentExpansion(
                                                            message.content,
                                                            message.id,
                                                            event,
                                                        )}
                                                    >
                                                        [{expandedMessageIds.includes(message.id)
                                                        ? translateTab('table_body.read_less')
                                                        : translateTab('table_body.read_more')}]
                                                    </span>
                                                ) : !message.isRead
                                                    && message.messageType === alfMessageTypes.INCOMING && (
                                                        <span
                                                            className={cx([
                                                                'global!ace-u-typography--variant-body-bold',
                                                                'global!ace-u-margin--left-4',
                                                                {
                                                                    'global!ace-u-typography--color-disabled': readMessageIds.includes(message.id),
                                                                    'global!ace-u-typography--color-highlighted': !readMessageIds.includes(message.id),
                                                                    'global!ace-u-cursor--pointer': !readMessageIds.includes(message.id),
                                                                },
                                                            ])}
                                                            onClick={() => { markMessageAsRead(message); }}
                                                        >
                                                            [{translateTab('table_body.read')}]
                                                        </span>
                                                )}
                                        </div>
                                    ) : message.subject || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="contact-name"
                                    qaIdentPartPostfix={message.invoiceId}
                                    colSpan={4}
                                >
                                    {message.messageType === alfMessageTypes.OUTGOING
                                        ? message.recipient || '-'
                                        : message.sender || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="user"
                                    qaIdentPartPostfix={message.invoiceId}
                                    colSpan={3}
                                >
                                    {message.creatorName || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="attachment"
                                    qaIdentPartPostfix={message.invoiceId}
                                    colSpan={2}
                                    className={cx('ace-u-padding--right-32')}
                                >
                                    {message.attachments.length > 0 && (
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-flex--justify-content-center',
                                            ])}
                                        >
                                            <InteractiveIcon
                                                icon={downloadIcon}
                                                className={cx([
                                                    'ace-c-interactive-icon--primary',
                                                    'global!ace-u-flex--shrink-0',
                                                    'global!ace-u-cursor--pointer',
                                                ])}
                                                onClick={event => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    const fileName = `${message.invoiceId ? `${message.invoiceId}-` : ''}${message.category ? `${translate(`global.message_category_type.${message.category.toLowerCase()}`)}-` : ''}${translateTab('file_name_text.attachment')}.zip`;
                                                    initiateDownloadAllDocuments({
                                                        attachments: message.attachments,
                                                        fileName: fileName.replaceAll(/\s+/g, ''),
                                                    });
                                                }}
                                            />
                                        </div>
                                    )}
                                    {message.attachments.length === 0 && (
                                        '-'
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {!!caseMessagesTotalCount && (
                <Paginator
                    page={caseMessagesPage}
                    count={Math.ceil(caseMessagesTotalCount / config.DEFAULT_PAGE_SIZE)}
                    onClick={page => handleOnPaginationChange(page)}
                />
            )}
        </Panel>
    );
};

InvoiceCommunicationTab.propTypes = {
    initiateReplyUploadFlow: PropTypes.func.isRequired,
    initiateDownloadAllDocuments: PropTypes.func.isRequired,
    searchMessages: PropTypes.func.isRequired,
    updateMessageAsRead: PropTypes.func.isRequired,
    caseMessages: PropTypes.array,
    invoice: PropTypes.object,
    serviceCase: PropTypes.object,
    history: PropTypes.object,
    caseMessagesTotalCount: PropTypes.number,
    caseMessagesPage: PropTypes.number,
};

InvoiceCommunicationTab.defaultProps = {
    caseMessages: [],
    invoice: null,
    serviceCase: null,
    history: null,
    caseMessagesTotalCount: 0,
    caseMessagesPage: 0,
};

const mapStateToProps = (state, props) => {
    const invoiceSelector = invoiceSelectors.createInvoicesSelector();
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();

    return {
        caseMessages: state.communication.messagesSearchResults,
        caseMessagesTotalCount: state.communication.messagesSearchTotalCount,
        caseMessagesPage: state.communication.messagesSearchResultsPage,
        invoice: invoiceSelector(state, props),
        serviceCase: serviceCaseSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateReplyUploadFlow: payload => dispatch({
        type: communicationActionTypes.INITIATE_UPLOAD_REPLY_FLOW,
        payload,
    }),
    initiateDownloadAllDocuments: payload => dispatch({
        type: communicationActionTypes.INITIATE_MESSAGE_ATTACHMENTS_DOWNLOAD_FLOW,
        payload,
    }),
    updateMessageAsRead: payload => dispatch({
        type: communicationActionTypes.UPDATE_MESSAGE_AS_READ,
        payload,
    }),
    searchMessages: payload => dispatch({
        type: communicationActionTypes.SEARCH_MESSAGES,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceCommunicationTab));
