import {put, fork, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {push, resolveRoute} from '@computerrock/formation-router';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as invoiceActionTypes from '../../invoices/invoiceActionTypes';
import modalIds from '../../modalIds';
import routePaths from '../../routePaths';
import deleteModalLocationTypes from '../../application/modals/deleteModalLocationTypes';

const serviceCaseDeleteFlow = function* serviceCaseDeleteFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take([serviceCaseActionTypes.INITIATE_SERVICE_CASE_DELETE_FLOW]);
        const {serviceCaseId} = payload;

        if (!serviceCaseId) continue;

        const {serviceCases} = yield select(state => state.serviceCases);
        const serviceCase = serviceCases[serviceCaseId];

        yield* openModal(modalIds.DELETE_MODAL, {type: deleteModalLocationTypes.CASE});

        const chosenModalAction = yield take([
            invoiceActionTypes.DECLINE_DELETE,
            serviceCaseActionTypes.CONFIRM_SERVICE_CASE_DELETE,
        ]);

        if (chosenModalAction.type === serviceCaseActionTypes.CONFIRM_SERVICE_CASE_DELETE) {
            yield fork(fetchRequest,
                serviceCaseActionTypes.DELETE_SERVICE_CASE_REQUEST,
                leaAssignmentFlowService.deleteServiceCase,
                {serviceCaseId});

            const serviceCaseDeleteActionResponse = yield take([
                serviceCaseActionTypes.DELETE_SERVICE_CASE_REQUEST_SUCCEEDED,
                serviceCaseActionTypes.DELETE_SERVICE_CASE_REQUEST_FAILED,
            ]);

            if (!serviceCaseDeleteActionResponse.error && serviceCase?.member?.membershipNo) {
                yield put(push(resolveRoute(routePaths.MEMBER, {membershipNo: serviceCase.member.membershipNo})));
            }
        }

        yield* closeModal(modalIds.DELETE_MODAL, {type: deleteModalLocationTypes.CASE});
    }
};

export default serviceCaseDeleteFlow;
