import {all, fork} from 'redux-saga/effects';
import provideKeycloakTokenToServicesFlow from './sagas/provideKeycloakTokenToServicesFlow';
import provideArcGISTokenToServicesFlow from './sagas/provideArcGISTokenToServicesFlow';
import errorMessageModalFlow from './sagas/errorMessageModalFlow';
import successMessageFlow from './sagas/successMessageFlow';
import warningMessageFlow from './sagas/warningMessageFlow';

const applicationWatcher = function* applicationWatcher() {
    yield all([
        fork(provideKeycloakTokenToServicesFlow),
        fork(provideArcGISTokenToServicesFlow),
        fork(errorMessageModalFlow),
        fork(successMessageFlow),
        fork(warningMessageFlow),
    ]);
};

export default applicationWatcher;
