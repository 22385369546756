import {fork, select, take, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import invoiceApprovalReasonModalTypes from '../modals/invoiceApprovalReasonModalTypes';
import * as invoiceActionTypes from '../invoiceActionTypes';

const initiateInvoiceApprovalFlow = function* initiateInvoiceApprovalFlow({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    const {invoiceServiceLines, statusData, invoiceId, isInvoiceOverpaid, isInvoicePartialPayment} = payload;

    if (invoiceServiceLines.length > 0) {
        const params = invoiceServiceLines.map(invoiceServiceLine => {
            return {lineNo: invoiceServiceLine.lineNo, invoiceLinesData: invoiceServiceLine, invoiceId};
        });

        yield fork(
            fetchRequest,
            invoiceActionTypes.BATCH_UPDATE_SERVICE_LINES_REQUEST,
            leaAssignmentFlowService.batchUpdateInvoiceLines,
            {params},
        );

        const batchUpdateServiceLinesResponseAction = yield take([
            invoiceActionTypes.BATCH_UPDATE_SERVICE_LINES_REQUEST_SUCCEEDED,
            invoiceActionTypes.BATCH_UPDATE_SERVICE_LINES_REQUEST_FAILED,
        ]);

        if (batchUpdateServiceLinesResponseAction.error) {
            return;
        }
    }

    let chosenInvoiceApprovalModalOption;

    if (isInvoiceOverpaid || isInvoicePartialPayment) {
        yield* openModal(modalIds.INVOICE_APPROVAL_REASON_MODAL, {
            type: isInvoiceOverpaid
                ? invoiceApprovalReasonModalTypes.OVERPAYMENT
                : invoiceApprovalReasonModalTypes.PARTIAL_PAYMENT,
        });

        chosenInvoiceApprovalModalOption = yield take([
            invoiceActionTypes.DECLINE_INVOICE_APPROVAL,
            invoiceActionTypes.CONFIRM_INVOICE_APPROVAL,
        ]);

        if (chosenInvoiceApprovalModalOption.type === invoiceActionTypes.CONFIRM_INVOICE_APPROVAL && statusData) {
            const {payload} = chosenInvoiceApprovalModalOption;
            const {approveReason, approveDescription} = payload;

            yield put({
                type: invoiceActionTypes.INITIATE_MANUAL_INVOICE_STATUS_UPDATE_FLOW,
                payload: {
                    ...statusData,
                    invoiceStatusData: {
                        ...statusData.invoiceStatusData,
                        approveReason,
                        approveDescription,
                    },
                },
            });
        }

        yield* closeModal(modalIds.INVOICE_APPROVAL_REASON_MODAL, {type: invoiceApprovalReasonModalTypes.OVERPAYMENT});
    }

    if (statusData && chosenInvoiceApprovalModalOption?.type !== invoiceActionTypes.DECLINE_INVOICE_APPROVAL) {
        yield put({
            type: invoiceActionTypes.INITIATE_MANUAL_INVOICE_STATUS_UPDATE_FLOW,
            payload: statusData,
        });
    }
};
export default initiateInvoiceApprovalFlow;
