import {produce} from 'immer';
import {User} from '@ace-de/eua-entity-types';
import * as userProfileActionTypes from './userProfileActionTypes';

const initialState = {
    user: null,
    allowedShiftRoles: null,
    users: {},
    leaUsers: {},
    loggedInUsers: {},
};

const userProfileReducer = produce((draft, action) => {
    const {payload, type} = action;

    switch (type) {
        case userProfileActionTypes.STORE_USER_PROFILE: {
            const {userDTO} = payload;
            draft.user = new User().fromDTO(userDTO);
            break;
        }
        case userProfileActionTypes.STORE_USER_PROFILES: {
            const {userDTOs} = payload;
            userDTOs.forEach(userDTO => {
                const userId = userDTO.id;
                const user = draft.users[userId];
                if (!user) {
                    draft.users[userId] = new User()
                        .fromDTO(userDTO);
                    return;
                }
                draft.users[userId] = user.fromDTO(userDTO);
            });
            break;
        }

        case userProfileActionTypes.STORE_LEA_USER_PROFILES: {
            const {userDTOs} = payload;
            userDTOs.forEach(userDTO => {
                const userId = userDTO.id;
                const user = draft.leaUsers[userId];
                if (!user) {
                    draft.leaUsers[userId] = new User()
                        .fromDTO(userDTO);
                    return;
                }
                draft.leaUsers[userId] = user.fromDTO(userDTO);
            });
            break;
        }

        default:
        // no-op
    }
}, initialState);
export default userProfileReducer;
