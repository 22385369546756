import {take, select, fork, put, delay} from 'redux-saga/effects';
import {closeModal} from '@computerrock/formation-router/sagas';
import {replace, resolveRoute} from '@computerrock/formation-router';
import {LeistContact} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import contactDataModalTypes from '../modals/contactDataModalTypes';
import * as contactActionTypes from '../contactActionTypes';
import * as invoiceActionTypes from '../../invoices/invoiceActionTypes';
import bcModalContentTypes from '../modals/bcModalContentTypes';
import routePaths from '../../routePaths';
import config from '../../config';

const contactCreationModalFlow = function* contactCreationFlow() {
    let shouldWaitForAction = true;
    let payloadData = null;
    let shouldRetryContactCreation = false;
    let retryContactCreationData = null;

    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contactActionTypes.INITIATE_CREATE_CONTACT_MODAL_FLOW);
            payloadData = payload;
        }
        const {location} = yield select(state => state.router);
        const isModalOpened = !!(new URLSearchParams(location.search).get('modal'));

        const {client, invoiceId: isOriginInvoicePage} = payloadData;
        let chosenModalOption;

        if (!shouldRetryContactCreation && client) {
            const searchParams = new URLSearchParams(location.search);
            if (!isModalOpened) {
                searchParams.set('modal', modalIds.BC_CONTACT_MODAL);
            }
            searchParams.set('contentType', bcModalContentTypes.CONTACT_DATA);
            searchParams.set('client', client);
            searchParams.set('type', contactDataModalTypes.CREATE_CONTACT);

            yield put(replace(resolveRoute(location.pathname, {}, {search: searchParams.toString()})));

            chosenModalOption = yield take([
                contactActionTypes.RETURN_TO_CONTACT_SEARCH,
                contactActionTypes.CONFIRM_CREATE_CONTACT,
            ]);
        }

        if (shouldRetryContactCreation
            || chosenModalOption.type === contactActionTypes.CONFIRM_CREATE_CONTACT) {
            const contactData = !shouldRetryContactCreation && chosenModalOption?.payload?.contactData
                ? chosenModalOption?.payload?.contactData : retryContactCreationData?.contactData;

            const invoiceId = !shouldRetryContactCreation && chosenModalOption?.payload?.invoiceId
                ? chosenModalOption?.payload?.invoiceId : retryContactCreationData?.invoiceId;

            const client = !shouldRetryContactCreation && chosenModalOption?.payload?.client
                ? chosenModalOption?.payload?.client : retryContactCreationData?.client;

            const loaderSearchParams = new URLSearchParams(location.search);
            if (!isModalOpened) {
                loaderSearchParams.set('modal', modalIds.BC_CONTACT_MODAL);
            }
            loaderSearchParams.set('contentType', bcModalContentTypes.LOADER);
            loaderSearchParams.set('type', contactDataModalTypes.CREATE_CONTACT);

            yield put(replace(resolveRoute(location.pathname, {}, {search: loaderSearchParams.toString()})));

            yield fork(fetchRequest,
                contactActionTypes.CREATE_NEW_CONTACT_REQUEST,
                leaAssignmentFlowService.createContact,
                {
                    contactData: {
                        client,
                        ...LeistContact.objectToDTO(contactData),
                    },
                });

            const responseAction = yield take([
                contactActionTypes.CREATE_NEW_CONTACT_REQUEST_SUCCEEDED,
                contactActionTypes.CREATE_NEW_CONTACT_REQUEST_FAILED,
            ]);

            yield delay(1300);
            shouldRetryContactCreation = false;

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {contactDTO} = response;
                const contactDTOs = [contactDTO];

                yield put({
                    type: contactActionTypes.STORE_CONTACTS,
                    payload: {contactDTOs},
                });

                shouldWaitForAction = true;

                yield* closeModal(modalIds.BC_CONTACT_MODAL, {contentType: '', type: ''});
                yield delay(100);
                if (isOriginInvoicePage) {
                    yield put({
                        type: invoiceActionTypes.INITIATE_CONTACT_SELECTION_FLOW,
                        payload: {
                            invoiceId,
                            client,
                            contactId: contactDTO.id,
                            initiateContactSearch: true,
                        },
                    });
                    continue;
                }

                const queryParams = new URLSearchParams();
                queryParams.append('ids', contactDTO.id);
                queryParams.append('client', client);
                yield put(replace(resolveRoute(routePaths.CONTACTS, {}, {search: queryParams.toString()})));
                continue;
            }

            if (responseAction.error) {
                if (responseAction.payload?.response?.status === config.STATUS_CODE_SERVICE_UNAVAILABLE) {
                    yield put({
                        type: contactActionTypes.SET_BC_ERROR_MESSAGE,
                        payload: {errorDescription: JSON.parse(responseAction.payload.message)},
                    });
                }

                const failedQueryParams = new URLSearchParams(location.search);
                if (!failedQueryParams.get('modal')) {
                    failedQueryParams.set('modal', modalIds.BC_CONTACT_MODAL);
                }
                failedQueryParams.set('contentType', bcModalContentTypes.BC_ACTION_FAILED);
                failedQueryParams.set('type', contactDataModalTypes.CREATE_CONTACT);
                yield put(replace(resolveRoute(location.pathname, {}, {search: failedQueryParams.toString()})));

                const nextAction = yield take([
                    contactActionTypes.RETRY_CONTACT_CREATION,
                    contactActionTypes.CANCEL_ACTION,
                    contactActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW,
                ]);

                if (nextAction.type === contactActionTypes.RETRY_CONTACT_CREATION) {
                    shouldRetryContactCreation = true;
                    shouldWaitForAction = false;
                    retryContactCreationData = {
                        contactData,
                        invoiceId,
                        client,
                    };
                    continue;
                }

                if (nextAction.type === contactActionTypes.CANCEL_ACTION) {
                    shouldWaitForAction = false;
                    shouldRetryContactCreation = false;
                    continue;
                }
            }
        }

        shouldWaitForAction = true;
        const searchParams = new URLSearchParams(location.search);
        if (isOriginInvoicePage) {
            searchParams.set('contentType', bcModalContentTypes.INVOICE_CONTACT_SEARCH);
            searchParams.set('client', client);

            yield put(replace(resolveRoute(location.pathname, {}, {search: searchParams.toString()})));
            continue;
        }

        yield* closeModal(modalIds.BC_CONTACT_MODAL, {contentType: '', type: '', contactId: ''});
    }
};

export default contactCreationModalFlow;
