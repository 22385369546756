import {fork, put, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

const fetchServiceCaseDocuments = function* fetchServiceCaseDocuments({payload}) {
    const {serviceCaseId} = payload;
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    if (!serviceCaseId) return;

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.FETCH_SERVICE_CASE_DOCUMENTS_REQUEST,
        leaAssignmentFlowService.getServiceCaseDocuments,
        {serviceCaseId},
    );

    const responseAction = yield take([
        serviceCaseActionTypes.FETCH_SERVICE_CASE_DOCUMENTS_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.FETCH_SERVICE_CASE_DOCUMENTS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {serviceCaseDocumentsDTOs} = responseAction.payload.response;

        yield put({
            type: serviceCaseActionTypes.STORE_SERVICE_CASE_DOCUMENTS,
            payload: {
                serviceCaseDocumentsDTOs,
            },
        });
    }
};

export default fetchServiceCaseDocuments;
