import {take, select, fork} from 'redux-saga/effects';
import {Task} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as taskActionTypes from '../taskActionTypes';

const createTask = function* createTask({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');
    const {taskData} = payload;

    yield fork(
        fetchRequest,
        taskActionTypes.CREATE_TASK_REQUEST,
        ecsTaskManagementService.createTask,
        {
            taskDTO: Task.objectToDTO(taskData),
        },
    );

    yield take([
        taskActionTypes.CREATE_TASK_REQUEST_SUCCEEDED,
        taskActionTypes.CREATE_TASK_REQUEST_FAILED,
    ]);
};

export default createTask;
