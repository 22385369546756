import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useStyles, Modal, HighlightCircle, Button, ButtonPrimary} from '@ace-de/ui-components';
import {Icon, infoIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import * as invoiceActionTypes from '../invoiceActionTypes';

const InvoiceCorrectionModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, confirmInvoiceCorrection, declineInvoiceCorrection} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('invoice_correction_modal');

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape') declineInvoiceCorrection();
    }, [declineInvoiceCorrection]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            contentClassName={cx('global!ace-u-modal-content-size--xs')}
            className={cx([
                'global!ace-u-padding--left-64',
                'global!ace-u-padding--right-64',
            ])}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-full-width',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'ace-c-highlight-circle--primary-highlight',
                        'global!ace-u-margin--bottom-24',
                    ])}
                >
                    <Icon
                        icon={infoIcon}
                        className={cx('ace-c-icon--xl', 'global!ace-c-icon--color-highlight')}
                    />
                </HighlightCircle>
                <p className={cx('global!ace-u-typography--variant-h2', 'global!ace-u-margin--bottom-16')}>
                    {translateModal('title.are_you_sure')}
                </p>
                <p className={cx('global!ace-u-margin--bottom-16', 'global!ace-u-typography--align-center')}>
                    {translateModal('body_text.invoice_correction')}
                </p>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-row',
                        'global!ace-u-flex--justify-center',
                        'global!ace-u-full-width',
                    ])}
                >
                    <Button
                        className={cx('global!ace-u-margin--right-24')}
                        onClick={declineInvoiceCorrection}
                    >
                        {translateModal('button_label.back')}
                    </Button>
                    <ButtonPrimary
                        onClick={confirmInvoiceCorrection}
                    >
                        {translateModal('button_label.correction')}
                    </ButtonPrimary>
                </div>
            </div>
        </Modal>
    );
};

InvoiceCorrectionModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    confirmInvoiceCorrection: PropTypes.func.isRequired,
    declineInvoiceCorrection: PropTypes.func.isRequired,
};

InvoiceCorrectionModal.defaultProps = {
    hasBackdrop: true,
};

const mapDispatchToProps = dispatch => {
    return {
        confirmInvoiceCorrection: payload => dispatch({
            type: invoiceActionTypes.CONFIRM_INVOICE_CORRECTION,
            payload,
        }),
        declineInvoiceCorrection: () => dispatch({
            type: invoiceActionTypes.DECLINE_INVOICE_CORRECTION,
        }),
    };
};

export default connect(null, mapDispatchToProps)(InvoiceCorrectionModal);
