import React, {useState, useEffect, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {matchPath, withRouter, resolveRoute} from '@computerrock/formation-router';
import * as memberActionTypes from './memberActionTypes';
import MemberSearchContext from './MemberSearchContext';
import config from '../config';

/**
 * Member search context provider
 */
const MemberSearchProvider = props => {
    const {children, history, location} = props;
    const {searchResultsRoute, minimumSearchQueryLength} = props;
    const {searchMembers, resetSearch} = props;
    const {searchResults, searchResultsCount} = props;
    const {searchResultsPrivate, searchResultsCountPrivate} = props;
    const {searchResultsCorporate, searchResultsCountCorporate} = props;
    const {areSearchResultsValid, markSearchResultsAsValid} = props;
    const searchMembersDebounced = useMemo(() => debounce(searchMembers, 250), [searchMembers]);

    // state
    const matchedPath = matchPath(location.pathname, {path: searchResultsRoute});
    const isSearchRouteActive = !!matchedPath;
    const [searchQueryString, setSearchQueryString] = useState(null);
    const [pageSize, setPageSize] = useState(0);
    const [searchResultsPage, setSearchResultsPage] = useState(null);
    const [searchResultsPagePrivate, setSearchResultsPagePrivate] = useState(null);
    const [searchResultsPageCorporate, setSearchResultsPageCorporate] = useState(null);

    /**
     * Set initial search parameters on route change (page refresh)
     */
    useEffect(() => {
        if (searchQueryString !== null || !matchedPath) return;

        const searchQueryParams = new URLSearchParams(location.search);
        if (searchQueryParams.has('searchQuery')) setSearchQueryString(searchQueryParams.get('searchQuery'));
        if (searchQueryParams.has('size')) setPageSize(searchQueryParams.get('size'));
        if (searchQueryParams.has('page')) setSearchResultsPage(searchQueryParams.get('page'));
        if (searchQueryParams.has('pagePrivate')) setSearchResultsPage(searchQueryParams.get('pagePrivate'));
        if (searchQueryParams.has('pageCorporate')) setSearchResultsPage(searchQueryParams.get('pageCorporate'));
    }, [searchQueryString, matchedPath, location, setSearchQueryString, setSearchResultsPage, setPageSize]);

    /**
     * On searchQueryString change:
     *  - if not on search results screen, executes search;
     *  - if on search results screen, navigates to search results screen with
     *    new query. Loader will execute search.
     */
    useEffect(() => {
        if (searchQueryString === null) return;

        if (searchQueryString === '') {
            setSearchResultsPage(0);
            resetSearch();
            markSearchResultsAsValid({
                areSearchResultsValid: false,
            });
        }

        const searchQueryParams = new URLSearchParams();
        searchQueryParams.append('searchQuery', searchQueryString);
        searchQueryParams.append('size', pageSize ? `${pageSize}` : `${config.DEFAULT_PAGE_SIZE}`);
        if (searchResultsPagePrivate !== null) searchQueryParams.append('pagePrivate', `${searchResultsPagePrivate}`);
        if (searchResultsPageCorporate !== null) searchQueryParams.append('pageCorporate', `${searchResultsPageCorporate}`);

        if (!isSearchRouteActive
            && searchQueryString.length >= minimumSearchQueryLength) {
            searchMembersDebounced({searchQueryParams});
        }

        if (isSearchRouteActive
            && searchQueryString.length >= minimumSearchQueryLength
            && searchResultsRoute) {
            history.push(resolveRoute(
                searchResultsRoute,
                {},
                {search: searchQueryParams.toString()},
            ));
        }
    }, [
        history,
        searchMembersDebounced,
        searchResultsRoute,
        isSearchRouteActive,
        resetSearch,
        markSearchResultsAsValid,
        searchQueryString,
        minimumSearchQueryLength,
        pageSize,
        searchResultsPage,
        searchResultsPagePrivate,
        searchResultsPageCorporate,
    ]);

    /**
     * Navigate to search result screen
     */
    const openSearchResultsScreen = useCallback(() => {
        if (searchResultsRoute && searchQueryString.length >= minimumSearchQueryLength) {
            const searchQueryParams = new URLSearchParams();
            searchQueryParams.append('searchQuery', searchQueryString);
            history.push(resolveRoute(
                searchResultsRoute,
                {},
                {search: searchQueryParams.toString()},
            ));
        }
    }, [history, searchQueryString, searchResultsRoute, minimumSearchQueryLength]);

    return (
        <MemberSearchContext.Provider
            value={{
                searchQueryString,
                setSearchQueryString,
                isSearchRouteActive,
                openSearchResultsScreen,
                areSearchResultsValid,
                searchResults,
                searchResultsCount,
                setSearchResultsPage,
                searchResultsPrivate,
                searchResultsCountPrivate,
                setSearchResultsPagePrivate,
                searchResultsCorporate,
                searchResultsCountCorporate,
                setSearchResultsPageCorporate,
            }}
        >
            {children}
        </MemberSearchContext.Provider>
    );
};

MemberSearchProvider.propTypes = {
    searchResults: PropTypes.array,
    searchResultsCount: PropTypes.number,
    searchResultsPrivate: PropTypes.array,
    searchResultsCountPrivate: PropTypes.number,
    searchResultsCorporate: PropTypes.array,
    searchResultsCountCorporate: PropTypes.number,
    searchMembers: PropTypes.func.isRequired,
    history: PropTypes.object,
    location: PropTypes.object,
    searchResultsRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    minimumSearchQueryLength: PropTypes.number,
    resetSearch: PropTypes.func.isRequired,
    areSearchResultsValid: PropTypes.bool.isRequired,
    markSearchResultsAsValid: PropTypes.func.isRequired,
};

MemberSearchProvider.defaultProps = {
    searchResults: [],
    searchResultsCount: 0,
    searchResultsPrivate: [],
    searchResultsCountPrivate: 0,
    searchResultsCorporate: [],
    searchResultsCountCorporate: 0,
    history: null,
    location: null,
    searchResultsRoute: null,
    minimumSearchQueryLength: 3,
};

const mapStateToProps = state => ({
    searchResults: state.members.memberSearchResults,
    searchResultsCount: state.members.memberSearchResultsCount,
    searchResultsPrivate: state.members.memberSearchResultsPrivate,
    searchResultsCountPrivate: state.members.memberSearchResultsCountPrivate,
    searchResultsCorporate: state.members.memberSearchResultsCorporate,
    searchResultsCountCorporate: state.members.memberSearchResultsCountCorporate,
    areSearchResultsValid: state.members.areSearchResultsValid,
});

const mapDispatchToProps = dispatch => ({
    searchMembers: payload => dispatch({
        type: memberActionTypes.SEARCH_MEMBERS,
        payload,
    }),
    resetSearch: () => dispatch({
        type: memberActionTypes.STORE_MEMBER_SEARCH_RESULTS,
        payload: {
            memberSearchResults: [],
            memberSearchResultsCount: 0,
            memberSearchResultsPrivate: [],
            memberSearchResultsCountPrivate: 0,
            memberSearchResultsCorporate: [],
            memberSearchResultsCountCorporate: 0,
        },
    }),
    markSearchResultsAsValid: payload => dispatch({
        type: memberActionTypes.MARK_SEARCH_RESULTS_AS_VALID,
        payload,
    }),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(MemberSearchProvider));
