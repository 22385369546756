import {fork, put, select, take} from 'redux-saga/effects';
import {persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contactActionTypes from '../contactActionTypes';
import config from '../../config';

const searchContacts = function* searchContacts() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    while (true) {
        const {payload} = yield take(contactActionTypes.SEARCH_CONTACTS);
        const {searchQueryParams} = payload;

        if (!searchQueryParams) continue;

        if (searchQueryParams.get('modal')) searchQueryParams.delete('modal');

        yield put({
            type: contactActionTypes.SET_CONTACTS_SEARCH_PERSISTENCE_STATE,
            payload: {persistenceState: persistenceStates.PENDING},
        });
        yield put({
            type: contactActionTypes.RESET_BC_ERROR_MESSAGE,
            payload: null,
        });

        yield fork(
            fetchRequest,
            contactActionTypes.GET_CONTACTS_SEARCH_RESULTS_REQUEST,
            leaAssignmentFlowService.getContacts,
            {searchQueryParams},
        );

        const responseAction = yield take([
            contactActionTypes.GET_CONTACTS_SEARCH_RESULTS_REQUEST_SUCCEEDED,
            contactActionTypes.GET_CONTACTS_SEARCH_RESULTS_REQUEST_FAILED,
        ]);
        if (responseAction.error) {
            yield put({
                type: contactActionTypes.STORE_CONTACTS_SEARCH_ERROR,
                payload: {isContactsSearchError: true},
            });

            if (responseAction.payload?.response?.status === config.STATUS_CODE_SERVICE_UNAVAILABLE) {
                yield put({
                    type: contactActionTypes.SET_BC_ERROR_MESSAGE,
                    payload: {errorDescription: JSON.parse(responseAction.payload.message)},
                });
            }
        }

        if (!responseAction.error) {
            const {payload: searchResultsPayload} = responseAction;
            const {contactDTOs, totalCount} = searchResultsPayload.response;

            yield put({
                type: contactActionTypes.STORE_CONTACTS_SEARCH_RESULTS,
                payload: {contactDTOs, totalCount},
            });

            yield put({
                type: contactActionTypes.STORE_CONTACTS_SEARCH_ERROR,
                payload: {isContactsSearchError: false},
            });
        }
        yield put({
            type: contactActionTypes.SET_CONTACTS_SEARCH_PERSISTENCE_STATE,
            payload: {persistenceState: persistenceStates.READY},
        });
    }
};

export default searchContacts;
