import {take, put, fork, select} from 'redux-saga/effects';
import {Invoice, alfInvoiceStatusTypes} from '@ace-de/eua-entity-types';
import {resolveRoute, push} from '@computerrock/formation-router';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as communicationActionTypes from '../communicationActionTypes';
import * as invoiceActionTypes from '../../invoices/invoiceActionTypes';
import routePaths from '../../routePaths';

const redirectToInvoicePageFlow = function* redirectToInvoicePageFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(communicationActionTypes.INITIATE_REDIRECT_TO_INVOICE_PAGE);
        const {invoiceId} = payload;
        if (!invoiceId) continue;

        const {invoices} = yield select(state => state.invoices);
        let invoice = invoices[invoiceId];

        if (!invoice) {
            yield fork(
                fetchRequest,
                invoiceActionTypes.FETCH_INVOICE_REQUEST,
                leaAssignmentFlowService.getInvoice,
                {invoiceId},
            );

            const fetchInvoiceResponseAction = yield take([
                invoiceActionTypes.FETCH_INVOICE_REQUEST_SUCCEEDED,
                invoiceActionTypes.FETCH_INVOICE_REQUEST_FAILED,
            ]);

            if (!fetchInvoiceResponseAction.error) {
                const {response} = fetchInvoiceResponseAction.payload;
                const {invoiceDTO} = response;
                invoice = new Invoice().fromDTO(invoiceDTO);
            }

            if (fetchInvoiceResponseAction.error) {
                continue;
            }
        }

        const {status} = invoice;
        if (status === alfInvoiceStatusTypes.OPEN) continue;

        if (status === alfInvoiceStatusTypes.IN_CREATION) {
            yield put(push(resolveRoute(
                routePaths.INVOICES,
                {
                    serviceCaseId: invoice.serviceCaseId,
                    invoiceId: invoice.id,
                },
            )));
            continue;
        }

        yield put(push(resolveRoute(
            routePaths.INVOICE_OVERVIEW,
            {
                serviceCaseId: invoice.serviceCaseId,
                invoiceId: invoice.id,
            },
        )));
    }
};
export default redirectToInvoicePageFlow;
