import React from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {Form, InputField, Option, SelectField, useStyles} from '@ace-de/ui-components';
import {Icon, photoIcon, deleteIcon} from '@ace-de/ui-components/icons';
import {alfAttachmentTypes} from '@ace-de/eua-entity-types';

const InvoiceDocumentItem = ({fileAsset, formValues, deleteFile}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('invoice_document_modal');

    if (!fileAsset) return null;

    return (
        <div
            className={cx([
                'global!ace-u-margin--bottom-24',
                'global!ace-u-width--full',
                'global!ace-u-grid',
            ])}
        >
            <Form name={fileAsset.id}>
                <div
                    className={cx([
                        'global!ace-u-padding--16',
                        'global!ace-u-margin--top-16',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--justify-space-between',
                        'global!ace-u-grid-column--span-4',
                        'global!ace-u-box-skin--pending',
                    ])}
                >
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <Icon icon={photoIcon} className={cx('global!ace-u-margin--right-8')} />
                        {fileAsset.fileName}
                    </div>
                    {deleteFile && (
                    <Icon
                        onClick={() => deleteFile(fileAsset)}
                        icon={deleteIcon}
                        className={cx([
                            'ace-c-icon--xs',
                            'ace-c-icon--color-medium-emphasis',
                            'global!ace-u-flex--justify-self-end',
                        ])}
                    />
                    )}
                </div>
                <InputField
                    name="description"
                    className={cx('global!ace-u-grid-column--span-4')}
                    label={`${translateModal('input_label.name')}*`}
                    value={formValues?.description || fileAsset.description || fileAsset.fileName?.split('.')[0]}
                />
                <SelectField
                    name="attachmentType"
                    className={cx('global!ace-u-grid-column--span-4')}
                    label={translateModal('input_label.type')}
                    value={formValues?.attachmentType || fileAsset.attachmentType}
                >
                    {Object.values(alfAttachmentTypes).filter(fileType => (
                        (fileType !== alfAttachmentTypes.ELLA_SOURCE && fileType !== alfAttachmentTypes.MIA_SOURCE)
                        || (fileType === alfAttachmentTypes.ELLA_SOURCE
                            && fileAsset.attachmentType === alfAttachmentTypes.ELLA_SOURCE)
                        || (fileType === alfAttachmentTypes.MIA_SOURCE
                        && fileAsset.attachmentType === alfAttachmentTypes.MIA_SOURCE)
                    )).map(fileType => (
                        <Option
                            key={fileType}
                            name={fileType}
                            value={fileType}
                        >
                            {translate(`global.document_type.${fileType.toLowerCase()}`)}
                        </Option>
                    ))}
                </SelectField>
            </Form>
        </div>
    );
};

InvoiceDocumentItem.propTypes = {
    fileAsset: PropTypes.object,
    formValues: PropTypes.object,
    deleteFile: PropTypes.func,
};

InvoiceDocumentItem.defaultProps = {
    fileAsset: null,
    formValues: null,
    deleteFile: null,
};

export default InvoiceDocumentItem;
