/**
 * Modal ids
 */
export default {
    INVOICE_CONTACT_SEARCH: 'invoice-contact-search',
    CONTACT_DATA: 'contact-data',
    CONTACT_ACCOUNT_PARTY_DATA: 'contact-account-party-data',
    INVOICE_DOCUMENT_MODAL: 'invoice-document-modal',
    DELETE_MODAL: 'delete-modal',
    INVOICE_LINE_DATA: 'invoice-line-data',
    INVOICE_SUCCESS_MODAL: 'invoice-success-modal',
    INA_CHECK_LOADER_MODAL: 'ina-check-loader-modal',
    INA_CHECK_RESPONSE_MODAL: 'ina-check-response-modal',
    LOADER: 'LOADER',
    BC_ACTION_FAILED_MODAL: 'bc-action-failed-modal',
    VKR_INVOICE_CREATION_MODAL: 'vkr-invoice-creation-modal',
    INVOICE_DECLINE_MODAL: 'invoice-decline-modal',
    SEND_MESSAGE_MODAL: 'send-message-modal',
    INVOICE_APPROVAL_REASON_MODAL: 'invoice-approval-reason-modal',
    SERVICE_CASE_DOCUMENT_UPLOAD_MODAL: 'service-case-document-upload-modal',
    UPLOAD_REPLY_MODAL: 'upload-reply-modal',
    TASK_DATA_MODAL: 'task-data-modal',
    INVOICE_CORRECTION_MODAL: 'invoice-correction-modal',
    BC_CONTACT_MODAL: 'bc-contact-modal',
    CONFIRM_VEHICLE_SELECTION_MODAL: 'confirm-vehicle-selection-modal',
    VEHICLE_DATA_MODAL: 'vehicle-data-modal',
    ERROR_MESSAGE_MODAL: 'error-message-modal',
    CLOSING_ESTIMATION_MODAL: 'closing-estimation-modal',
    SUCCESS_MESSAGE_MODAL: 'success-message-modal',
    WARNING_MESSAGE_MODAL: 'warning-message-modal',
    EXPANDED_MAP_MODAL: 'expanded-map-modal',
};
