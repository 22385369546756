import {fork, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceActionTypes from '../invoiceActionTypes';
import modalIds from '../../modalIds';
import deleteModalLocationTypes from '../../application/modals/deleteModalLocationTypes';

const invoiceLineDeleteFlow = function* invoiceLineDeleteFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(invoiceActionTypes.INITIATE_INVOICE_LINE_DELETE_FLOW);
        const {invoiceId, lineNo} = payload;

        if (!lineNo || !invoiceId) continue;

        yield* openModal(modalIds.DELETE_MODAL, {type: deleteModalLocationTypes.INVOICE_LINE});

        const chosenModalAction = yield take([
            invoiceActionTypes.DECLINE_DELETE,
            invoiceActionTypes.CONFIRM_INVOICE_LINE_DELETE,
        ]);

        if (chosenModalAction.type === invoiceActionTypes.CONFIRM_INVOICE_LINE_DELETE) {
            yield fork(fetchRequest,
                invoiceActionTypes.DELETE_INVOICE_LINE_REQUEST,
                leaAssignmentFlowService.deleteInvoiceLine,
                {invoiceId, lineNo});

            yield take([
                invoiceActionTypes.DELETE_INVOICE_LINE_REQUEST_SUCCEEDED,
                invoiceActionTypes.DELETE_INVOICE_LINE_REQUEST_FAILED,
            ]);
        }

        yield* closeModal(modalIds.DELETE_MODAL, {type: deleteModalLocationTypes.INVOICE_LINE});
    }
};

export default invoiceLineDeleteFlow;
