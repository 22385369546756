import React, {useMemo, useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {ArcGISMiniMapWidget, createMarkerGraphic, markerVehiclePNG, useArcGISMap} from '@ace-de/eua-arcgis-map';
import {useStyles, Panel} from '@ace-de/ui-components';
import * as serviceCaseSelectors from '../serviceCaseSelectors';
import config from '../../config';

const DamageLocationPanel = ({serviceCase}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('sc_base_data_tab');
    const arcGISMap = useArcGISMap('sc-base-data-damage-location');
    const {damage} = serviceCase;

    const mapCenter = useMemo(() => {
        if (damage?.location?.coordinates) {
            return [damage?.location?.coordinates.longitude, damage?.location?.coordinates.latitude];
        }

        return config.GERMANY_CENTER_COORDINATES;
    }, [damage]);

    useEffect(() => {
        if (!arcGISMap) return;

        if (mapCenter) {
            const [longitude, latitude] = mapCenter;
            arcGISMap.setGraphics({
                graphics: [
                    ...(latitude && longitude
                        ? [createMarkerGraphic({
                            id: 'damageLocation',
                            longitude: longitude,
                            latitude: latitude,
                            icon: markerVehiclePNG,
                        })] : []),
                ],
            });
        }
    }, [damage, arcGISMap, mapCenter]);

    if (!damage) return null;
    const {location} = damage;

    return (
        <Panel
            title={translateTab('damage_location_panel.title')}
            className={cx([
                'ace-c-panel--full-bleed-content',
            ])}
        >
            <ArcGISMiniMapWidget
                name="sc-base-data-damage-location"
                tempArcGISMapId={config.TEMP_ARCGIS_MAP_ID}
            />
            {location ? (
                <div className={cx('global!ace-u-margin--32')}>
                    <p className={cx('global!ace-u-typography--variant-body-bold')}>
                        {location.name}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-body-bold')}>
                        {`${location.address?.street ? `${location.address?.street}, ` : ''}${location.address?.postCode} ${location.address?.city}`}
                        &nbsp;
                        {location.address?.country ? translate(`global.country.${snakeCase(location.address?.country)}`) : ''}
                    </p>
                    <p className={cx('global!ace-u-typography')}>
                        {location.coordinates?.getDMSCoordinates() || '-'}
                    </p>
                </div>
            ) : null}
        </Panel>
    );
};

DamageLocationPanel.propTypes = {
    serviceCase: PropTypes.object,
};

DamageLocationPanel.defaultProps = {
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: getServiceCase(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(DamageLocationPanel));
