import {produce} from 'immer';
import {persistenceStates} from '@ace-de/eua-entity-types';
import * as dashboardActionTypes from './dashboardActionTypes';

const initialState = {
    reporterTasksAmount: 0,
    invoicesInStatusManualCheck: 0,
    invoicesInStatusInCreation: 0,
    invoicesEKR: 0,
    invoicesWithSubStatusEllaInquiry: 0,
    invoicesWithOpenEstimations: 0,
    tasksPersistenceState: persistenceStates.PENDING,
    invoicesPersistenceState: persistenceStates.PENDING,
};

/**
 * Dashboard reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const dashboardReducer = produce((draft, action) => {
    switch (action.type) {
        case dashboardActionTypes.STORE_REPORTER_INVOICES_AMOUNT: {
            const {reporterInvoiceAmounts} = action.payload;
            const {invoicesInStatusManualCheck, invoicesInStatusInCreation, invoicesEKR} = reporterInvoiceAmounts;
            const {invoicesWithSubStatusEllaInquiry, invoicesWithOpenEstimations} = reporterInvoiceAmounts;
            draft.invoicesInStatusManualCheck = invoicesInStatusManualCheck;
            draft.invoicesInStatusInCreation = invoicesInStatusInCreation;
            draft.invoicesEKR = invoicesEKR;
            draft.invoicesWithSubStatusEllaInquiry = invoicesWithSubStatusEllaInquiry;
            draft.invoicesWithOpenEstimations = invoicesWithOpenEstimations;
            break;
        }

        case dashboardActionTypes.STORE_REPORTER_TASKS_AMOUNT: {
            const {reporterTasksAmount} = action.payload;
            draft.reporterTasksAmount = reporterTasksAmount;
            break;
        }

        case dashboardActionTypes.SET_REPORTER_INVOICES_PERSISTENCE_STATE: {
            const {newPersistenceState} = action.payload;
            draft.invoicesPersistenceState = newPersistenceState;
            break;
        }

        case dashboardActionTypes.SET_REPORTER_TASKS_PERSISTENCE_STATE: {
            const {newPersistenceState} = action.payload;
            draft.tasksPersistenceState = newPersistenceState;
            break;
        }

        default:
        //  no-op
    }
    return draft;
}, initialState);

export default dashboardReducer;
