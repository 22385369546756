import {take} from 'redux-saga/effects';
import {resolveRoute} from '@computerrock/formation-router';
import updateInvoiceStatus from './updateInvoiceStatus';
import * as invoiceActionTypes from '../invoiceActionTypes';

/**
 * Update Invoice status flow
 */
const initiateUpdateInvoiceStatusFlow = function* initiateUpdateInvoiceStatusFlow() {
    while (true) {
        const {payload} = yield take(invoiceActionTypes.UPDATE_INVOICE_STATUS);
        const {invoice, invoiceStatusData, routePath, history} = payload;

        yield* updateInvoiceStatus({
            caller: invoiceActionTypes.UPDATE_INVOICE_STATUS,
            invoiceId: invoice.id,
            invoiceStatusData,
        });

        if (routePath) {
            history.push(resolveRoute(routePath,
                {serviceCaseId: invoice.serviceCaseId, invoiceId: invoice.id},
                {search: ''}));
        }
    }
};

export default initiateUpdateInvoiceStatusFlow;
