export const validateDriverAndOwnerData = data => {
    if (!data) return false;

    const {isIdenticalToMember, address, memberRelationship, addressIdenticalToMember} = data;
    const {firstName, lastName} = data;

    if (isIdenticalToMember) return true;

    if (typeof isIdenticalToMember === 'boolean'
        && !isIdenticalToMember
        && (!firstName || !lastName || !memberRelationship)) {
        return false;
    }

    return !(typeof isIdenticalToMember === 'boolean'
        && !isIdenticalToMember
        && !addressIdenticalToMember
        && (!address || !address.street || !address.postCode || !address.city));
};
