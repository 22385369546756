import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {useStyles, Panel, DataRow} from '@ace-de/ui-components';
import * as serviceCaseSelectors from '../serviceCaseSelectors';

const DriverAndOwnerPanel = ({serviceCase}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('sc_base_data_tab');

    if (!serviceCase) return null;
    const {vehicle} = serviceCase;

    return (
        <Panel
            title={(
                <div className={cx('global!ace-u-two-column-layout')}>
                    <div>{translateTab('driver_and_owner_panel.owner_title')}</div>
                    <div>{translateTab('driver_and_owner_panel.driver_title')}</div>
                </div>
            )}
            hasTwoColumnHeader={true}
            qaIdent="driver-and-owner"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('driver_and_owner_panel_row_label.is_member')}
                        qaIdent="owner-is-member"
                    >
                        {typeof vehicle?.owner?.isIdenticalToMember === 'boolean' ? (
                            vehicle?.owner?.isIdenticalToMember
                                ? translateTab('driver_and_owner_panel_row_label.is_member_yes')
                                : translateTab('driver_and_owner_panel_row_label.is_member_no')
                        ) : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('driver_and_owner_panel_row_label.relationship_to_member')}
                        qaIdent="owner-relationship-to-member"
                    >
                        {vehicle?.owner?.memberRelationship
                            ? translate(`global.member_relationship_type.${vehicle.owner.memberRelationship.toLowerCase()}`)
                            : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('driver_and_owner_panel_row_label.first_name')}
                        qaIdent="owner-first-name"
                    >
                        {vehicle?.owner?.name ? `${vehicle.owner.name.split(' ')[0]}` : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('driver_and_owner_panel_row_label.last_name')}
                        qaIdent="owner-last-name"
                    >
                        {vehicle?.owner?.name ? `${vehicle.owner.name.split(' ')[1]}` : '-'}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('driver_and_owner_panel_row_label.is_member')}
                        qaIdent="driver-is-member"
                    >
                        {typeof vehicle?.driver?.isIdenticalToMember === 'boolean' ? (
                            vehicle?.driver?.isIdenticalToMember
                                ? translateTab('driver_and_owner_panel_row_label.is_member_yes')
                                : translateTab('driver_and_owner_panel_row_label.is_member_no')
                        ) : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('driver_and_owner_panel_row_label.relationship_to_member')}
                        qaIdent="driver-relationship-to-member"
                    >
                        {vehicle?.driver?.memberRelationship
                            ? translate(`global.member_relationship_type.${vehicle?.driver.memberRelationship.toLowerCase()}`)
                            : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('driver_and_owner_panel_row_label.first_name')}
                        qaIdent="driver-first-name"
                    >
                        {vehicle?.driver?.name ? `${vehicle?.driver?.name.split(' ')[0]}` : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('driver_and_owner_panel_row_label.last_name')}
                        qaIdent="driver-last-name"
                    >
                        {vehicle?.driver?.name ? `${vehicle?.driver?.name.split(' ')[1]}` : '-'}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

DriverAndOwnerPanel.propTypes = {
    serviceCase: PropTypes.object,
};

DriverAndOwnerPanel.defaultProps = {
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: getServiceCase(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(DriverAndOwnerPanel));
