import {put} from 'redux-saga/effects';
import {alfClientTypes} from '@ace-de/eua-entity-types';
import * as memberActionTypes from '../memberActionTypes';

const loadMemberBCDetails = function* loadMemberBCDetails({payload}) {
    const {match} = payload;
    const {membershipNo} = match.params;
    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('ids', membershipNo);
    searchQueryParams.append('client', alfClientTypes.WACE);

    yield put({
        type: memberActionTypes.FETCH_MEMBER_BC_DETAILS,
        payload: {searchQueryParams},
    });
};

export default loadMemberBCDetails;
