import {produce} from 'immer';
import {ECSHistory, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import * as caseLogActionTypes from './caseLogActionTypes';

const initialState = {
    caseLogFilterResults: [],
    caseLogFilterResultsCount: 0,
    caseLogFilterResultsPage: 0,
    caseLogServices: [],
};

/**
 * Case log reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const caseLogReducer = produce((draft, action) => {
    switch (action.type) {
        case caseLogActionTypes.STORE_CASE_LOGS: {
            const {caseLogFilterResults, caseLogFilterResultsCount, pageNumber} = action.payload;
            draft.caseLogFilterResults = caseLogFilterResults.map(caseLogDTO => {
                return new ECSHistory().fromDTO(caseLogDTO);
            });
            draft.caseLogFilterResultsCount = typeof (caseLogFilterResultsCount) === 'number'
                ? caseLogFilterResultsCount
                : 0;
            draft.caseLogFilterResultsPage = pageNumber || 0;
            break;
        }

        case caseLogActionTypes.STORE_CASE_LOG_SERVICES: {
            const {serviceTypes} = action.payload;

            // If serviceTypes contains UNKNOWN service type that means that serviceType is not selected yet on ECS
            // and in that case we want to show VEHICLE filter. As soon as some serviceType is selected on ECS,
            // we won't have UNKNOWN value anymore, hence we don't need to show VEHICLE filter.
            const hasUnknownServiceType = serviceTypes.includes('UNKNOWN');
            draft.caseLogServices = serviceTypes.filter(serviceType => {
                if (hasUnknownServiceType && serviceType === efServiceAssignmentTypes.VEHICLE) return serviceType;
                return serviceType !== 'UNKNOWN' && serviceType !== efServiceAssignmentTypes.VEHICLE;
            });
            break;
        }

        default:
        //     no-op
    }
    return draft;
}, initialState);

export default caseLogReducer;
