import {fork, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as invoiceActionTypes from '../invoiceActionTypes';

const downloadInvoiceDocument = function* downloadInvoiceDocument() {
    const {serviceManager} = yield select(state => state.application);
    const alfFileAssetsService = serviceManager.loadService('alfFileAssetsService');

    while (true) {
        const {payload} = yield take(invoiceActionTypes.INITIATE_INVOICE_DOCUMENT_DOWNLOAD_FLOW);
        const {fileName, url} = payload;

        yield fork(
            fetchRequest,
            invoiceActionTypes.DOWNLOAD_INVOICE_DOCUMENT_REQUEST,
            alfFileAssetsService.downloadFile,
            {
                fileName,
                url,
                shouldOpenFile: true,
            },
        );
        yield take([
            invoiceActionTypes.DOWNLOAD_INVOICE_DOCUMENT_REQUEST_SUCCEEDED,
            invoiceActionTypes.DOWNLOAD_INVOICE_DOCUMENT_REQUEST_FAILED,
        ]);
    }
};

export default downloadInvoiceDocument;
