/**
 * * Command action types
 * */
export const SEARCH_CONTACTS = '[contacts] SEARCH_CONTACTS';
export const INITIATE_CREATE_CONTACT_MODAL_FLOW = '[contacts] INITIATE_CREATE_CONTACT_MODAL_FLOW';
export const CONFIRM_CREATE_CONTACT = '[contacts] CONFIRM_CREATE_CONTACT';
export const DECLINE_CREATE_CONTACT = '[contacts] DECLINE_CREATE_CONTACT';
export const RETRY_CONTACT_CREATION = '[contacts] RETRY_CONTACT_CREATION';
export const CANCEL_ACTION = '[contacts] CANCEL_ACTION';
export const RESET_CONTACT_SEARCH = '[contacts] RESET_CONTACT_SEARCH';
export const INITIATE_CREATE_ACCOUNT_PARTY_FLOW = '[contacts] INITIATE_CREATE_ACCOUNT_PARTY_FLOW';
export const INITIATE_CREATE_ACCOUNT_PARTY_MODAL_FLOW = '[contacts] INITIATE_CREATE_ACCOUNT_PARTY_MODAL_FLOW';
export const DECLINE_CREATE_ACCOUNT_PARTY = '[contacts] DECLINE_CREATE_ACCOUNT_PARTY';
export const CONFIRM_CREATE_ACCOUNT_PARTY = '[contacts] CONFIRM_CREATE_ACCOUNT_PARTY';
export const RETRY_ACCOUNT_PARTY_CREATION = '[contacts] RETRY_ACCOUNT_PARTY_CREATION';
export const INITIATE_EDIT_CONTACT_MODAL_FLOW = '[contacts] INITIATE_EDIT_CONTACT_MODAL_FLOW';
export const DECLINE_EDIT_CONTACT = '[contacts] DECLINE_EDIT_CONTACT';
export const CONFIRM_EDIT_CONTACT = '[contacts] CONFIRM_EDIT_CONTACT';
export const RETRY_EDIT_CONTACT = '[contacts] RETRY_EDIT_CONTACT';
export const INITIATE_EDIT_ACCOUNT_PARTY_FLOW = '[contacts] INITIATE_EDIT_ACCOUNT_PARTY_FLOW';
export const DECLINE_EDIT_ACCOUNT_PARTY = '[contacts] DECLINE_EDIT_ACCOUNT_PARTY';
export const CONFIRM_EDIT_ACCOUNT_PARTY = '[contacts] CONFIRM_EDIT_ACCOUNT_PARTY';
export const RETRY_EDIT_ACCOUNT_PARTY = '[contacts] RETRY_EDIT_ACCOUNT_PARTY';
export const FETCH_ACCOUNT_PARTY = '[contacts] FETCH_ACCOUNT_PARTY';
export const DECLINE_BC_CONTACT_MODAL_FLOW = '[contacts] DECLINE_BC_CONTACT_MODAL_FLOW';
export const INITIATE_CONTACT_COMMUNICATION_MODAL_FLOW = '[contacts] INITIATE_CONTACT_COMMUNICATION_MODAL_FLOW';
export const RELOAD_CREATE_ACCOUNT_MODAL_FLOW = '[contacts] RELOAD_CREATE_ACCOUNT_MODAL_FLOW';
export const RETURN_TO_CONTACT_SEARCH = '[contacts] RETURN_TO_CONTACT_SEARCH';
export const SEARCH_CONTACT_ADDRESS_GEOLOCATION = '[contacts] SEARCH_CONTACT_ADDRESS_GEOLOCATION';
export const SELECT_CONTACT_ADDRESS_GEOLOCATION = '[contacts] SELECT_CONTACT_ADDRESS_GEOLOCATION';

/**
 * Event action types
 */
export const GET_CONTACTS_SEARCH_RESULTS_REQUEST = '[contacts] GET_CONTACTS_SEARCH_RESULTS_REQUEST';
export const GET_CONTACTS_SEARCH_RESULTS_REQUEST_SENT = '[contacts] GET_CONTACTS_SEARCH_RESULTS_REQUEST_SENT';
export const GET_CONTACTS_SEARCH_RESULTS_REQUEST_SUCCEEDED = '[contacts] GET_CONTACTS_SEARCH_RESULTS_REQUEST_SUCCEEDED';
export const GET_CONTACTS_SEARCH_RESULTS_REQUEST_FAILED = '[contacts] GET_CONTACTS_SEARCH_RESULTS_REQUEST_FAILED';

export const CREATE_NEW_CONTACT_REQUEST = '[contacts] CREATE_NEW_CONTACT_REQUEST';
export const CREATE_NEW_CONTACT_REQUEST_SENT = '[contacts] CREATE_NEW_CONTACT_REQUEST_SENT';
export const CREATE_NEW_CONTACT_REQUEST_SUCCEEDED = '[contacts] CREATE_NEW_CONTACT_REQUEST_SUCCEEDED';
export const CREATE_NEW_CONTACT_REQUEST_FAILED = '[contacts] CREATE_NEW_CONTACT_REQUEST_FAILED';

export const CREATE_NEW_ACCOUNT_PARTY_REQUEST = '[contacts] CREATE_NEW_ACCOUNT_PARTY_REQUEST';
export const CREATE_NEW_ACCOUNT_PARTY_REQUEST_SENT = '[contacts] CREATE_NEW_ACCOUNT_PARTY_REQUEST_SENT';
export const CREATE_NEW_ACCOUNT_PARTY_REQUEST_FAILED = '[contacts] CREATE_NEW_ACCOUNT_PARTY_REQUEST_FAILED';
export const CREATE_NEW_ACCOUNT_PARTY_REQUEST_SUCCEEDED = '[contacts] CREATE_NEW_ACCOUNT_PARTY_REQUEST_SUCCEEDED';

export const UPDATE_CONTACT_REQUEST = '[contacts] UPDATE_CONTACT_REQUEST';
export const UPDATE_CONTACT_REQUEST_SENT = '[contacts] UPDATE_CONTACT_REQUEST_SENT';
export const UPDATE_CONTACT_REQUEST_FAILED = '[contacts] UPDATE_CONTACT_REQUEST_FAILED';
export const UPDATE_CONTACT_REQUEST_SUCCEEDED = '[contacts] UPDATE_CONTACT_REQUEST_SUCCEEDED';

export const UPDATE_ACCOUNT_PARTY_REQUEST = '[contacts] UPDATE_ACCOUNT_PARTY_REQUEST';
export const UPDATE_ACCOUNT_PARTY_REQUEST_SENT = '[contacts] UPDATE_ACCOUNT_PARTY_REQUEST_SENT';
export const UPDATE_ACCOUNT_PARTY_REQUEST_FAILED = '[contacts] UPDATE_ACCOUNT_PARTY_REQUEST_FAILED';
export const UPDATE_ACCOUNT_PARTY_REQUEST_SUCCEEDED = '[contacts] UPDATE_ACCOUNT_PARTY_REQUEST_SUCCEEDED';

export const FETCH_ACCOUNT_PARTY_REQUEST = '[contacts] FETCH_ACCOUNT_PARTY_REQUEST';
export const FETCH_ACCOUNT_PARTY_REQUEST_SENT = '[contacts] FETCH_ACCOUNT_PARTY_REQUEST_SENT';
export const FETCH_ACCOUNT_PARTY_REQUEST_SUCCEEDED = '[contacts] FETCH_ACCOUNT_PARTY_REQUEST_SUCCEEDED';
export const FETCH_ACCOUNT_PARTY_REQUEST_FAILED = '[contacts] FETCH_ACCOUNT_PARTY_REQUEST_FAILED';

export const SEARCH_CONTACT_ADDRESS_GEOLOCATION_REQUEST = '[contacts] SEARCH_CONTACT_ADDRESS_GEOLOCATION_REQUEST';
export const SEARCH_CONTACT_ADDRESS_GEOLOCATION_REQUEST_SENT = '[contacts] SEARCH_CONTACT_ADDRESS_GEOLOCATION_REQUEST_SENT';
export const SEARCH_CONTACT_ADDRESS_GEOLOCATION_REQUEST_SUCCEEDED = '[contacts] SEARCH_CONTACT_ADDRESS_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_CONTACT_ADDRESS_GEOLOCATION_REQUEST_FAILED = '[contacts] SEARCH_CONTACT_ADDRESS_GEOLOCATION_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_CONTACTS_SEARCH_RESULTS = '[contacts] STORE_CONTACTS_SEARCH_RESULTS';
export const STORE_CONTACTS_SEARCH_ERROR = '[contacts] STORE_CONTACTS_SEARCH_ERROR';
export const STORE_CONTACTS = '[contacts] STORE_CONTACTS';
export const STORE_ACCOUNT_PARTY = '[contacts] STORE_ACCOUNT_PARTY';
export const SET_BC_ERROR_MESSAGE = '[contacts] SET_BC_ERROR_MESSAGE';
export const RESET_BC_ERROR_MESSAGE = '[contacts] RESET_BC_ERROR_MESSAGE';
export const SET_CONTACTS_SEARCH_PERSISTENCE_STATE = '[contacts] SET_CONTACTS_SEARCH_PERSISTENCE_STATE';
export const SET_CONTACT_ADDRESS_CANDIDATES = '[contacts] SET_CONTACT_ADDRESS_CANDIDATES';
export const RESET_CONTACT_ADDRESS_CANDIDATES = '[contacts] RESET_CONTACT_ADDRESS_CANDIDATES';
export const SET_CONTACT_ADDRESS_CANDIDATES_SEARCH_ERROR = '[contacts] SET_CONTACT_ADDRESS_CANDIDATES_SEARCH_ERROR';
export const RESET_CONTACT_ADDRESS_CANDIDATES_SEARCH_ERROR = '[contacts] RESET_CONTACT_ADDRESS_CANDIDATES_SEARCH_ERROR';
