import {put} from 'redux-saga/effects';
import * as invoiceActionTypes from '../invoiceActionTypes';

const loadInvoiceRelevantLocations = function* loadRelevantInvoiceLocatins({payload}) {
    const {match} = payload;
    const {invoiceId} = match.params;

    yield put({
        type: invoiceActionTypes.INITIATE_INVOICE_RELEVANT_LOCATIONS_FLOW,
        payload: {invoiceId},
    });
};

export default loadInvoiceRelevantLocations;
