import React from 'react';
import PropTypes from 'prop-types';
import {useStyles, checkmarkIcon, Option, HighlightCircle, Icon, caseIcon} from '@ace-de/ui-components';
import {serviceIcons} from '../../invoices/ui-elements/serviceIcons';

const AssignmentTypeOption = ({value, isSelected, label, onKeyDown}) => {
    const {cx} = useStyles();
    const isValueServiceCase = value === 'SERVICE_CASE';

    if (!isValueServiceCase && !serviceIcons.hasOwnProperty(value)) return null;

    const handleOnKeyDown = event => {
        if (event.key === 'Enter' && typeof onKeyDown === 'function') onKeyDown(value);
    };

    return (
        <Option
            className={cx('global!ace-u-padding--left-0')}
            value={value}
            name={`option${value}`}
        >
            <div
                className={cx([
                    'global!ace-u-full-width',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-flex--align-center',
                ])}
                tabIndex={0}
                onKeyDown={handleOnKeyDown}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <HighlightCircle
                        className={cx([
                            'ace-c-highlight-circle--small',
                            'global!ace-u-margin--right-16',
                            'ace-c-highlight-circle--primary-highlight',
                        ])}
                    >
                        <Icon
                            icon={!isValueServiceCase ? serviceIcons[value]['icon'] : caseIcon}
                            className={cx([
                                'ace-c-icon--m',
                                'ace-c-icon--color-highlight',
                            ])}
                        />
                    </HighlightCircle>
                    <span className={cx('global!ace-u-typography--variant-body-medium')}>
                        {label}
                    </span>
                </div>
                {isSelected && (
                    <Icon icon={checkmarkIcon} className={cx('ace-c-icon--color-success')} />
                )}
            </div>
        </Option>
    );
};

AssignmentTypeOption.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string,
    isSelected: PropTypes.bool,
    onKeyDown: PropTypes.func,
};

AssignmentTypeOption.defaultProps = {
    label: '',
    isSelected: false,
    onKeyDown: null,
};

export default AssignmentTypeOption;
