import {select, take, fork, put} from 'redux-saga/effects';
import {InvoiceLine} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import updateInvoice from './updateInvoice';
import * as invoiceActionTypes from '../invoiceActionTypes';

/**
 * Create InvoiceLine
 */
const createInvoiceLine = function* createInvoiceLine({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    const {invoiceId, invoiceLineData, invoiceData, triggerINACheck, shouldUpdateInvoiceData} = payload;

    if (!invoiceId || !invoiceLineData) return;

    if (shouldUpdateInvoiceData && invoiceData) {
        yield* updateInvoice({
            caller: invoiceActionTypes.SUBMIT_INVOICE_BASIC_DATA_FORM,
            invoiceId,
            invoiceData,
            triggerINACheck,
        });
    }

    yield fork(fetchRequest, invoiceActionTypes.CREATE_INVOICE_LINE_REQUEST,
        leaAssignmentFlowService.createInvoiceLine,
        {
            invoiceId,
            invoiceLineDTO: InvoiceLine.objectToDTO(invoiceLineData),
        });

    const responseAction = yield take([
        invoiceActionTypes.CREATE_INVOICE_LINE_REQUEST_SUCCEEDED,
        invoiceActionTypes.CREATE_INVOICE_LINE_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {invoiceLineDTO} = response;

        yield put({
            type: invoiceActionTypes.STORE_INVOICE_LINES,
            payload: {invoiceLineDTO, invoiceId},
        });
    }
};

export default createInvoiceLine;
