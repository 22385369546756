import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useStyles, Modal, HighlightCircle, Button, ButtonPrimary} from '@ace-de/ui-components';
import {Icon, warningIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import * as invoiceActionTypes from '../invoiceActionTypes';

const ClosingEstimationModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, confirmClosingEstimation, declineClosingEstimation} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('closing_estimation_modal');

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape') declineClosingEstimation();
    }, [declineClosingEstimation]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            contentClassName={cx([
                'global!ace-u-padding--left-64',
                'global!ace-u-padding--right-64',
                'global!ace-u-modal-content-size--s',
            ])}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-full-width',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'ace-c-highlight-circle--primary-highlight',
                        'global!ace-u-margin--bottom-24',
                    ])}
                >
                    <Icon
                        icon={warningIcon}
                        className={cx(['ace-c-icon--xl', 'global!ace-c-icon--color-highlight'])}
                    />
                </HighlightCircle>
                <p className={cx('global!ace-u-typography--align-center', 'global!ace-u-typography--variant-h2', 'global!ace-u-margin--bottom-16')}>
                    {translateModal('modal_title.confirm_closing_estimation')}
                </p>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-row',
                        'global!ace-u-flex--justify-center',
                        'global!ace-u-full-width',
                    ])}
                >
                    <Button
                        name="buttonDeclineUpdate"
                        className={cx([
                            'global!ace-u-margin--right-24',
                            'global!ace-u-padding--left-64',
                            'global!ace-u-padding--right-64',
                        ])}
                        onClick={declineClosingEstimation}
                    >
                        {translateModal('button_label.cancel')}
                    </Button>
                    <ButtonPrimary
                        name="buttonConfirmUpdate"
                        className={cx('global!ace-u-padding--left-64', 'global!ace-u-padding--right-64')}
                        onClick={confirmClosingEstimation}
                    >
                        {translateModal('button_label.yes')}
                    </ButtonPrimary>
                </div>
            </div>
        </Modal>
    );
};

ClosingEstimationModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    confirmClosingEstimation: PropTypes.func.isRequired,
    declineClosingEstimation: PropTypes.func.isRequired,
};

ClosingEstimationModal.defaultProps = {
    hasBackdrop: true,
};

const mapDispatchToProps = dispatch => {
    return {
        confirmClosingEstimation: () => dispatch({
            type: invoiceActionTypes.CONFIRM_CLOSING_OF_BBZ,
        }),
        declineClosingEstimation: () => dispatch({
            type: invoiceActionTypes.DECLINE_CLOSING_OF_BBZ,
        }),
    };
};

export default connect(null, mapDispatchToProps)(ClosingEstimationModal);
