import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {renderRoutes} from '@computerrock/formation-router';
import {useStyles, AppLayout, AppLayoutFooter} from '@ace-de/ui-components';
import '@ace-de/ui-components/index.module.scss';
import '@arcgis/core/assets/esri/themes/light/main.css';
import config from '../config';

const ApplicationScreen = ({route}) => {
    const {cx} = useStyles();

    return (
        <AppLayout>
            {renderRoutes(route.routes)}
            <AppLayoutFooter>
                <div
                    className={cx([
                        'global!ace-u-typography--variant-body',
                        'global!ace-u-typography--color-disabled-darken',
                        'global!ace-u-pointer-events--auto',
                    ])}
                >
                    Version: {config.APP_VERSION} Build: {config.APP_BUILD}
                </div>
            </AppLayoutFooter>

        </AppLayout>
    );
};

ApplicationScreen.propTypes = {
    route: PropTypes.object.isRequired,
    // someStateValue: PropTypes.object,
};

ApplicationScreen.defaultProps = {
    // no-op
    // someStateValue: null,
};

const mapStateToProps = state => ({
    // no-op
    // someStateValue: state.domain.someStateValue
});

const mapDispatchToProps = dispatch => ({
    // no-op
    // callAction: payload => dispatch({
    //     type: someActionTypes.CALL_ACTION,
    //     payload,
    // }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ApplicationScreen);
