import React, {useState, useEffect, Fragment, useCallback} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {Modal, useStyles, HighlightCircle, LoaderSpinner} from '@ace-de/ui-components';
import {useHistory} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {InteractiveIcon, closeIcon} from '@ace-de/ui-components/icons';
import InvoiceContactSearch from '../ui-elements/InvoiceContactSearch';
import ContactData from '../ui-elements/ContactData';
import BCActionFailed from '../ui-elements/BCActionFailed';
import bcModalsConfig from './bcModalsConfig';
import bcModalContentTypes from './bcModalContentTypes';
import ContactAccountPartyData from '../ui-elements/ContactAccountPartyData';
import SendMessageContent from '../ui-elements/SendMessageContent';
import SuccessMessageContent from '../../application/ui-elements/SuccessMessageContent';
import * as contactActionTypes from '../contactActionTypes';
import * as invoiceSelectors from '../../invoices/invoiceSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';

const BCContactModal = props => {
    const {cx} = useStyles();
    const {closeModal, returnToContactSearch, invoice, searchPersistenceState, serviceCase} = props;
    const history = useHistory();
    const {translate} = useTranslate();
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));
    const contentType = queryParams.get('contentType');
    const modalConfig = bcModalsConfig[contentType];
    const loaderType = queryParams.get('type');
    const isSearchInProgress = searchPersistenceState === persistenceStates.PENDING;

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape' && !isSearchInProgress) {
            if (contentType === bcModalContentTypes.INVOICE_CONTACT_SEARCH) {
                closeModal();
                return;
            }
            returnToContactSearch();
        }
    }, [closeModal, contentType, isSearchInProgress, returnToContactSearch]);


    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    useEffect(() => {
        setQueryParams(new URLSearchParams(history.location.search));
    }, [history.location.search, setQueryParams]);

    if (!contentType || !modalConfig) return null;

    return (
        <Modal
            action={contentType !== bcModalContentTypes.LOADER && contentType !== bcModalContentTypes.BC_ACTION_FAILED
                ? (
                    <InteractiveIcon
                        icon={closeIcon}
                        onClick={contentType === bcModalContentTypes.INVOICE_CONTACT_SEARCH
                            ? closeModal
                            : returnToContactSearch
                        }
                        isDisabled={contentType === bcModalContentTypes.INVOICE_CONTACT_SEARCH && isSearchInProgress}
                    />
                )
                : (<Fragment />)
            }
            title={modalConfig.title
                ? translate(queryParams.get('type') && contentType !== bcModalContentTypes.INVOICE_CONTACT_SEARCH
                    ? `${modalConfig.title}${queryParams.get('type').toLowerCase()}`
                    : modalConfig.title)
                : ''
            }
            hasBackdrop={modalConfig.hasBackdrop}
            hasContentPadding={modalConfig.hasContentPadding}
            hasColoredHeader={modalConfig.hasColoredHeader}
            contentClassName={cx(modalConfig.contentClassName)}
        >
            {contentType === bcModalContentTypes.INVOICE_CONTACT_SEARCH && (
                <InvoiceContactSearch invoice={invoice} />
            )}
            {contentType === bcModalContentTypes.CONTACT_DATA && (
                <ContactData />
            )}
            {contentType === bcModalContentTypes.LOADER && (
                <Fragment>
                    <HighlightCircle
                        className={cx([
                            'ace-c-highlight-circle--medium',
                            'ace-c-highlight-circle--primary-highlight',
                        ])}
                    >
                        <LoaderSpinner />
                    </HighlightCircle>
                    {loaderType && (
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-h2',
                                'global!ace-u-margin--top-32',
                            ])}
                        >
                            {translate(`loader_modal.modal_title.${loaderType.toLowerCase()}`)}
                        </p>
                    )}
                </Fragment>
            )}
            {contentType === bcModalContentTypes.BC_ACTION_FAILED && (
                <BCActionFailed />
            )}
            {contentType === bcModalContentTypes.CONTACT_ACCOUNT_PARTY_DATA && (
                <ContactAccountPartyData invoice={invoice} />
            )}
            {contentType === bcModalContentTypes.COMMUNICATION && (
                <SendMessageContent serviceCase={serviceCase} invoice={invoice} />
            )}
            {contentType === bcModalContentTypes.SUCCESS_MESSAGE && (
                <SuccessMessageContent />
            )}
        </Modal>
    );
};

BCContactModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    returnToContactSearch: PropTypes.func.isRequired,
    invoice: PropTypes.object,
    serviceCase: PropTypes.object,
    searchPersistenceState: PropTypes.string.isRequired,
};

BCContactModal.defaultProps = {
    invoice: null,
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const getInvoice = invoiceSelectors.createInvoicesSelector();
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();

    return {
        invoice: getInvoice(state, props),
        serviceCase: getServiceCase(state, props),
        searchPersistenceState: state.contacts.searchPersistenceState,
    };
};

const mapDispatchToProps = dispatch => ({
    closeModal: payload => dispatch({
        type: contactActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW,
        payload,
    }),
    returnToContactSearch: payload => dispatch({
        type: contactActionTypes.RETURN_TO_CONTACT_SEARCH,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BCContactModal);
