import config from '../config';

const formatCurrencyAmount = (amount, activeLocale, options = {}) => {
    let isValidNumber = true;
    if (!['string', 'number'].includes(typeof amount)) isValidNumber = false;
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) isValidNumber = false;

    const {showCurrency = true, minimumFractionDigits = 2, maximumFractionDigits = 2} = options;
    return (isValidNumber ? parsedAmount : 0).toLocaleString(activeLocale, {
        ...(showCurrency ? {style: 'currency'} : {}),
        ...(showCurrency ? {currency: config.CURRENCY} : {}),
        minimumFractionDigits,
        maximumFractionDigits,
    });
};

export default formatCurrencyAmount;
