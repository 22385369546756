import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {HighlightCircle, Icon, closeIcon, InteractiveIcon, warningIcon, Modal, useStyles} from '@ace-de/ui-components';
import * as applicationActionTypes from '../applicationActionTypes';

const WarningMessageModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, closeModal, location} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('warning_message_modal');
    const warningMessageType = location?.query?.warningMessageType;

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape') closeModal();
    }, [closeModal]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={closeModal}
                />
            )}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-full-width',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-content-center',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'ace-c-highlight-circle--success',
                    ])}
                >
                    <Icon
                        icon={warningIcon}
                        className={cx([
                            'ace-c-icon--xxl',
                            'ace-c-icon--color-primary',
                        ])}
                    />
                </HighlightCircle>
                <h1 className={cx('global!ace-u-margin--24-0')}>
                    {translateModal(`warning_message_header.${snakeCase(warningMessageType)}`)}
                </h1>
                {warningMessageType && typeof warningMessageType === 'string' && (
                    <p className={cx(['global!ace-u-margin--bottom-24', 'global!ace-u-typography--align-center'])}>
                        {translateModal(`warning_message.${snakeCase(warningMessageType)}`)}
                    </p>
                )}
            </div>
        </Modal>
    );
};

WarningMessageModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    location: PropTypes.object,
};

WarningMessageModal.defaultProps = {
    hasBackdrop: true,
    location: null,
};

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch({
        type: applicationActionTypes.DECLINE_WARNING_MESSAGE_FLOW,
        payload: null,
    }),
});

export default connect(null, mapDispatchToProps)(WarningMessageModal);
