import {AccessControlPlugin} from '@ace-de/eua-user-access-control';
import {clientTypes} from '@ace-de/eua-entity-types';
import leaAuthorizationRoles from './application/leaAuthorizationRoles';
import routePaths from './routePaths';

/**
 * Instantiate AccessControlPlugin for LEA auth layer
 *
 * @type {AccessControlPlugin}
 */
const leaAccessControl = new AccessControlPlugin({
    name: 'leaSystem',
    client: clientTypes.LEA_CLIENT,
    systemRoles: leaAuthorizationRoles,
    systemAccessDeniedURI: routePaths.SYSTEM_ACCESS_DENIED,
    routeAccessDeniedURI: routePaths.ROUTE_ACCESS_DENIED,
});

export default leaAccessControl;
