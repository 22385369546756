import {eupUserRoleTypes, clientTypes} from '@ace-de/eua-entity-types';
import {leaFeatureActions, leaFeatures} from './leaFeatures';
import routePaths from '../routePaths';
import scOverviewScreenTabs from '../service-cases/scOverviewScreenTabs';
import invoiceOverviewScreenTabs from '../invoices/invoiceOverviewScreenTabs';

/**
 * LEA system available roles
 */
const leaAuthorizationRoles = {
    [eupUserRoleTypes.AUDITOR]: {
        name: eupUserRoleTypes.AUDITOR,
        features: {
            [leaFeatureActions.CREATE]: [
                leaFeatures.SERVICE_CASE,
                leaFeatures.INVOICE,
                leaFeatures.TASKS,
                leaFeatures.MESSAGE,
                leaFeatures.DOCUMENT,
                leaFeatures.CONTACT,
                leaFeatures.INTERNAL_NOTES,
            ],
            [leaFeatureActions.READ]: [
                leaFeatures.MEMBER_DATA,
                leaFeatures.SERVICE_CASE,
                leaFeatures.INVOICE,
                leaFeatures.MESSAGE,
                leaFeatures.DOCUMENT,
                leaFeatures.TASKS,
                leaFeatures.KPI_INFO,
                leaFeatures.CONTACT,
                leaFeatures.STATUS_INVOICE_IN_CREATION,
                leaFeatures.STATUS_INVOICE_MANUAL_CHECK,
                leaFeatures.STATUS_INVOICE_APPROVED,
                leaFeatures.STATUS_INVOICE_OPEN,
                leaFeatures.STATUS_INVOICE_CLOSED,
                leaFeatures.STATUS_INVOICE_DECLINED,
                leaFeatures.STATUS_INVOICE_PAID,
                leaFeatures.STATUS_INVOICE_BC_ERROR,
                leaFeatures.STATUS_INVOICE_BOOKED,
                leaFeatures.STATUS_CASE_NEW,
                leaFeatures.STATUS_CASE_OPEN,
                leaFeatures.STATUS_CASE_CLOSED,
                leaFeatures.INTERNAL_NOTES,
                leaFeatures.ELLA_NOTIFICATIONS,
            ],
            [leaFeatureActions.UPDATE]: [
                leaFeatures.MEMBER_DATA,
                leaFeatures.CASE_STATUS,
                leaFeatures.INVOICE_STATUS,
                leaFeatures.SERVICE_CASE,
                leaFeatures.INVOICE,
                leaFeatures.TASKS,
                leaFeatures.CONTACT,
                leaFeatures.STATUS_INVOICE_IN_CREATION,
                leaFeatures.STATUS_INVOICE_MANUAL_CHECK,
                leaFeatures.STATUS_INVOICE_APPROVED,
                leaFeatures.STATUS_INVOICE_OPEN,
                leaFeatures.STATUS_INVOICE_CLOSED,
                leaFeatures.STATUS_INVOICE_DECLINED,
                leaFeatures.STATUS_INVOICE_PAID,
                leaFeatures.STATUS_INVOICE_BC_ERROR,
                leaFeatures.STATUS_INVOICE_BOOKED,
                leaFeatures.STATUS_CASE_NEW,
                leaFeatures.STATUS_CASE_OPEN,
                leaFeatures.STATUS_CASE_CLOSED,
                leaFeatures.INVOICE_ACTIONS, // this is for the AC 2 of ACELEA-2012
            ],
            [leaFeatureActions.DELETE]: [
                leaFeatures.SERVICE_CASE,
                leaFeatures.INVOICE,
                leaFeatures.DOCUMENT,
            ],
        },
        systems: [clientTypes.LEA_CLIENT],
        forbiddenRoutes: [],
        allowedRoutes: [],
    },
    [eupUserRoleTypes.LEA_VIEWER]: {
        name: eupUserRoleTypes.LEA_VIEWER,
        features: {
            [leaFeatureActions.CREATE]: [

            ],
            [leaFeatureActions.READ]: [
                leaFeatures.MEMBER_DATA,
                leaFeatures.SERVICE_CASE,
                leaFeatures.INVOICE,
                leaFeatures.MESSAGE,
                leaFeatures.STATUS_INVOICE_APPROVED,
                leaFeatures.STATUS_INVOICE_DECLINED,
                leaFeatures.STATUS_INVOICE_CLOSED,
                leaFeatures.STATUS_INVOICE_PAID,
                leaFeatures.STATUS_INVOICE_BOOKED,
                leaFeatures.STATUS_CASE_OPEN,
                leaFeatures.STATUS_CASE_CLOSED,
            ],
            [leaFeatureActions.UPDATE]: [
                // todo revisit when editing rights story is available
                leaFeatures.STATUS_INVOICE_APPROVED,
                leaFeatures.STATUS_INVOICE_CLOSED,
                leaFeatures.STATUS_INVOICE_DECLINED,
                leaFeatures.STATUS_INVOICE_PAID,
                leaFeatures.STATUS_INVOICE_BOOKED,
                leaFeatures.STATUS_CASE_OPEN,
                leaFeatures.STATUS_CASE_CLOSED,
            ],
            [leaFeatureActions.DELETE]: [

            ],
        },
        systems: [clientTypes.LEA_CLIENT],
        forbiddenRoutes: [
            routePaths.CONTACTS,
            routePaths.TASKS_SEARCH,
            `${routePaths.SERVICE_CASES_OVERVIEW}?activeTab=${scOverviewScreenTabs.DOCUMENT}`,
            `${routePaths.SERVICE_CASES_OVERVIEW}?activeTab=${scOverviewScreenTabs.TASKS}`,
            `${routePaths.INVOICE_OVERVIEW}?activeTab=${invoiceOverviewScreenTabs.TASKS}`,
        ],
        allowedRoutes: [],
    },
};

export default leaAuthorizationRoles;
