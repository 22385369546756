import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {kebabCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {arrowDownIcon, arrowUpIcon, DataRow, InteractiveIcon, Panel, useStyles} from '@ace-de/ui-components';

const MIAFormServicePanel = ({service, isDefaultExpanded = false}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translatePanel = createTranslateShorthand('mia_form_service_panel');
    const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);

    if (!service) return null;

    return (
        <Panel
            title={(
                <div className={cx('global!ace-u-flex')}>
                    <p className={cx('global!ace-u-typography--variant-h2', 'global!ace-u-margin--right-32')}>
                        {translate(`global.service_type.${service.type.toLowerCase()}`)}
                    </p>
                    <InteractiveIcon
                        name={`expend-section-arrow-${kebabCase(service.type.toLowerCase())}`}
                        icon={isExpanded ? arrowUpIcon : arrowDownIcon}
                        onClick={() => setIsExpanded(!isExpanded)}
                    />
                </div>
            )}
        >
            {isExpanded && (
                <div className={cx('global!ace-u-flex', 'global!ace-u-flex--direction-column')}>
                    <div className={cx('global!ace-u-two-column-layout')}>
                        <div>
                            <h2 className={cx('global!ace-u-padding--24-0')}>
                                {translatePanel('subtitle.case_data')}
                            </h2>
                            <DataRow
                                label={translatePanel('data_row_label.type_of_trip')}
                                qaIdent="type-of-trip"
                            >
                                {service.tripPurpose
                                    ? translate(`global.trip_purpose_type.${service.tripPurpose.toLowerCase()}`)
                                    : '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.end_of_repair')}
                                qaIdent="end-of-repair"
                            >
                                {service.endOfRecoveryDateTime || '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.number_of_passengers')}
                                qaIdent="no-of-passengers"
                            >
                                {service.numberOfPassengers || '-'}
                            </DataRow>
                            <h2 className={cx('global!ace-u-padding--24-0')}>
                                {translatePanel('subtitle.insurance')}
                            </h2>
                            <DataRow
                                label={translatePanel('data_row_label.did_insurance_covered_cost')}
                                qaIdent="did-insurance-cover-costs"
                            >
                                {service.isInsuranceCoversCosts
                                    ? translatePanel('did_insurance_covered_cost.yes')
                                    : translatePanel('did_insurance_covered_cost.no')}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.insurance_name')}
                                qaIdent="insurance-name"
                            >
                                {service.insuranceName || '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.are_costs_covered_by_third_parties')}
                                qaIdent="are-costs-covered-by-third-parties"
                            >
                                {service.thirdParty ? (service.thirdParty.isCoveringCosts
                                    ? translatePanel('are_costs_covered_by_third_parties.yes')
                                    : translatePanel('are_costs_covered_by_third_parties.no'))
                                    : '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.costs_covered_by')}
                                qaIdent="costs-covered-by"
                            >
                                {service.thirdParty?.costsCoveredBy || '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.costs_purpose')}
                                qaIdent="costs-purpose"
                            >
                                {service.thirdParty?.costsPurpose || '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.costs_amount')}
                                qaIdent="costs-amount"
                            >
                                {service.thirdParty?.costsAmount || '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.is_drivers_license_valid')}
                                qaIdent="is-drivers-license-valid"
                            >
                                {service.isDriversLicenseValid
                                    ? translatePanel('is_drivers_license_valid.yes')
                                    : translatePanel('is_drivers_license_valid.no')}
                            </DataRow>
                        </div>
                        <div>
                            <h2 className={cx('global!ace-u-padding--24-0')}>
                                {translatePanel('subtitle.police_accident')}
                            </h2>
                            <DataRow
                                label={translatePanel('data_row_label.did_you_had_an_accident')}
                                qaIdent="did-you-had-an-accident"
                            >
                                {service.isAccident
                                    ? translatePanel('did_you_had_an_accident.yes')
                                    : translatePanel('did_you_had_an_accident.no')}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.is_recorded_by_police')}
                                qaIdent="is-recorded-by-police"
                            >
                                {service.isRecordedByPolice
                                    ? translatePanel('is_recorded_by_police.yes')
                                    : translatePanel('is_recorded_by_police.no')}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.police_department')}
                                qaIdent="police-department"
                            >
                                {service.policeDepartment || '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.police_record_number')}
                                qaIdent="police-record-no"
                            >
                                {service.policeRecordNumber || '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.was_the_accident_caused_by_third_party')}
                                qaIdent="accident-caused-by-third-party"
                            >
                                {service.thirdParty ? (
                                    service.thirdParty.isCoveringCosts
                                        ? translatePanel('was_the_accident_caused_by_third_party.yes')
                                        : translatePanel('was_the_accident_caused_by_third_party.no')
                                ) : '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.name')}
                                qaIdent="name"
                            >
                                {service.thirdParty?.name || '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.street')}
                                qaIdent="street"
                            >
                                {service.thirdParty?.address?.street || '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.post_code')}
                                qaIdent="post_code"
                            >
                                {service.thirdParty?.address?.postCode || '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.city')}
                                qaIdent="city"
                            >
                                {service.thirdParty?.address?.city || '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.license_plate')}
                                qaIdent="license-plate"
                            >
                                {service.thirdParty?.licensePlate || '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.insurance_name')}
                                qaIdent="third-party-insurance-name"
                            >
                                {service.thirdParty?.insuranceName || '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.insurance_number')}
                                qaIdent="insurance-no"
                            >
                                {service.thirdParty?.insuranceNumber || '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.damage_number')}
                                qaIdent="damage-no"
                            >
                                {service.thirdParty?.damageNumber || '-'}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.is_alcohol_test_done')}
                                qaIdent="is-alcohol-test-done"
                            >
                                {service.isAlcoholTestDone
                                    ? translatePanel('is_alcohol_test_done.yes')
                                    : translatePanel('is_alcohol_test_done.no')}
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.alcohol_test_result')}
                                qaIdent="alcohol-test-result"
                            >
                                {service.alcoholTestResult || '-'}
                            </DataRow>
                        </div>
                    </div>
                </div>
            )}
        </Panel>
    );
};

MIAFormServicePanel.propTypes = {
    service: PropTypes.object,
    isDefaultExpanded: PropTypes.bool,
};

MIAFormServicePanel.defaultProps = {
    service: null,
    isDefaultExpanded: false,
};

export default MIAFormServicePanel;
