import {delay, fork, put, select, take} from 'redux-saga/effects';
import * as memberActionTypes from '../memberActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';

const fetchMemberBCDetails = function* fetchMemberBCDetails() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(memberActionTypes.FETCH_MEMBER_BC_DETAILS);
        const {searchQueryParams} = payload;

        yield fork(
            fetchRequest,
            memberActionTypes.FETCH_MEMBER_BC_DETAILS_REQUEST,
            leaAssignmentFlowService.getContacts,
            {searchQueryParams},
        );

        yield put({
            type: memberActionTypes.SET_ARE_MEMBER_BC_DETAILS_LOADING,
            payload: {areMemberBCDetailsLoading: true},
        });

        const responseAction = yield take([
            memberActionTypes.FETCH_MEMBER_BC_DETAILS_REQUEST_SUCCEEDED,
            memberActionTypes.FETCH_MEMBER_BC_DETAILS_REQUEST_FAILED,
        ]);

        yield delay(1300);
        yield put({
            type: memberActionTypes.SET_ARE_MEMBER_BC_DETAILS_LOADING,
            payload: {areMemberBCDetailsLoading: false},
        });

        if (!responseAction.error) {
            const {payload: searchResultsPayload} = responseAction;
            const {contactDTOs} = searchResultsPayload.response;

            yield put({
                type: memberActionTypes.STORE_MEMBER_BC_DETAILS,
                payload: {memberBCDetails: contactDTOs[0]},
            });

            yield put({
                type: memberActionTypes.SET_IS_MEMBER_BC_DETAILS_ERROR,
                payload: {isMemberBCDetailsError: false},
            });
            continue;
        }
        yield put({
            type: memberActionTypes.SET_IS_MEMBER_BC_DETAILS_ERROR,
            payload: {isMemberBCDetailsError: true},
        });
    }
};

export default fetchMemberBCDetails;
