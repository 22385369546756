import {select, fork, take, put} from 'redux-saga/effects';
import {ehmCaseLogActionTypes, ehmCaseLogCategoryTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceActionTypes from '../invoiceActionTypes';

/**
 * Fetch invoice read only approval data
 */
const fetchInvoiceReadOnlyApprovalData = function* fetchInvoiceReadOnlyApprovalData({payload}) {
    const {invoiceId, serviceCaseId} = payload;

    const {serviceManager} = yield select(state => state.application);
    const ecsHistoryManagementService = serviceManager.loadService('ecsHistoryManagementService');

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('category', ehmCaseLogCategoryTypes.INVOICE);
    searchQueryParams.append('actionTypes', ehmCaseLogActionTypes.INVOICE_APPROVED);
    searchQueryParams.append('sort', 'eventTime,desc');
    searchQueryParams.append('sort', 'eventDate,desc');

    yield fork(
        fetchRequest,
        invoiceActionTypes.FETCH_INVOICE_READ_ONLY_APPROVAL_DATA_REQUEST,
        ecsHistoryManagementService.filterCaseLogs,
        {
            serviceCaseId,
            searchQueryParams,
        },
    );

    const responseAction = yield take([
        invoiceActionTypes.FETCH_INVOICE_READ_ONLY_APPROVAL_DATA_REQUEST_SUCCEEDED,
        invoiceActionTypes.FETCH_INVOICE_READ_ONLY_APPROVAL_DATA_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {caseLogFilterResults} = response;
        if (caseLogFilterResults.length > 0) {
            const latestLog = caseLogFilterResults.find(caseLogFilterResult => {
                return caseLogFilterResult.invoiceId === invoiceId;
            });

            if (latestLog) {
                const {users} = yield select(state => state.user);
                yield put({
                    type: invoiceActionTypes.STORE_INVOICE_READ_ONLY_APPROVAL_DATA,
                    payload: {
                        invoiceId,
                        approvedBy: latestLog.subjectDetails.id ? (latestLog.subjectDetails?.name
                                || `${users[latestLog.subjectDetails.id]?.firstName} ${users[latestLog.subjectDetails.id]?.lastName}`)
                            : null,
                        approvedAt: latestLog.eventDate || '',
                    },
                });
            }
        }
    }
};

export default fetchInvoiceReadOnlyApprovalData;
