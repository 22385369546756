import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {FileAssetsDropZone, FileAssetsInput, useFileAssets} from '@ace-de/eua-file-assets';
import {ButtonPrimary, HighlightCircle, Modal, useStyles} from '@ace-de/ui-components';
import {InteractiveIcon, Icon, closeIcon, uploadIcon, plusIcon, infoAlertIcon, saveIcon, photoIcon, deleteIcon} from '@ace-de/ui-components/icons';
import {Form, Option, SelectField, InputField} from '@ace-de/ui-components/form';
import {alfAttachmentTypes} from '@ace-de/eua-entity-types';
import config from '../../config';
import allowedAttachmentMIMETypes from '../../invoices/allowedAttachmentMIMETypes';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as invoiceSelectors from '../../invoices/invoiceSelectors';
import styles from './SCDocumentUploadModal.module.scss';

const SCDocumentUploadModal = props => {
    const {cx} = useStyles(props, styles);
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('sc_document_upload_modal');
    const {hasBackdrop} = props;
    const {declineDocumentUpload, confirmDocumentUpload, serviceCaseInvoices} = props;
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const {configure: configureFileAssetsService, fileList, deleteFile} = useFileAssets({
        service: 'alfFileAssetsService',
        fileList: 'invoice-documents',
    });

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape') declineDocumentUpload();
    }, [declineDocumentUpload]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    useEffect(() => {
        configureFileAssetsService({
            maxFileSize: config.MAXIMUM_FILE_ASSETS_FILE_SIZE_BYTES,
            allowedMIMETypes: allowedAttachmentMIMETypes,
        });
    }, [configureFileAssetsService]);

    const handleOnSubmit = formValues => {
        const fileListAssets = [...fileList.fileAssets.values()];
        confirmDocumentUpload({
            attachments: Object.entries(formValues).map(([key, value]) => {
                if (!value || !fileListAssets.find(fileAsset => (
                    fileAsset.id === key
                ))) return null;
                return {
                    ...value,
                    id: key,
                    fileName: fileList.fileAssets.get(key).fileName,
                };
            }).filter(value => !!value),
        });
    };

    const validateFormFields = (formValues, deletedFileAssetId = null) => {
        if (Object.values(formValues).length === 0) return;
        const fileListAssets = [...fileList.fileAssets.values()];
        let isSubmitButtonDisabled = false;

        Object.entries(formValues).forEach(([key, value]) => {
            if (!value || !fileListAssets.find(fileAsset => (
                fileAsset.id === key
            )) || deletedFileAssetId === key) {
                return;
            }
            if (!value.description || !value.invoiceId) isSubmitButtonDisabled = true;
        });
        setIsSubmitDisabled(isSubmitButtonDisabled);
    };

    const handleOnDeleteFile = (fileAsset, formValues) => {
        deleteFile(
            fileAsset,
            config.SHOULD_SEND_DOCUMENT_DELETE_REQUEST,
        );
        validateFormFields(formValues, fileAsset.id);
    };

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineDocumentUpload}
                />
            )}
            hasBackdrop={hasBackdrop}
            hasColoredHeader={true}
            title={translateModal('modal_title.document_upload')}
            contentClassName={cx([
                'ace-c-modal__content--scrollable',
                'global!ace-u-modal-content-size--m',
            ])}
        >
            <FileAssetsDropZone
                service="alfFileAssetsService"
                fileList="invoice-documents"
                className={cx([
                    'global!ace-u-padding--32',
                    'global!ace-u-margin--32-256',
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'global!ace-u-margin--right-32',
                        'ace-c-highlight-circle--medium',
                        'ace-c-highlight-circle--primary-highlight',
                    ])}
                >
                    <Icon
                        icon={uploadIcon}
                        className={cx([
                            'ace-c-icon--xxl',
                            'ace-c-icon--color-highlight',
                        ])}
                    />
                </HighlightCircle>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <h3 className={cx('global!ace-u-typography--variant-h3')}>
                        {translateModal('title.document_upload')}
                    </h3>
                    <FileAssetsInput
                        service="alfFileAssetsService"
                        fileList="invoice-documents"
                        className={cx([
                            'global!ace-u-margin--16-0',
                            'ace-c-file-input--no-chrome',
                        ])}
                    >
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-typography--variant-highlight',
                                'global!ace-u-typography--color-highlighted',
                                'global!ace-u-cursor--pointer',
                            ])}
                        >
                            <Icon
                                icon={plusIcon}
                                className={cx([
                                    'global!ace-u-margin--right-8',
                                    'ace-c-icon--color-highlight',
                                ])}
                            />
                            {translateModal('label.search_documents')}
                        </div>
                    </FileAssetsInput>
                </div>
            </FileAssetsDropZone>
            {fileList.errors.length > 0 && (
                <div className={cx('global!ace-u-margin--8-0')}>
                    {fileList.errors.map((errorInfo, index) => {
                        return (
                            <div
                                key={index}
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-margin--4-0',
                                    'global!ace-u-typography--variant-caption',
                                    'global!ace-u-typography--color-warning',
                                ])}
                            >
                                <Icon
                                    icon={infoAlertIcon}
                                    className={cx([
                                        'global!ace-u-margin--right-4',
                                        'ace-c-icon--s',
                                        'ace-c-icon--color-warning',
                                    ])}
                                />
                                {translateModal('upload_error.message')}
                            </div>
                        );
                    })}
                </div>
            )}
            <div className={cx('global!ace-u-width--full')}>
                <Form name="fileAssetsForm" onChange={validateFormFields} onSubmit={handleOnSubmit}>
                    {formValues => (
                        <>
                            {[...fileList.fileAssets.values()].length > 0
                                && [...fileList.fileAssets.values()].map(fileAsset => (
                                    <div
                                        key={fileAsset.id}
                                        className={cx([
                                            'global!ace-u-margin--bottom-24',
                                            'global!ace-u-width--full',
                                            'global!ace-u-grid',
                                        ])}
                                    >
                                        <Form name={fileAsset.id}>
                                            <div
                                                className={cx([
                                                    'global!ace-u-padding--16',
                                                    'global!ace-u-margin--top-16',
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--align-center',
                                                    'global!ace-u-flex--justify-space-between',
                                                    'global!ace-u-grid-column--span-3',
                                                    'global!ace-u-box-skin--pending',
                                                ])}
                                            >
                                                <div
                                                    className={cx([
                                                        'ace-c-sc-document-upload-modal__file-name',
                                                    ])}
                                                >
                                                    <Icon icon={photoIcon} className={cx('global!ace-u-margin--right-4')} />
                                                    {fileAsset.fileName}
                                                </div>
                                                <Icon
                                                    onClick={() => handleOnDeleteFile(fileAsset, formValues)}
                                                    icon={deleteIcon}
                                                    className={cx([
                                                        'ace-c-icon--l',
                                                        'ace-c-icon--color-medium-emphasis',
                                                        'global!ace-u-flex--justify-self-end',
                                                    ])}
                                                />
                                            </div>
                                            <InputField
                                                name="description"
                                                className={cx('global!ace-u-grid-column--span-3')}
                                                label={`${translateModal('input_label.name')}*`}
                                                value={formValues?.description || fileAsset.fileName?.split('.')[0]}
                                            />
                                            <SelectField
                                                name="attachmentType"
                                                className={cx('global!ace-u-grid-column--span-3')}
                                                label={translateModal('input_label.type')}
                                                value={formValues?.attachmentType || fileAsset.attachmentType}
                                            >
                                                {Object.values(alfAttachmentTypes).map(fileType => (
                                                    <Option
                                                        key={fileType}
                                                        name={fileType}
                                                        value={fileType}
                                                    >
                                                        {translate(`global.document_type.${fileType.toLowerCase()}`)}
                                                    </Option>
                                                ))}
                                            </SelectField>
                                            <SelectField
                                                name="invoiceId"
                                                className={cx('global!ace-u-grid-column--span-3')}
                                                label={`${translateModal('input_label.invoice')}*`}
                                                value={formValues?.invoiceId || fileAsset.invoiceId}
                                            >
                                                {serviceCaseInvoices.map(invoice => (
                                                    <Option
                                                        key={invoice.id}
                                                        name={`invoice-${invoice.id}`}
                                                        value={invoice.id}
                                                    >
                                                        {invoice.id}
                                                    </Option>
                                                ))}
                                            </SelectField>
                                        </Form>
                                    </div>
                                ))}
                            <div
                                className={cx([
                                    'global!ace-u-width--full',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--justify-center',
                                ])}
                            >
                                <ButtonPrimary
                                    name="confirm-invoice-document-upload"
                                    isDisabled={isSubmitDisabled || [...fileList.fileAssets.values()].length === 0}
                                    className={cx('global!ace-u-margin--top-32')}
                                    type="submit"
                                >
                                    <Icon
                                        icon={saveIcon}
                                        className={cx(['ace-c-icon--color-contrast', 'global!ace-u-margin--right-8'])}
                                    />
                                    {translateModal('button_label.save')}
                                </ButtonPrimary>
                            </div>
                        </>
                    )}
                </Form>
            </div>
        </Modal>
    );
};

SCDocumentUploadModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    declineDocumentUpload: PropTypes.func.isRequired,
    confirmDocumentUpload: PropTypes.func.isRequired,
    serviceCaseInvoices: PropTypes.array,
};

SCDocumentUploadModal.defaultProps = {
    hasBackdrop: false,
    serviceCaseInvoices: [],
};

const mapStateToProps = (state, props) => {
    const serviceCaseInvoicesSelector = invoiceSelectors.createServiceCaseInvoicesSelector();

    return {
        serviceCaseInvoices: serviceCaseInvoicesSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineDocumentUpload: () => dispatch({
        type: serviceCaseActionTypes.DECLINE_SERVICE_CASE_DOCUMENT_UPLOAD,
    }),
    confirmDocumentUpload: payload => dispatch({
        type: serviceCaseActionTypes.CONFIRM_SERVICE_CASE_DOCUMENT_UPLOAD,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SCDocumentUploadModal);
