import {take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import modalIds from '../../modalIds';
import updateServiceCase from './updateServiceCase';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

/**
 * Service case vehicle selection flow (uses modal)
 * patches the selected vehicle snapshot on a case
 */
const selectServiceCaseVehicleFlow = function* selectServiceCaseVehicleFlow() {
    while (true) {
        const {payload} = yield take(serviceCaseActionTypes.INITIATE_SERVICE_CASE_VEHICLE_SELECTION_FLOW);
        const {serviceCaseData, serviceCaseId} = payload;

        yield* openModal(modalIds.CONFIRM_VEHICLE_SELECTION_MODAL);

        const chosenModalOption = yield take([
            serviceCaseActionTypes.CONFIRM_SERVICE_CASE_VEHICLE_SELECTION,
            serviceCaseActionTypes.DECLINE_SERVICE_CASE_VEHICLE_SELECTION,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === serviceCaseActionTypes.CONFIRM_SERVICE_CASE_VEHICLE_SELECTION) {
            yield* updateServiceCase({
                caller: serviceCaseActionTypes.CONFIRM_SERVICE_CASE_VEHICLE_SELECTION,
                serviceCaseId,
                serviceCaseData,
            });
        }

        yield* closeModal(modalIds.CONFIRM_VEHICLE_SELECTION_MODAL);
    }
};

export default selectServiceCaseVehicleFlow;
