import {take, select, call, delay, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceActionTypes from '../invoiceActionTypes';

const drawRoutesAndPolygons = function* drawRoutesAndPolygons() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    while (true) {
        const {payload} = yield take(invoiceActionTypes.DRAW_MAP_ROUTES_AND_POLYGONS);
        const {invoiceId} = payload;
        const {invoices} = yield select(state => state.invoices);
        const {roundTripRoute, polygonId, filterQuery} = invoices[invoiceId];
        const arcGISMap = yield call(arcGISMapService.getMap, 'invoice-relevant-locations-map-expand');
        yield delay(700);
        if (arcGISMap) {
            const routeLayer = yield call(arcGISMap.getLayer, 'invoice-relevant-routes');
            const invoiceRelevantLocationsServiceAreas = yield call(arcGISMap.getLayer, 'invoice-relevant-locations-service-areas-expanded');
            if (routeLayer && roundTripRoute) {
                const selectedTowingDestinationId = 1;
                yield call(routeLayer.setFeatures,
                    {
                        features: [
                            {
                                attributes: {
                                    locationId: selectedTowingDestinationId,
                                    FID: 1,
                                    establishmentType: undefined,
                                    arcGISPoint: undefined,
                                    arcGISExtent: undefined,
                                    openingHours: undefined,
                                    routeToReferentialPoint: undefined,
                                },
                                geometry: roundTripRoute.geometry,
                            },
                        ],
                    });
                yield call(routeLayer.selectFeatureByAttribute, {
                    where: `locationId = '${selectedTowingDestinationId}'`,
                });
                routeLayer.show();
            }

            if (invoiceRelevantLocationsServiceAreas) {
                let hasPolygon = false;
                if (filterQuery) {
                    yield fork(
                        fetchRequest,
                        invoiceActionTypes.FILTER_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST,
                        invoiceRelevantLocationsServiceAreas.filterFeaturesByAttribute,
                        filterQuery,
                    );

                    const filterContractPartnerServiceAreasResponseAction = yield take([
                        invoiceActionTypes.FILTER_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SUCCEEDED,
                        invoiceActionTypes.FILTER_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_FAILED,
                    ]);

                    if (!filterContractPartnerServiceAreasResponseAction.error) {
                        const {response} = filterContractPartnerServiceAreasResponseAction.payload;
                        const {featureDTOs} = response;

                        if (featureDTOs.length > 0) {
                            hasPolygon = true;
                        }
                    }
                }

                if (hasPolygon && polygonId) {
                    yield call(invoiceRelevantLocationsServiceAreas.selectFeatureByAttribute, {
                        where: `contractPa = '${polygonId}'`,
                    });
                    invoiceRelevantLocationsServiceAreas.show();
                }
            }
        }
    }
};
export default drawRoutesAndPolygons;
