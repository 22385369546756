/**
 * Command action types
 */

/**
 * Event action types
 */
export const FETCH_REPORTER_TASK_AMOUNT_REQUEST = '[dashboard] FETCH_REPORTER_TASK_AMOUNT_REQUEST';
export const FETCH_REPORTER_TASK_AMOUNT_REQUEST_SENT = '[dashboard] FETCH_REPORTER_TASK_AMOUNT_REQUEST_SENT';
export const FETCH_REPORTER_TASK_AMOUNT_REQUEST_SUCCEEDED = '[dashboard] FETCH_REPORTER_TASK_AMOUNT_REQUEST_SUCCEEDED';
export const FETCH_REPORTER_TASK_AMOUNT_REQUEST_FAILED = '[dashboard] FETCH_REPORTER_TASK_AMOUNT_REQUEST_FAILED';

export const FETCH_REPORTER_INVOICES_AMOUNT_REQUEST = '[dashboard] FETCH_REPORTER_INVOICES_AMOUNT_REQUEST';
export const FETCH_REPORTER_INVOICES_AMOUNT_REQUEST_SENT = '[dashboard] FETCH_REPORTER_INVOICES_AMOUNT_REQUEST_SENT';
export const FETCH_REPORTER_INVOICES_AMOUNT_REQUEST_SUCCEEDED = '[dashboard] FETCH_REPORTER_INVOICES_AMOUNT_REQUEST_SUCCEEDED';
export const FETCH_REPORTER_INVOICES_AMOUNT_REQUEST_FAILED = '[dashboard] FETCH_REPORTER_INVOICES_AMOUNT_REQUEST_FAILED';

export const FETCH_CURRENT_USERS_TASKS_REQUEST = '[dashboard] FETCH_CURRENT_USERS_TASKS_REQUEST';
export const FETCH_CURRENT_USERS_TASKS_REQUEST_SENT = '[dashboard] FETCH_CURRENT_USERS_TASKS_REQUEST_SENT';
export const FETCH_CURRENT_USERS_TASKS_REQUEST_SUCCEEDED = '[dashboard] FETCH_CURRENT_USERS_TASKS_REQUEST_SUCCEEDED';
export const FETCH_CURRENT_USERS_TASKS_REQUEST_FAILED = '[dashboard] FETCH_CURRENT_USERS_TASKS_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_REPORTER_TASKS_AMOUNT = '[dashboard] STORE_REPORTER_TASKS_AMOUNT';
export const STORE_REPORTER_INVOICES_AMOUNT = '[dashboard] STORE_REPORTER_INVOICES_AMOUNT';
export const SET_REPORTER_INVOICES_PERSISTENCE_STATE = '[dashboard] SET_REPORTER_INVOICES_PERSISTENCE_STATE';
export const SET_REPORTER_TASKS_PERSISTENCE_STATE = '[dashboard] SET_REPORTER_TASKS_PERSISTENCE_STATE';
