import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {alfDeclineReasonTypes} from '@ace-de/eua-entity-types';
import {useStyles, Modal, HighlightCircle, TextAreaField, Button, ButtonPrimary, SelectField, Option} from '@ace-de/ui-components';
import {Icon, warningIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import config from '../../config';
import * as invoiceActionTypes from '../invoiceActionTypes';

const InvoiceDeclineModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, confirmInvoiceDecline, declineInvoiceDecline} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('invoice_decline_modal');
    const [declineReason, setDeclineReason] = useState('');
    const [declineDescription, setDeclineDescription] = useState('');

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape') declineInvoiceDecline();
    }, [declineInvoiceDecline]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            contentClassName={cx([
                'global!ace-u-padding--left-64',
                'global!ace-u-padding--right-64',
            ])}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-full-width',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'ace-c-highlight-circle--primary-highlight',
                        'global!ace-u-margin--bottom-24',
                    ])}
                >
                    <Icon
                        icon={warningIcon}
                        className={cx(['ace-c-icon--xl', 'global!ace-c-icon--color-highlight'])}
                    />
                </HighlightCircle>
                <p className={cx('global!ace-u-typography--variant-h2', 'global!ace-u-margin--bottom-16')}>
                    {translateModal('modal_title.justify_rejection')}
                </p>
                <p className={cx('global!ace-u-margin--bottom-16')}>
                    {translateModal('modal_description.provide_details')}
                </p>
                <SelectField
                    className={cx(['global!ace-u-full-width', 'global!ace-u-margin--bottom-48'])}
                    name="declineReason"
                    label={translateModal('select_field_label.decline_category')}
                    placeholder={translateModal('select_field_placeholder.please_select')}
                    value={declineReason}
                    onChange={setDeclineReason}
                >
                    {Object.keys(alfDeclineReasonTypes).map(alfDeclineReasonType => {
                        return (
                            <Option
                                name={`${alfDeclineReasonType}Option`}
                                value={alfDeclineReasonType}
                                key={alfDeclineReasonType}
                            >
                                {translate(`global.invoice_decline_reason.${alfDeclineReasonType.toLowerCase()}`)}
                            </Option>
                        );
                    })}
                </SelectField>
                <TextAreaField
                    placeholder={translateModal('textarea_placeholder.please_elaborate')}
                    label={translateModal('textarea_label.rejection_reason')}
                    className={cx('global!ace-u-margin--bottom-24')}
                    isResizable={false}
                    maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                    cols={70}
                    name="declineDescription"
                    value={declineDescription}
                    onChange={setDeclineDescription}
                />
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-row',
                        'global!ace-u-flex--justify-center',
                        'global!ace-u-full-width',
                    ])}
                >
                    <Button
                        name="buttonDeclineUpdate"
                        className={cx([
                            'global!ace-u-margin--right-24',
                            'global!ace-u-padding--left-64',
                            'global!ace-u-padding--right-64',
                        ])}
                        onClick={declineInvoiceDecline}
                    >
                        {translateModal('button_label.cancel')}
                    </Button>
                    <ButtonPrimary
                        name="buttonConfirmUpdate"
                        className={cx('global!ace-u-padding--left-64', 'global!ace-u-padding--right-64')}
                        isDisabled={!declineReason}
                        onClick={() => {
                            confirmInvoiceDecline({
                                declineReason,
                                declineDescription,
                            });
                        }}
                    >
                        {translateModal('button_label.confirm')}
                    </ButtonPrimary>
                </div>
            </div>
        </Modal>
    );
};

InvoiceDeclineModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    confirmInvoiceDecline: PropTypes.func.isRequired,
    declineInvoiceDecline: PropTypes.func.isRequired,
};

InvoiceDeclineModal.defaultProps = {
    hasBackdrop: true,
};

const mapDispatchToProps = dispatch => {
    return {
        confirmInvoiceDecline: payload => dispatch({
            type: invoiceActionTypes.CONFIRM_INVOICE_DECLINE_FLOW,
            payload,
        }),
        declineInvoiceDecline: () => dispatch({
            type: invoiceActionTypes.DECLINE_INVOICE_DECLINE_FLOW,
            payload: {},
        }),
    };
};

export default connect(null, mapDispatchToProps)(InvoiceDeclineModal);
