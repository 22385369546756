import React from 'react';

export default React.createContext({
    isSearchRouteActive: false,
    searchQueryString: '',
    areSearchResultsValid: true,
    setSearchQueryString: () => undefined,
    searchResults: null,
    searchResultsCount: null,
    setSearchResultsPage: () => undefined,
    searchResultsPrivate: null,
    searchResultsCountPrivate: null,
    setSearchResultsPagePrivate: () => undefined,
    searchResultsCorporate: null,
    searchResultsCountCorporate: null,
    setSearchResultsPageCorporate: () => undefined,
});
