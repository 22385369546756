import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceActionTypes from '../invoiceActionTypes';

const searchInvoices = function* searchInvoices({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    const {searchQueryParams} = payload;

    yield fork(fetchRequest,
        invoiceActionTypes.SEARCH_INVOICES_REQUEST,
        leaAssignmentFlowService.getInvoices, {
            searchQueryParams,
        });

    const searchInvoicesResponseAction = yield take([
        invoiceActionTypes.SEARCH_INVOICES_REQUEST_SUCCEEDED,
        invoiceActionTypes.SEARCH_INVOICES_REQUEST_FAILED,
    ]);

    if (!searchInvoicesResponseAction.error) {
        const {response} = searchInvoicesResponseAction.payload;
        const {invoiceDTOs, totalCount} = response;

        yield put({
            type: invoiceActionTypes.STORE_INVOICES_SEARCH_RESULTS,
            payload: {invoiceDTOs, totalCount},
        });
    }
};

export default searchInvoices;
