/**
 * @typedef {string} tabId
 */

/**
 * Dashboard screen tabs
 *
 * @enum {tabId}
 */
export default {
    DASHBOARD: 'dashboard',
    INVOICES: 'invoices',
    SERVICE_CASES: 'service-cases',
    TASKS: 'tasks',
    CONTACT: 'contact',
};
