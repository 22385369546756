import {take, put, select} from 'redux-saga/effects';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import leaAccessControl from '../../leaAccessControl';
import {leaFeatureActions, leaFeatures} from '../../application/leaFeatures';

const updateServiceCase = function* updateServiceCase(params) {
    const {caller, serviceCaseId, serviceCaseData} = params;
    const serviceCase = yield select(state => state.serviceCases.serviceCases[serviceCaseId]);

    const isServiceCaseUpdateAvailable = leaAccessControl.grantFeatureAccess(
        leaFeatures.SERVICE_CASE,
        leaFeatureActions.UPDATE,
    );

    if (!serviceCase || !isServiceCaseUpdateAvailable) return;

    yield put({
        type: serviceCaseActionTypes.BATCH_UPDATE_SERVICE_CASE,
        payload: {serviceCaseId, serviceCaseData},
        meta: {caller},
    });

    let batchUpdateServiceCaseResponse;
    while (true) {
        const batchUpdateServiceCaseAction = yield take(
            serviceCaseActionTypes.BATCH_UPDATE_SERVICE_CASE_COMPLETED,
        );
        const {meta = {}} = batchUpdateServiceCaseAction;

        if (typeof meta.caller !== 'undefined' && meta.caller !== caller) continue;
        batchUpdateServiceCaseResponse = batchUpdateServiceCaseAction;
        break;
    }

    return batchUpdateServiceCaseResponse;
};
export default updateServiceCase;
