import {put, select, take, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as caseLogActionTypes from '../caseLogActionTypes';

/**
 * Filter case logs
 */
const filterCaseLogs = function* filterCaseLogs({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const ecsHistoryManagementService = serviceManager.loadService('ecsHistoryManagementService');
    const {searchQueryParams, serviceCaseId} = payload;

    yield fork(fetchRequest,
        caseLogActionTypes.FILTER_CASE_LOGS_REQUEST,
        ecsHistoryManagementService.filterCaseLogs, {
            serviceCaseId,
            searchQueryParams,
        });

    const filterCaseLogsResponseAction = yield take([
        caseLogActionTypes.FILTER_CASE_LOGS_REQUEST_SUCCEEDED,
        caseLogActionTypes.FILTER_CASE_LOGS_REQUEST_FAILED,
    ]);

    if (!filterCaseLogsResponseAction.error) {
        const {response} = filterCaseLogsResponseAction.payload;
        const {
            caseLogFilterResults,
            totalCount: caseLogFilterResultsCount,
            pageNumber,
        } = response;

        yield put({
            type: caseLogActionTypes.STORE_CASE_LOGS,
            payload: {caseLogFilterResults, caseLogFilterResultsCount, pageNumber},
        });
    }
};

export default filterCaseLogs;
