import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, useHistory, resolveRoute, useRouterState, matchPath} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Option} from '@ace-de/ui-components';
import {ContentBlock, ContentItem} from '@ace-de/ui-components/app-layout';
import {Tab, TabBar} from '@ace-de/ui-components/navigation';
import routePaths from '../../routePaths';
import keycloakAuth from '../../keycloakAuth';
import dashboardScreenTabs from '../dashboardScreenTabs';
import LEALogo from './LEALogo';
import AvatarMenu from './AvatarMenu';
import NotificationBar from './NotificationBar';
import leaAccessControl from '../../leaAccessControl';
import {leaFeatureActions, leaFeatures} from '../../application/leaFeatures';

const NavigationBarContent = props => {
    const {cx} = useStyles();
    const history = useHistory();
    const {translate} = useTranslate();
    const {activeRoutePath} = useRouterState();
    const {signOutUser, user} = props;

    const handleOnSelect = value => {
        if (value === 'logout') {
            signOutUser({});
        }
    };

    const navigateToRoute = routePath => {
        const {location} = history;
        const matchedPath = matchPath(location.pathname, {path: routePath});

        if (!routePath || matchedPath?.isExact) return;
        // other routes
        history.push(resolveRoute(routePath));
    };

    const areTasksAvailable = leaAccessControl.grantFeatureAccess(leaFeatures.TASKS, leaFeatureActions.READ);
    const areCasesAvailable = leaAccessControl.grantFeatureAccess(leaFeatures.SERVICE_CASE, leaFeatureActions.READ);
    const areInvoicesAvailable = leaAccessControl.grantFeatureAccess(leaFeatures.INVOICE, leaFeatureActions.READ);
    const areContactsAvailable = leaAccessControl.grantFeatureAccess(leaFeatures.CONTACT, leaFeatureActions.READ);
    const areNotificationsAvailable = leaAccessControl.grantFeatureAccess(
        leaFeatures.ELLA_NOTIFICATIONS,
        leaFeatureActions.READ,
    );

    return (
        <ContentBlock
            className={cx([
                'ace-c-content-block',
                'global!ace-u-flex',
                'global!ace-u-flex--align-center',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--large-span-10',
                    'ace-c-content-item--x-large-span-10',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <Link to={resolveRoute(routePaths.DASHBOARD)}>
                    <LEALogo />
                </Link>
                <TabBar
                    name="dashboardTabs"
                    value={activeRoutePath}
                    onChange={navigateToRoute}
                >
                    <Tab
                        name={dashboardScreenTabs.DASHBOARD}
                        value={routePaths.DASHBOARD_SECTION}
                    >
                        {translate('navigation_bar.tab_name.dashboard')}
                    </Tab>
                    {areInvoicesAvailable && (
                        <Tab
                            name={dashboardScreenTabs.INVOICES}
                            value={routePaths.INVOICES_SEARCH}
                        >
                            {translate('navigation_bar.tab_name.invoices')}
                        </Tab>
                    )}
                    {areCasesAvailable && (
                        <Tab
                            name={dashboardScreenTabs.SERVICE_CASES}
                            value={routePaths.SERVICE_CASES_SEARCH}
                        >
                            {translate('navigation_bar.tab_name.cases')}
                        </Tab>
                    )}
                    {areTasksAvailable && (
                        <Tab
                            name={dashboardScreenTabs.TASKS}
                            value={routePaths.TASKS_SEARCH}
                        >
                            {translate('navigation_bar.tab_name.tasks')}
                        </Tab>
                    )}
                    {areContactsAvailable && (
                        <Tab
                            name={dashboardScreenTabs.CONTACT}
                            value={routePaths.CONTACTS}
                        >
                            {translate('navigation_bar.tab_name.contact')}
                        </Tab>
                    )}
                </TabBar>
            </ContentItem>
            <ContentItem
                className={cx([
                    'ace-c-content-item--large-span-2',
                    'ace-c-content-item--x-large-span-2',
                    'global!ace-u-flex',
                    {'global!ace-u-flex--justify-space-between': areNotificationsAvailable},
                    {'global!ace-u-flex--justify-flex-end': !areNotificationsAvailable},
                    'global!ace-u-flex--align-center',
                ])}
            >
                {areNotificationsAvailable && (
                    <NotificationBar />
                )}
                <AvatarMenu
                    userImage=""
                    userName={user?.initials || 'NBS'}
                    className="ace-c-avatar--small"
                    onSelect={handleOnSelect}
                >
                    <Option
                        name="avatarOptionLogout"
                        value="logout"
                    >
                        {translate('navigation_bar.avatar_menu_option.log_out')}
                    </Option>
                </AvatarMenu>
            </ContentItem>
        </ContentBlock>
    );
};

NavigationBarContent.propTypes = {
    signOutUser: PropTypes.func,
    user: PropTypes.object,
};

NavigationBarContent.defaultProps = {
    signOutUser: () => null,
    user: null,
};

const mapStateToProps = state => ({
    user: state.user.user,
});

const mapDispatchToProps = dispatch => ({
    signOutUser: () => dispatch(keycloakAuth.signOutUserAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBarContent);
